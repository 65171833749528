import { EyeIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { NextresDocument } from 'pages/Loan'
import { useEffect, useMemo, useState } from 'react'
import { getTrustLedger, openS3Document } from 'services/apis'
import { Accordion } from 'stories/components'
import { normalizeNumber } from 'utils'

import type { LoanDocument } from './types'

const TrustLedgerLables: Record<string, string> = {
  // reference: 'Reference',
  // fromWhom: 'From Whom Received/Paid',
  eligibleAmount: 'Eligible Amount',
  netEligibleAmount: 'Net Eligible Amount',
  recommendedDisbursement: 'Max Eligible Disbursement',
  previouslyDisbursed: 'Previously Disbursed',
  payment: 'Draw Issued',
  // deposit: 'Deposit',
  fee: 'Fee',
  balance: 'Construction Balance',
  date: 'Date',
}

export const ConstructionLedger = ({ loanNumber, documents }: { loanNumber: number; documents: LoanDocument[] }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [rehabBudget, setRehabBudget] = useState(-1)
  const [constructionReserve, setConstructionReserve] = useState(-1)
  const [interestReserve, setInterestReserve] = useState(-1)
  const [rehabBudgetData, setRehabBudgetData] = useState<Record<string, any>>()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getTrustLedger(loanNumber)

      if (res.success !== undefined && res.message) {
        setData([])
      } else {
        setData(res.data)
        setRehabBudget(res.rehabBudget)
        setConstructionReserve(res.constructionReserve)
        setInterestReserve(res.interestReserve)
      }
      setLoading(false)
    })()
  }, [loanNumber])

  useEffect(() => {
    const rehabBudgetData: Record<string, any> = {}

    if (rehabBudget !== -1) {
      rehabBudgetData['Rehab Budget'] = `$ ${normalizeNumber(rehabBudget, 2)}`
      rehabBudgetData['Construction Reserve'] = `$ ${normalizeNumber(constructionReserve, 2)}`
      rehabBudgetData['Percent'] = `% ${normalizeNumber((constructionReserve * 100) / rehabBudget, 2)}`
    }

    if (interestReserve !== -1) rehabBudgetData['Interest Reserve'] = `$ ${normalizeNumber(interestReserve, 2)}`

    setRehabBudgetData(rehabBudgetData)
  }, [rehabBudget, constructionReserve, interestReserve])

  const renderRehabBudget = useMemo(() => {
    if (!rehabBudgetData) return <></>

    return <Accordion data={rehabBudgetData} />
  }, [rehabBudgetData])

  const renderTable = useMemo(() => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {data.map((item, index) => {
          const files: NextresDocument[] = item.files
            ? item.files
                .map((doc: NextresDocument) => {
                  const foundDoc = documents.find((v) => v.originalKey == doc.fileKey)
                  if (!foundDoc) return null
                  return {
                    ...doc,
                    fileKey: foundDoc.fileKey,
                  }
                })
                .filter((v: any) => !!v)
            : []

          return (
            <div key={index} className="rounded px-2 py-1 shadow">
              <div className="flex justify-between items-center p-1">
                <span className="text-desc">Draw No</span>
                <span className="text-sm">{index + 1}</span>
              </div>

              {Object.keys(item)
                .sort((a, b) => {
                  if (a > b) return -1
                  if (a < b) return 1
                  return 0
                })
                .map((key, idx) => {
                  if (TrustLedgerLables[key])
                    return (
                      <div
                        key={`${index}-${idx}`}
                        className={`flex justify-between items-center rounded-sm px-1 py-1.5 ${
                          idx % 2 === 0 ? 'bg-slate-100' : ''
                        }`}
                      >
                        <span className="text-desc">{TrustLedgerLables[key]}</span>
                        <span className="text-xs">{item[key]}</span>
                      </div>
                    )
                })}

              {!!files && !!files.length && (
                <>
                  <div className="border-b text-sm p-1">Files ({files.length})</div>

                  <div className="grid grid-cols-7 gap-2 w-full rounded-sm bg-slate-100 p-1">
                    <p className="text-desc">No</p>
                    <p className="text-desc col-span-5">File Name</p>
                  </div>
                  {files.map((doc: NextresDocument, id: number) => (
                    <div
                      key={`${index}-${id}`}
                      className={`grid grid-cols-7 gap-2 items-center w-full rounded-sm p-1 ${
                        id % 2 !== 0 ? 'bg-slate-100' : ''
                      }`}
                    >
                      <p className="text-xs">{id + 1}</p>
                      <p className="text-xs col-span-5 truncate">{doc.name}</p>
                      <div className="flex justify-center">
                        <button
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                          onClick={() => openS3Document(doc.fileKey)}
                        >
                          <EyeIcon className="w-3.5 h-3.5" />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )
        })}
      </div>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={loading} />
      <div className="mb-4">{renderRehabBudget}</div>

      {renderTable}
    </div>
  )
}
