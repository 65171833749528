import { XMarkIcon } from '@heroicons/react/24/outline'
import { SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { getSettingValue } from 'services/apis'

export const BroadcastHeader = () => {
  const [message, setMessage] = useState('')
  const [isClosed, setClosed] = useState(false)

  useEffect(() => {
    getSettingValue(SettingKey.CompanyBroadcast).then((value) => setMessage(value))
  }, [])

  return (
    <div
      className={`bg-indigo-900 text-white max-h-0 transition-all delay-500 overflow-hidden ${
        !isClosed && !!message ? 'max-h-48' : ''
      }`}
    >
      <div className="fullContent flex items-center">
        <p className="text-center flex-1 py-2">{message}</p>
        <span onClick={() => setClosed(true)} className="cursor-pointer">
          <XMarkIcon className="w-10 h-10 text-white p-2 hover-shadow1" />
        </span>
      </div>
    </div>
  )
}
