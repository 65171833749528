import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, EnvelopeOpenIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { logout } from 'actions'
import { LogoBlue, LogoWhite } from 'assets'
import Dropdown from 'components/Dropdown'
import { isTopManager } from 'config'
import { cloneDeep } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import type { RootState } from 'reducers'
import { getBadgeCount } from 'services/apis'
import { getAllParentArticles } from 'services/apis/service.articles'

import { BroadcastHeader } from './BroadcastHeader'
import { getMenus, getNavigations } from './navigations'

const useBadgeCounts = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth)

  const { data, isLoading, error, refetch } = useQuery(
    'badges',
    async () => {
      if (isAuthenticated) return getBadgeCount()
      return {}
    },
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: true,
      retryDelay: 45000,
      staleTime: 1000 * 60 * 5,
    },
  )
  return { data, isLoading, error, refetch }
}

function _Header({
  type = 'white',
  showBroadcastHeader = true,
}: {
  type: 'white' | 'dark'
  showBroadcastHeader?: boolean
}) {
  const [showMenu, setShowMenu] = useState(false)
  const navigations = getNavigations()
  const menus = getMenus()
  const auth = useSelector((state: RootState) => state.auth)
  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated } = auth
  const _isManager = auth && isTopManager(auth.profile.accountType)
  const [badgeCounts, setBadgeCounts] = useState<Record<string, number>>({})

  const [sourceList, setSourceList] = useState<string[]>([])
  const queryClient = useQueryClient()
  const { data: _badgeCounts, refetch: refetchBadgeCounts } = useBadgeCounts()

  useEffect(() => {
    getAllParentArticles().then((res) => {
      console.log(res)
      setSourceList(res)
    })
  }, [])

  useEffect(() => {
    setBadgeCounts(_badgeCounts || {})
  }, [_badgeCounts])

  const color = useMemo(() => {
    if (type == 'white') return 'white'
    else if (type == 'dark') return 'indigo-800'
  }, [type])

  const isMain = type == 'dark'

  const activeMenu = (to: string) => {
    if (location.pathname.startsWith(to)) {
      return `bg-gray-100`
    }
    return ''
  }

  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/home')
  }

  useEffect(() => {
    setShowMenu(false)

    if (!isAuthenticated) setBadgeCounts({})
  }, [auth])

  useEffect(() => queryClient.clear(), [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated && location.pathname == '/notification' && badgeCounts['notification']) {
      const newData = cloneDeep(badgeCounts)
      newData['notification'] = 0
      setBadgeCounts(newData)
    } else refetchBadgeCounts()
  }, [auth, location.pathname])

  const getNotificationCN = (size: number) => {
    return size >= 100 ? 'w-8 h-5 top-[-10px] right-[-16px]' : 'w-6 h-4 top-[-2px] right-[-8px]'
  }

  return (
    <Popover className={`${isMain ? `bg-${color}` : ''} relative z-50`}>
      {showBroadcastHeader && <BroadcastHeader />}
      <div className="fullContent relative py-2 z-10">
        <nav className="relative flex items-center" aria-label="Global">
          <div className="flex items-center flex-grow flex-shrink-0 md:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/home">
                <span className="sr-only">finresi</span>
                {isMain ? <LogoWhite /> : <LogoBlue />}
              </Link>
              <div className="flex items-center md:hidden">
                {_isManager && (
                  <>
                    <Link
                      to="/tasks"
                      className={`relative rounded-full p-1.5 header-profile-name cursor-pointer ${
                        isMain ? 'text-white hover:bg-indigo-250 hover:text-gray-900' : 'hover:bg-indigo-100'
                      }`}
                    >
                      <EnvelopeOpenIcon className="w-5 h-5" />
                      {!!badgeCounts['task'] && (
                        <div
                          className={`absolute ${getNotificationCN(
                            badgeCounts['task'],
                          )} text-sm flex items-center justify-center text-white rounded-full bg-red-700`}
                        >
                          {badgeCounts['task']}
                        </div>
                      )}
                    </Link>
                    <div className="mx-4">
                      <span className={`block rounded w-[1px] h-5 bg-${isMain ? 'white' : 'gray-400'}`}></span>
                    </div>
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <Link
                      to="/notification"
                      className={`relative rounded-full p-1.5 header-profile-name cursor-pointer ${
                        isMain ? 'text-white hover:bg-indigo-250 hover:text-gray-900' : 'hover:bg-indigo-100'
                      }`}
                    >
                      <BellIcon className="w-5 h-5" />
                      {!!badgeCounts['notification'] && (
                        <div
                          className={`absolute ${getNotificationCN(
                            badgeCounts['notification'],
                          )} text-sm flex items-center justify-center text-white rounded-full bg-red-700`}
                        >
                          {badgeCounts['notification']}
                        </div>
                      )}
                    </Link>
                    <div className="mx-4">
                      <span className={`block rounded w-[1px] h-5 bg-${isMain ? 'white' : 'gray-400'}`}></span>
                    </div>
                  </>
                )}
                <Popover.Button
                  className={`rounded-md p-2 inline-flex items-center justify-center text-${
                    isMain ? 'white' : 'gray-500'
                  } hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div
            className={`hidden flex-wrap items-center md:flex md:ml-8 ${
              _isManager ? 'md:space-x-2' : 'md:space-x-4'
            } flex-1 justify-between`}
          >
            {Object.keys(navigations).map((key, index) => {
              const item: any = navigations[key]
              if (!item.visible) return null
              if (!item.href) return <div className={item.className} key={`nav-${item.name}-${index}`} />
              if (item.name === 'Services')
                return (
                  <Dropdown
                    key={`nav-${item.name}-${index}`}
                    headerType={isMain ? 'white' : 'dark'}
                    title="Services"
                    sourceList={sourceList}
                  />
                )
              else
                return (
                  <Link
                    key={`nav-${item.name}-${index}`}
                    to={item.href}
                    className={`py-3 my-2 text-sm font-semibold text-${isMain ? 'white' : 'gray-900'} ${
                      activeMenu(item.href) || (item.className ? '' : isMain ? 'bg-gray-100' : '')
                    } ${item.className || 'px-2 hover:bg-gray-200 rounded-lg'}`}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.name }} />
                  </Link>
                )
            })}
            {auth.isAuthenticated && (
              <div className="flex items-center">
                {_isManager && (
                  <>
                    <Link
                      to="/tasks"
                      className={`relative rounded-full p-1.5 header-profile-name cursor-pointer ${
                        isMain ? 'text-white hover:bg-indigo-250 hover:text-gray-900' : 'hover:bg-indigo-100'
                      }`}
                      key="menu0-task"
                    >
                      <EnvelopeOpenIcon className="w-5 h-5" />
                      {!!badgeCounts['task'] && (
                        <div
                          className={`absolute ${getNotificationCN(
                            badgeCounts['task'],
                          )} text-sm flex items-center justify-center text-white rounded-full bg-red-700`}
                        >
                          {badgeCounts['task']}
                        </div>
                      )}
                    </Link>
                    <div className="mx-4" key="menu1-task">
                      <span className={`block rounded w-[1px] h-5 bg-${isMain ? 'white' : 'gray-600'}`}></span>
                    </div>
                  </>
                )}
                {[
                  <Link
                    to="/notification"
                    className={`relative rounded-full p-1.5 header-profile-name cursor-pointer ${
                      isMain ? 'text-white hover:bg-indigo-250 hover:text-gray-900' : 'hover:bg-indigo-100'
                    }`}
                    key="menu0"
                  >
                    <BellIcon className="w-5 h-5" />
                    {!!badgeCounts['notification'] && (
                      <div
                        className={`absolute ${getNotificationCN(
                          badgeCounts['notification'],
                        )} text-sm flex items-center justify-center text-white rounded-full bg-red-700`}
                      >
                        {badgeCounts['notification']}
                      </div>
                    )}
                  </Link>,
                  <div className="mx-4" key="menu1">
                    <span className={`block rounded w-[1px] h-5 bg-${isMain ? 'white' : 'gray-600'}`}></span>
                  </div>,
                  <p key="menu2" className={`text-${isMain ? 'white' : 'gray-600'} p-2`}>
                    {auth.profile.name}
                  </p>,
                  <div
                    key="menu3"
                    className="relative p-2 space-y-1.5 hover:bg-gray-200 hover:cursor-pointer rounded"
                    onMouseEnter={() => setShowMenu(true)}
                    onMouseLeave={() => setShowMenu(false)}
                  >
                    <div className={`${isMain ? 'bg-indigo-250' : 'bg-indigo-100'} rounded-full p-1.5`}>
                      <UserIcon className="w-5 h-5" />
                    </div>
                    {showMenu && (
                      <div className="absolute p-5 border w-72 rounded-lg right-0 space-y-4 z-50 bg-white">
                        {menus.map((item, index) => {
                          if (item.visible) {
                            if (item.label === 'divider') {
                              return (
                                <div
                                  key={`menu-${item.label}-${index}`}
                                  className="block rounded h-px bg-gray-400"
                                ></div>
                              )
                            }
                            return (
                              <Link
                                className="font-medium pl-1 hover:underline block"
                                key={`menu-${item.label}-${index}`}
                                to={item.href}
                              >
                                {item.label}
                              </Link>
                            )
                          }
                        })}
                        <div className="font-medium hover:underline pl-1" onClick={handleLogout}>
                          Sign Out
                        </div>
                      </div>
                    )}
                  </div>,
                ]}
              </div>
            )}
          </div>
        </nav>
      </div>
      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <LogoBlue />
              </div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Close main menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {Object.keys(navigations).map((key, index) => {
                const item: any = navigations[key]
                if (!item.visible) return null
                if (key === 'span1') return null

                return (
                  <Link
                    key={`n-${item.name}-${index}`}
                    to={item.href}
                    className={`block px-3 py-2 text-sm text-black font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                      item.href,
                    )} ${item.className} ${item.className && 'rounded-full text-center'}`}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.name }} />
                  </Link>
                )
              })}
              {auth.isAuthenticated && (
                <div>
                  <div className="block rounded h-px bg-gray-100"></div>
                  {menus.map(
                    (item, index) =>
                      item.visible &&
                      (item.label === 'divider' ? (
                        <div className="block rounded h-px bg-gray-100" key={`m-${item.label}-${index}`}></div>
                      ) : (
                        <Link
                          key={`trans-menu-${item.label}-${index}`}
                          to={item.href}
                          className={`block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                            item.href,
                          )}`}
                        >
                          {item.label}
                        </Link>
                      )),
                  )}
                  <div
                    className="block px-3 py-2 rounded-md text-base text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50"
                    onClick={handleLogout}
                  >
                    Sign Out
                  </div>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export const MainHeader = _Header
