import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { sendForgetPasswordEmail } from 'services/apis'
import { Button, Input } from 'stories/components'
import { useTitle } from 'utils'

const defaultInputs = {
  email: '',
}

export function ForgetPasswordPage() {
  useTitle('Forgot Password')

  const [isLoading, setLoading] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const [errors, setErrors] = useState({
    email: '',
  })

  const auth = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.isAuthenticated) navigate('/auth/signIn')
  }, [])

  const onSubmit = async () => {
    const newErrors = cloneDeep(defaultInputs)
    if (!inputStates.email) newErrors.email = 'Required'
    setErrors(newErrors)

    if (!inputStates.email) return

    setLoading(true)
    sendForgetPasswordEmail(inputStates.email)
      .then(() => {
        toast('A Password Reset Email has been sent. Please open URL from email.', { type: 'info' })
        navigate('/auth/signIn')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState: any = Object.assign({}, inputStates)
    newState[key] = value
    setInputStates(newState)
  }

  return (
    <div className="max-w-md px-4 py-6 md:px-10 md:py-10 bg-white rounded-xl xs:w-120 w-[100vw]">
      <div className="p-1">
        <Input
          type="email"
          title="Email"
          placeholder="Please input your email."
          value={inputStates.email}
          required
          autoFocus
          error={errors.email ? 'Please provide a valid email address.' : ''}
          onChange={(value) => onChange('email', value)}
        />
        <div className="my-7"></div>

        <Button className="mt-1" color="blue" full bold onClick={onSubmit} loading={isLoading}>
          Send
        </Button>

        <Link to="/auth/signIn">
          <p className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">Back to Login</p>
        </Link>
      </div>
    </div>
  )
}
