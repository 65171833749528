import type { AccountProfile } from 'config'

import { AUTH_LOGOUT, AUTH_PROFILE_UPDATE } from './types'

export function logout() {
  return { type: AUTH_LOGOUT }
}

export function authUpdateProfile(profile: AccountProfile) {
  return { type: AUTH_PROFILE_UPDATE, profile }
}
