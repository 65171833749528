import { InputType } from 'config'

export const defaultArticleInputs = (): Record<string, InputType> => {
  return {
    img: {
      inputType: 'file',
      title: 'Photo',
      required: true,
      multiple: false,
      acceptFileTypes: 'image/*',
    },
    imagePreview: {
      inputType: 'custom',
      title: 'Photo Preview',
    },
    title: {
      inputType: 'text',
      title: 'Title',
      required: true,
    },
    listTitle: {
      inputType: 'text',
      title: 'List Title',
      required: true,
    },

    description: {
      inputType: 'text',
      title: 'Description',
      required: false,
    },

    tag: {
      inputType: 'text',
      title: 'Tag',
      required: false,
      placeholder: 'Input tag with comma.',
    },

    isPublished: {
      inputType: 'Toggle',
      title: 'Publish',
      required: false,
      value: false,
    },

    author: {
      inputType: 'text',
      title: 'Author',
      required: false,
    },

    parentId: {
      inputType: 'select',
      title: 'Parent Article',
      required: false,
      options: [],
      hasDefaultOption: true,
    },
    isParent: {
      inputType: 'Toggle',
      title: 'Parent Article',
      required: false,
      value: false,
    },

    schemaName: {
      inputType: 'text',
      title: 'Schema Name',
      required: false,
    },
    schemaApplicationCategory: {
      inputType: 'text',
      title: 'Schema Application Category',
      required: false,
    },
    schemaPrice: {
      inputType: 'text',
      title: 'Schema Price',
      required: false,
    },

    isBlog: {
      inputType: 'Toggle',
      title: 'Display as Blog Post',
      required: false,
      value: false,
    },
    isCanonical: {
      inputType: 'Toggle',
      title: 'Canonical',
      required: false,
      value: false,
    },
  }
}

export const defaultDataInputs = (): Record<string, InputType> => {
  return {
    image: {
      inputType: 'file',
      title: 'Photo',
      required: false,
      multiple: false,
      acceptFileTypes: 'image/*',
    },
    imagePreview: {
      inputType: 'custom',
      title: 'Photo Preview',
    },

    orderNum: {
      inputType: 'text',
      title: '#No',
      required: false,
      type: 'number',
    },
    title: {
      inputType: 'text',
      title: 'Title',
      required: true,
    },

    addTransform: {
      inputType: 'Toggle',
      title: 'Add Transfrom',
      required: false,
      value: false,
    },

    content: {
      inputType: 'ckeditor',
      title: 'Content',
      required: true,
      span: 'full',
    },
  }
}
