import ArticleBox from 'components/ArticleBox'
import { Image } from 'components/Image'
import MobileTableContent from 'components/MobileTableContent'
import OtherArticleItem from 'components/OtherArticleItem'
import SocialButtons from 'components/SocialButtons'
import TableContent from 'components/TableContent'
import { IServiceArticle } from 'pages/ServiceArticle/types'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getServiceArticleBySlug, getServiceArticleOtherSlugList } from 'services/apis/service.articles'
import { deslugify, sortedArray, useTitle } from 'utils'

export const ParentServiceArticle = () => {
  const { slug } = useParams()
  const [articleItem, setArticleItem] = useState<IServiceArticle>()
  const [childArticleItems, setChildArticleItems] = useState<IServiceArticle[]>([])
  useTitle('Service Article')
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)

    getServiceArticleBySlug(slug ?? '')
      .then((article: IServiceArticle) => {
        setArticleItem(article)
        useTitle(article.title)
      })
      .catch((err) => {
        console.log(err)
        navigate('/')
      })
    getServiceArticleOtherSlugList(slug ?? '')
      .then((childArticles: IServiceArticle[]) => {
        setChildArticleItems(childArticles)
      })
      .catch((err) => {
        console.log(err)
        navigate('/')
      })
  }, [slug])

  return (
    <div>
      <div className={`relative items-center bg-white'`}>
        <div className="fullContent">
          <div className="grid grid-cols-12 pt-16 items-center mb-20">
            <div className="col-span-12 mb-4 lg:col-span-12 lg:gap-2">
              <p className="text-xs text-sky-800 tracking-wider font-semibold uppercase hidden">
                {' '}
                {deslugify(slug ?? '')}
              </p>
              <h1 className="font-semibold text-2xl mt-4 text-left mb-4 text-black font-inter">{articleItem?.title}</h1>
              <p className="mt-6 text-md lg:text-base text-left text-black pr-6 text-opacity-70 hidden">
                {articleItem?.description}
              </p>
              <div className="items-center gap-6 mt-16 hidden">
                <p className="text-sm  flex-1 text-black  text-opacity-70">
                  By <span className="font-bold">{articleItem?.author}</span> |{' '}
                  <span>{articleItem?.createdAt.toString()}</span>
                </p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-12 pl-0 flex justify-center mt-8">
              <div>
                <Image
                  src={articleItem?.imageURL}
                  alt={articleItem?.title}
                  width={800}
                  height={480}
                  className="rounded-xl"
                ></Image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fullContent mb-28">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-8 lg:mr-12">
            {sortedArray(articleItem?.serviceData ?? []).map((article, index) => (
              <ArticleBox articleItem={article} key={index} />
            ))}
          </div>
          <MobileTableContent items={sortedArray(articleItem?.serviceData ?? [])} />
          <div className="col-span-12 lg:col-span-4 px-8">
            <TableContent items={sortedArray(articleItem?.serviceData ?? [])} />
            <div className="mt-24">
              <p className="text-2xl font-bold mb-6 text-black font-inter">Check Out Our Services</p>
              <p className="text-xl font-bold mb-6 text-black font-inter mt-8">Related Articles</p>
              {childArticleItems
                .filter((item) => {
                  return item.slug !== articleItem?.slug
                })
                .map((otherArticle, index) => (
                  <OtherArticleItem key={index} article={otherArticle} linkHref={`/${slug}/${otherArticle.slug}`} />
                ))}
              <SocialButtons />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
