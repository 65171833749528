import type { Position } from 'google-map-react'

export function getSWCoordinates(coordinatesCollection: Position[]): Position {
  const lowestLng = Math.min(...coordinatesCollection.map((coordinates) => coordinates.lng))
  const lowestLat = Math.min(...coordinatesCollection.map((coordinates) => coordinates.lat))

  return {
    lng: lowestLng,
    lat: lowestLat,
  }
}

export function getNECoordinates(coordinatesCollection: Position[]): Position {
  const highestLng = Math.max(...coordinatesCollection.map((coordinates) => coordinates.lng))
  const highestLat = Math.max(...coordinatesCollection.map((coordinates) => coordinates.lat))

  return {
    lng: highestLng,
    lat: highestLat,
  }
}

export function getBounds(coordinatesCollection: Position[]): { center: Position; zoom: number } {
  if (coordinatesCollection.length == 0)
    return {
      center: {
        lat: 39.8283,
        lng: -98.5795,
      },
      zoom: 5,
    }

  const sw = getSWCoordinates(coordinatesCollection)
  const ne = getNECoordinates(coordinatesCollection)
  const center: Position = {
    lat: (sw.lat + ne.lat) / 2,
    lng: (sw.lng + ne.lng) / 2,
  }
  return {
    center,
    zoom: coordinatesCollection.length == 1 ? 11 : 5,
  }
}
