import type { InputType } from 'config'
import { formatDateYMD, thisTime } from 'utils'

import { ResourceDocumentOptions } from './ResourceDetail'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    type: {
      inputType: 'select',
      title: 'Type',
      options: ResourceDocumentOptions,
      hasDefaultOption: true,
      value: '',
      required: true,
    },
    name: {
      inputType: 'text',
      title: 'Name',
      value: '',
      required: true,
    },
    date: {
      inputType: 'text',
      type: 'date',
      title: 'Date',
      value: formatDateYMD(thisTime().toDate()),
      required: true,
    },
    file: {
      inputType: 'File',
      title: 'Document',
      value: '',
      acceptFileTypes: 'application/pdf',
    },
  }
}
