import { XMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { RenderInput } from 'utils'

import { defaultInputs, IErrorLog } from './constants'

interface IProps {
  exception: IErrorLog
  onClose: () => void
}

export const ExceptionDetails = (props: IProps) => {
  const { exception, onClose } = props

  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  useEffect(() => {
    const nInputs = cloneDeep(inputs)
    Object.keys(nInputs).forEach((key) => {
      nInputs[key].value = (exception as any)[key]
    })
    setInputs(nInputs)
  }, [exception])

  return (
    <div className="relative my-2 border py-2 px-3 rounded bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-base font-bold mb-2">Exception Details</h2>
        <span
          className="text-sm flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
          onClick={() => onClose()}
        >
          Close
          <XMarkIcon className="w-4 h-4" />
        </span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (key === 'reqBody' && !exception.reqBody) return <></>
          return (
            <div className={`col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={() => {}} />
            </div>
          )
        })}
      </div>
    </div>
  )
}
