import { s3BucketUrl } from 'config'

export const dummyLoans = [
  {
    id: '4',
    loan: 'BELMONT, NC #77',
    loanNumber: '1,100,000',
    remainingInterest: '127.79',
    term: '8 months',
    interest: '206.96',
    image: `${s3BucketUrl}/yieldeasy/sample/77.jpg`,
    description: 'Condo',
    title: 'Single Family Home Fix & Flip',
    bedrooms: 4,
    bathrooms: 4.1,
    ytm: 10,
    ltv: 65,
    progress: 22,
    closed: false,
  },
  {
    id: '96',
    loan: 'Santa Monica, CA #76',
    loanNumber: '1,365,000',
    remainingInterest: '73.35',
    term: '10 months',
    interest: '581.57',
    image: `${s3BucketUrl}/yieldeasy/sample/76.jpg`,
    description: 'Single',
    title: 'Condo unit Bridge Loan',
    bedrooms: 1,
    bathrooms: 1,
    ytm: 11.99,
    ltv: 50,
    progress: 7,
    closed: false,
  },
  {
    id: '47',
    loan: 'Los Angeles, CA #75',
    loanNumber: '2,250,000',
    remainingInterest: '235.21',
    term: '9 months',
    interest: '674.24',
    image: `${s3BucketUrl}/yieldeasy/sample/75.jpg`,
    description: 'Condo',
    title: 'Single Family Home Fix & Flip',
    bedrooms: 5,
    bathrooms: 6.1,
    ytm: 10.74,
    ltv: 64.99,
    progress: 3,
    closed: false,
  },
  {
    id: '24',
    loan: 'Lauderhill, FL #59',
    loanNumber: '313,300',
    remainingInterest: '30,000',
    term: '2 months',
    interest: '82.5',
    image: `${s3BucketUrl}/yieldeasy/sample/50.jpg`,
    description: 'PUD',
    title: 'Single Family Home Fix & Flip',
    bedrooms: 4,
    bathrooms: 3.1,
    ytm: 10.24,
    ltv: 55,
    progress: 94,
    closed: false,
  },
]
