import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getNotifications, markAsAllNotificationRead } from 'services/apis'
import { formatTime, useTitle } from 'utils'

import { DbNotification, NotificationTypeMap } from './type'

export const NotificationPage = () => {
  useTitle('Notifications')

  const [filters, setFilters] = useState<Record<string, any>>({
    pageNum: 0,
  })
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<DbNotification[]>([])

  useEffect(() => {
    refetch(filters)
  }, [])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (data.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getNotifications({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ total, data: _data }) => {
        if (_pageNum == 0) setData(_data)
        else {
          const newData = cloneDeep(data)
          newData.push(..._data)
          setData(newData)
        }

        setTotal(total)
      })
      .finally(() => {
        setLoading(false)
        markAsAllNotificationRead()
      })
  }

  const onChangeFilter = (key: 'type' | 'pageNum', value: any, needRefetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    refetch(newFilters, needRefetch ? 0 : -1)
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <p className="text-4xl font-semibold mb-10 text-center">Notifications</p>

      <InfiniteScroll
        dataLength={data.length}
        next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
        hasMore={data.length < total}
        loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
      >
        {data.map((item, index) => (
          <div key={index}>
            {index != 0 && item.isRead && !data[index - 1].isRead && (
              <div className="flex-1 flex items-center gap-2 mb-4">
                <div className="flex-1 border-b border-b-red-500 h-0.5" />
                <p className="text-red-500 text-sm">NEW</p>
                <div className="flex-1 border-b border-b-red-500 h-0.5" />
              </div>
            )}
            <div className="border mb-4 rounded-lg bg-white relative overflow-visible" key={item.id}>
              <div className="flex flex-col md:flex-row border-b items-baseline md:items-center cursor-pointer p-4">
                <p className="text-base font-semibold">
                  {index + 1}. [{NotificationTypeMap[item.type]}]
                </p>
                <p className="text-sm text-gray-600 flex-1 pl-4">{item.value}</p>
                <p className="text-desc pl-4">{formatTime(item.createdAt)}</p>
              </div>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}
