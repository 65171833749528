import { SignUpSteps } from 'pages/Auth/SignUp/types'

export const Step = ({
  title,
  step,
  totalStep,
  progress,
}: {
  title: string
  step: number
  totalStep: number
  progress?: number
}) => {
  return (
    <div className="mb-1">
      <div className="flex justify-between text-sm mb-2">
        <p>{title}</p>
        <p>
          {step - SignUpSteps.InvestorType + 1} / {totalStep}
        </p>
      </div>
      <div className="w-full rounded-full bg-gray-200">
        <div
          className="h-1 rounded-full bg-indigo-500"
          style={{ width: `${progress || ((step - SignUpSteps.InvestorType + 1) / totalStep) * 100}%` }}
        />
      </div>
    </div>
  )
}
