import { imgTakeControlWealth } from 'assets'
import { Image } from 'components/Image'
import { normalizeNumber } from 'utils'

import { dummyLoans } from './data'

export const TakeControlWealthDemo = () => {
  return (
    <div className="object-none h-80 py-10 overflow-hidden relative">
      <img src={imgTakeControlWealth} className="object-none h-60 mx-auto" />

      <div
        className="absolute left-[50%] top-0 flex gap-2"
        style={{ scale: '0.5', transform: 'translate(-100%, -30px)' }}
      >
        {dummyLoans.map((item, index) => (
          <div key={`${item.title}-takecontrol-${index}`} className="w-64 lg:w-72 rounded-xl overflow-hidden">
            <Image src={item.image} className="w-full block aspect-video object-cover" />
            <div className="bg-white p-6 border-l border-r border-gray-300">
              <p className="font-bold mb-2">{item.loan}</p>
              <p className="text-xs text-gray-400">
                {item.description} with {item.bedrooms} bedrooms and {item.bathrooms} full bath
              </p>
            </div>
            <div className="bg-gray-100 flex divide-x border rounded-b-xl border-gray-300 border-t-0">
              {[
                [`${item.ytm}%`, 'APY'],
                [`${normalizeNumber(item.ltv, 2)}%`, 'LTV'],
                [item.term, 'TERM'],
              ].map((v) => (
                <div key={v[1]} className="flex-1 text-center py-6">
                  <p className="font-bold text-sm">{v[0]}</p>
                  <p className="text-xs text-gray-400">{v[1]}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
