import {
  API_LOAN,
  API_LOAN_COMMERCIAL,
  API_LOAN_COMMERCIAL_TRUSTLEDGER,
  API_LOAN_FCI,
  API_LOAN_LOG,
  API_LOAN_NEXTRES_DOCUMENTS,
  API_LOANS,
  API_MARKETPLACE_FILTER_OPTIONS,
} from 'config'
import Api from 'services/api'

export const getMarketplaceFilterOptions = () => {
  return Api.get(API_MARKETPLACE_FILTER_OPTIONS)
}

export const getLoan = (id: string | number) => {
  return Api.get(API_LOAN, {}, { id })
}

export const deleteLoan = (id: number) => {
  return Api.delete(API_LOAN, {}, { id })
}

export const updateLoan = (id: string | number, data: Record<string, any>) => {
  return Api.post(API_LOAN, data, { id })
}

export const updateLoanData = (id: number, data: Record<string, any>) => {
  return Api.put(API_LOAN, data, { id })
}

export const getCommercialLoans = (data: Record<string, any>) => {
  return Api.get(API_LOAN_COMMERCIAL, data)
}

export const importCommercialLoans = (loanNumbers: number[]) => {
  return Api.post(API_LOAN_COMMERCIAL, { loanNumbers })
}

export const getTrustLedger = (loanNumber: number) => {
  return Api.get(API_LOAN_COMMERCIAL_TRUSTLEDGER, {}, { loanNumber })
}

export const getLoanLogs = (key: string) => {
  return Api.get(API_LOAN_LOG, {}, { key })
}

export const findLoans = (filters: Record<string, any>, isAuthenticated = false) => {
  return Api.get(API_LOANS, filters, { isAuth: isAuthenticated ? 'auth' : '' })
}

export const getLoanNextresDocuments = (loanNumber: number) => {
  return Api.get(API_LOAN_NEXTRES_DOCUMENTS, {}, { loanNumber })
}

export const getLoanFciStatus = (id: string | number) => {
  return Api.get(API_LOAN_FCI, {}, { id })
}
