import { BuildingLibraryIcon, BuildingOffice2Icon, CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Button } from 'stories/components'

import { ContentHeader } from './ContentHeader'

export const ChooseMethodContent = ({ defaultData, onNext }: { defaultData: any; onNext: Function }) => {
  const [method, setMethod] = useState(defaultData || 0)

  return (
    <div className="rounded-lg bg-white divide-y">
      <ContentHeader description="You'll get the following account details: Bank name, Bank code, Account." />

      <div
        className={`relative py-4 px-2 cursor-pointer hover:bg-slate-50 ${method == 0 && 'bg-slate-100'}`}
        onClick={() => setMethod(0)}
      >
        <div className="flex gap-2 items-center mb-2">
          <BuildingLibraryIcon className="w-6 h-6" />
          <p className="text-lg text-gray-800">Withdraw via ACH</p>
        </div>
        <p className="text-desc ml-8">
          We'll withdraw to the linked Bank Accounts on Payments pages. Easy to transfer using Plaid platform.
        </p>
        {method == 0 && <CheckIcon className="text-green-400 w-6 h-6 absolute right-4 top-4" />}
      </div>

      <div
        className={`relative py-4 px-2 cursor-pointer hover:bg-slate-50 ${method == 1 && 'bg-slate-100'}`}
        onClick={() => setMethod(1)}
      >
        <div className="flex gap-2 items-center mb-2">
          <BuildingOffice2Icon className="w-6 h-6" />
          <p className="text-lg text-gray-800">Withdraw via Wire Transfer</p>
        </div>
        <p className="text-desc ml-8">
          We'll withdraw to your accounts via Wire Transfer. Please make sure the bank accounts holder names.
        </p>
        {method == 1 && <CheckIcon className="text-green-400 w-6 h-6 absolute right-4 top-4" />}
      </div>

      <div className="pt-4">
        <Button full color="green" onClick={() => onNext(method)}>
          Next
        </Button>
      </div>
    </div>
  )
}
