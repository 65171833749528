import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { confirmTaxCert } from 'services/apis'

import { ProfileMenuTitles } from './Profile'

export const TaxCertRedirectPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const envelopeId = searchParams.get('envelopeId')
    // const envelopeEvent = searchParams.get('event')

    if (!envelopeId) {
      //  || envelopeEvent != 'signing_complete'
      navigate(`/profile#${ProfileMenuTitles.TAX_CERT}`)
      return
    }

    confirmTaxCert(envelopeId)
      .then(({ status }) => {
        status && toast('Your W-8 Form has been successfully submitted.', { type: 'info' })
      })
      .finally(() => navigate(`/profile#${ProfileMenuTitles.TAX_CERT}`))
  }, [])

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <LayoutLoading show />
    </div>
  )
}
