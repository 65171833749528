import cloneDeep from 'clone-deep'
import type { CustomInput } from 'config'
import { useEffect, useState } from 'react'
import { createServiceArticle, updateServiceArticle } from 'services/apis/service.articles'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultArticleInputs } from './constants'
import type { IArticleParent, IServiceArticle } from './types'

export const ServiceArticleModal = ({
  data,
  onClose,
  articleList,
}: {
  data: IServiceArticle | null
  onClose: Function
  articleList: IArticleParent[]
}) => {
  const [inputs, setInputs] = useState(defaultArticleInputs())
  const [isLoading, setLoading] = useState(false)

  const setDefaultOptions = () => {
    const newInputs = cloneDeep(inputs)
    if (newInputs.parentId.inputType === 'select') {
      newInputs.parentId.options = articleList.map((item) => item.title)
    }
    setInputs(newInputs)
  }

  useEffect(() => {
    if (!data) {
      setDefaultOptions()
      return
    }
    const newInputs = cloneDeep(inputs)
    if (newInputs.parentId.inputType === 'select') {
      newInputs.parentId.options = articleList
        .filter((result) => {
          return result.id !== data.id
        })
        .map((item) => item.title)
    }

    Object.keys(newInputs).forEach((key) => {
      if (key === 'parentId') {
        if ((data as any)[key] !== '0') {
          newInputs[key].value = articleList.filter((article) => {
            return article.id.toString() === (data as any)[key]
          })[0].title
        }
      } else (data as any)[key] && (newInputs[key].value = (data as any)[key] || '')
    })

    setInputs(newInputs)
  }, [])

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    if (key === 'isParent' && newInputs[key].value) {
      newInputs.parentId.options = []
    } else {
      newInputs.parentId.options = articleList.map((item) => item.title)
    }
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      if (key === 'parentId' && !data?.isParent) {
        if (
          articleList.filter((article) => {
            return article.title === value
          }).length > 0
        )
          values[key] = articleList
            .filter((article) => {
              return article.title === value
            })[0]
            .id.toString()
        else values[key] = '0'
      } else {
        values[key] = value || ''
      }
    })
    setInputs(newInputs)
    if (hasError) return false
    if (data?.isParent) delete values.parentId
    delete values.imagePreview

    setLoading(true)
    if (!data)
      createServiceArticle(values)
        .then((newData) => onClose(newData))
        .catch(() => setLoading(false))
    else
      updateServiceArticle(data.id, values)
        .then((newData) => onClose(newData))
        .catch(() => setLoading(false))
  }

  const renderPhotoPreview = () => {
    const image = inputs.img.value
    if (!image) return <div />
    const link = typeof image == 'string' ? data?.imageURL : URL.createObjectURL(image)
    return <img src={link} className="w-80 h-40 rounded-lg border-gray-200 border-4 object-cover" />
  }

  return (
    <Modal
      isOpen
      loading={isLoading}
      onClose={() => onClose()}
      onOk={onSubmit}
      title={`${data ? 'Update' : 'Create'} Service Article`}
      titleOkay="Submit"
      titleCancel="Close"
    >
      <div className="w-196 flex flex-col gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]
            if (key == 'imagePreview') (input as CustomInput).render = renderPhotoPreview

            return (
              <div key={index} className={input.span ? `col-span-${input.span}` : ''}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
