import { Loading } from 'components/Loading'
import { TransferLedgerBalance } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { getTransferLedgers } from 'services/apis'
import { normalizeNumber, removeComma } from 'utils'

export const TransferLedgerBalances = () => {
  const [isLoading, setLoading] = useState(true)
  const [balance, setBalance] = useState<TransferLedgerBalance>()

  useEffect(() => {
    getTransferLedgers()
      .then(({ balance }) => setBalance(balance))
      .finally(() => setLoading(false))
  }, [])

  const available = useMemo(() => (balance ? removeComma(balance.available) : 0), [balance])
  const pending = useMemo(() => (balance ? removeComma(balance.pending) : 0), [balance])

  const totalBalance = useMemo(() => {
    if (!balance) return 0
    return removeComma(available) + removeComma(pending)
  }, [available, pending])

  if (isLoading || !balance) return <Loading />

  return (
    <div className="flex gap-4">
      <div>
        <p className="mb-2">
          Ledger Balance
          <span className="font-bold ml-2">$ {normalizeNumber(totalBalance, 2)}</span>
        </p>
        <div className="rounded-full h-1 bg-green-200">
          <div className="rounded-full h-1 bg-green-500" style={{ width: `${(available / totalBalance) * 100}%` }} />
        </div>
      </div>
      <div className="text-sm">
        <p>
          Available <span className="ml-2">$ {normalizeNumber(available, 2)}</span>
        </p>
        <p>
          Pending <span className="ml-2">$ {normalizeNumber(pending, 2)}</span>
        </p>
      </div>
    </div>
  )
}
