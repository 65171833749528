import { AccountType, InputType, states } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'stories/components'
import { countries } from 'stories/components/Input/PhoneSelector'
import { InputConvert, RenderInput, validateInputs } from 'utils'

import { CompanyTypeOptions } from '../AccountType'

const defaultInputs = (): Record<string, InputType> => {
  return {
    legalName: {
      title: '',
      placeholder: 'Full Entity Name',
      inputType: 'text0',
      type: 'text',
      required: true,
    },
    typeOfTrust: {
      title: '',
      placeholder: 'Type of Trust',
      inputType: 'text0',
      type: 'text',
      required: true,
    },
    dateTrustCreated: {
      title: '',
      inputType: 'text0',
      type: 'date',
      required: true,
    },
    // skipForNow: {
    //   title: 'Skip for now and complete later.',
    //   inputType: 'checkbox',
    //   required: false,
    // },
    entityAddress: {
      title: 'Entity Address',
      placeholder: 'Address',
      inputType: 'map',
      type: 'text',
      required: true,
      className: '!bg-transparent',
    },
    jurisdiction: {
      title: '',
      placeholder: 'Jurisdiction of registration',
      inputType: 'select0',
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
      options: states,
      error: '',
      required: true,
      visible: true,
    },
    // certificateFormation: {
    //   inputType: 'file',
    //   title: '',
    //   required: false,
    //   multiple: true,
    // },
    // addedCert: {
    //   title:
    //     'I will provide the Certificate of Formation later, understanding that my investment won’t be processed until then.',
    //   inputType: 'checkbox',
    //   required: false,
    // },
    ein: {
      title: '',
      placeholder: 'XX-XXXXXXX',
      inputType: 'text0',
      type: 'ein',
      required: true,
    },
    isFundAccredit: {
      title: 'All owners of my company of fund are individually accredited',
      inputType: 'checkbox',
    },
  }
}

export const CompanySection = ({
  accountType,
  step,
  onSubmit: _onSubmit,
}: {
  accountType: AccountType
  step: JSX.Element
  onSubmit: Function
}) => {
  const isCompany = accountType == AccountType.Company
  const [companyType, setCompanyType] = useState('')
  const [inputStates, setInputStates] = useState(defaultInputs())

  useEffect(() => {
    const newState = cloneDeep(inputStates)
    if (accountType == AccountType.Company) {
      newState.dateTrustCreated.disabled = true
      newState.typeOfTrust.disabled = true
    } else {
      newState.legalName.placeholder = 'Full Trust Name'

      newState.ein.disabled = true
      newState.entityAddress.disabled = true
      // newState.addedCert.disabled = true
      newState.isFundAccredit.disabled = true
      newState.jurisdiction.disabled = true
    }
    setInputStates(newState)
  }, [accountType])

  const isDisabled = useMemo(() => {
    if (accountType == AccountType.Company) {
      if (!companyType) return true
    }
    return false
  }, [accountType, companyType])

  const onChange = (key: string, value: string) => {
    const newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''

    if (key === 'entityAddress') {
      if (value.endsWith('USA')) newState.jurisdiction.options = states
      else newState.jurisdiction.options = countries
    }

    // if (key === 'certificateFormation') updateDisable(newState, true)
    // if (key === 'addedCert') updateDisable(newState, false)
    setInputStates(newState)
  }

  // const updateDisable = (newInputs: Record<string, InputType>, changeType: boolean) => {
  //   if (changeType) {
  //     const certFormat = newInputs.certificateFormation.value
  //     newInputs['addedCert'].disabled = certFormat ? true : false
  //   } else {
  //     const addedCert = newInputs.addedCert.value
  //     newInputs['certificateFormation'].disabled = addedCert
  //   }
  // }

  const onSubmit = async () => {
    const { hasError, data, newInputStates } = validateInputs(inputStates)
    if (hasError) {
      setInputStates(newInputStates)
      return
    }
    _onSubmit({
      ...data,
      companyType,
    })
  }

  return (
    <div className="max-w-lg px-8 md:px-0 md:mx-auto w-full md:w-120">
      <div className="mb-4">{step}</div>

      <div className="mb-4">
        <p className="text-xl font-medium">{isCompany ? `Entity Name` : `Trust Name`}</p>
      </div>
      <div>
        {RenderInput({
          input: inputStates['legalName'],
          Key: 'legalName',
          onChange,
        })}
      </div>

      {accountType == AccountType.Trust && (
        <>
          <div className="mb-4">
            <p className="text-xl font-medium">Trust Type</p>
          </div>
          <div>
            {RenderInput({
              input: inputStates['typeOfTrust'],
              Key: 'typeOfTrust',
              onChange,
            })}
          </div>
          <div className="mb-4">
            <p className="text-xl font-medium">Date of Trust Created</p>
          </div>
          {RenderInput({
            input: inputStates['dateTrustCreated'],
            Key: 'dateTrustCreated',
            onChange,
          })}
          {/* <div className="my-4">
            <p className="text-xl font-medium">Upload Trust Document</p>
          </div>
          <div className="flex justify-center">
            {RenderInput({
              input: inputStates['certificateFormation'],
              Key: 'certificateFormation',
              onChange,
            })}
          </div> */}
          {/* <div className="mb-4">
            {RenderInput({
              input: inputStates['skipForNow'],
              Key: 'skipForNow',
              onChange,
            })}
          </div> */}
        </>
      )}

      {accountType == AccountType.Company && (
        <>
          <div className="mb-4">
            <p className="text-xl font-medium">Entity Address</p>
          </div>
          <div>
            {RenderInput({
              input: inputStates['entityAddress'],
              Key: 'entityAddress',
              onChange,
            })}
          </div>
          <div className="my-4">
            <p className="text-xl font-medium">Jurisdiction of registration</p>
          </div>
          <div>
            {RenderInput({
              input: inputStates['jurisdiction'],
              Key: 'jurisdiction',
              onChange,
            })}
          </div>

          <div className="mb-4">
            <p className="text-xl font-medium">Entity EIN</p>
          </div>
          <div>
            {RenderInput({
              input: inputStates['ein'],
              Key: 'ein',
              onChange,
            })}
          </div>

          <div className="mb-2">
            <p className="text-xl font-medium">Entity Type</p>
          </div>

          <p className="text-sm text-gray-700 mb-4">Do any one of the following apply to you?</p>

          <div className="mb-4">
            {Object.keys(CompanyTypeOptions).map((v) => (
              <Button
                full
                color={companyType == v ? 'indigo' : `white`}
                key={v}
                onClick={() => setCompanyType(v)}
                className="py-4 !border-gray-300 font-medium"
              >
                {CompanyTypeOptions[v]}
              </Button>
            ))}
          </div>

          {/* <div className="my-4">
            <p className="text-xl font-medium">Upload Certificate of Formation</p>
          </div>
          <div className="flex justify-center">
            {RenderInput({
              input: inputStates['certificateFormation'],
              Key: 'certificateFormation',
              onChange,
            })}
          </div> */}

          {/* <div className="mb-4">
            {RenderInput({
              input: inputStates['addedCert'],
              Key: 'addedCert',
              onChange,
            })}
          </div> */}

          <div className="mb-4">
            {RenderInput({
              input: inputStates['isFundAccredit'],
              Key: 'isFundAccredit',
              onChange,
            })}
          </div>
        </>
      )}

      <Button full onClick={onSubmit} className="py-4" disabled={isDisabled}>
        Finish
      </Button>
    </div>
  )
}
