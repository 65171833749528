import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getFaq } from 'services/apis'

import type { DbFaq } from './type'

export const FaqFooter = () => {
  const [faqItems, setFaqItems] = useState<DbFaq[]>([])
  const [faqStatus, setFaqStatus] = useState<Record<number, boolean>>({})

  useEffect(() => {
    getFaq().then((data) => setFaqItems(data))
  }, [])

  const onFaqToggle = (id: number) => {
    const newStatus = cloneDeep(faqStatus)
    newStatus[id] = !newStatus[id]
    setFaqStatus(newStatus)
  }

  return (
    <div className="max-w-[58.5rem] mx-auto">
      <p className="text-white text-4xl md:mb-12 mb-6 text-center">Frequently Asked Questions</p>
      {faqItems.map((item, index) => {
        const isActive = faqStatus[item.id]
        return (
          <div
            className="border border-white/30 md:mb-6 mb-3 rounded-lg text-gray-200 overflow-hidden"
            key={`${index}-${item.title}`}
          >
            <div
              className={`flex justify-between items-center cursor-pointer px-6 py-4 hover:bg-gray-800`}
              onClick={(e) => {
                !e.defaultPrevented && onFaqToggle(item.id)
              }}
            >
              <p className="text-lg font-medium">{item.title}</p>
              <div className="flex gap-2 items-center">
                <ChevronRightIcon
                  className={`w-6 h-6 text-gray-200 transition-all duration-500 transform ${isActive && 'rotate-90'}`}
                />
              </div>
            </div>
            {isActive ? (
              <div className="text-sm overflow-hidden duration-500 transition-all text-gray-300 px-6">
                <p className="pt-2 pb-4">{item.content}</p>
              </div>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}
