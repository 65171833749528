// import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { InvestReadyHistoryTable } from 'pages/User/modal'
import type { IdentityVerificationGetResponse } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import {
  createInvestReady,
  getInvestReadyQuestionsForm,
  getPlaidIdentityVerificationDetails,
  updateInvestReady,
  uploadFiles,
} from 'services/apis'
import { ButtonGroup } from 'stories/components'

import { InvestReadyForm } from './Form'
import {
  InvestReadyCert,
  InvestReadyStatus,
  type IR_FormResponse,
  type IR_QuestionSetType,
  IR_QuestionSetTypeOptions,
  type IR_Response,
} from './type'

export const InvestReadyEditor = ({
  types,
  data,
  onClose,
  onBack,
}: {
  types: IR_QuestionSetType[]
  data: InvestReadyCert | null
  onClose: Function
  onBack?: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [questionsType, setQuestionsType] = useState<IR_QuestionSetType>(types[0])
  const [formId, setFormId] = useState(0)
  const [formData, setFormData] = useState<IR_Response<IR_FormResponse> | null>(null)
  const { id: userId } = useSelector((state: RootState) => state.auth.profile)

  const [showReason, setShowReason] = useState(false)
  const [values, setValues] = useState<Record<string, any>>({})
  const [kycDetails, setKycDetails] = useState<IdentityVerificationGetResponse>()

  useEffect(() => {
    getPlaidIdentityVerificationDetails().then((data) => setKycDetails(data))
  }, [])

  useEffect(() => {
    data && setQuestionsType(data.type)
  }, [data])

  useEffect(() => {
    if (!questionsType) return

    setLoading(true)
    getInvestReadyQuestionsForm(questionsType)
      .then(({ id, form }) => {
        setFormId(id)
        setFormData(form)
      })
      .finally(() => setLoading(false))
  }, [questionsType])

  useEffect(() => {
    if (showReason) onReasonClose('')
  }, [showReason])

  const patchFiles = async (values: Record<string, any> | Record<string, any>[]) => {
    if (Array.isArray(values)) {
      for (const key in values) await patchFiles(values[key])
    } else {
      for (let key in values) {
        const value = values[key]
        if (value instanceof File) {
          const [fileKey] = await uploadFiles({ path: `investReady/${userId}` }, [value])
          values[key] = fileKey
        } else if (typeof values[key] == 'object') await patchFiles(values[key])
      }
    }
  }

  const onSubmit = async (values: Record<string, any>) => {
    if (!questionsType) return
    setValues(values)
    setShowReason(true)
  }

  const onReasonClose = async (reason: string | null) => {
    setShowReason(false)
    if (reason === null) {
      setValues({})
      return
    }

    setLoading(true)
    await patchFiles(values)

    if (!data)
      createInvestReady(formId, values, reason)
        .then(() => {
          toast('A new verification has been submitted.', { type: 'success' })
          onClose(true)
        })
        .finally(() => setLoading(false))
    else
      updateInvestReady(data.id, values, reason)
        .then(() => {
          toast('A verification has been submitted again.', { type: 'success' })
          onClose(true)
        })
        .finally(() => setLoading(false))
  }

  const form = useMemo(() => {
    if (!formData || !questionsType) return null
    return (
      <InvestReadyForm
        type={questionsType}
        defaultValues={data ? data.data : {}}
        formData={formData}
        editable={!data || data.status == InvestReadyStatus.Rejected}
        kycDetails={kycDetails}
        onSubmit={onSubmit}
        onBack={onBack ? () => onBack() : null}
      />
    )
  }, [formData, kycDetails])

  const typeOptions = useMemo(() => {
    if (types.length == 1) return null
    const options: Record<string, string> = {}
    types.forEach((type) => {
      options[type] = IR_QuestionSetTypeOptions[type]
    })
    return options
  }, [types])

  return (
    <div>
      {/* {data && (
        <Button
          link
          onClick={() => {
            onClose(false)
          }}
        >
          <p className="flex items-center gap-2">
            <ArrowLeftIcon className="w-6 h-6" />
            Back to List
          </p>
        </Button>
      )} */}

      <LayoutLoading show={loading} />
      <div className="w-full border-b mb-4">
        {data ? (
          <div>
            <p className="text-lg mb-2">Histories</p>
            <InvestReadyHistoryTable history={data.history} createdBy={false} />
          </div>
        ) : (
          <div className="flex justify-center">
            {typeOptions && (
              <ButtonGroup
                title={typeOptions}
                value={questionsType as string}
                onChange={setQuestionsType}
                className="!text-base py-1.5 px-2.5"
              />
            )}
          </div>
        )}
      </div>
      {form}

      {/* {showReason && <InvestReadyReasonModal forUser onClose={onReasonClose} />} */}
    </div>
  )
}
