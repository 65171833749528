import { ArrowsRightLeftIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import type { ProfitStatus } from 'pages/Invest'
import { InvestStatus, ProfitColorMap } from 'pages/Payment'
import { TransferEventType, TransferStatus } from 'plaid'

export const TransactionColorMap: Record<
  TransferStatus | TransferEventType | InvestStatus | ProfitStatus | 'profit' | 'distribution',
  string
> = {
  ...ProfitColorMap,
  [TransferStatus.Pending]: 'bg-indigo-200 text-gray-800',
  [TransferStatus.Posted]: 'bg-indigo-600 text-white',
  [TransferStatus.Settled]: 'bg-green-500 text-white',
  [TransferStatus.FundsAvailable]: 'bg-green-500 text-white',
  [TransferStatus.Cancelled]: 'bg-gray-200 text-gray-800',
  [TransferStatus.Failed]: 'bg-red-500 text-white',
  [TransferStatus.Returned]: 'bg-yellow-200 text-yellow-800',
  [TransferEventType.Swept]: 'bg-green-500 text-white',
  [TransferEventType.SweptSettled]: 'bg-green-500 text-white',
  [TransferEventType.ReturnSwept]: 'bg-yellow-200 text-yellow-600',
  [TransferEventType.SweepPending]: 'bg-indigo-200 text-gray-800',
  [TransferEventType.SweepPosted]: 'bg-indigo-600 text-white',
  [TransferEventType.SweepSettled]: 'bg-green-500 text-white',
  [TransferEventType.SweepReturned]: 'bg-yellow-200 text-yellow-800',
  [TransferEventType.SweepFailed]: 'bg-red-500 text-white',
  [TransferEventType.RefundPending]: 'bg-indigo-200 text-gray-800',
  [TransferEventType.RefundCancelled]: 'bg-gray-200 text-gray-800',
  [TransferEventType.RefundFailed]: 'bg-red-500 text-white',
  [TransferEventType.RefundPosted]: 'bg-indigo-600 text-white',
  [TransferEventType.RefundSettled]: 'bg-green-500 text-white',
  [TransferEventType.RefundReturned]: 'bg-yellow-200 text-yellow-800',
  [TransferEventType.RefundSwept]: 'bg-yellow-200 text-yellow-600',
  [TransferEventType.RefundReturnSwept]: 'bg-yellow-200 text-yellow-800',
  [InvestStatus.Retraded]: 'bg-red-200 text-gray-800',
  profit: 'bg-blue-500 text-white',
  distribution: 'bg-blue-500 text-white',
}

export const TransactionStatusTextMap: Record<TransferStatus, string> = {
  [TransferStatus.Pending]: 'Pending',
  [TransferStatus.Posted]: 'Processing',
  [TransferStatus.Settled]: 'Completed',
  [TransferStatus.FundsAvailable]: 'FundsAvailable',
  [TransferStatus.Cancelled]: 'Cancelled',
  [TransferStatus.Failed]: 'Failed',
  [TransferStatus.Returned]: 'Returned',
}

export const TransactionStatus = ({
  status,
  onClick = null,
}: {
  status: TransferStatus | TransferEventType | InvestStatus | ProfitStatus | 'profit' | 'distribution'
  onClick?: Function | null
}) => {
  return (
    <p
      className={`${TransactionColorMap[status]} px-2 py-0.5 inline-block rounded-md w-24 text-center capitalize ${
        onClick && 'cursor-pointer'
      }`}
      onClick={() => onClick && onClick()}
    >
      {(TransactionStatusTextMap as any)[status] || status}
    </p>
  )
}

export const TransactionEventIcon = ({ event }: { event: TransferEventType }) => {
  if (event == TransferEventType.Pending) return <ArrowsRightLeftIcon className="w-4 h-4 text-gray-800" />
  if (TransferEventType.Returned == event) return <XMarkIcon className="w-4 h-4 text-yellow-400" />
  return <CheckIcon className="w-4 h-4 text-green-500" />
}

export const TransactionEventTexts: Record<TransferEventType, string> = {
  [TransferEventType.Pending]: 'The transfer was successfully created',
  [TransferEventType.Cancelled]: 'The transfer was cancelled',
  [TransferEventType.Failed]: 'The transfer was failed',
  [TransferEventType.Posted]: 'The transfer was submitted to the payment network',
  [TransferEventType.Settled]: 'The transfer was settled',
  [TransferEventType.FundsAvailable]: 'The transfer funds are available',
  [TransferEventType.Swept]: 'The funds for this transfer were included in sweep',
  [TransferEventType.SweptSettled]: `The transfer's sweep was settled`,
  [TransferEventType.Returned]: '',
  [TransferEventType.ReturnSwept]: '',
  [TransferEventType.SweepPending]: '',
  [TransferEventType.SweepPosted]: '',
  [TransferEventType.SweepSettled]: '',
  [TransferEventType.SweepReturned]: '',
  [TransferEventType.SweepFailed]: '',
  [TransferEventType.RefundPending]: '',
  [TransferEventType.RefundCancelled]: '',
  [TransferEventType.RefundFailed]: '',
  [TransferEventType.RefundPosted]: '',
  [TransferEventType.RefundSettled]: '',
  [TransferEventType.RefundReturned]: '',
  [TransferEventType.RefundSwept]: '',
  [TransferEventType.RefundReturnSwept]: '',
}

export const UserTransactionStatus: Record<TransferStatus, string> = {
  [TransferStatus.Pending]: 'Pending',
  [TransferStatus.Posted]: 'Paid',
  [TransferStatus.Settled]: 'Completed',
  [TransferStatus.FundsAvailable]: 'FundsAvailable',
  [TransferStatus.Cancelled]: 'Cancelled',
  [TransferStatus.Failed]: 'Failed',
  [TransferStatus.Returned]: 'Returned',
}

export const ExceptionalTransactionStatus = [TransferStatus.Cancelled, TransferStatus.Failed, TransferStatus.Returned]

export const NormalTransactionStatus = [TransferStatus.Pending, TransferStatus.Posted, TransferStatus.Settled]
