import cloneDeep from 'clone-deep'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, RenderInput } from 'utils'

import type { IResourceDocument } from '../Resources'
import { defaultInputs } from './constants'

interface IProps {
  data: IResourceDocument
  loading: string
  onClose: () => void
  onAdd: (data: Record<string, string | File>) => void
  onUpdate: (data: Record<string, string | File>) => void
}

export const ResourceDocumentOptions: Record<string, string> = {
  ppm: 'Private Placement Memorandum',
  WireTransfer: 'Finresi Wire Instructions',
}

export const ResourceDetail = (props: IProps) => {
  const { data, loading, onClose, onAdd, onUpdate } = props

  const [title, setTitle] = useState('')
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (data) {
      setTitle('Update')

      const newInputs = cloneDeep(inputs)

      Object.keys(newInputs).forEach((key) => {
        newInputs[key].value = (data as any)[key]
      })

      setInputs(newInputs)
    } else {
      setTitle('Add')
    }
  }, [data])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)

    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''

    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}

    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value

      if (newInputs[key].error) hasError = true
    }

    if (title === 'Add') {
      if (!newInputs['file'].value) {
        newInputs['file'].error = 'Required field'
        hasError = true
      }
    }

    setInputs(newInputs)
    if (hasError) return

    if (title === 'Add') onAdd(data)
    else onUpdate(data)
  }

  return (
    <Modal
      title={`${title} Resource`}
      titleOkay={title}
      loading={['add'].includes(loading)}
      onClose={onClose}
      onOk={onSubmit}
      isOpen
    >
      <div className="md:w-[400px] flex flex-col gap-4">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]

          return (
            <div key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
