import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Footer, MainHeader } from 'stories/layouts'

export const DashboardLayout = (props: any) => {
  const { children } = props
  const location = useLocation()

  const isWhitePage = useMemo(() => {
    return true
    const whitePages = ['how_it_works', 'lenders', 'faq', 'privacy_policy', 'terms_of_service', 'pipeline']

    for (const page of whitePages) if (location.pathname.includes(page)) return true
    if (location.pathname == '/marketplace') return true
    return false
  }, [location.pathname])

  return (
    <div className={`relative lg:w-full h-full-screen ${isWhitePage ? 'bg-gray-100/50' : 'bg-slate-100'}`}>
      <div className="shadow-sm w-full bg-white">
        <MainHeader type="white" />
      </div>
      <main className="mx-auto main">{children}</main>
      <Footer />
    </div>
  )
}
