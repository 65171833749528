import type { DBInvestment } from 'pages/Payment'
import { addDays, dateDiffDays, dayProfit } from 'utils'

export const estimatedRemainingProfit = (v: DBInvestment) => {
  let days = 0

  if (v.lastProfitDate) days = dateDiffDays(v.loan.maturityDate, addDays(v.lastProfitDate, 1))
  else days = dateDiffDays(v.loan.maturityDate, addDays(v.startDate, 1))

  return dayProfit(v.amount, v.ytm, days)
}
