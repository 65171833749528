import { ArrowLeftIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { cloneDeep } from 'lodash'
import type { DbBalance } from 'pages/Payment'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getBalances } from 'services/apis'

import type { Loan } from '../types'
import { InvestComplete } from './InvestComplete'
import { InvestInDeal } from './InvestInDeal'
import { InvestSign } from './InvestSign'

export const OpenForInvestment = ({
  loan,
  onClose,
  onDeposit,
  isIRAInvest = false,
}: {
  loan: Loan
  onClose: Function
  onDeposit: Function
  isIRAInvest?: boolean
}) => {
  const navigate = useNavigate()
  const [data, setData] = useState<Record<string, any>[]>([])
  const [step, setStep] = useState(-1)
  const [balance, setBalance] = useState<DbBalance>()
  const [method, setMethod] = useState<string>('')
  const isOpen = true

  const [searchParams] = useSearchParams()
  const envelopeId = searchParams.get('envelopeId')

  useEffect(() => {
    getBalances().then((data) => setBalance(data))
    if (envelopeId) setStep(2)
    else setStep(0)
  }, [])

  const onNext = (values: Record<string, any>) => {
    if (!isOpen) {
      onClose()
      return
    }
    if (step == 2) {
      navigate(`/dashboard`)
      onClose()
      return
    }
    const newData = cloneDeep(data)
    newData[step] = values
    setData(newData)

    setStep(step + 1)
  }

  return (
    <>
      <div
        tabIndex={-1}
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[198] md:inset-0 h-modal md:h-full justify-center flex"
      >
        <div className={`relative p-4 w-full h-full md:h-auto max-w-xl`}>
          <div className="relative bg-white rounded-xl pb-6 overflow-hidden">
            {/*header*/}
            <div
              className={`flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-xl ${
                !isOpen ? 'bg-gray-100' : step < 2 ? 'bg-light-green' : 'bg-indigo-800'
              } relative pb-20`}
            >
              <div className="text-center">
                <div
                  className={`bg-black ${
                    !isOpen ? 'text-white' : step < 2 ? 'text-light-green' : 'text-white'
                  } rounded-full inline-block p-2`}
                >
                  <ShoppingBagIcon className="w-8 h-8" />
                </div>
                {step < 2 ? (
                  <p className="font-semibold text-sm">{isOpen ? 'Open for investment' : 'Investment is closed'}</p>
                ) : method ? (
                  <p className="text-white font-semibold text-sm">
                    {method == 'Balance' ? (
                      <>Investment confirmation</>
                    ) : (
                      <>
                        Congratulations! Please deposit <br />
                        via wire to continue.
                      </>
                    )}
                  </p>
                ) : null}
              </div>

              {step == 1 && (
                <button
                  type="button"
                  className={`${
                    step < 2 ? 'text-black' : 'text-white'
                  } bg-transparent border border-transparent hover:border-gray-500 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white absolute left-2 top-6`}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowLeftIcon className="w-5 h-5" />
                </button>
              )}

              {step <= 1 && (
                <button
                  type="button"
                  className={`${
                    step < 2 ? 'text-black' : 'text-white'
                  } bg-transparent border border-transparent hover:border-gray-500 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white absolute right-2 top-6`}
                  onClick={() => onClose()}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
              )}
            </div>

            {step == 0 && (
              <InvestInDeal
                isIRAInvest={isIRAInvest}
                isOpen={isOpen}
                loan={loan}
                data={data}
                balance={balance}
                onNext={onNext}
                onDeposit={onDeposit}
                onClose={onClose}
              />
            )}
            {step == 1 && <InvestSign data={data} loan={loan} onNext={onNext} />}
            {step == 2 && <InvestComplete loan={loan} onNext={onNext} onSetMethod={(v: string) => setMethod(v)} />}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
    </>
  )
}
