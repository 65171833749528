import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { updateProfit } from 'services/apis'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, RenderInput } from 'utils'

import { ProfitStatus } from '../type'

const defaultInputs = (): Record<string, InputType> => {
  return {
    comment: {
      inputType: 'textarea',
      type: 'text',
      title: 'Comment',
      value: '',
      span: 2,
      required: false,
    },
    override: {
      inputType: 'toggle',
      title: 'Override Validation',
    },
  }
}

export const ProfitMarkModal = ({
  ids,
  status,
  onClose,
  isBatch,
}: {
  ids: number[]
  status: ProfitStatus
  onClose: Function
  isBatch: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())
  const [message, setMessage] = useState('')

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false
    if (!inputs['override'].value && isBatch) {
      toast('You should enable override to approve these items.', { type: 'info' })
      return
    }
    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    updateProfit({ ids: ids, status, comment: data.comment, override: data.override })
      .then(({ success, message }) => {
        if (success) {
          onClose(true)
          return
        }
        setMessage(message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={`Are you sure to Mark as ${status}...`}
      titleOkay="Confirm"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        <p className="mt-3">
          Are you sure to Mark as{' '}
          <span className="uppercase">
            {status == ProfitStatus.Approved ? (
              <span className="text-green-500 font-bold">Approved</span>
            ) : (
              <span className="text-red-800 font-bold">Rejected</span>
            )}
          </span>
          ?
        </p>
        <div className="md:w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            if (key == 'override') return null
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>

        {(message || isBatch) && (
          <>
            {message && !isBatch && (
              <p className="px-4 py-2 text-sm mb-2 border border-yellow-600 text-yellow-700 bg-yellow-50 rounded-lg">
                {message}
              </p>
            )}

            <div className="input">
              <RenderInput input={inputs['override']} Key="override" onChange={onChange} />
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
