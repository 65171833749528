import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { itemCountPerPage } from 'config'
import { Accordion } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { deleteServiceArticle, deleteServiceData, getServiceArticles } from 'services/apis/service.articles'
import { svgSearch } from 'stories/assets'
import { Button, Input, Pagination } from 'stories/components'
import { confirm, formatDate, sortedArray } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

import { ServiceArticleModal } from './ServiceArticleModal'
import { ServiceDataModal } from './ServiceDataModal'
import { type IArticleParent, type IServiceArticle, type IServiceData } from './types'
export const ManageServiceArticles = () => {
  useTitle(`Services Management`)

  const [isLoading, setLoading] = useState(false)
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [currentItem, setCurrentItem] = useState<IServiceArticle | null>()
  const [currentDataItem, setCurrentDataItem] = useState<IServiceData | null>()
  const [addServiceData, setAddServiceData] = useState<number>(0)
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)
  const [values, setValues] = useState<IServiceArticle[]>([])
  const [articleList, setArticleList] = useState<IArticleParent[]>([])
  const [filterQuery, setFilterQuery] = useState('')
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'createdAt',
    orderDir: -1,
  })
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  useEffect(() => {
    if (isLoading) return
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [pageNum])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && filterData(filters, 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const onChangeFilter = (key: string, value: any) => {
    if (!isGetUsersOnce) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else {
      filterData(newFilters, 0)
      setPageNum(0)
    }
  }

  const filterData = async (filters: any, _pageNum: number = -1) => {
    if (isLoading) return
    if (_pageNum === -1) _pageNum = pageNum
    const filterData = {
      ...filters,
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    }

    setLoading(true)
    const { data, total, articleList } = await getServiceArticles(filterData)
    console.log(articleList)
    setValues(data)
    setTotal(total)
    setArticleList(articleList)
    setLoading(false)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const onAdd = () => {
    setCurrentItem(null)
  }

  const onAddData = (id: number) => {
    setAddServiceData(id)
    setCurrentDataItem(null)
  }

  const onSort = (key: string, sortOrder: number) => {
    if (sortOrder == 0) sortOrder = -1
    const newFilters = Object.assign({}, filters)
    newFilters['orderBy'] = key
    newFilters['orderDir'] = sortOrder
    setFilters(newFilters)
  }

  const onDelete = async (item: IServiceArticle) => {
    if (!item.id) return

    const result = await confirm('Do you want to remove this data?')
    if (!result) return

    const index = values.findIndex((v) => v.id === item.id)
    if (index === -1) return

    setLoading(true)
    if (item.id) await deleteServiceArticle(item.id)

    const newValues = cloneDeep(values)
    newValues.splice(index, 1)
    setValues(newValues)
    setLoading(false)
  }

  const onDeleteData = async (item: IServiceData) => {
    if (!item.id) return

    const result = await confirm('Do you want to remove this data?')
    if (!result) return
    console.log(item.articleServiceId)
    const index = values.findIndex((v) => v.id === Number(item.articleServiceId))
    const index1 = values[index].serviceData.findIndex((v) => v.id === item.id)
    if (index1 === -1) return

    setLoading(true)
    if (item.id) await deleteServiceData(item.id)

    const newValues = cloneDeep(values)
    newValues[index].serviceData.splice(index1, 1)
    setValues(newValues)
    setLoading(false)
  }

  const onClose = async (updatedItem?: IServiceArticle) => {
    if (!updatedItem) {
      setCurrentItem(undefined)
      return
    }
    const newData = cloneDeep(values)
    // const { url } = await downloadS3Documents(updatedItem.image)
    // updatedItem.image = url

    if (currentItem) {
      const index = newData.findIndex((v) => v.id == updatedItem.id)
      newData[index] = updatedItem
    } else newData.push(updatedItem)

    filterData(filters)
    setCurrentItem(undefined)
  }

  const onCloseData = async (updatedItem?: IServiceData) => {
    if (!updatedItem) {
      setCurrentDataItem(undefined)
      return
    }
    const newData = cloneDeep(values)
    // const { url } = await downloadS3Documents(updatedItem.image)
    // updatedItem.image = url
    const index = newData.findIndex((v) => v.id == addServiceData)

    if (currentDataItem) {
      const index1 = newData[index].serviceData.findIndex((v) => v.id == updatedItem.id)
      newData[index].serviceData[index1] = updatedItem
    } else {
      newData[index].serviceData.push(updatedItem)
    }

    filterData(filters)
    setCurrentDataItem(undefined)
  }

  // Toggle function to expand or collapse a row
  const toggleRow = (rowId: number): void => {
    const isRowExpanded = expandedRows.includes(rowId)
    const newExpandedRows = isRowExpanded
      ? expandedRows.filter((id) => id !== rowId) // Remove from expanded rows
      : [...expandedRows, rowId] // Add to expanded rows

    setExpandedRows(newExpandedRows)
  }
  interface ExpandableRowContentProps {
    details: IServiceData[]
    isExpanded: boolean
  }

  const ExpandableRowContent: React.FC<ExpandableRowContentProps> = ({ details, isExpanded }) => {
    return (
      <>
        {isExpanded && (
          <tr
            className={`transition-all duration-300 ease-in-out ${
              isExpanded ? 'max-h-screen' : 'max-h-0'
            } overflow-hidden`}
          >
            <td colSpan={9} className="p-0">
              <div
                className={`transition-all duration-300 ease-in-out ${
                  isExpanded ? 'opacity-100' : 'opacity-0'
                } px-6 py-2`}
              >
                {details && details.length > 0 && (
                  <Accordion collapseAll>
                    {sortedArray(details).map((detail, index) => (
                      <Accordion.Panel key={index}>
                        <Accordion.Title className="py-1">
                          {detail.orderNum.toString() + '. ' + detail.title}
                        </Accordion.Title>
                        <Accordion.Content>
                          <div className="relative w-full h-fit">
                            <span className="flex absolute right-0 top-0">
                              <button
                                className="text-shade-blue hover-shadow1 cursor-pointer p-1"
                                onClick={() => {
                                  setAddServiceData(Number(detail.articleServiceId))
                                  setCurrentDataItem(detail)
                                }}
                              >
                                <PencilSquareIcon className="w-4 h-4" />
                              </button>

                              <button
                                className="text-red-700 hover-shadow1 cursor-pointer p-1"
                                onClick={() => onDeleteData(detail)}
                              >
                                <TrashIcon className="w-4 h-4"></TrashIcon>
                              </button>
                            </span>
                            <div className="mr-16">
                              {detail.imageURL.length > 0 && <img src={detail.imageURL} alt={detail.title} />}

                              <div dangerouslySetInnerHTML={{ __html: detail?.content ?? '' }} className="ck-content" />
                            </div>
                          </div>
                        </Accordion.Content>
                      </Accordion.Panel>
                    ))}
                  </Accordion>
                )}
              </div>
            </td>
          </tr>
        )}
      </>
    )
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <LayoutLoading show={isLoading} />

        <div className="flex flex-wrap items-center gap-2 mb-3">
          <p className="text-4xl font-semibold mb-10 flex gap-4 items-center">Manage Services ({total})</p>
          <div className="flex-1" />
          <Button onClick={onAdd}>New Service Article</Button>
        </div>

        <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-3">
          <Input
            type="search"
            title="Search ..."
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>

        <div className="table-container relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr className="font-variation-settings-600">
                <th scope="col" className="px-2 py-3 w-[40px]">
                  No
                </th>
                {renderHeader({
                  title: 'Title',
                  key: 'title',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'title' ? parseInt(filters.orderDir) : 0,
                })}

                {renderHeader({
                  title: 'Tag',
                  key: 'tag',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'tag' ? parseInt(filters.orderDir) : 0,
                })}

                {renderHeader({
                  title: 'Article Type',
                  key: 'isParent',
                  index: 0,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'isParent' ? parseInt(filters.orderDir) : 0,
                  className: 'w-[150px]',
                })}

                {renderHeader({
                  title: 'Published',
                  key: 'isPublished',
                  index: 1,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'isPublished' ? parseInt(filters.orderDir) : 0,
                })}

                {renderHeader({
                  title: 'Created At',
                  key: 'createdAt',
                  index: 4,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'createdAt' ? parseInt(filters.orderDir) : 0,
                  className: 'w-[150px]',
                })}

                {renderHeader({
                  title: 'Updated At',
                  key: 'updatedAt',
                  index: 4,
                  onSort,
                  sortable: true,
                  sortOrder: filters.orderBy == 'updatedAt' ? parseInt(filters.orderDir) : 0,
                  className: 'w-[150px]',
                })}

                <th scope="col" className="px-2 py-3 w-[100px]">
                  Action
                </th>
                <th scope="col" className="px-2 py-3 w-[100px]"></th>
              </tr>
            </thead>
            <tbody className="text-[14px] text-gray-900">
              {values
                .filter((v) => {
                  const { query } = filters
                  if (!query) return true
                  return (
                    (v.title && v.title.toLowerCase().includes(query.toLowerCase())) ||
                    (v.listTitle && v.listTitle.toLowerCase().includes(query.toLowerCase()))
                  )
                })
                .sort((a: any, b: any) => (a[filters.orderBy] > b[filters.orderBy] ? 1 : -1) * filters.orderDir)
                .map((value, index) => {
                  return (
                    <React.Fragment key={value.id}>
                      <tr className={`border-b ${index % 2 && 'bg-slate-50'} cursor-pointer hover:bg-gray-100`}>
                        <td className="font-variation-settings-600 text-shade-blue hover:underline cursor-pointer pl-3 py-3">
                          {index + 1}
                        </td>
                        <td className="hover:underline cursor-pointer px-2 py-2">{value.title}</td>
                        <td className="hover:underline cursor-pointer px-2 py-2">{value.tag}</td>
                        <td className="hover:underline cursor-pointer px-2 py-2">
                          {value.isParent ? (
                            <span className="p-2 bg-yellow-400 text-white">Parent</span>
                          ) : (
                            <span className="p-2 bg-gray-200">Children</span>
                          )}
                        </td>

                        <td className="px-2 py-2">{value.isPublished ? 'Yes' : 'No'}</td>

                        <td className="px-2 py-2">{formatDate(value.createdAt)}</td>

                        <td className="px-2 py-2">{formatDate(value.updatedAt)}</td>
                        <td className="px-2 py-2">
                          <span className="flex">
                            <button
                              className="text-green-500 hover-shadow1 cursor-pointer p-1"
                              onClick={() => onAddData(value.id)}
                            >
                              <PlusIcon className="w-4 h-4" />
                            </button>
                            <button
                              className="text-shade-blue hover-shadow1 cursor-pointer p-1"
                              onClick={() => setCurrentItem(value)}
                            >
                              <PencilSquareIcon className="w-4 h-4" />
                            </button>

                            <button
                              className="text-red-700 hover-shadow1 cursor-pointer p-1"
                              onClick={() => onDelete(value)}
                            >
                              <TrashIcon className="w-4 h-4"></TrashIcon>
                            </button>
                          </span>
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap text-sm text-blue-500"
                          onClick={() => toggleRow(value.id)}
                        >
                          {expandedRows.includes(value.id) ? 'Hide Details' : 'Show Details'}
                        </td>
                      </tr>
                      {/* Use the new ExpandableRowContent component */}
                      <ExpandableRowContent details={value.serviceData} isExpanded={expandedRows.includes(value.id)} />
                    </React.Fragment>
                  )
                })}
            </tbody>
          </table>
          {currentDataItem !== undefined && (
            <ServiceDataModal serviceArticleId={addServiceData} data={currentDataItem} onClose={onCloseData} />
          )}
          {currentItem !== undefined && (
            <ServiceArticleModal data={currentItem} onClose={onClose} articleList={articleList} />
          )}
        </div>

        <div className="flex justify-end items-center mt-3">
          <div className="flex-1" />
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
