import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { type IResourceDocument, ResourceDocumentType } from 'pages/Admin/Tools/Resources'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import {
  getLatestResources,
  openS3Document,
  openSubAgreement,
  openUnsignedAgreement,
  signInvestment,
} from 'services/apis'
import { Checkbox } from 'stories/components'
import { normalizeNumber } from 'utils'

import type { Loan } from '../types'

export const InvestSign = ({ data, loan }: { data: Record<string, any>[]; loan: Loan; onNext: Function }) => {
  const { subAgreementAt } = useSelector((state: RootState) => state.auth.profile)
  const [isLoading, setLoading] = useState(false)
  const [signStatus, setSignStatus] = useState<Record<string, boolean>>({})
  const [resources, setResources] = useState<IResourceDocument[]>([])

  const values = useMemo(() => {
    const rlt: Record<string, any> = {
      'Invest as': data[0].investAs,
    }
    return rlt
  }, [data])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getLatestResources()
      setResources(res)
      setLoading(false)
    })()
  }, [])

  const allSigned = useMemo(() => {
    return signStatus['rule501'] && signStatus['termsof'] && signStatus['privatePlacement']
  }, [signStatus])

  const onChangeSign = (key: string, v: boolean) => {
    const newStatus = cloneDeep(signStatus)
    newStatus[key] = v
    setSignStatus(newStatus)
  }

  const onReadFull = () => {
    const { amount } = data[0]
    openUnsignedAgreement(loan.id, amount)
  }

  const onSubmit = () => {
    if (!subAgreementAt) {
      toast('Please sign the Subscription Agreement before proceeding.', { type: 'warning' })
      return
    }
    setLoading(true)
    signInvestment(loan.id, {
      amount: data[0].amount,
      investAs: data[0].investAs,
    })
      .then(({ redirectUrl }) => {
        // onNext()
        window.open(redirectUrl, '_self')
      })
      .catch(() => setLoading(false))
  }

  const openDocument = async (type: ResourceDocumentType) => {
    setLoading(true)
    const targetResource = resources.find((item) => item.type === type)

    if (!targetResource) {
      toast(`This Resource document doesn't exist`, { type: 'error' })
      return
    }

    await openS3Document(targetResource.fileKey, false, targetResource.name + '.pdf')

    setLoading(false)
  }

  const onOpenAgreement = () => {
    setLoading(true)
    openSubAgreement()
      .then(({ redirectUrl }) => window.open(redirectUrl, '_blank'))
      .finally(() => setLoading(false))
  }

  return (
    <div className="w-full">
      <LayoutLoading show={isLoading} />
      <div className="px-6 -mt-14 relative">
        <div className="flex justify-center text-center gap-4 px-12 pb-6">
          <div className="rounded-xl bg-white p-6 border">
            <p className="text-4xl mb-6 font-semibold">$ {normalizeNumber(data[0].amount)}</p>
            <p className="text-desc">You're about to invest</p>
          </div>
        </div>
      </div>

      <div className="font-semibold text-sm py-4 border-b">
        {Object.keys(values).map((key) => (
          <div className="px-6 py-1.5 flex gap-2" key={key}>
            <p>{key}</p>
            <p>{values[key]}</p>
          </div>
        ))}
        <div className="px-6 py-1.5 flex flex-col gap-2">
          <Checkbox
            id="rule501"
            title={
              <p>
                I am an Accredited Investor according to
                <a
                  href="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501"
                  target="_blank"
                  className="text-link mx-1"
                >
                  Rule 501
                </a>
              </p>
            }
            value={signStatus['rule501']}
            onChange={(v) => onChangeSign('rule501', v)}
          />

          <Checkbox
            id="termsof"
            title={
              <p>
                I have read and agree to the terms of the
                <span
                  className="mx-1 text-link cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    onOpenAgreement()
                    return false
                  }}
                >
                  Subscription Agreement,
                </span>
                the
                <span
                  className="text-link mx-1"
                  onClick={(e) => {
                    e.preventDefault()
                    onReadFull()
                    return false
                  }}
                >
                  Promissory Note Secured by CSA
                </span>
                attached thereto.
              </p>
            }
            value={signStatus['termsof']}
            onChange={(v) => onChangeSign('termsof', v)}
          />

          <Checkbox
            id="privatePlacement"
            title={
              <p>
                I have read and understood the
                <span
                  className="mx-1 text-link cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    openDocument(ResourceDocumentType.PPM)
                    return false
                  }}
                >
                  Private Placement Memorandum
                </span>
                , including without limitation, all <b className="font-medium mx-1">risk factors</b> and
                <b className="font-medium mx-1">conflicts of interest</b> set forth therein.
              </p>
            }
            value={signStatus['privatePlacement']}
            onChange={(v) => onChangeSign('privatePlacement', v)}
          />
        </div>
      </div>

      <div className="px-6">
        <button
          type="button"
          onClick={onSubmit}
          disabled={!allSigned}
          className={`w-full ${
            allSigned ? 'text-black bg-light-green hover:bg-green-300' : 'text-gray-600 bg-green-200'
          } focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-semibold rounded-lg text-sm items-center px-5 py-2.5 text-center flex justify-center gap-2`}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
