import { EyeIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { AccountTypeText, InputType, itemCountPerPage, SettingKey } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { createBroadcastEmail, getBroadcastEmails, getSettingValue, openS3Document, updateSetting } from 'services/apis'
import { Button, Input2, Pagination, PlainTable } from 'stories/components'
import { confirm, formatTime, InputConvert, InputValidate, RenderInput, useTitle } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    accountType: {
      inputType: 'multiselect',
      title: 'Account Type',
      options: AccountTypeText,
      required: true,
      value: {},
      sort: true,
    },
    title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      required: true,
    },
    content: {
      inputType: 'textarea',
      title: 'Content',
      value: '',
      required: true,
    },
    file: {
      inputType: 'file',
      title: 'File',
      required: false,
    },
  }
}

export interface BroadcastEmail {
  accountType: string[]
  title: string
  content: string
  createdAt: string
  fileKey: string
}

export const BroadcastPage = () => {
  useTitle('Admin Tools / Broadcast')

  const [action, setAction] = useState('')
  const [content, setContent] = useState('')
  const [inputs, setInputs] = useState(defaultInputs())
  const [history, setHistory] = useState<BroadcastEmail[]>([])
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setAction('setting')
    reset()
    getSettingValue(SettingKey.CompanyBroadcast)
      .then(async (value) => setContent(value))
      .finally(() => setAction(''))
  }, [])

  useEffect(() => {
    fetchHistory()
  }, [pageNum])

  const fetchHistory = async () => {
    setAction('history')
    const { data, total } = await getBroadcastEmails({
      skip: pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setHistory(data)
    setTotal(total)
    setAction('')
  }

  const reset = () => {
    const newInputs = cloneDeep(inputs)
    for (const key in inputs) newInputs[key].value = ''
    newInputs.accountType.value = {}
    Object.keys(AccountTypeText).forEach((type) => {
      newInputs.accountType.value[type] = true
    })
    setInputs(newInputs)
  }

  const onPageNavigate = (num: number) => {
    setPageNum(num)
  }

  const historyTable = history.map((item, index) => [
    pageNum * itemCountPerPage + index + 1,
    item.accountType.map((type) => AccountTypeText[type]).join(', '),
    item.title,
    item.content,
    <div className="whitespace-nowrap">{formatTime(item.createdAt)}</div>,
    item.fileKey && (
      <span className="flex" onClick={() => openS3Document(item.fileKey)}>
        <span className="p-1 text-indigo-500 transition-all duration-200 hover-shadow1 rounded cursor-pointer">
          <EyeIcon className="w-4 h-4" />{' '}
        </span>
      </span>
    ),
  ])

  const onChange = (value: string) => {
    setContent(value)
  }

  const onSubmit = async () => {
    setAction('setting')
    await updateSetting(SettingKey.CompanyBroadcast, content)
    setAction('')
    toast('Company Broadcast has been saved.', { type: 'info' })
  }

  const onChangeForm = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSendEmail = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    data.accountType = Object.keys(data.accountType).filter((key) => data.accountType[key])
    const typeText = data.accountType.map((type: string) => AccountTypeText[type]).join(', ')
    const isConfirmed = await confirm(
      <p className="text-gray-500 mb-2">
        Do you want to send email to All <b>{typeText}</b>?
      </p>,
    )
    if (!isConfirmed) return

    setAction('history')

    createBroadcastEmail(data)
      .then(async () => {
        toast('Email has been broadcasted successfully.', { type: 'success' })
        await fetchHistory()
        reset()
      })
      .finally(() => setAction(''))
  }

  return (
    <div>
      <h1 className="text-2xl font-bold flex items-center pb-5">
        Company Broadcast
        {action && (
          <span className="ml-3">
            <Loading />
          </span>
        )}
      </h1>

      <div className="relative mb-6">
        <div className="mb-4">
          <Input2 title="Content" type="text" value={content} onChange={(value) => onChange(value)} />
        </div>
        <Button className="w-[250px]" loading={action == 'setting'} onClick={onSubmit}>
          Save
        </Button>
      </div>

      <div className="shadow1 p-4 rounded">
        <div className="border-b font-bold mb-4">Send Emails</div>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-12">
          <div className="md:col-span-7 gap-4 mb-3 flex flex-col">
            {Object.keys(inputs).map((key, index) => {
              if (key === 'file') return null
              let input = inputs[key]

              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChangeForm} />
                </div>
              )
            })}
          </div>
          <div className={`md:col-span-5 input md:p-5`}>
            <RenderInput input={inputs.file} Key="file" onChange={onChangeForm} />
          </div>
        </div>
        <div className="my-2">
          <Button className="w-[250px]" loading={action == 'history'} onClick={onSendEmail}>
            Send Email
          </Button>
        </div>

        <div className="-mb-4 mt-5">
          <div className="-mb-4">
            <PlainTable
              header={['No', 'Account Type', 'Title', 'Content', 'Created At', 'File']}
              data={historyTable}
              tdClass="px-4 py-2 break-spaces"
            />
          </div>
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={pageNum}
          />
        </div>
      </div>
    </div>
  )
}
