import { ON_APPLICATION_DEPOSIT_DATA, ON_APPLICATION_ERROR, ON_APPLICATION_UTM_DATA } from 'actions'
import { toast } from 'react-toastify'
import { createReducer } from 'utils'

export interface AppReducerState {
  isLoading: boolean
  statusBar: boolean
  iapSettings: Record<string, any>
  afxEnabled: boolean
  downloads: any[]
  sortKey: number
  uploadingStates: Record<string, any>
  cryptoBase: null

  depositData: Record<number, any> | null
  depositId: number
  utmData: Record<string, string> | null
}

const initialState: AppReducerState = {
  isLoading: false,
  statusBar: true,
  iapSettings: {},
  afxEnabled: true,
  downloads: [],
  sortKey: Math.floor(Math.random() * 1000) + 101,
  uploadingStates: {},
  cryptoBase: null,

  depositData: null,
  depositId: 0,
  utmData: null,
}

export const application = createReducer(initialState, {
  [ON_APPLICATION_ERROR](state: AppReducerState, { error: appError, toastDuration = 5000 }: any) {
    const error = (appError && appError.message) || null
    error && error != 'Unauthorized' && toast(error, { type: 'error' })
    return {
      ...state,
      error,
      message: null,
      isLoading: false,
      toastDuration: error ? toastDuration : undefined,
    }
  },
  [ON_APPLICATION_DEPOSIT_DATA]: (
    state: AppReducerState,
    { depositData, depositId }: { depositData: Record<number, any>; depositId: number },
  ) => {
    return {
      ...state,
      depositData,
      depositId,
    }
  },
  [ON_APPLICATION_UTM_DATA]: (state: AppReducerState, { utmData }: { utmData: Record<string, any> | null }) => {
    return {
      ...state,
      utmData,
    }
  },
})
