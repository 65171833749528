import { InboxArrowDownIcon } from '@heroicons/react/24/outline'
import { type IResourceDocument, ResourceDocumentType } from 'pages/Admin/Tools/Resources'
import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { getResources, openS3Document, transferWire } from 'services/apis'
import { normalizeNumber } from 'utils'

import { TransactionMethod } from '../constants'
import { DepositConfirmation } from '../Deposit/DepositConfirmation'
import { ContentHeader } from './ContentHeader'
import { renderBankAccount } from './util'

const finresiWireInstruction: Record<string, any> = {
  'Routing Number': '021000021',
  'Account Number': '936037800',
  'Account Name': 'Finresi Inc',
  'Account Address': '12 Penns Trail, Suite 138 Newtown PA 18940',
}

export const ConfirmationWireContent = ({
  direction,
  data,
  onNext,
  onBack,
}: {
  direction: TransferIntentCreateMode
  data: Record<number, any>
  onNext: Function
  onBack: Function
}) => {
  const isDeposit = direction == TransferIntentCreateMode.Payment

  const [isLoading, setLoading] = useState(false)
  const [wireInstruction, setWireInstruction] = useState<IResourceDocument>()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getResources()
      const targetData = res.find((item: IResourceDocument) => item.type === ResourceDocumentType.WireTransfer)
      setWireInstruction(targetData)
      setLoading(false)
    })()
  }, [])

  const confirmedData: Record<string, any> = useMemo(() => {
    const { amount, bankName, institution, routingNumber, accountNumber, subType } = data[1]
    const strAmount = normalizeNumber(amount, 2)

    if (isDeposit)
      return {
        Method: 'Wire Transfer',
        'Amount to Deposit (USD)': `$ ${strAmount}`,
      }

    return {
      Method: 'Wire Transfer',
      'Amount to Withdraw (USD)': `$ ${strAmount}`,
      'Bank Name': institution ? renderBankAccount(null, institution, false) : bankName,
      'Routing Number': routingNumber,
      'Account Number': accountNumber,
      Type: subType,
    }
  }, [data])

  const onSubmit = () => {
    const { institution } = data[1]
    const reqData: Record<string, any> = {
      direction,
      ...data[1],
      institutionId: institution ? institution.institution_id : null,
    }
    delete reqData.institution
    setLoading(true)
    transferWire(reqData, isDeposit ? 'deposit' : 'withdraw')
      .then((res) => onNext(res.id))
      .catch(() => setLoading(false))
  }

  return (
    <div className="rounded-lg bg-white divide-y relative">
      <ContentHeader description="Please confirm the below details." onBack={onBack} />

      <div className="pt-4 px-2">
        {Object.keys(confirmedData).map((title) => (
          <div className="mb-4" key={title}>
            <p className="text-desc mb-2">{title}</p>
            <div className="capitalize">{confirmedData[title]}</div>
          </div>
        ))}
      </div>

      {isDeposit && wireInstruction && (
        <div className="pt-4 px-2">
          <div className="flex justify-between items-center mb-4">
            <p>Wire Instruction</p>
            <InboxArrowDownIcon
              className="w-7 h-7 cursor-pointer transition-all duration-200 text-indigo-500 hover-shadow1 p-1"
              onClick={() => openS3Document(wireInstruction.fileKey)}
            />
          </div>

          <div className="grid grid-cols-3 gap-2">
            {Object.keys(finresiWireInstruction).map((title, index) => (
              <div key={title} className={`${index === 3 ? 'col-span-3' : ''} mb-4`}>
                <p className="text-desc mb-2">{title}</p>
                <div className="capitalize">{finresiWireInstruction[title]}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <DepositConfirmation
        isDeposit={isDeposit}
        data={data}
        method={data[0] == 0 ? TransactionMethod.Plaid : TransactionMethod.Wire}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </div>
  )
}
