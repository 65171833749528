import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'

export const usePasswordValidation = ({
  firstPassword = '',
  secondPassword = '',
  requiredLength = 8,
}: {
  firstPassword: string
  secondPassword: string | null
  requiredLength?: number
}) => {
  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [match, setMatch] = useState(false)

  useEffect(() => {
    setValidLength(firstPassword.length >= requiredLength ? true : false)
    setUpperCase(firstPassword.toLowerCase() !== firstPassword)
    setLowerCase(firstPassword.toUpperCase() !== firstPassword)
    setHasNumber(/\d/.test(firstPassword))
    setMatch(!!firstPassword && firstPassword === secondPassword)
    setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword))
  }, [firstPassword, secondPassword, requiredLength])

  return [validLength, hasNumber, upperCase, match, specialChar, lowerCase]
}

export const usePasswordValid = ({
  firstPassword = '',
  secondPassword = '',
  requiredLength = 8,
}: {
  firstPassword: string
  secondPassword: string | null
  requiredLength?: number
}) => {
  const [validLength, hasNumber, upperCase, match, specialChar] = usePasswordValidation({
    firstPassword,
    secondPassword,
    requiredLength,
  })
  return validLength && hasNumber && upperCase && specialChar && (secondPassword === null || match)
}

export const PasswordValidation = ({
  firstPassword,
  secondPassword = null,
}: {
  firstPassword: string
  secondPassword?: string | null
}) => {
  const [validLength, hasNumber, upperCase, match, specialChar] = usePasswordValidation({
    firstPassword,
    secondPassword,
  })

  const checkIcon = <CheckIcon className="w-4 h-4 text-green-400" />
  const uncheckIcon = <XMarkIcon className="w-4 h-4 text-red-500" />

  return (
    <div className="mb-4 text-sm text-gray-600">
      <div className="flex gap-2 items-center">
        {upperCase ? checkIcon : uncheckIcon}
        <p>1 capital character</p>
      </div>
      <div className="flex gap-2 items-center">
        {validLength ? checkIcon : uncheckIcon}
        <p>8 minimum characters</p>
      </div>
      <div className="flex gap-2 items-center">
        {hasNumber ? checkIcon : uncheckIcon}
        <p>1 number</p>
      </div>
      <div className="flex gap-2 items-center">
        {specialChar ? checkIcon : uncheckIcon}
        <p>1 unique character</p>
      </div>
      {secondPassword !== null && (
        <div className="flex gap-2 items-center">
          {match ? checkIcon : uncheckIcon}
          <p>Passwords match</p>
        </div>
      )}
    </div>
  )
}
