import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { LogoBlue } from 'assets'
import { Link, useNavigate } from 'react-router-dom'

import { BroadcastHeader } from './BroadcastHeader'

export function AuthHeader() {
  const navigate = useNavigate()

  return (
    <div className="w-full">
      <BroadcastHeader />
      <div className="max-w-screen-xl mx-10 xl:mx-auto relative pt-10 pb-6 px-2">
        <div className="absolute left-10 top-10">
          <div
            className="flex justify-center items-center gap-2 hover:cursor-pointer text-gray-700"
            onClick={() => navigate('/')}
          >
            <ArrowLeftIcon className="w-4 h-4" />
            <span className="text-sm">Back To Home</span>
          </div>
        </div>
        <div className="flex items-center justify-center w-full md:w-auto">
          <Link to="/home">
            <span className="sr-only">Finresi</span>
            <LogoBlue />
          </Link>
        </div>
      </div>
    </div>
  )
}
