import { authUpdateProfile, logout } from 'actions'
import { PasswordValidation, usePasswordValid } from 'components'
import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { updatePassword } from 'services/apis'
import { Button } from 'stories/components'
import { InputConvert, RenderInput, useTitle, validateInputs } from 'utils'

const defaultInputs = (isReset: boolean): Record<string, InputType> => {
  return {
    old: {
      inputType: 'text0',
      type: 'password',
      title: isReset ? 'Temporary Password' : 'Old Password',
      hasIcon: true,
      required: true,
    },
    new: {
      inputType: 'text0',
      type: 'password',
      title: 'New Password',
      hasIcon: true,
      required: true,
    },
    confirm: {
      inputType: 'text0',
      type: 'password',
      title: 'Confirm Password',
      hasIcon: true,
      required: true,
    },
  }
}

export const ChangePassword = ({ isReset = false }: { isReset?: boolean }) => {
  useTitle('Change Password')

  const { profile } = useSelector((state: RootState) => state.auth)

  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs(isReset))
  const isValidPassword = usePasswordValid({
    firstPassword: inputs.new ? inputs.new.value : '',
    secondPassword: inputs.confirm ? inputs.confirm.value : '',
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputs(newState)
  }

  const onSubmit = () => {
    const { hasError, data, newInputStates } = validateInputs(inputs)
    if (!hasError && data['old'] == data['new']) {
      toast('New Password is the same as the current password.', { type: 'warning' })
      return
    }
    if (hasError) {
      setInputs(newInputStates)
      return
    }
    delete data.confirm

    setLoading(true)
    updatePassword(data)
      .then(() => {
        toast('Password has been updated successfully.', { type: 'success' })
        if (profile.isResetPassword) {
          dispatch(authUpdateProfile({ ...profile, isResetPassword: false }))
          navigate('/dashboard')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="max-w-md text-sm">
      {Object.keys(inputs).map((key, index) => {
        let input = inputs[key]

        return (
          <div className={`input col-span-${input.span || 1}`} key={index}>
            <RenderInput input={input} Key={key} onChange={onChange} />
          </div>
        )
      })}
      <PasswordValidation firstPassword={inputs.new.value || ''} secondPassword={inputs.confirm.value || ''} />

      <Button disabled={!isValidPassword} onClick={onSubmit} loading={isLoading} full>
        {isReset ? 'Reset' : 'Change Password'}
      </Button>
    </div>
  )
}

export const ChangePasswordPage = () => {
  useTitle('Change Password')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(logout())
    navigate('/auth/signIn')
  }

  return (
    <div className="max-w-md px-4 py-6 md:px-10 md:py-10 bg-white rounded-sm  xs:w-120 w-[100vw]">
      <div className="p-1">
        <ChangePassword isReset />

        <p className="block mt-3 hover:text-gray-900 hover:underline text-gray-500 cursor-pointer" onClick={onLogout}>
          Back to Login
        </p>
      </div>
    </div>
  )
}
