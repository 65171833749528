import { Modal } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { formatTime } from 'utils'

import type { DbTask } from '../type'

export const TaskStatusModal = ({ task, onClose }: { onClose: any; task: DbTask }) => {
  const renderHistory = () => {
    const header = ['No', 'Action', 'By', 'Date', 'Comment']
    const data = task.commentHistory.map((item, index) => [
      index + 1,
      item.status,
      item.commentedBy,
      formatTime(item.createdAt),
      <pre>{item.comment}</pre>,
    ])
    return (
      <div className="-mb-6">
        <PlainTable header={header} data={data} />
      </div>
    )
  }

  const title = `Status Change History - #${task.id}`

  return (
    <Modal title={title} titleOkay="" onClose={onClose} isOpen>
      <div className="max-w-screen-xl">
        <div className="w-full">{renderHistory()}</div>
      </div>
    </Modal>
  )
}
