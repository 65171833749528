import { BuildingLibraryIcon } from '@heroicons/react/24/outline'

export const BankAccount = ({
  accountName,
  logo = null,
  bankName = null,
  accountNumber = null,
}: {
  accountName: string
  logo?: string | null | undefined
  bankName?: string | null
  accountNumber?: number | string | null
}) => {
  return (
    <div className="flex items-center gap-2 justify-between lg:justify-start p-2">
      <p className="text-md">{accountName}</p>
      <div className="flex items-center gap-1">
        {logo ? (
          <img src={`data:image/png;base64, ${logo}`} className="w-4 h-4 object-contain" />
        ) : (
          <BuildingLibraryIcon className="w-4 h-4 text-gray-600" />
        )}
        {bankName && <p className="text-desc capitalize">{bankName}</p>}
        {accountNumber && <p className="text-desc">(···· {accountNumber})</p>}
      </div>
    </div>
  )
}
