import { Bars3Icon } from '@heroicons/react/24/outline'
import { IServiceData } from 'pages/ServiceArticle/types'
import React, { useState } from 'react'

interface TableContentProps {
  items: IServiceData[]
}

const MobileTableContent: React.FC<TableContentProps> = ({ items }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleScroll = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      setIsMenuOpen(false)
    }
  }
  return (
    <>
      <div className="fixed bottom-20 right-5">
        <button
          className=" bg-sky-800 text-white p-4 rounded-full shadow-lg lg:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Bars3Icon className="w-4 h-4" />
        </button>
        {isMenuOpen && (
          <div className="absolute bottom-full -translate-y-3 right-0 lg:hidden max-w-80">
            <div className="px-10 py-8 bg-white rounded-xl shadow-lg lg:max-w-xs lg:mx-auto mt-8 lg:mt-0">
              <p className="text-md text-black font-bold mb-4 font-inter">Table of content</p>
              <ul className="mb-4">
                {items.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleScroll(item.id.toString())}
                    className="mb-4 last:mb-0 text-gray-900 text-sm whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer"
                  >
                    {item.title}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MobileTableContent
