import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addFaq, updateFaq } from 'services/apis'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, RenderInput } from 'utils'

import type { DbFaq } from '../type'

const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      inputType: 'text',
      type: 'text',
      title: 'Title',
      value: '',
      required: true,
      autoFocus: true,
    },
    content: {
      inputType: 'textarea',
      isLegacy: false,
      type: 'text',
      title: 'Content',
      value: '',
      required: true,
    },
  }
}

export const NewFaqModal = ({ item, onClose }: { item: DbFaq | null; onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    if (!item) return
    let newInputs = cloneDeep(inputs)
    newInputs.title.value = item.title
    newInputs.content.value = item.content
    setInputs(newInputs)
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    if (!item)
      addFaq(data)
        .then(() => {
          toast('New FAQ has been created.', { type: 'info' })
          onClose(true)
        })
        .finally(() => setLoading(false))
    else
      updateFaq(item.id, data)
        .then(() => {
          toast('The FAQ has been updated.', { type: 'info' })
          onClose(true)
        })
        .finally(() => setLoading(false))
  }

  return (
    <Modal
      title={item ? 'Edit FAQ' : 'Add FAQ'}
      titleOkay="Submit"
      loading={loading}
      onClose={() => onClose(false)}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900 w-144">
        <div className="text-sm">
          {Object.keys(inputs).map((key, index) => {
            return (
              <div className={`input mb-4`} key={index}>
                <RenderInput input={inputs[key]} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
