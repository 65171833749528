import { ArrowLeftIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline'

export const ContentHeader = ({ description, onBack }: { description: string; onBack?: Function }) => {
  return (
    <div className="text-center py-4">
      <div className="mb-2 relative">
        {onBack && (
          <span
            className="absolute left-0 top-2 text-gray-700 hover:text-gray-400 cursor-pointer"
            onClick={() => onBack()}
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </span>
        )}
        <div className="rounded-full bg-green-400 p-2 inline-block">
          <BuildingLibraryIcon className="w-6 h-6 text-white" />
        </div>
      </div>
      <p className="text-sm text-gray-600">{description}</p>
    </div>
  )
}
