import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, StepProgressBar } from 'stories/components'

import { ChooseMethodContent } from './ChooseMethod'
import { ConfirmationBankContent } from './ConfirmationBank'
import { ConfirmationWireContent } from './ConfirmationWire'
import { CongratulationsContent } from './Congratulations'
import { LinkedBankAccountsContent } from './LinkedBankAccounts'
import { WireTransferContent } from './WireTransfer'

export const WithdrawModal = ({ onClose }: { onClose: Function }) => {
  // useTitle('Withdraw')

  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Record<number, any>>({})
  const [step, setStep] = useState(0)

  useEffect(() => {
    setLoading(false)
  }, [])

  const onNext = (v: any) => {
    if (step == 3) {
      onClose()
      navigate('/payment/transaction')
      return
    }
    let newData = cloneDeep(data)
    if (step == 0 && newData[0] != v) newData = {}
    newData[step] = v
    setData(newData)
    setStep(step + 1)
  }

  const onBack = () => {
    setStep(step - 1)
  }

  return (
    <Modal
      isOpen
      title={step == 3 ? 'Thank You' : 'Complete these steps to get your funds'}
      titleOkay=""
      titleCancel=""
      // className="lg:!overflow-visible"
      onClose={() => onClose(false)}
    >
      <div className="sm:w-112">
        <div className="w-full relative">
          <LayoutLoading show={isLoading} />
          <div className="w-full relative">
            <div className="w-full px-1">
              <StepProgressBar options={['Choose Method', 'Get Details', 'Confirmation', 'Done']} index={step} />
            </div>

            <div>
              {step == 0 && <ChooseMethodContent defaultData={data[0]} onNext={onNext} />}

              {step == 1 && data[0] == 0 && (
                <LinkedBankAccountsContent
                  direction={TransferIntentCreateMode.Disbursement}
                  defaultData={data[1]}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}
              {step == 2 && data[0] == 0 && (
                <ConfirmationBankContent
                  direction={TransferIntentCreateMode.Disbursement}
                  data={data}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}

              {step == 1 && data[0] == 1 && (
                <WireTransferContent
                  direction={TransferIntentCreateMode.Disbursement}
                  defaultData={data[1]}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}
              {step == 2 && data[0] == 1 && (
                <ConfirmationWireContent
                  direction={TransferIntentCreateMode.Disbursement}
                  data={data}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}

              {step == 3 && (
                <CongratulationsContent direction={TransferIntentCreateMode.Disbursement} data={data} onNext={onNext} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
