// import Link from 'next/link'
import { IServiceData } from 'pages/ServiceArticle/types'
import React from 'react'

interface TableContentProps {
  items: IServiceData[]
}

const TableContent: React.FC<TableContentProps> = ({ items }) => {
  const handleScroll = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <>
      <div className="px-10 py-8 bg-white rounded-xl shadow-lg lg:max-w-xs lg:mx-auto mt-8 lg:mt-0 hidden lg:block">
        <p className="text-md text-black font-bold mb-4 font-inter">Table of content</p>
        <ul className="mb-4">
          {items.map((item, index) => (
            <li
              key={index}
              onClick={() => handleScroll(item.id.toString())}
              className="mb-4 last:mb-0 text-gray-900 text-sm whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer"
            >
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default TableContent
