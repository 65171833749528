import { Dropdown, Tooltip } from 'flowbite-react'
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js'
import { useMemo, useState } from 'react'
import { flagLink } from 'utils'

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
const countryCodes = getCountries()
const callingCodes: any = {}
export const countries: any = {}
export const countryCodeByCallingCode: any = {}

countryCodes.forEach((code) => {
  countries[`${code}`] = regionNames.of(code)
  const callingCode = getCountryCallingCode(code)
  callingCodes[`${code}`] = callingCode
  countryCodeByCallingCode[callingCode] = code
})
countryCodeByCallingCode['1'] = 'US'

const sortedCountries = Object.keys(countries).sort((a, b) => (countries[a] > countries[b] ? 1 : -1))

export const PhoneSelector = ({
  countryCode,
  disabled,
  onChange,
}: {
  countryCode: CountryCode
  disabled: boolean
  onChange: Function
}) => {
  const [filterName, setFilterName] = useState('')

  const renderItem = (code: CountryCode, full: boolean) => {
    if (!code) return <div />
    return (
      <div className={`flex gap-1 items-center ${full ? 'w-72' : 'w-6'}`}>
        <img src={flagLink(code)} className="w-6 h-4" />
        {full ? (
          <span className="text-sm">
            {countries[code] || ''}
            (+{callingCodes[code] || ''})
          </span>
        ) : (
          <span></span>
          // <span className="text-sm">+{callingCodes[code] || ''}</span>
        )}
      </div>
    )
  }

  if (disabled)
    return (
      <Tooltip content={countries[countryCode]}>
        <div className="bg-gray-100 px-2 py-1.5">
          <img src={flagLink(countryCode)} className="w-6 h-4" />
        </div>
      </Tooltip>
    )
  const menus = useMemo(
    () =>
      sortedCountries
        .filter((code) => countries[code].toLowerCase().includes(filterName.toLowerCase()))
        .map((country) => (
          <Dropdown.Item
            key={country}
            value={country}
            onClick={() => {
              setFilterName('')
              onChange(country)
            }}
            theme={{
              base: `relative flex items-center justify-start py-1.5 px-3 text-sm text-gray-700 cursor-pointer w-full hover:bg-gray-100 focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white  ${
                countryCode == country ? 'bg-gray-200' : ''
              }`,
            }}
          >
            {renderItem(country as any, true)}
          </Dropdown.Item>
        )),
    [countryCode, filterName],
  )

  return (
    <Dropdown
      label={<Tooltip content={countries[countryCode]}>{renderItem(countryCode, false)}</Tooltip>}
      color="gray"
      fullSized
      value={countryCode}
      className="bg-white overflow-y-auto"
      size="xs"
      theme={{
        content: 'py-1 max-h-80 relative',
        floating: {
          header: 'block py-0 px-0 text-sm text-gray-700 dark:text-gray-200 w-full',
          base: 'relative z-50 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none !left-0',
        },
      }}
    >
      <Dropdown.Header className="!p-0">
        <div className="block text-sm w-full">
          <input
            placeholder="Filter by country name.."
            value={filterName}
            className="px-3 py-2 w-full block appearance-none focus:outline-none focus:ring-0"
            onChange={(e) => setFilterName(e.target.value)}
          />
        </div>
      </Dropdown.Header>
      {menus}
    </Dropdown>
  )
}
