import { BarsArrowDownIcon, BarsArrowUpIcon, EyeIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { LoanDocument } from 'pages/Marketplace'
import { useEffect, useMemo, useState } from 'react'
import { getLoanNextresDocuments, openS3Document } from 'services/apis'
import { Checkbox, Input2, Modal } from 'stories/components'

export interface NextresDocument {
  id?: number
  createdAt: number
  fileKey: string
  name: string
  status: string
  category: string
}

export const NextresDocumentsDialog = ({
  id,
  documents: originalDocuments,
  onClose,
  onSubmit: _onSubmit,
}: {
  id: number
  documents: LoanDocument[]
  onClose: Function
  onSubmit: Function
}) => {
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [documents, setDocuments] = useState<NextresDocument[]>([])
  const [selected, setSelected] = useState<Record<string, boolean>>({})
  const [sortKey, setSortKey] = useState('')
  const [sortDir, setSortDir] = useState(true)

  useEffect(() => {
    getLoanNextresDocuments(id)
      .then((res) => {
        if (res.success) setDocuments(res.data.map((doc: any) => doc))
      })
      .finally(() => setLoading(false))
  }, [])

  const sortedDocuments = useMemo(() => {
    const query = searchQuery.trim().toLowerCase()
    let docs = documents
      .filter((doc) => {
        if (!query) return true
        return doc.name.toLowerCase().includes(query) || (doc.category && doc.category.toLowerCase().includes(query))
      })
      .sort((a: any, b: any) => {
        const aV = a[sortKey]
        const bV = b[sortKey]
        if (aV > bV) return 1
        if (aV == bV) return 0
        return -1
      })
    if (sortDir) docs = docs.reverse()
    return docs
  }, [documents, sortDir, sortKey, searchQuery])

  const onSort = (key: string) => {
    setSortKey(key)
    if (sortKey == key) setSortDir(!sortDir)
  }

  const onSelected = (key: string, value: boolean) => {
    const newData = cloneDeep(selected)
    if (!value) delete newData[key]
    else newData[key] = value
    setSelected(newData)
  }

  const onSubmit = () => {
    const filteredDocs = documents.filter((doc) => Object.keys(selected).includes(doc.fileKey))
    return _onSubmit(filteredDocs)
  }

  const renderHeader = (key: string, title: any) => {
    return (
      <div className="flex flex-wrap font-normal items-center">
        <p>{title}</p>

        <span className="ml-2">
          {sortKey == key &&
            (sortDir ? <BarsArrowUpIcon className="w-3 h-3" /> : <BarsArrowDownIcon className="w-3 h-3" />)}
        </span>
      </div>
    )
  }

  const renderRow = (document: NextresDocument, index: number) => {
    const imported = !!originalDocuments.find((doc) => doc.originalKey == document.fileKey)
    const title = `${index + 1}. ${document.name}`

    return (
      <tr className={`bg-gray-50`} key={`first-${document.fileKey}`}>
        <td className="px-3 py-2">
          {imported ? (
            <p className="text-gray-600 pl-6">{title}</p>
          ) : (
            <Checkbox
              id={`condition-no-${document.fileKey}`}
              title={title}
              checked={!!selected[document.fileKey]}
              onChange={(value) => onSelected(document.fileKey, value)}
            />
          )}
        </td>
        <td className="px-2">
          <span className="text-[13.5px]">{document.category}</span>
        </td>
        <td className="px-2">
          <button
            className="p-1 cursor-pointer hover-shadow1 rounded flex justify-center"
            onClick={() => openS3Document(document.fileKey, true)}
          >
            <EyeIcon className="w-4 h-4" />
          </button>
        </td>
      </tr>
    )
  }

  return (
    <Modal
      title={'Select Documents to import from Nextres'}
      titleOkay="Import"
      titleCancel="Close"
      loading={loading}
      disabled={!Object.keys(selected).length}
      isOpen
      onClose={() => onClose()}
      onOk={onSubmit}
    >
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full h-fit mb-2 gap-2">
          <Input2
            type="search"
            title="Search"
            hasIcon
            icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
          />
        </div>
        <div className="relative overflow-auto max-h-[calc(90vh-200px)]">
          <table className="w-full font-normal text-sm text-left text-black">
            <thead className="border-b">
              <tr>
                <th
                  className="bg-gray-100 px-2 py-2 border-b border-slate-300 cursor-pointer"
                  onClick={() => onSort('name')}
                >
                  {renderHeader('name', 'Name')}
                </th>
                <th
                  className="bg-gray-100 px-2 border-r border-slate-300 cursor-pointer"
                  onClick={() => onSort('category')}
                >
                  {renderHeader('category', 'Category')}
                </th>
                <th className="bg-gray-100 px-2 font-normal">Action</th>
              </tr>
            </thead>

            <tbody className="text-gray-900">
              {sortedDocuments.map((document: any, index: number) => renderRow(document, index))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  )
}
