import { ClockIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import type { Color } from 'stories/types'

interface InputProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * rows
   */
  rows?: number
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is autofocus
   */
  autoFocus?: boolean
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * Is has icon
   */
  required?: boolean
  /**
   * Icon component
   */
  prefix?: string
  /**
   * onChange
   */
  history?: boolean
  /**
   * Icon component
   */

  onBlurNull?: boolean

  copyable?: boolean

  isLegacy?: boolean

  onChange: (e: any) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  showHistory?: () => void
  onKeyPress?: (e: any) => void
}

/**
 * Primary UI component for user interaction
 */
export const TextArea = ({
  rows = 4,
  color = 'sky',
  disabled = false,
  autoFocus = false,
  title = '',
  placeholder = ' ',
  name = '',
  value = '',
  error = '',
  className = '',
  prefix = '',
  history = false,
  required = false,
  onBlurNull = true,
  copyable = false,
  isLegacy = true,
  onChange = () => {},
  showHistory = () => {},
  onBlur = () => {},
  ...props
}: InputProps) => {
  const classNames = [
    'block',
    'rounded-t',
    'px-2.5',
    'pb-[2px]',
    isLegacy ? 'pt-2' : 'pt-[27px]',
    'w-full',
    'text-[15px]',
    'text-gray-900',
    'border',
    'border-gray-300',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-${color}-600`,
    'peer',
    prefix.length > 0 && 'pl-7',
    error && 'border-rose-700',
    disabled ? 'cursor-not-allowed bg-gray-100' : 'bg-white',
  ]

  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (rows || !inputRef.current) return
    const onChange = () => {
      if (!inputRef.current) return
      inputRef.current.style.height = `${inputRef.current?.scrollHeight}px`
    }
    onChange()
    inputRef.current.addEventListener('change', onChange)
    return () => inputRef.current?.removeEventListener('change', onChange)
  }, [inputRef.current])

  const changeInput = (e: any) => {
    const _value = e.target.value
    onChange(_value)
    if (!onBlurNull) {
      setTimeout(() => {
        if (document.activeElement === inputRef.current) {
          if (inputRef?.current?.value === _value) {
            inputRef?.current?.blur()
          }
        }
      }, 5000)
    }
  }

  const copyValue = () => {
    toast('Copied to your Clipboard!', { type: 'info' })
    navigator.clipboard.writeText(value)
  }

  return (
    <div className="input-container">
      <div className={`group relative z-0 w-full ${className}`}>
        {prefix.length > 0 && (
          <div className="text-gray-600 text-[15px] flex absolute top-[28px] left-0 items-center pl-3 pointer-events-none">
            {prefix}
          </div>
        )}
        {isLegacy && title && <p className="font-medium mb-2">{title}</p>}
        <textarea
          rows={rows}
          name={name}
          className={classNames.join(' ')}
          placeholder={placeholder}
          ref={inputRef}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          required={required}
          onChange={changeInput}
          onBlur={() => onBlur()}
          {...props}
        />
        {!isLegacy && (
          <label
            htmlFor={name}
            className="absolute text-[12px] text-gray-700 top-2.5 border-b z-10 origin-[0] left-2.5 flex gap-2 items-center"
          >
            {title}
            {required && '*'}
            {history && (
              <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
                <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
              </span>
            )}
            {copyable && (
              <span className="ml-1 hidden group-hover:inline" onClick={() => copyValue()}>
                <DocumentDuplicateIcon className="h-[14px] w-[14px] text-gray-600 cursor-pointer" aria-hidden="true" />
              </span>
            )}
          </label>
        )}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pl-2 pt-[1px]">{error}</p>}
    </div>
  )
}
