import type { DbPlaidAccount } from 'pages/Payment'
import { HistoriesTab } from 'pages/Payment/Histories'
import { useEffect, useState } from 'react'
import { getPlaidAccounts } from 'services/apis'

export const HistorySection = (props: { setLoading: Function }) => {
  const [accounts, setAccounts] = useState<DbPlaidAccount[]>([])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    props.setLoading(true)
    getPlaidAccounts()
      .then((data) => setAccounts(data.filter((account: DbPlaidAccount) => !account.deleted)))
      .finally(() => props.setLoading(false))
  }

  return <HistoriesTab accounts={accounts} defaultTrxId={0} />
}
