import _imgAuthFooter from './auth-footer.png'
import _imgCongratulations from './congratulations.png'
import _LogoE from './eicon.png'
import _svgIcon from './icon.svg'
import _LogoBlue from './logo.svg'
import _LogoWhite from './logo-white.svg'
import _LogoWhiteText from './logo-white-text.svg'

export const LogoBlue = () => <img src={_LogoBlue} className="w-36" />
export const LogoWhite = () => <img src={_LogoWhite} className="h-12" />
export const LogoWhiteText = () => <img src={_LogoWhiteText} className="w-36" />
export const imgLogoWhite = _LogoWhite
export const imgLogo = _LogoBlue
export const imgAuthFooter = _imgAuthFooter

export const imgCongratulations = _imgCongratulations
export const LogoE = _LogoE

export * from './aboutus'
export * from './home'
export * from './howitworks'
export * from './icons'
export * from './lenders'
export * from './profile'

export const svgIcon = _svgIcon
