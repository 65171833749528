import { ArrowRightIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline'
import {
  imgBetterLoans,
  imgBgGray,
  imgConnectInvestor,
  imgEarnMonthly,
  imgInstitutionalGraph,
  imgLoanPrincipalLock,
  imgLoanPrincipalPie,
  imgRealEstateGray,
  imgRealEstateGreen,
  imgRealEstateTrx,
  imgTopLeft,
  imgUnderwritePurchase,
  imgWelcomeMarketplace,
  svgIcon,
} from 'assets'
import { Image } from 'components/Image'
import { dummyLoans } from 'pages/Home/data'
import { Link } from 'react-router-dom'
import { useTitle } from 'utils'

import { FaqFooter } from './faqFooter'

export const HowItWorksPage = () => {
  useTitle('How it Works')

  return (
    <div>
      <div className="w-full bg-[#648aee] relative overflow-hidden">
        <img src={imgBgGray} className="absolute w-full top-0 left-0 right-0 bottom-0 h-full" />

        <div className="fullContent relative py-16 lg:py-0">
          <div className="grid lg:flex lg:flex-row-reverse items-center gap-10">
            <div className="relative text-white flex-[3] z-10">
              <p className="xs:text-5xl text-4xl font-medium">Good Debt & Real Estate</p>
              <p className="xs:text-4xl text-3xl mb-6 font-medium text-light-green">
                How it all works to generate wealth.
              </p>
              <p className="md:mb-16 mb-8">
                Our mission is to offer a platform that can sustain the ups and downs of any economy, empowering
                accredited investors to generate wealth through private real estate debt. Here’s how we do it.
              </p>

              <div className="grid grid-cols-1 sm:grid-cols-5 relative md:mb-16 mb-8">
                {[
                  'Lenders originate real estate loans',
                  'Finresi evaluates and purchases loans',
                  'Finresi opens up investment opportunity to our investor community',
                  'Investors participate and receive monthly distributions',
                  'At loan maturity, investors can reinvest or withdraw',
                ].map((v, index) => (
                  <div key={index} className="relative h-12">
                    {index != 4 && (
                      <div className="block bg-white/50 w-0.5 h-full ml-[7px] sm:m-0 sm:h-0.5 sm:w-full rounded-full" />
                    )}
                    <div
                      className={`w-4 h-4 ${
                        index < 2 ? 'bg-white' : 'bg-white/70'
                      } rounded-full absolute top-0 left-0 sm:relative sm:-mt-[8px] sm:mb-4`}
                    />
                    <p className={`text-xs xl:pr-8 pr-4 text-white absolute top-0 ml-8 sm:relative sm:m-0`}>{v}</p>
                  </div>
                ))}
                <div
                  className="bg-white absolute top-0 left-0 w-0.5 h-1/3 ml-[7px] sm:m-0 sm:w-1/3 sm:h-0.5"
                  // style={{ width: '30%' }}
                />
              </div>

              <div className="block">
                <Link
                  to="/auth/signUp"
                  className="inline-block py-3 px-6 bg-light-green rounded-full text-black hover:bg-white hover:text-gray-600 font-semibold"
                >
                  Sign up
                </Link>
              </div>
            </div>
            <div className="flex-[2.4] xl:py-24 lg:py-16 overflow-visible flex flex-wrap lg:block justify-around gap-y-4">
              <img src={imgTopLeft} className="absolute md:top-0 bottom-0 md:h-full object-contain" />

              <div className="w-64 lg:ml-auto rounded-lg overflow-hidden relative">
                <Image src={dummyLoans[0].image} className="w-full block aspect-video object-cover" />
                <div className="bg-white p-4 border-l border-r border-gray-300">
                  <div className="flex gap-4 items-center mb-2">
                    <div className="flex-1">
                      <p className="text-xs">70% Funded</p>
                      <div className="bg-light-green h-1 rounded-full" style={{ width: '70%' }} />
                    </div>
                    <span className="bg-yellow-300 text-2xs text-black font-semibold px-1.5 py-1 rounded-md">
                      Closed
                    </span>
                  </div>
                  <p className="font-bold mb-1 text-xs">Bartlett, NY Acquisition #432</p>
                  <p className="text-xs text-gray-400">Single Family home with 2 bedrooms and 1 full bath</p>
                </div>

                <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0">
                  <div className="flex divide-x">
                    {[
                      ['12%', 'APY'],
                      ['80%', 'LTV'],
                      ['12 Months', 'TERM'],
                    ].map((v, index) => (
                      <div key={index} className="flex-1 text-center py-2">
                        <p className="font-bold text-xs">{v[0]}</p>
                        <p className="text-2xs text-gray-400">{v[1]}</p>
                      </div>
                    ))}
                  </div>
                  <div className="px-4 py-2">
                    <p className="bg-black rounded-md py-1 text-white text-center text-xs">View Detail</p>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg p-6 pr-16 text-xs h-40 inline-block relative lg:-mt-16 lg:ml-10">
                <p className="font-semibold text-light-green text-xl">11.5%</p>
                <p className="text-gray-400 mb-4">Annual Return</p>
                <p className="font-semibold text-xl">2 months</p>
                <p className="text-gray-400">Term Remaining</p>
              </div>

              <div className="w-72 rounded-xl overflow-hidden relative lg:ml-auto">
                <div className="bg-white p-4 border-l border-r border-gray-300">
                  <div className="flex flex-col gap-1 mb-4">
                    <p className="text-lg font-semibold">
                      <span className="text-light-green">$1,834,000</span> Funded
                    </p>
                    <div className="bg-gray-200 rounded-full">
                      <div className="bg-light-green h-1.5 rounded-full" style={{ width: '80%' }} />
                    </div>
                    <p className="text-xs text-gray-400">Funded by 138 Investors</p>
                  </div>
                  <p className="font-bold">$4,813,000</p>
                  <p className="text-xs text-gray-400">Target</p>
                </div>

                <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0 px-4">
                  <div className="flex">
                    {[
                      ['12%', 'APY'],
                      ['80%', 'LTV'],
                      ['12 Months', 'TERM'],
                    ].map((v, index) => (
                      <div key={index} className="flex-1 py-6">
                        <p className="font-bold text-sm">{v[0]}</p>
                        <p className="text-2xs text-gray-400">{v[1]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="flex flex-col md:gap-28 gap-16 content my-16 md:my-32 text-gray-800">
          <div className="text-center">
            <p className="md:text-5xl text-4xl font-semibold p-4 inline-block rounded-lg">Our step-by-step process.</p>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-16">
            <div className="flex-[3] flex justify-center">
              <div className="relative overflow-visible">
                <Image src={imgBetterLoans} className="object-cover xs:w-[328px] xs:h-[328px] xs:mx-20 mx-auto" />
                <div className="text-base">
                  <p className="absolute bg-light-green px-2 py-1 rounded-md top-10 right-0">Exclusive partnership</p>
                  <p className="absolute bg-gray-200 px-2 py-1 rounded-md top-32 left-0">Better loan origination</p>
                  <p className="absolute bg-gray-200 px-2 py-1 rounded-md top-60 left-6 xs:left-12">Risk management</p>
                  <p className="absolute bg-gray-200 px-2 py-1 rounded-md top-56 right-10">Quality loans</p>
                </div>
              </div>
            </div>
            <div className="flex-[2]">
              <div className="max-w-xl md:max-w-sm">
                <p className="xs:text-4xl text-3xl font-semibold mb-4">Better diligence. Better loans.</p>
                <p>
                  We have a partner-exclusive relationship with a high-volume lender, giving us first rights to their
                  more than $500M+ of loan applications.
                  <br />
                  <br />
                  Unlike other platforms that just buy loans, this lets us control and manage our risk, which allows our
                  team to focus on adding the best deals on our platform.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row items-center gap-16 mb-14">
            <div className="flex-1">
              <div className="max-w-xl md:max-w-sm mx-auto font-medium">
                <div>
                  <p className="xs:text-4xl text-3xl font-semibold mb-4">
                    We underwrite and purchase loans in accordance with our institutional criteria.
                  </p>
                  <p>Underwrite, approve, and purchase the loans we want to offer on our platform.</p>
                </div>
              </div>
            </div>
            <div className="flex-1 flex justify-center">
              <div className="relative overflow-visible">
                <Image src={imgUnderwritePurchase} className="object-cover w-[329px] h-[217px]" />
                <div className="text-lg">
                  <p className="absolute bg-light-green px-2 py-1 rounded-md top-4 right-6">Loan</p>
                  <p className="absolute bg-gray-200 px-2 py-1 rounded-md top-32 left-0">Loan</p>
                  <p className="absolute bg-gray-200 px-2 py-1 rounded-md top-48 left-16">Loan</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-around lg:gap-16 md:gap-4 gap-16">
            <div className="flex flex-1 xl:flex-none justify-center relative">
              <Image src={imgWelcomeMarketplace} className="object-cover w-[729px] h-[466px]" />

              <div className="absolute top-36 xl:left-14 lg:left-[6%] left-4 shadow-md bg-white rounded-lg p-4 pr-8 text-xs inline-block">
                <p className="font-semibold text-light-green text-lg">11.5%</p>
                <p className="text-gray-400 mb-4">Annual Return</p>
                <p className="font-semibold text-lg">2 months</p>
                <p className="text-gray-400">Term Remaining</p>
              </div>

              <div className="absolute top-48 lg:right-[6%] right-4 shadow-md w-64 rounded-xl overflow-hidden">
                <div className="bg-white p-4 border-l border-r border-gray-300">
                  <div className="flex flex-col gap-1 mb-4">
                    <p className="text-lg font-semibold">
                      <span className="text-light-green">$1,834,000</span> Funded
                    </p>
                    <div className="bg-gray-200 rounded-full">
                      <div className="bg-light-green h-1.5 rounded-full" style={{ width: '80%' }} />
                    </div>
                    <p className="text-xs text-gray-400">Funded by 138 Investors</p>
                  </div>
                  <p className="font-bold">$4,813,000</p>
                  <p className="text-xs text-gray-400">Target</p>
                </div>

                <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0 px-4">
                  <div className="flex">
                    {[
                      ['12%', 'APY'],
                      ['80%', 'LTV'],
                      ['12 Months', 'TERM'],
                    ].map((v, index) => (
                      <div key={index} className="flex-1 py-6">
                        <p className="font-bold text-sm">{v[0]}</p>
                        <p className="text-2xs text-gray-400">{v[1]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="max-w-xl md:max-w-sm mx-auto font-medium">
                <p className="xs:text-4xl text-3xl font-semibold mb-4">Browse available opportunities.</p>
                <p className="mb-4">
                  Once dilligenced and purchased by Finresi, the available investment opportunities are offered on our
                  platform.
                </p>
                <p className="mb-4">
                  These are all first-lien loans secured by an actual asset (the property), meaning in the unlikely
                  event of foreclosure, you get paid back first.
                </p>
                <p className="bg-orange-200 text-orange-700 p-5 rounded-md">
                  Diversify your investment portfolio with residential or commercial, different borrowers, and across
                  all 50 U.S. states!
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row items-center justify-around gap-16">
            <div className="">
              <div className="max-w-xl md:max-w-sm mx-auto font-medium">
                <p className="xs:text-4xl text-3xl font-semibold mb-4">Connect with investor relations experts.</p>
                <p className="mb-4">
                  Our investor relations team can help you understand our underwriting process, the details of each
                  deal, and explain the various terminology so you can make your best investing decisions.
                </p>
              </div>
            </div>
            <div className="flex justify-center relative">
              <Image src={imgConnectInvestor} className="object-cover xs:w-[447px] xs:h-[429px]" />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center justify-around gap-16 relative">
            <img src={imgEarnMonthly} className="absolute -top-8 right-[50%]" />
            <div className="flex-1 relative">
              <div className="shadow-xl rounded-lg px-8 py-6 text-sm w-64 ml-auto mr-14 flex flex-col gap-y-2 bg-white mt-16 mb-6">
                <ReceiptPercentIcon className="text-indigo-500 w-12 h-12" />
                <p className="uppercase">Interest Income</p>
                <p className="font-semibold text-base">$6,000.00</p>
                <p>October</p>
              </div>
              <div className="shadow-xl rounded-lg px-8 py-6 text-sm w-64 ml-auto -mr-4 flex flex-col gap-y-2 bg-white">
                <ReceiptPercentIcon className="text-indigo-500 w-12 h-12" />
                <p className="uppercase">Interest Income</p>
                <p className="font-semibold text-base">$9,000.00</p>
                <p>November</p>
              </div>
            </div>
            <div className="flex-1 relative">
              <div className="max-w-xl md:max-w-sm mx-auto font-medium">
                <p className="xs:text-4xl text-3xl font-semibold mb-4">Earn monthly income as loans are paid.</p>
                <p className="mb-4">
                  Now that you’ve invested in some deals, you’ll earn monthly interest as real estate investors make
                  interest payments on their loans. We handle the servicing of all loans and provide updates as needed.
                </p>
                <p className="bg-amber-100 text-amber-800 p-5 rounded-md mb-4">
                  The majority of deals are a 12-month term, but you can invest at any time.
                </p>
                <p className="text-2xl font-semibold mb-4">What is the unrecorded collateral agreement?</p>
                <p className="">
                  You enter into this agreement on every deal you invest in. In the unlikely event of Finresi’s
                  insolvency, this gives you a true stake in the underlying loan connected to the property, so you never
                  have to worry about being made whole.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col-reverse md:flex-row items-center gap-16">
            <div className="flex-1">
              <div className="max-w-xl md:max-w-sm mx-auto font-medium">
                <div className="mb-4">
                  <p className="xs:text-4xl text-3xl font-semibold mb-4">The loan - and principal is repaid.</p>
                  <p>
                    Once the property is sold or refinanced, the loan is repaid, and so is your principal. To date, we
                    have repaid 100% of principal.
                  </p>
                </div>
                <p className="text-2xl font-semibold mb-4">Principal Protection™</p>
                <p className="mb-4">
                  In addition to repaying 100% of principal, we set aside a fixed amount from each loan to protect
                  investors’ principal.
                </p>
              </div>
            </div>
            <div className="flex-1 flex justify-center">
              <div className="relative overflow-visible">
                <Image src={imgLoanPrincipalLock} className="object-cover w-[281px] h-[240px] m-10" />

                <div className="absolute bg-white rounded-lg shadow-lg w-44 p-6 text-sm inline-block top-48 lg:-right-12 md:right-0 xs:-right-12 right-0">
                  <p className="font-semibold text-light-green text-xl">11.5%</p>
                  <p className="text-gray-400 mb-4">Annual Return</p>
                  <p className="font-semibold text-xl">2 months</p>
                  <p className="text-gray-400">Term Remaining</p>
                </div>

                <div className="absolute bg-white rounded-lg shadow-lg w-80 p-4 text-sm inline-block top-10 lg:-left-20 md:left-0 xs:-left-20 left-0">
                  <p className="text-gray-400 text-base mb-4">Loan Overview</p>
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="text-gray-600">Property Price</p>
                      <p className="font-semibold text-xl">$390,000</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Deposit</p>
                      <p className="font-semibold text-xl">
                        $39,000{' '}
                        <span className="font-semibold bg-green-100 text-green-500 text-xl p-1 rounded-sm">10%</span>
                      </p>
                    </div>
                  </div>
                </div>

                <Image
                  src={imgLoanPrincipalPie}
                  className="absolute object-cover w-[92px] h-[92px] left-[50%] -top-2"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-16">
            <div className="flex items-center md:block flex-wrap justify-center gap-4 flex-1 relative w-full">
              <div className="w-80 md:ml-auto">
                <div className="flex justify-between items-center gap-3">
                  <p className="flex-1 text-lg font-bold">Total Earned</p>
                  <p>YTD</p>
                  <p className="px-2 py-1 bg-gray-100 rounded-sm">All Time</p>
                </div>
                {[
                  ['$150,000', 'Total Profit'],
                  ['$150,000', 'Invested Cash'],
                  ['$150,000', 'Lifetime Deposit'],
                  ['$45,000', 'Withdrawn'],
                ].map((v, index) => (
                  <div
                    key={index}
                    className={`mb-4 p-4 ${
                      index < 3 ? 'shadow-2xl' : ''
                    } rounded-lg overflow-hidden bg-white border border-gray-100`}
                  >
                    <p className={`${index == 0 ? 'text-light-green' : ''} text-xl font-semibold mb-2`}>{v[0]}</p>
                    <p className="text-gray-600 text-sm">{v[1]}</p>
                  </div>
                ))}
              </div>
              <div className="lg:absolute w-80 h-52 md:ml-auto left-0 top-24 shadow-2xl rounded-xl bg-white overflow-hidden px-8 py-6">
                <div className="flex items-center mb-4 gap-2">
                  <p className="flex-1 text-gray-600">Active Properties</p>
                  <p className="text-sm">2022</p>
                  <p className="text-sm text-white bg-black rounded-lg p-2">2023</p>
                </div>
                <div className="grid grid-cols-5 gap-6 items-end">
                  {[
                    [80, 'Aug'],
                    [30, 'Sep'],
                    [50, 'Oct'],
                    [20, 'Nov'],
                    [70, 'Dec'],
                  ].map((v, index) => (
                    <div key={index}>
                      <div
                        className={`${index == 0 || index == 4 ? 'bg-light-green' : 'bg-gray-200'} rounded-t-md mb-2`}
                        style={{ height: `${v[0]}px` }}
                      />
                      <div className="text-sm text-gray-600 text-center">{v[1]}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="max-w-xl md:max-w-sm mx-auto font-medium">
                <div className="mb-4">
                  <p className="xs:text-4xl text-3xl font-semibold mb-4">Keep your money making money.</p>
                  <p>
                    We’ve made it easy to reinvest your repaid principal into new deals, straight from your investment
                    dashboard. You can also withdraw and your money will be sent to the linked bank account of your
                    choice.
                  </p>
                </div>
                <p className="bg-green-100 p-5 rounded-md mb-4">
                  Track, manage, and analyze every investment, past and present, from your investor dashboard.
                </p>

                <div className="flex gap-3">
                  <Link
                    to="/marketplace"
                    className="py-3 px-6 bg-light-green rounded-full text-black hover:bg-white font-semibold border border-white hover:border-light-green"
                  >
                    Start Investing
                  </Link>
                  <Link
                    to="/auth/signIn"
                    className="flex items-center gap-3 py-3 px-6 bg-white rounded-full font-semibold border border-white hover:bg-indigo-500 hover:text-white"
                  >
                    Login
                    <ArrowRightIcon className="w-3 h-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-gray-100 relative">
        <div className="content lg:pt-32 pt-16">
          <div className="text-center max-w-3xl mx-auto md:mb-10 mb-4">
            <p className="xs:text-4xl text-3xl font-semibold mb-10">
              Why investors choose real estate loans as an asset class.
            </p>
            <div className="flex flex-col xs:flex-row text-2xl font-bold items-center gap-4">
              <div className="flex-1 flex flex-row-reverse xs:flex-row justify-end items-center gap-2">
                <p>Traditional Investing</p>
                <div className="w-6 h-6 bg-black/5" />
              </div>
              <p className="p-8 rounded-full bg-black/10">VS</p>
              <div className="flex-1 flex items-center gap-2">
                <div className="w-6 h-6 bg-indigo-500" />
                <p>Finresi</p>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-6 mx-auto mb-8">
            <div className="rounded-xl bg-white shadow-xl overflow-hidden px-8 py-8">
              <p className="uppercase mb-6">Investment Property</p>
              <div className="flex items-end lg:gap-16 gap-8 text-center justify-around">
                <div className="flex flex-col items-center">
                  <div className="w-14 h-36 bg-gray-200 rounded-sm mb-8" />
                  <p>
                    High down payments and
                    <br />
                    diligence fees.
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="uppercase mb-4">Finresi</p>
                  <div className="w-14 h-10 bg-indigo-500 rounded-sm mb-8" />
                  <p className="text-indigo-500">
                    Low investment
                    <br />
                    minimums, just $5K.
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-xl bg-white shadow-xl overflow-hidden px-8 py-8">
              <div className="flex items-start gap-8 text-center justify-around">
                <div className="flex flex-col items-center">
                  <p className="uppercase mb-6">Real Estate Transactions</p>
                  <img src={imgRealEstateTrx} className="object-cover w-36 mb-2" />
                  <p className="mt-8">
                    Too many cooks in the kitchen. Traditional RE investing means A LOT of third-parties involved.
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <img src={svgIcon} className="w-6 h-6 mb-2" />
                  <div className="border-t-[2px] border-r-[2px] border-indigo-500 w-3 h-3 -rotate-45" />
                  <div className="w-0.5 bg-indigo-500 h-24 -mt-3 mb-1" />
                  <p className="bg-indigo-100 text-indigo-500 px-2 py-0.5 font-semibold rounded-sm">$</p>

                  <p className="text-indigo-500 mt-8">
                    It’s just you and us. You don’t need to deal with borrowers, contractors, or other investors.
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-xl bg-white shadow-xl overflow-hidden px-8 py-8">
              <div className="flex gap-4 text-center justify-around">
                <div className="flex flex-col items-center">
                  <p className="uppercase mb-6">Institutional Investing</p>
                  <img src={imgInstitutionalGraph} className="object-cover w-48" />
                  <div className="grid grid-cols-5 w-full">
                    {['Jan', 'Mar', 'May', 'Jul', 'Aug'].map((v, index) => (
                      <p key={index} className="text-gray-400 text-sm">
                        {v}
                      </p>
                    ))}
                  </div>
                  <p className="md:mt-16 mt-12">
                    401k, IRA, and other investments are subject to micro and macroeconomic effects on principal.
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <p className="uppercase mb-6">Finresi</p>
                  <div className="w-48">
                    <img src={svgIcon} className="w-6 h-6 mb-2 mx-auto" />
                    <div className="bg-indigo-500 h-0.5 mb-2" />
                    <p className="uppercase">no fluctuating principal</p>
                  </div>

                  <p className="text-indigo-500 md:mt-10 mt-6">
                    Short-term means less exposure, and you get a fixed, monthly rate for the life of the loan.
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-xl bg-white shadow-xl overflow-hidden px-8 py-8">
              <div className="flex items-end text-center justify-between">
                <div className="flex flex-col items-center max-w-xs">
                  <p className="uppercase mb-6">Real Estate Limited Protection</p>
                  <img src={imgRealEstateGray} className="object-cover w-[97px]" />
                  <p className="mt-8">Traditional real estate comes with limited downside protection</p>
                </div>
                <div className="flex flex-col items-center max-w-xs">
                  <img src={imgRealEstateGreen} className="object-cover w-[114px]" />
                  <p className="text-indigo-500 mt-8">First-lien carries the highest priority for repayment.</p>
                </div>
              </div>
            </div>
          </div>

          <p className="text-lg max-w-5xl text-center mx-auto font-medium mb-10">
            Our comprehensive process begins by{' '}
            <span className="text-indigo-500">identifying prospective real estate loans</span> being used for
            renovation, construction, or rental purposes. It culminates in a seamless journey towards{' '}
            <span className="text-indigo-500">generating monthly profits</span> for our investors. Allow us to handle
            all the details in between.
          </p>

          <div className="flex gap-3 justify-center mb-32">
            <Link
              to="/marketplace"
              className="py-3 px-6 bg-light-green rounded-full text-black hover:bg-white font-semibold border border-white hover:border-light-green"
            >
              Start Investing
            </Link>
            <Link
              to="/auth/signIn"
              className="flex items-center gap-3 py-3 px-6 bg-white rounded-full font-semibold border border-white hover:bg-indigo-500 hover:text-white"
            >
              Login
              <ArrowRightIcon className="w-3 h-3" />
            </Link>
          </div>
        </div>

        <div className="w-full relative lg:pt-24 md:pt-14 pt-10 pb-8 bg-[#22262A] md:mt-88 mt-64">
          <div className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-[url('./assets/home/bgGray.png')]" />

          <div className="content relative">
            <div className="bg-[#15181A] relative md:p-16 px-6 py-10 rounded-xl overflow-hidden md:-mt-88 -mt-64 md:mb-20 mb-14">
              <div className="h-128 w-128 absolute -left-64 -top-64 bg-gradient-to-br from-indigo-500 to-indigo-500/0 blur-2xl rounded-full" />
              <div className="h-128 w-128 absolute -right-64 -bottom-64 bg-gradient-to-br from-indigo-500/50 to-indigo-500/0 blur-3xl rounded-full" />
              <p className="md:text-5xl xs:text-4xl text-3xl text-center relative text-white font-semibold max-w-4xl mb-8 mx-auto">
                Explore wealth creation through a resilient asset class. Sign up.
              </p>
              <div className="flex justify-center md:mb-10 mb-2">
                <Link
                  to="/auth/signUp"
                  className="py-3 px-8 bg-indigo-500 rounded-full text-white hover:bg-black hover:text-white font-semibold border border-white/0 hover:border-indigo-500"
                >
                  Sign Up
                </Link>
              </div>
            </div>

            <FaqFooter />
          </div>
        </div>
      </div>
    </div>
  )
}
