import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Step } from 'stories/components'

import { InvestReadyEditor, IR_QuestionSetType } from './InvestReady'
import { AccreditedType, AccreditedTypeSection } from './InvestReady/Sections'

const FormTypes: Record<string, IR_QuestionSetType[]> = {
  incomeAsset: [IR_QuestionSetType.IncomeUpload, IR_QuestionSetType.Asset],
  thirdParty: [IR_QuestionSetType.ThirdPartyRequest, IR_QuestionSetType.ThirdPartyUpload],
  letter: [IR_QuestionSetType.EntityTrustUpload],
}

export const AccreditedVerificationPage = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const state = location.state || {}

  const [step, setStep] = useState(0)
  const [type, setType] = useState('')

  useEffect(() => {
    const { data, types } = state
    if (!data) return
    let type = ''
    Object.keys(FormTypes).forEach((_type) => {
      if (FormTypes[_type].includes(types[0])) type = _type
    })
    setType(type)
    if (type) setStep(1)
  }, [])

  const renderContents = () => {
    switch (step) {
      case 0:
        return (
          <AccreditedTypeSection
            onSubmit={(type: string) => {
              setType(type)
              setStep(1)
            }}
          />
        )
      case 1:
        if (!type) return null
        return (
          <div className="mx-auto max-w-3xl">
            <InvestReadyEditor
              types={FormTypes[type]}
              data={state.data || null}
              onBack={() => setStep(0)}
              onClose={() => {
                setStep(2)
              }}
            />
          </div>
        )
      case 2:
        return (
          <div className="mx-auto max-w-lg px-4 py-6 md:px-10 md:py-10 bg-white rounded-xl relative">
            <div className="p-1 justify-center">
              <ShieldCheckIcon className="w-20 h-20 text-green-500 mb-3 mx-auto" />
              <p className="font-medium text-center text-xl mb-4">{AccreditedType[type].title}</p>
              <p className="text-sm mb-6 text-center">
                Thank you for submitting your information. Once reviewed and approved, you will be eligible to fund the
                account and begin investing. This process may take up to 48 hours.
              </p>

              <p className="font-bold text-center mb-4">
                Please note: you are required to have a complete user profile prior to making an investment.
              </p>

              <Button className="mt-1" color="indigo" full bold onClick={() => navigate(`/dashboard`)}>
                View Dashboard
              </Button>
            </div>
          </div>
        )
    }
  }

  return (
    <div className="fullContent p-10 min-h-[60vh]">
      <div className="max-w-lg md:px-0 md:mx-auto w-full mb-8">
        <Step title="Step" step={step + 1} totalStep={3} progress={((step + 1) / 3) * 100} />
      </div>

      <div>{renderContents()}</div>
    </div>
  )
}
