import _imgBgGray from './bgGray.png'
import _imgBgMountain from './bgMountain.jpg'
import _imgBlackHome from './blackhome.png'
import _imgCard from './card.svg'
import _imgCard1 from './card_1.svg'
import _imgCircleGraph from './circlegraph.svg'
import _imgCompany1 from './company1.svg'
import _imgCompany2 from './company2.svg'
import _imgCompany3 from './company3.svg'
// import _imgCompany4 from './company4.svg'
// import _imgCompany5 from './company5.png'
import _imgCompany6 from './company6.png'
import _downsideRiskProtection from './downsideRiskProtection.jpg'
import _imgGrayHome from './grayhome.png'
import _imgGreenGraph from './greengraph.svg'
import _headerBack from './header_back.png'
import _imgHomeBase from './home_base.svg'
import _imgLaptop from './laptop.png'
import _imgTakeControlWealth from './takeControlWealth.jpg'

export const imgHomeDownsideRiskProtection = _downsideRiskProtection
export const imgHomeHeaderBack = _headerBack
export const imgGrayHome = _imgGrayHome
export const imgCircleGraph = _imgCircleGraph
export const imgBlackHome = _imgBlackHome
export const imgGreenGraph = _imgGreenGraph

export const imgBgGray = _imgBgGray
export const imgCompany1 = _imgCompany1
export const imgCompany2 = _imgCompany2
export const imgCompany3 = _imgCompany3
// export const imgCompany4 = _imgCompany4
// export const imgCompany5 = _imgCompany5
export const imgCompany6 = _imgCompany6

export const imgTakeControlWealth = _imgTakeControlWealth
export const imgBgMountain = _imgBgMountain
export const imgLaptop = _imgLaptop

export const imgCard = _imgCard
export const imgCard1 = _imgCard1
export const imgHomeBase = _imgHomeBase
