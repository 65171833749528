import { CurrencyDollarIcon } from '@heroicons/react/24/outline'
// import { LayoutLoading } from 'components/LayoutLoading'
import { type InputType, MinTrxAmount } from 'config'
import { Dropdown } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
// import { PlaidAccount, PlaidInstitution, usePlaidLink } from 'react-plaid-link'
import { getBalances, getPlaidAccounts } from 'services/apis'
import { Button } from 'stories/components'
import { InputValidate, normalizeNumber, RenderInput } from 'utils'

import type { DbPlaidAccount } from '../constants'
import { ContentHeader } from './ContentHeader'
import { renderBankAccount } from './util'

export const LinkedBankAccountsContent = ({
  direction,
  defaultData,
  onNext,
  onBack,
}: {
  direction: TransferIntentCreateMode
  defaultData: any
  onNext: Function
  onBack: Function
}) => {
  const [isLoading, setLoading] = useState(true)
  const [activeAccount, setActiveAccount] = useState('')
  const [accounts, setAccounts] = useState<DbPlaidAccount[] | null>(null)
  const [amountInput, setAmountInput] = useState<InputType>({
    title: `Input Amount to ${direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'} (USD)`,
    inputType: 'Text0',
    type: 'thousandSep',
    required: true,
    hasIcon: true,
    icon: <CurrencyDollarIcon className="w-4 h-4" />,
    autoFocus: true,
  })
  const [accountError, setAccountError] = useState('')
  const [available, setAvailable] = useState(0)

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    setLoading(true)
    getPlaidAccounts()
      .then((data) => setAccounts(data.filter((account: DbPlaidAccount) => !account.deleted)))
      .finally(() => setLoading(false))
    setActiveAccount('')
  }

  useEffect(() => {
    if (defaultData) setActiveAccount(defaultData.accountId)

    const newInput = cloneDeep(amountInput)
    getBalances().then(({ available }) => {
      if (direction == TransferIntentCreateMode.Disbursement) {
        newInput.title = `${newInput.title} (Available: $${normalizeNumber(available, 2)})`
      }
      if (defaultData) newInput.value = String(defaultData.amount)
      setAmountInput(newInput)
      setAvailable(available)
    })
  }, [defaultData])

  const strDirectionToFrom = useMemo(
    () => (direction == TransferIntentCreateMode.Payment ? 'Deposit from' : 'Withdraw to'),
    [direction],
  )

  const onUpdateAmount = (v: string) => {
    const newInput = cloneDeep(amountInput)
    newInput.value = v
    newInput.error = ''
    setAmountInput(newInput)
  }

  const onSubmit = () => {
    let error = InputValidate(amountInput)
    if (Number(amountInput.value) < MinTrxAmount) error = `This amount should be more than ${MinTrxAmount}.`
    const amountValue = Number(String(amountInput.value).replace(/,/g, ''))
    if (direction == TransferIntentCreateMode.Disbursement && !error && amountValue > Number(available)) {
      error = `The amount should not exceed your available balance of $${normalizeNumber(available, 2)}`
    }

    if (error) {
      const newInput = cloneDeep(amountInput)
      newInput.error = error
      setAmountInput(newInput)
      return
    }

    if (!activeAccount) {
      setAccountError('Please select account.')
      return
    }

    const item = getAccount(activeAccount)
    onNext({
      amount: Number(String(amountInput.value).replace(/,/g, '')),
      accountId: activeAccount,
      ...item,
    })
  }

  const getAccount = (accountId: string) => {
    if (accountId == 'new')
      return {
        account: null,
        institution: null,
      }
    return accounts && accounts.find(({ accountId: id }) => id == accountId)
  }

  const renderAccount = (accountId: string, isMain: boolean) => {
    if (accountId == 'new') return renderBankAccount(null, null, isMain)

    const item = getAccount(accountId)
    if (!item || !item.account)
      return <div className="flex gap-2 items-center sm:w-92 w-full">Please Select Account.</div>

    const { account, institution } = item
    return renderBankAccount(account, institution, isMain)
  }

  const renderPayForm = () => {
    return (
      <>
        {/* <LayoutLoading show={isBankLoading} /> */}
        <div className="pt-4 px-2">
          <p className="text-sm font-medium text-gray-700 mb-2">{strDirectionToFrom}</p>
          <Dropdown
            label={renderAccount(activeAccount, true)}
            color="gray"
            fullSized
            value={activeAccount}
            className="max-h-80 overflow-y-auto"
          >
            {accounts &&
              accounts
                .filter((account) => !account.deleted)
                .map(({ account }) => (
                  <Dropdown.Item
                    key={account.id}
                    value={account.id}
                    onClick={() => {
                      setActiveAccount(account.id)
                      setAccountError('')
                    }}
                  >
                    {renderAccount(account.id, false)}
                  </Dropdown.Item>
                ))}
            <Dropdown.Item
              value={''}
              onClick={() => {
                setActiveAccount('new')
                setAccountError('')
              }}
            >
              {renderAccount('new', false)}
            </Dropdown.Item>
          </Dropdown>
          <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mb-2">{accountError}</p>

          <div className="text-sm text-gray-700">
            <RenderInput Key="inputAmount" input={amountInput} onChange={(_: string, v: string) => onUpdateAmount(v)} />
          </div>
        </div>

        <div className="px-2 pt-4">
          <Button full color="green" onClick={() => onSubmit()} loading={isLoading}>
            Review
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="rounded-lg bg-white divide-y">
      <ContentHeader description="Please choose your linked bank accounts." onBack={onBack} />

      {renderPayForm()}
      {/* {accounts && accounts.length == 0 ? <BankLinkForm onBankLink={load} /> : renderPayForm()} */}
    </div>
  )
}
