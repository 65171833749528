import { API_DOCS_DOWNLOAD, API_DOCS_MULTI_DOWNLOAD, API_DOCS_UPLOAD, baseUrl } from 'config'
import { store } from 'reducers'
import Api from 'services/api'

export const downloadS3Documents = (key: string, directDownload = false) => {
  return Api.post(
    API_DOCS_DOWNLOAD,
    { key, directDownload },
    {},
    directDownload
      ? {
          responseType: 'blob',
          timeout: 250000,
        }
      : { timeout: 250000 },
  )
}

export const openS3Document = async (fileKey: string, isNewWindow = false, downloadFileName = '') => {
  const res = await downloadS3Documents(fileKey, !!downloadFileName)

  if (!!downloadFileName) {
    downloadFile(downloadFileName, res)
    return
  }

  if (res.content) {
    downloadFile(fileKey, res.content)
    return
  }
  if (!isNewWindow) {
    var windowReference: any = window.open()
    windowReference.location = res.url
  } else {
    window.open(res.url, '_blank', 'resizable=yes')
  }
}

export const downloadFile = (fileName: string, contentBlob: Blob) => {
  const aElement = document.createElement('a')
  aElement.setAttribute('download', fileName)
  const href = URL.createObjectURL(contentBlob)
  aElement.href = href
  aElement.setAttribute('target', '_blank')
  aElement.click()
  URL.revokeObjectURL(href)
}

export const openS3Documents = async (loanNumber: number, fileName: string, keys: string[]) => {
  const state = store.getState()
  const {
    auth: { token },
  } = state

  const url = `${baseUrl}${API_DOCS_MULTI_DOWNLOAD}/${loanNumber}?fileName=${fileName}&keys=${keys.join(
    ',',
  )}&token=${token}`

  const windowReference: any = window.open()
  windowReference.document.title = 'Downloading Documents...'
  windowReference.location = url
}

export const uploadFiles = (data: { path: string }, files: Array<File>) => {
  return Api.uploadFiles(API_DOCS_UPLOAD, data, {}, files)
}
