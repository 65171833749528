import type { Loan } from 'pages/Marketplace'
import { Link } from 'react-router-dom'
import { getSimplifiedAddress } from 'utils'

export const LoanLink = ({
  loan,
  showId = true,
  simple = false,
  goToPipeline = false,
}: {
  loan: Loan
  showId?: boolean
  simple?: boolean
  goToPipeline?: boolean
}) => {
  const url = goToPipeline ? `/pipeline/${loan.id}` : `/marketplace/${loan.id}#myInvestments`

  return (
    <Link to={url} className="flex gap-2 items-center border-b mt-1">
      <p className={simple ? 'text-lg' : ''}>
        {simple ? getSimplifiedAddress(loan.propertyAddress) : loan.propertyAddress}
        {showId && <span className="font-bold text-indigo-500 hover:underline cursor-pointer ml-2">#{loan.id}</span>}
      </p>
    </Link>
  )
}
