import {
  API_BASE_SUBMIT_USER,
  API_FAQ,
  API_INVESTREADY_FORM_BY_ID,
  API_INVESTREADY_FORM_BY_TYPE,
  API_SETTING,
} from 'config'
import type { IR_FormResponse, IR_QuestionSetType, IR_Response } from 'pages/Profile'
import Api from 'services/api'

export const submitUserForm = (data: Record<string, any>) => {
  return Api.post(API_BASE_SUBMIT_USER, data)
}

export const getSettingValue = (key: string) => {
  return Api.get(API_SETTING, {}, { key })
}

export const updateSetting = (key: string, value: string) => {
  return Api.put(API_SETTING, { key, value }, { key: '' })
}

export const getInvestReadyQuestionsForm = (
  type: IR_QuestionSetType,
): Promise<{ id: number; form: IR_Response<IR_FormResponse> }> => {
  return Api.get(API_INVESTREADY_FORM_BY_TYPE, {}, { type })
}

export const getInvestReadyQuestionsFormById = (
  id: number,
): Promise<{ id: number; form: IR_Response<IR_FormResponse> }> => {
  return Api.get(API_INVESTREADY_FORM_BY_ID, {}, { id })
}

export const getFaq = () => {
  return Api.get(API_FAQ)
}
