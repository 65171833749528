import { LayoutLoading } from 'components/LayoutLoading'
import { WireDepositExpireDays } from 'config'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { updateEnvelopeStatus } from 'services/apis'
import { getSimplifiedAddress, normalizeNumber } from 'utils'

import type { Loan } from '../types'

export const InvestComplete = ({
  loan,
  onNext,
  onSetMethod,
}: {
  loan: Loan
  onNext: Function
  onSetMethod: Function
}) => {
  const [title, setTitle] = useState('')
  const [searchParams] = useSearchParams()
  const envelopeId = searchParams.get('envelopeId')
  const [isLoading, setLoading] = useState(true)
  // const [status, setStatus] = useState('')
  const [amount, setAmount] = useState(null)
  const [method, setMethod] = useState('')

  useEffect(() => {
    if (!envelopeId) return
    updateEnvelopeStatus(loan.id, envelopeId)
      .then(({ amount, method }) => {
        // setStatus(status)
        setAmount(amount)
        onSetMethod(method)
        setMethod(method)
      })
      .catch(() => onNext())
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const title = getSimplifiedAddress(loan.propertyAddress)
    setTitle(`${title} #${loan.id}`)
  }, [loan])

  return (
    <div className="w-full">
      <LayoutLoading show={isLoading} />
      <div className="px-6 -mt-14 relative">
        <div className="text-center gap-4 px-12 pb-6">
          <div className="rounded-xl bg-white p-6 border">
            {amount && <p className="text-4xl mb-6 text-light-green font-semibold">$ {normalizeNumber(amount)}</p>}
            <p className="text-sm text-gray-600">
              Invested in <span className="text-black font-semibold">{title}</span>
            </p>
            {/* {status && (
              <p className="text-sm text-gray-600">
                Document Sign has been <span className="text-black font-semibold">{status}</span>
              </p>
            )} */}
          </div>

          {method == 'Wire' && (
            <p className="text-desc mt-4">
              We've sent an email with guide. Please follow it to deposit in {WireDepositExpireDays} days.
            </p>
          )}
        </div>
      </div>

      <div className="px-6">
        <button
          type="button"
          onClick={() => onNext()}
          className="text-white w-full bg-black hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-green-800 font-semibold rounded-lg text-sm items-center px-5 py-2.5 text-center flex justify-center gap-2"
        >
          Back to Dashboard
        </button>
      </div>
    </div>
  )
}
