import { Link } from 'react-router-dom'

export const AuthFooter = () => {
  return (
    <p className="text-sm mb-6">
      <>
        By using the Finresi platform, I agree to the{' '}
        <Link to="/privacy_policy" className="text-link mr-1 whitespace-nowrap">
          Privacy Policy
        </Link>
        and
        <Link to="/terms_of_service" className="text-link ml-1 whitespace-nowrap">
          Terms of Service
        </Link>
        .<br />
      </>

      {/* This site is protected by reCAPTCHA and the Google{' '}
      <a
        target="_blank"
        href="https://policies.google.com/privacy?hl=en-US"
        className="text-link mr-1 whitespace-nowrap"
      >
        Privacy Policy
      </a>
      and
      <a target="_blank" href="https://policies.google.com/terms?hl=en-US" className="text-link mx-1 whitespace-nowrap">
        Terms of Service
      </a>
      apply. */}
    </p>
  )
}
