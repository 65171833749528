export enum FciLoanStatus {
  Assigned = 'assigned',
  Bankruptcy = 'bankruptcy',
  ChargeOff = 'charge off',
  Closed = 'closed',
  CompleteChargeOff = 'complete charge off',
  Delinquency = 'delinquency',
  EscrowImpounds = 'escrow/impounds',
  FinalBoarding = 'final boarding',
  Foreclosure = 'foreclosure',
  Imported = 'imported',
  LossMitRequest = 'loss-mit request',
  OnHold = 'on hold',
  PaidOff = 'paid off',
  PayoffDemand = 'payoff demand',
  Performing = 'performing',
  PreBoarding = 'pre boarding',
  PreForeclosure = 'pre foreclosure',
  REO = 'reo',
  RESPA = 'respa',
  Transferred = 'transferred',
  TransferredOut = 'transferred out',
}

export const FciLoanNotInstructStatus = [
  // FciLoanStatus.Bankruptcy,
  // FciLoanStatus.ChargeOff,
  // FciLoanStatus.Closed,
  // FciLoanStatus.CompleteChargeOff,
  FciLoanStatus.Delinquency,
  FciLoanStatus.Foreclosure,
  // FciLoanStatus.LossMitRequest,
  // FciLoanStatus.PreForeclosure,
  // FciLoanStatus.REO,
]
