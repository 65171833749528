import { ArrowDownIcon, ArrowTrendingUpIcon, ArrowUpIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { isTopManager } from 'config'
import {
  type InvestReadyCert,
  InvestReadyForm,
  InvestReadyStatus,
  IR_FormResponse,
  IR_QuestionSetTypeOptions,
  IR_Response,
} from 'pages/Profile'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'reducers'
import {
  approveUserInvestReady,
  getInvestReadyQuestionsFormById,
  rejectInvestReady,
  submitUserInvestReady,
} from 'services/apis'
import { confirm } from 'utils'

import { InvestReadyApproveModal, InvestReadyReasonModal } from './modal'

export const InvestReadyViewer = ({
  data,
  refresh,
  onClose,
}: {
  data: InvestReadyCert
  refresh: Function
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(true)
  const [formData, setFormData] = useState<IR_Response<IR_FormResponse> | null>(null)
  const [showReject, setShowReject] = useState(false)
  const [message, setMessage] = useState('')
  const [showApprove, setShowApprove] = useState(false)

  const auth = useSelector((state: RootState) => state.auth)
  const { accountType } = auth.profile
  const _isManager = isTopManager(accountType)

  useEffect(() => {
    setLoading(true)
    getInvestReadyQuestionsFormById(data.form.id)
      .then(({ form }) => {
        setFormData(form)
      })
      .finally(() => setLoading(false))
  }, [])

  const onReject = () => {
    setShowReject(true)
  }

  const onRejectClose = async (reason: string | null) => {
    setShowReject(false)

    if (!reason) return

    setMessage('')
    setLoading(true)
    rejectInvestReady(data.id, reason)
      .then(() => onClose(true))
      .finally(() => setLoading(false))
  }

  const onSubmit = async () => {
    const result = await confirm('Are you sure to submit this request?')
    if (!result) return

    setMessage('')
    setLoading(true)
    submitUserInvestReady(data.id)
      .then((result: { success: number; message: string }) => {
        if (result.success) onClose(true)
        else {
          refresh()
          setMessage(result.message)
        }
      })
      .finally(() => setLoading(false))
  }

  const onApprove = () => {
    setShowApprove(true)
  }

  const onApproveClose = async (expiresOn: string | null) => {
    setShowApprove(false)
    if (!expiresOn) return

    setLoading(true)
    approveUserInvestReady(data.id, expiresOn)
      .then(() => refresh())
      .finally(() => setLoading(false))
  }

  return (
    <div className="relative border py-2 px-3 rounded bg-white">
      <LayoutLoading show={isLoading} />

      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
          <span className="text-base">{IR_QuestionSetTypeOptions[data.type]}</span>
        </h2>

        {_isManager && data.status == InvestReadyStatus.Pending && (
          <div className="flex items-center gap-2">
            <span
              className="text-sm flex items-center ml-2 gap-1 text-yellow-500 hover:underline cursor-pointer"
              onClick={() => onReject()}
            >
              Reject
              <ArrowDownIcon className="w-4 h-4" />
            </span>
            <span
              className="text-sm flex items-center ml-2 gap-1 text-indigo-500 hover:underline cursor-pointer"
              onClick={() => onSubmit()}
            >
              Submit
              <ArrowUpIcon className="w-4 h-4" />
            </span>
            <span
              className="text-sm flex items-center ml-2 gap-1 text-green-500 hover:underline cursor-pointer"
              onClick={() => onApprove()}
            >
              Approve
              <CheckIcon className="w-4 h-4" />
            </span>
          </div>
        )}
        {_isManager && data.status == InvestReadyStatus.Approved && (
          <div className="flex items-center gap-2">
            <span
              className="text-sm flex items-center ml-2 gap-1 text-green-500 hover:underline cursor-pointer"
              onClick={() => onApprove()}
            >
              Extend
              <ArrowTrendingUpIcon className="w-4 h-4" />
            </span>
          </div>
        )}

        <span
          className="text-sm flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
          onClick={() => onClose()}
        >
          Close
          <XMarkIcon className="w-4 h-4" />
        </span>
      </div>

      {!!message && (
        <p className="border border-yellow-600 bg-yellow-50 text-yellow-500 p-2 rounded-md mb-4">{message}</p>
      )}

      {formData && (
        <InvestReadyForm
          type={data.type}
          defaultValues={data ? data.data : {}}
          formData={formData}
          editable={false}
          visibleDescription={false}
        />
      )}

      {showReject && <InvestReadyReasonModal forUser={false} onClose={onRejectClose} />}

      {showApprove && <InvestReadyApproveModal data={data} onClose={onApproveClose} />}
    </div>
  )
}
