import cloneDeep from 'clone-deep'
import { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Button } from 'stories/components'
import { InputConvert, RenderInput, validateInputs } from 'utils'

export enum IRAType {
  LLC = 'LLC',
  Custodian = 'Custodian',
}

export const IRAOptions: Record<IRAType, string> = {
  [IRAType.LLC]: 'LLC with Checkbook Control',
  [IRAType.Custodian]: 'Custodian-Controlled SDIRA',
}

const defaultInputs = (): Record<string, InputType> => {
  return {
    IRA_Type: {
      title: 'IRA Type',
      placeholder: 'Type of IRA',
      inputType: 'select0',
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
      options: IRAOptions,
      error: '',
      required: true,
      visible: true,
    },
    LLC_Name: {
      title: 'IRA Name',
      placeholder: 'Full Name of IRA',
      inputType: 'text0',
      type: 'text',
      required: true,
      visible: true,
    },
    LLC_EIN: {
      title: 'LLC EIN',
      placeholder: 'XX-XXXXXXX',
      inputType: 'text0',
      type: 'ein',
      required: true,
      visible: true,
    },
    // LLC_Bank_Account: {
    //   title: 'LLC Bank Account',
    //   placeholder: 'LLC Bank Account Information',
    //   inputType: 'text0',
    //   type: 'text',
    //   visible: true,
    // },
    // certificateFormation: {
    //   inputType: 'file',
    //   title: 'LLC Document',
    //   required: false,
    //   visible: true,
    //   multiple: true,
    // },
    CustodianName: {
      title: 'Custodian Name',
      placeholder: 'Full Name of Custodian',
      inputType: 'text0',
      type: 'text',
      required: true,
      visible: true,
    },
    CustodianContact: {
      title: 'Custodian Contact',
      placeholder: 'Email / Phone',
      inputType: 'text0',
      type: 'text',
      required: true,
      visible: true,
    },
    IRA_Account_Number: {
      title: 'IRA Account Number',
      placeholder: 'IRA Account Number',
      inputType: 'text0',
      type: 'text',
      required: true,
      visible: true,
    },
    CustodianAddress: {
      title: 'Custodian Address',
      placeholder: 'Custodian Address',
      inputType: 'map',
      type: 'text',
      required: true,
      visible: true,
      className: '!bg-transparent',
    },
  }
}

export const IRASecion = ({ step, onSubmit: _onSubmit }: { step: JSX.Element; onSubmit: Function }) => {
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    const nInputs = cloneDeep(inputs)
    switchVisibleFields(nInputs)
    setInputs(nInputs)
  }, [])

  const switchVisibleFields = (nInputs: Record<string, InputType>) => {
    if (nInputs.IRA_Type.value === IRAType.LLC) {
      nInputs.LLC_Name.visible = true
      nInputs.LLC_EIN.visible = true
      // nInputs.LLC_Bank_Account.visible = true
      // nInputs.certificateFormation.visible = true
      nInputs.CustodianName.visible = false
      nInputs.CustodianContact.visible = false
      nInputs.IRA_Account_Number.visible = false
      nInputs.CustodianAddress.visible = false
    } else {
      nInputs.LLC_Name.visible = false
      nInputs.LLC_EIN.visible = false
      // nInputs.LLC_Bank_Account.visible = false
      // nInputs.certificateFormation.visible = false
      nInputs.CustodianName.visible = true
      nInputs.CustodianContact.visible = true
      nInputs.IRA_Account_Number.visible = true
      nInputs.CustodianAddress.visible = true
    }
  }

  const onChange = (key: string, value: string) => {
    const newState = cloneDeep(inputs)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    if (key === 'IRA_Type') switchVisibleFields(newState)
    setInputs(newState)
  }

  const onSubmit = () => {
    const { hasError, data, newInputStates } = validateInputs(inputs)
    if (hasError) {
      setInputs(newInputStates)
      return
    }
    _onSubmit({
      ...data,
    })
  }

  return (
    <div className="max-w-lg px-8 md:px-0 md:mx-auto w-full md:w-120">
      <div className="mb-4">{step}</div>

      <div>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (!input.visible) return <></>

          return (
            <div className={`my-4`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      <Button full onClick={onSubmit} className="py-4">
        Finish
      </Button>
    </div>
  )
}
