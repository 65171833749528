import { useLocation } from 'react-router-dom'
import { BlackFooter, Footer, MainHeader } from 'stories/layouts'

export const BaseLayout = (props: any) => {
  const { children } = props

  const { pathname } = useLocation()

  const isBlackFooter = ['/', '/home', '/how_it_works'].includes(pathname)

  return (
    <div className={`relative lg:w-full h-full-screen`}>
      {/* <BaseHeader /> */}
      <MainHeader type="white" />
      <main className="mx-auto main">{children}</main>
      {isBlackFooter ? <BlackFooter /> : <Footer />}
    </div>
  )
}
