import { cloneDeep } from 'lodash'
import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { RootState } from 'reducers'
import { Modal, StepProgressBar } from 'stories/components'
import { isInvestableProfile } from 'utils'

import { ConfirmationBankContent } from '../Withdraw/ConfirmationBank'
import { ConfirmationWireContent } from '../Withdraw/ConfirmationWire'
import { CongratulationsContent } from '../Withdraw/Congratulations'
import { LinkedBankAccountsContent } from '../Withdraw/LinkedBankAccounts'
import { WireTransferContent } from '../Withdraw/WireTransfer'
import { ChooseMethodContent } from './ChooseMethod'

export const DepositModal = ({ onClose }: { onClose: Function }) => {
  // useTitle('Deposit')

  const navigate = useNavigate()
  const [data, setData] = useState<Record<number, any>>({})
  const [step, setStep] = useState(0)

  const { depositData } = useSelector((state: RootState) => state.application)

  useEffect(() => {
    isInvestableProfile(false).then((isInvestable) => {
      if (!isInvestable) navigate('/dashboard')
    })
  }, [])

  useEffect(() => {
    if (!depositData) return
    setData(depositData)
    setStep(2)
  }, [])

  const onNext = (v: any) => {
    if (step == 3) {
      onClose()
      navigate('/payment/transaction')
      return
    }
    let newData = cloneDeep(data)
    if (step == 0 && newData[0] != v) newData = {}
    newData[step] = v
    setData(newData)
    setStep(step + 1)
  }

  const onBack = () => {
    setStep(step - 1)
  }

  return (
    <Modal
      isOpen
      title={step == 3 ? 'Thank You' : 'Complete these steps to deposit your funds'}
      titleOkay=""
      titleCancel=""
      // className="lg:!overflow-visible"
      onClose={() => onClose(false)}
    >
      <div className="sm:w-112">
        <div className="w-full relative">
          <div className="w-full relative">
            <div className="w-full px-1">
              <StepProgressBar options={['Choose Method', 'Get Details', 'Confirmation', 'Done']} index={step} />
            </div>

            {/* {step == -1 && <SignSubscriptionContent onNext={onSign} />} */}

            <div>
              {step == 0 && <ChooseMethodContent defaultData={data[0]} onNext={onNext} />}

              {step == 1 && data[0] == 0 && (
                <LinkedBankAccountsContent
                  direction={TransferIntentCreateMode.Payment}
                  defaultData={data[1]}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}
              {step == 2 && data[0] == 0 && (
                <ConfirmationBankContent
                  direction={TransferIntentCreateMode.Payment}
                  data={data}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}

              {step == 1 && data[0] == 1 && (
                <WireTransferContent
                  direction={TransferIntentCreateMode.Payment}
                  defaultData={data[1]}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}
              {step == 2 && data[0] == 1 && (
                <ConfirmationWireContent
                  direction={TransferIntentCreateMode.Payment}
                  data={data}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}

              {step == 3 && (
                <CongratulationsContent direction={TransferIntentCreateMode.Payment} data={data} onNext={onNext} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
