import {
  API_ADMIN_ADD_RESOURCE,
  API_ADMIN_BALANCE,
  API_ADMIN_BALANCE_BLOCK,
  API_ADMIN_BALANCE_SUM,
  API_ADMIN_EMAILS,
  API_ADMIN_LATEST_RESOURCES,
  API_ADMIN_RESOURCE,
  API_ADMIN_RESOURCES,
  API_ADMIN_STATISTICS,
  API_ADMIN_STATISTICS_BALANCE,
  API_ADMIN_STATISTICS_PAY,
  API_ADMIN_STATISTICS_SUMMARY,
  API_ADMIN_TASK,
  API_ADMIN_TASK_STATUS,
  API_ADMIN_TASKS,
  API_EMAIL_BROADCAST,
  API_FAQ,
  API_FAQ_BY_ID,
  API_FAQ_EXCHANGE_ORDER,
} from 'config'
import Api from 'services/api'
import { fileJsonConvert } from 'utils'

export const getStatistics = () => {
  return Api.get(API_ADMIN_STATISTICS)
}

export const getStatisticsSummary = () => {
  return Api.get(API_ADMIN_STATISTICS_SUMMARY)
}

export const getPaymentStatistics = (year: string) => {
  return Api.get(API_ADMIN_STATISTICS_PAY, { year })
}

export const getBalanceStatistics = () => {
  return Api.get(API_ADMIN_STATISTICS_BALANCE)
}

export const blockUserBalance = (userId: number) => {
  return Api.get(API_ADMIN_BALANCE_BLOCK, {}, { userId })
}

export const getUserBalances = (filters: Record<string, any>) => {
  return Api.get(API_ADMIN_BALANCE, filters)
}

export const getBalanceSum = () => {
  return Api.get(API_ADMIN_BALANCE_SUM)
}

export const getBroadcastEmails = (filters: Record<string, any>) => {
  return Api.get(API_EMAIL_BROADCAST, filters)
}

export const createBroadcastEmail = (data: Record<string, string | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_EMAIL_BROADCAST, data, {}, json.files)
}

export const findTasks = (filters: Record<string, any>) => {
  return Api.get(API_ADMIN_TASKS, filters)
}

export const deleteTask = (id: number) => {
  return Api.delete(API_ADMIN_TASK, {}, { id })
}

export const getAdminEmails = () => {
  return Api.get(API_ADMIN_EMAILS)
}

export const updateTask = (id: number, data: Record<string, any>) => {
  return Api.put(API_ADMIN_TASK, data, { id })
}

export const addTask = (data: Record<string, any>) => {
  return Api.post(API_ADMIN_TASKS, data)
}

export const updateTaskStatus = (id: number, data: { status: string; comment: string }) => {
  return Api.put(API_ADMIN_TASK_STATUS, data, { id })
}

export const getResources = () => {
  return Api.get(API_ADMIN_RESOURCES)
}

export const getLatestResources = () => {
  return Api.get(API_ADMIN_LATEST_RESOURCES)
}

export const removeResource = (id: number) => {
  return Api.delete(API_ADMIN_RESOURCE, {}, { id })
}

export const addResource = (data: Record<string, string | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_ADMIN_ADD_RESOURCE, data, {}, json.files)
}

export const updateResource = (id: number, data: Record<string, string | File>) => {
  const json = fileJsonConvert(data)
  return Api.uploadFiles(API_ADMIN_RESOURCE, data, { id }, json.files)
}

export const addFaq = (data: Record<string, string>) => {
  return Api.post(API_FAQ, data)
}

export const updateFaq = (id: number, data: Record<string, string>) => {
  return Api.put(API_FAQ_BY_ID, data, { id })
}

export const deleteFaq = (id: number) => {
  return Api.delete(API_FAQ_BY_ID, {}, { id })
}

export const exchangeFaqOrder = (id1: number, id2: number) => {
  return Api.post(API_FAQ_EXCHANGE_ORDER, {}, { id1, id2 })
}
