import {
  API_ADD_SERVICE_ARTICLE,
  API_ADD_SERVICE_DATA,
  API_DELETE_SERVICE_ARTICLE,
  API_DELETE_SERVICE_DATA,
  API_GET_ALL_SERVICE_ARTICLES,
  API_GET_OTHER_SLUG_LIST,
  API_GET_PARENT_SERVICE_ARTICLES,
  API_GET_SERVICE_ARTICLES_FILTER,
  API_GET_SERVICE_SLUG,
  API_UPDATE_SERVICE_ARTICLE,
  API_UPDATE_SERVICE_DATA,
} from 'config'
import Api from 'services/api'

export const getAllServiceArticles = () => {
  return Api.get(API_GET_ALL_SERVICE_ARTICLES)
}

export const getAllParentArticles = () => {
  return Api.get(API_GET_PARENT_SERVICE_ARTICLES)
}

export const getServiceArticles = (filters: Record<string, any>) => {
  return Api.get(API_GET_SERVICE_ARTICLES_FILTER, filters)
}

export const getServiceArticleBySlug = (slug: string) => {
  return Api.get(API_GET_SERVICE_SLUG, {}, { slug })
}

export const getServiceArticleOtherSlugList = (slug: string) => {
  return Api.get(API_GET_OTHER_SLUG_LIST, {}, { slug })
}

export const createServiceArticle = (data: Record<string, any>) => {
  return Api.uploadFiles(API_ADD_SERVICE_ARTICLE, data, {}, [])
}

export const updateServiceArticle = (id: number, data: Record<string, any>) => {
  return Api.uploadFiles(API_UPDATE_SERVICE_ARTICLE, data, { id }, [])
}

export const deleteServiceArticle = (id: number) => {
  return Api.delete(API_DELETE_SERVICE_ARTICLE, {}, { id })
}

export const createServiceData = (data: Record<string, any>) => {
  return Api.uploadFiles(API_ADD_SERVICE_DATA, data, {}, [])
}

export const updateServiceData = (id: number, data: Record<string, any>) => {
  return Api.uploadFiles(API_UPDATE_SERVICE_DATA, data, { id }, [])
}

export const deleteServiceData = (id: number) => {
  return Api.delete(API_DELETE_SERVICE_DATA, {}, { id })
}
