import { Link } from 'react-router-dom'
import { thisTime } from 'utils'

export const Footer = () => {
  return (
    <div className="fullContent pt-8 pb-4">
      <div className="link font-variation-settings-600 text-[14px] mt-3 mb-4 hover:underline text-center text-gray-800/60">
        <a href="https://www.facebook.com/tryfinresi/" rel="noopener noreferrer" target="_blank">
          <button className="p-2 font-semibold inline-flex items-center rounded">
            <svg className="h-4 w-4 rounded-sm" fill="currentColor" width="24" height="24" viewBox="0 0 24 24">
              <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
            </svg>
          </button>
        </a>
        <a href="https://twitter.com/Finresi/" rel="noopener noreferrer" target="_blank" className="ml-2">
          <button className="p-2 font-semibold inline-flex items-center rounded">
            <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
              <path d="M14.095 10.316 22.286 1h-1.94L13.23 9.088 7.551 1H1l8.59 12.231L1 23h1.94l7.51-8.543L16.45 23H23l-8.905-12.684zm-2.658 3.022-.872-1.218L3.64 2.432h2.98l5.59 7.821.869 1.219 7.265 10.166h-2.982l-5.926-8.3z"></path>
            </svg>
          </button>
        </a>
        <a href="https://www.instagram.com/finresi/" rel="noopener noreferrer" target="_blank" className="ml-2">
          <button className="p-2 font-semibold inline-flex items-center rounded">
            <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </a>
        <a
          href="https://www.linkedin.com/company/yieldeasy/"
          rel="noopener noreferrer"
          target="_blank"
          className="ml-2"
        >
          <button className="p-2 font-semibold inline-flex items-center rounded">
            <svg className="h-4 w-4 rounded-sm" fill="currentColor" viewBox="0 0 24 24">
              <path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </button>
        </a>
      </div>

      <div className="flex flex-wrap px-3 text-center mb-4">
        <div className="text-[15px] text-center mx-auto text-sm gap-1">
          <span>© Copyright {thisTime().format('YYYY')} Finresi, Inc. All Rights Reserved. |</span>
          <Link to="/privacy_policy" rel="noopener noreferrer nofollow" className="mx-1 hover:underline ">
            Privacy Policy
          </Link>
          <span>|</span>
          <Link to="/terms_of_service" rel="noopener noreferrer nofollow" className="mx-1 hover:underline ">
            Terms of Service
          </Link>
        </div>
      </div>

      <div>
        <p className="text-desc mb-1">
          Disclaimer: The content provided on this website is for informational purposes only and should not be
          construed as an offer or solicitation to buy or sell securities. We do not recommend or endorse any investment
          strategy or security mentioned on this website. By accessing and using this website, you agree to abide by our{' '}
          <Link to="/terms_of_service" className="text-link whitespace-nowrap" rel="noopener noreferrer nofollow">
            Terms of Service
          </Link>
          ,
          <Link to="/privacy_policy" className="text-link ml-1 whitespace-nowrap" rel="noopener noreferrer nofollow">
            Privacy Policy
          </Link>
          , and Notice of Right to Opt-Out. Please be aware that past performance is not necessarily indicative of
          future results. Historical returns, expected returns, and probability projections are subject to change
          without notice and may not reflect actual future performance. All investments involve risks, including the
          risk of losing money. It is your responsibility to conduct your own research and seek advice from a qualified
          professional before making any investment decisions. We strive to provide full disclosure and transparency in
          all our communications.
        </p>
        <p className="text-desc">Finresi, Inc and any of its subsidiaries or affiliates are not investment advisors.</p>
      </div>
      <div className="flex divide-x justify-end border-t border-t-gray-300 pt-2 mt-2">
        <a href="mailto:info@finresi.com" className="text-desc border-gray-700 px-2 cursor-pointer">
          info@finresi.com
        </a>
        <a href="tel:18332204686" className="text-desc px-2 border-gray-700 cursor-pointer">
          1-833-220-4686
        </a>
      </div>
    </div>
  )
}
