import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'

export const renderHeader = ({
  title,
  sortable = false,
  key,
  sortOrder = 1,
  index,
  onSort,
  border = false,
  className = '',
  btnClassName = '',
  alignClassName = '',
  additionalChild = null,
  ...props
}: {
  title: string | JSX.Element
  sortable: boolean
  key: string
  sortOrder: number
  index: number
  onSort: Function
  border?: boolean
  className?: string
  btnClassName?: string
  [key: string]: any
}) => {
  if (!sortable)
    return (
      <th scope="col" className="py-3 px-2" key={`${key}-${title}-${index}`} {...props}>
        {title}
      </th>
    )

  const _onSort = () => {
    if (sortOrder == 0) sortOrder = 1
    onSort(key, 0 - sortOrder)
  }

  return (
    <th
      scope="col"
      className={`py-3 px-2 ${border ? 'border' : ''} ${className}`}
      key={`${key}-${title}-${index}`}
      {...props}
    >
      <div className={`flex gap-2 items-center ${alignClassName}`}>
        <button
          className={`flex uppercase bg-transparent font-bold text-left ${btnClassName}`}
          onClick={() => _onSort()}
        >
          {title}
          {sortOrder !== 0 ? (
            sortOrder == 1 ? (
              <BarsArrowUpIcon className="w-3 h-3 ml-2" />
            ) : (
              <BarsArrowDownIcon className="w-3 h-3 ml-2" />
            )
          ) : (
            <div className="w-3 h-3 ml-2" />
          )}
        </button>
        {additionalChild && additionalChild}
      </div>
    </th>
  )
}
