import {
  BanknotesIcon,
  BuildingOffice2Icon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  EyeIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { authUpdateProfile } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { AccountStatus } from 'config'
import { InvestReadyHistoryModal } from 'pages/User/modal'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { RootState } from 'reducers'
import { getInvestReady, getInvestReadyExpiresOn } from 'services/apis'
import { Button, PlainTable } from 'stories/components'
import { formatDate, formatTime, useTitle } from 'utils'

import { ProfileMenuTitles } from '../Profile'
import { InvestReadyCertStatus } from './CertStatus'
import { InvestReadyEditor } from './Editor'
import { type InvestReadyCert, InvestReadyStatus, IR_QuestionSetTypeOptions } from './type'

export const InvestReadyCertificateStatusText = ['Rejected', 'Pending', 'Approved']

export const InvestReadyTab = () => {
  useTitle('Accredited Investor Verification')

  const { profile } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<InvestReadyCert[] | null>(null)
  const [expiresOn, setExpiresOn] = useState('')
  const dispatch = useDispatch()

  const [activeInvestReady, setActiveInvestReady] = useState(-1)
  const [historyId, setHistoryId] = useState(0)

  const isKycPassed = profile.status >= AccountStatus.KycPassed

  useEffect(() => {
    if (!isKycPassed) {
      setLoading(false)
      return
    }
    refresh()
  }, [])

  const isApproved = useMemo(() => {
    return expiresOn && new Date(expiresOn) > new Date()
  }, [expiresOn])

  // const isUnderReviewExist = !!data && !!data.filter((item) => item.status == InvestReadyStatus.Pending).length
  // const isSubmittedExist = !!data && !!data.filter((item) => item.status == InvestReadyStatus.Submitted).length

  const refresh = () => {
    setLoading(true)
    getInvestReady()
      .then((data) => setData(data))
      .finally(() => setLoading(false))

    getInvestReadyExpiresOn().then(({ expiresOn }) => {
      setExpiresOn(expiresOn)

      dispatch(
        authUpdateProfile({
          ...profile,
          accreditedExpiresOn: expiresOn ? new Date(expiresOn) : null,
        }),
      )
    })
  }

  const onCloseForm = (needRefresh: boolean) => {
    setActiveInvestReady(-1)
    needRefresh && refresh()
  }

  const renderData = () => {
    if (!data) return null

    const tableData = data.map((item, index) => [
      index + 1,
      IR_QuestionSetTypeOptions[item.type],
      `${item.data['accredify[person][first_name]']} ${item.data['accredify[person][last_name]']}`,
      <InvestReadyCertStatus data={item} />,
      formatTime(item.updatedAt),
      <div>
        <p>{formatDate(item.expiresOn)}</p>
        {item.status == InvestReadyStatus.Approved && item.certificate && item.certificate.certificate && (
          <a className={`text-link`} href={item.certificate.certificate} target="_blank">
            View Certificate
          </a>
        )}
      </div>,
      <div className="flex gap-2">
        <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
          <ClockIcon className="w-4 h-4 text-indigo-600" />
        </div>

        {[InvestReadyStatus.Rejected, InvestReadyStatus.Failed].includes(item.status) && (
          <span
            className="flex items-center gap-2 hover:underline cursor-pointer"
            onClick={() => {
              // setActiveInvestReady(item.id)
              let activeData: InvestReadyCert | null = null
              if (data) activeData = data.find((v) => v.id == item.id) || null
              const options = activeData ? [activeData.type] : []

              navigate('/accreditedVerification', {
                state: {
                  data: activeData,
                  types: options,
                },
              })
            }}
          >
            {item.status == InvestReadyStatus.Rejected ? (
              <PencilSquareIcon className="w-4 h-4 text-indigo-600" />
            ) : (
              <EyeIcon className="w-4 h-4 text-indigo-600" />
            )}
          </span>
        )}
      </div>,
    ])

    return (
      <div className="mb-6">
        {isApproved ? <p className="mb-4">Expires On: {formatDate(expiresOn)}</p> : ''}
        <PlainTable
          tdClass="text-sm px-2 py-2"
          header={['No', 'Type', 'Name', 'Status', 'Updated At', 'Expires On', '']}
          classNames={Array(10)
            .fill(0)
            .map(() => 'px-2 py-3')}
          data={tableData}
        />
        {/* {!isApproved && (isUnderReviewExist || isSubmittedExist) && (
          <p className="inline-block text-sm p-2 rounded-md border border-gray-400 text-gray-500 bg-gray-50">
            {isSubmittedExist
              ? `We've submitted your information to a third-party verification platform. They will take 3-4 business days to process it and notify you of the result.`
              : `Your request has been successfully submitted. We will review it within 1-2 business days and notify you of the result.`}
          </p>
        )} */}
      </div>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <InvestReadyHistoryModal createdBy={false} history={item.history} onClose={() => setHistoryId(0)} />
  }

  const renderDescriptions = () => {
    const data = [
      {
        icon: BanknotesIcon,
        title: 'Income or Asset Verification',
        description:
          'For income verification, investor must provide proof of annual income exceeding $200,000 for the last 2 years (or $300,000 if investing jointly with a spouse).  For asset verification, investor must pass the net worth qualification by providing a bank or brokerage statement with at least $1M in assets.',
      },
      {
        icon: BuildingOffice2Icon,
        title: 'Third Party Verification',
        description:
          'Investor can send a verification request to their accountant or CPA to verify their accreditation status.',
      },
      {
        icon: ClipboardDocumentCheckIcon,
        title: 'Letter Documentation',
        description: `Investor may provide a letter from an alternative verification provider confirming their accreditation status.`,
      },
    ]
    return (
      <div className="mb-10">
        <p className="mb-4">
          In order to comply with SEC regulations, Finresi must verify the accredited investor status of all investors
          on the platform. You may verify using one of the below methods.
        </p>

        <div className="mb-4 ml-2">
          {data.map(({ icon: Icon, title, description }, index) => (
            <div className="flex items-center gap-4 py-2" key={index}>
              <div className="relative rounded-lg overflow-hidden border">
                <div className="w-28 h-28" />
                <div className="w-full h-full left-0 top-0 absolute" />
                <Icon className="absolute w-10 h-10 top-9 left-9 text-gray-400" />
              </div>
              <div className="flex-1">
                <p className="font-medium text-lg">{title}</p>
                <p className="text-sm text-gray-500">{description}</p>
              </div>
            </div>
          ))}
        </div>

        <p className="mb-4 text-sm text-gray-500">
          It is important to note that the specific requirements for accredited investor verification may vary depending
          on the investment opportunity and the entity requiring the verification. Investors must comply with the
          applicable rules and regulations to ensure they are eligible to participate in certain investment
          opportunities.
        </p>
      </div>
    )
  }

  const isFetched = data && !data.length

  if (activeInvestReady >= 0) {
    let activeData: InvestReadyCert | null = null
    if (data) activeData = data.find((v) => v.id == activeInvestReady) || null
    return <InvestReadyEditor types={activeData ? [activeData.type] : []} data={activeData} onClose={onCloseForm} />
  }

  const isShowDescription = isFetched || !isKycPassed

  return (
    <div className="pb-4">
      <LayoutLoading show={isLoading} />

      {isShowDescription && renderDescriptions()}

      {!isKycPassed && (
        <div className="justify-center text-center">
          <span
            className="font-medium py-3.5 px-6 border border-yellow-400 text-yellow-500 rounded-lg mx-auto cursor-pointer hover:bg-yellow-400 hover:text-white hover:shadow transition-all duration-200"
            onClick={() => navigate(`/profile#${ProfileMenuTitles.KYC}`)}
          >
            Please complete Identity Verification
          </span>
        </div>
      )}

      {isKycPassed && !!data && (
        <div>
          {!!data.length && renderData()}
          {renderHistoryModal()}
          {isApproved && (
            <div className="justify-center text-center">
              <p className="inline-block font-medium py-3.5 px-6 border border-green-400 text-green-500 rounded-lg mx-auto">
                Congratulations. Verification is Passed.
              </p>
            </div>
          )}
          <div className="flex flex-col items-center mt-6">
            <div className="max-w-[16rem] mx-auto">
              <Button
                full
                className="font-medium py-3.5 px-6"
                onClick={() => {
                  // setActiveInvestReady(0)
                  navigate('/accreditedVerification')
                }}
              >
                Create New Verification
              </Button>
            </div>
          </div>
          {/* {isApproved ? (
            <div className="justify-center text-center">
              <p className="inline-block font-medium py-3.5 px-6 border border-green-400 text-green-500 rounded-lg mx-auto">
                Congratulations. Verification is Passed.
              </p>
            </div>
          ) : (
            !isSubmittedExist && (
              <div className="flex flex-col items-center">
                <div className="max-w-[16rem] mx-auto">
                  <Button
                    full
                    className="font-medium py-3.5 px-6"
                    onClick={() => {
                      // setActiveInvestReady(0)
                      navigate('/accreditedVerification')
                    }}
                  >
                    Create New Verification
                  </Button>
                </div>
              </div>
            )
          )} */}
        </div>
      )}
    </div>
  )
}
