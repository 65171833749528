import { AUTH_TOKEN_SET } from 'actions'
import { AuthLayout, BaseLayout, DashboardLayout } from 'layouts'
import { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState } from 'reducers'
import { AuthRoutes, MainRoutes } from 'routes'
import useSyncSetState from 'use-sync-set-state'
import { isEmpty } from 'utils'

import { BaseRoutes } from './routes'

const queryClient = new QueryClient()

function App() {
  const { pathname } = useLocation()
  const { isAuthenticated, profile } = useSelector((state: RootState) => state.auth)

  const [authData, setAuthData] = useSyncSetState('authData', null)
  const dispatch = useDispatch()

  const isBaseRoute = !pathname || ['/', '/home', '/how_it_works'].includes(pathname) || !isAuthenticated
  const isAuthRoute = pathname.startsWith('/auth')
  const isConfirmEmail = pathname.startsWith('/auth/confirmEmail')
  const isRequireChangePwd = profile.isResetPassword

  useEffect(() => {
    if (!authData) return

    dispatch({
      type: AUTH_TOKEN_SET,
      ...authData,
    })
    setAuthData(null)
  }, [authData])

  useEffect(() => {
    if (isEmpty(profile) || !profile?.name) {
      ;(window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'z96ktnj6',
      })
      return
    }

    ;(window as any).Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'z96ktnj6',
      name: profile.name,
      email: profile.email,
      created_at: Math.round(new Date(profile.createdAt).getTime() / 1000),
    })
  }, [profile])

  useEffect(() => {
    ;(window as any).Intercom('update')
  }, [pathname])

  useEffect(() => {
    if (!isAuthenticated) {
      ;(window as any).Intercom('shutdown')
      ;(window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'z96ktnj6',
      })
    }
  }, [isAuthenticated])

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        {isRequireChangePwd ? (
          <AuthLayout>
            <MainRoutes />
          </AuthLayout>
        ) : (!isAuthenticated && isAuthRoute) || isConfirmEmail ? (
          <AuthLayout>
            <AuthRoutes />
          </AuthLayout>
        ) : isBaseRoute ? (
          <BaseLayout>
            <BaseRoutes />
          </BaseLayout>
        ) : (
          <DashboardLayout>
            <MainRoutes />
          </DashboardLayout>
        )}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
