import { authUpdateProfile } from 'actions'
import { imgCardIcon } from 'assets'
import { Image } from 'components/Image'
import { Loading } from 'components/Loading'
import { AccountStatus } from 'config'
import { IdentityVerificationStatus } from 'plaid'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { RootState } from 'reducers'
import { getPlaidIdentityVerification } from 'services/apis'
import { Button } from 'stories/components'
import { formatTimeLong, useTitle } from 'utils'

import { ProfileMenuTitles } from './Profile'

export const KYCStatus = () => {
  useTitle('Identity Verification')

  const navigate = useNavigate()
  const { profile } = useSelector((state: RootState) => state.auth)
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState<IdentityVerificationStatus | null>(null)
  const [approvedAt, setApprovedAt] = useState<string | null>(null)
  const dispatch = useDispatch()

  const isKycPassed = profile.status >= AccountStatus.KycPassed

  useEffect(() => {
    // if (isKycPassed) return
    setLoading(true)
    getPlaidIdentityVerification()
      .then(({ status, approvedAt, country }) => {
        if (status == IdentityVerificationStatus.Success) {
          dispatch(
            authUpdateProfile({
              ...profile,
              status: AccountStatus.KycPassed,
              country,
            }),
          )
        }
        setStatus(status)
        setApprovedAt(approvedAt)
      })
      .finally(() => setLoading(false))
  }, [])

  console.log(status)

  const onVerify = () => {
    navigate('/kyc')
  }

  const data = [
    {
      icon: imgCardIcon,
      title: 'Verification Requirements',
      description: (
        <>
          To complete this verification process successfully, please have your <b>phone</b> and your <b>ID card</b> on
          hand and ready for use.
        </>
      ),
    },
  ]

  return (
    <div className="min-h-[50vh]">
      {(!isKycPassed || !approvedAt) && (
        <div className="mb-10">
          {data.map(({ icon, title, description }, index) => (
            <div className="flex items-center gap-4 mb-8" key={index}>
              <div>
                <div className="relative rounded-lg overflow-hidden border">
                  <div className="w-36 h-24" />
                  <div className="w-full h-full left-0 top-0 absolute" />
                  <Image src={icon} className="absolute h-10 brightness-50 top-6 left-[50%] -translate-x-[50%]" />
                </div>
              </div>
              <div className="flex-1">
                <p className="font-medium text-lg">{title}</p>
                <p className="text-sm text-gray-700">{description}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="flex flex-col items-center w-full justify-center">
        {profile.status < AccountStatus.ProfileCompleted ? (
          <span
            className="font-medium py-3.5 px-6 border border-yellow-400 text-yellow-500 rounded-lg mx-auto cursor-pointer hover:bg-yellow-400 hover:text-white hover:shadow transition-all duration-200"
            onClick={() => navigate(`/profile#${ProfileMenuTitles.PROFILE}`)}
          >
            Please complete Account Information
          </span>
        ) : isKycPassed ? (
          approvedAt ? (
            <span className="font-medium py-3.5 px-6 border border-green-400 text-green-500 rounded-lg mx-auto">
              Identity verification completed on {formatTimeLong(approvedAt)}
            </span>
          ) : null
        ) : (
          <div className="">
            <div className="mb-2">
              {isLoading && <Loading />}
              {/* {!!status && (
                <span className="p-2 bg-yellow-400 text-white text-sm rounded-lg">Not Passed - {status}</span>
              )} */}
            </div>
            {!isLoading && (
              <div className="max-w-[16rem] mx-auto">
                <Button full className="font-medium py-3.5 px-6" onClick={onVerify}>
                  Verify Identity
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
