import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { defaultFilterInputs } from 'pages/Marketplace/constants'
import { MainHeader } from 'stories/layouts'
import { normalizeNumber, RenderInput } from 'utils'

import { dummyLoans } from './data'

export const MarketplaceDemo = () => {
  const filterInputs = defaultFilterInputs()

  return (
    <div className="absolute md:right-[50%] md:object-cover left-0 md:left-auto max-w-[180vw] md:max-w-fit w-[1195px] h-[1088px] skew-x-12">
      <div
        className="bg-white md:w-[85rem] sm:w-220 w-196 sm:h-[125%] h-220 rounded-lg"
        style={{ transform: 'translate(0%, -15%) scale(0.7)' }}
      >
        <MainHeader type="white" showBroadcastHeader={false} />
        <div className="w-full bg-gray-100/50">
          <div className="fullContent py-4">
            <div className="mb-4">
              <p className="text-lg font-bold">Investment Opportunities</p>
              <p className="text-desc">
                Browse, search or filter the following opportunities, then click on any one to learn and to invest.
              </p>
            </div>

            <div className="mb-4 text-sm">
              <div className="grid lg:grid-cols-8 grid-cols-4 bg-gray-200/40 p-4 pb-0 rounded-md justify-between gap-2 md:scale-100 scale-90">
                {Object.keys(filterInputs).map((key, index) => {
                  const input = filterInputs[key] as any
                  if (key == 'query') {
                    ;(input.hasIcon = true), (input.icon = <MagnifyingGlassIcon className="w-4 h-4" />)
                  }

                  return (
                    <div className={`input ${key == 'query' ? 'col-span-2' : ''}`} key={index}>
                      <RenderInput input={input} Key={key} onChange={() => {}} />
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex-[4]">
              <div className="grid lg:grid-cols-4 grid-cols-3 gap-4">
                {[...dummyLoans, dummyLoans[2], dummyLoans[3], dummyLoans[1], dummyLoans[0]].map((item, index) => (
                  <div className="rounded-lg overflow-hidden relative" key={index}>
                    <Image src={item.image} className="w-full block aspect-video object-cover" />
                    <div className="bg-white p-4 border-l border-r border-gray-300">
                      <div className="flex gap-4 items-center mb-2">
                        <div className="flex-1">
                          <p className="text-xs">{item.progress}% Funded</p>
                          <div className="w-full bg-gray-200 rounded-full">
                            <div className="bg-light-green h-1 rounded-full" style={{ width: `${item.progress}%` }} />
                          </div>
                        </div>
                        <span className="bg-light-green text-2xs text-white font-semibold px-1.5 py-1 rounded-md capitalize">
                          Available
                        </span>
                      </div>
                      <p className="font-bold mb-1 text-base">{item.loan}</p>
                      <p className="text-xs text-gray-400">{item.title}</p>
                    </div>

                    <div className="bg-gray-100 border rounded-b-xl border-gray-300 border-t-0 pb-2">
                      <div className="flex divide-x">
                        {[
                          [`${item.ytm}%`, 'APY'],
                          [`${normalizeNumber(item.ltv, 0)}%`, 'LTV'],
                          [item.term, 'TERM'],
                        ].map((v) => (
                          <div key={v[1]} className="flex-1 text-center py-2">
                            <p className="font-bold text-xs">{v[0]}</p>
                            <p className="text-2xs text-gray-400">{v[1]}</p>
                          </div>
                        ))}
                      </div>
                      <div className="px-4 py-2">
                        <div className={`flex gap-4 items-center justify-center`}>
                          <p className="bg-black rounded-md py-2 text-white text-center text-xs flex-1 hover:bg-light-green">
                            Invest Now
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-0 top-0 w-full h-full" style={{ zIndex: 60 }} />
      </div>
    </div>
  )
}
