import { ClockIcon, EyeIcon } from '@heroicons/react/24/outline'
import { authUpdateProfile } from 'actions'
import { AccountType, InputType } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { IEntityData } from 'pages/User'
import { CertFormatHistoryModal } from 'pages/User/modal/CertFormatHistory'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'reducers'
import { getCertFormations, updateSelfUserData, uploadCertFormation } from 'services/apis'
import { Button, PlainTable } from 'stories/components'
import { formatTime, InputConvert, InputValidate, RenderInput, useTitle } from 'utils'

import { CertFormat, CertFormationType, CertFormatStatusStyles, CertFormatStatusText } from './CertFormatType'
import { Entities } from './Entities'
import { ProfileMenuTitles } from './Profile'

const defaultInputs = (): Record<string, InputType> => {
  return {
    certificateFormation: {
      inputType: 'file',
      title: '',
      required: true,
      multiple: true,
    },
  }
}

export const CertFormationTab = () => {
  const { profile } = useSelector((state: RootState) => state.auth)
  const { accountType, country } = profile

  const isCompany = [AccountType.Company, AccountType.Individual].includes(accountType)
  const isTrust = [AccountType.Trust].includes(accountType)

  const certFormationTypes = isCompany
    ? [CertFormationType.EntityDocument]
    : isTrust
    ? [CertFormationType.TrustDocument]
    : [CertFormationType.LLCDocument]

  useTitle(isCompany ? `Entity Setup` : isTrust ? `Trust Document` : 'LLC Document')

  const isNonUS = country && country != 'US'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [inputs, setInputs] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<CertFormat[]>()
  const [historyId, setHistoryId] = useState(0)
  const [selectedEntity, setSelectedEntity] = useState<IEntityData>()

  useEffect(() => {
    getCertFormations(certFormationTypes).then(({ data }) => {
      setData(data)
    })
  }, [])

  const accreditationPassed = useMemo(() => {
    const { country, accountType } = profile
    if (accountType === AccountType.Individual) return true
    const isNonUS = country && country != 'US'
    if (isNonUS) {
      if (profile.taxCertPassed) return true
      else return false
    } else {
      if (!!profile.accreditedExpiresOn && new Date(profile.accreditedExpiresOn) > new Date()) return true
      else return false
    }
  }, [profile])

  const entityCompleted = useMemo(() => {
    if (!isCompany) return true
    if (!selectedEntity) return false
    const { entityName, entityType, entityAddress, jurisdiction, ein } = selectedEntity
    return !!entityName && !!entityType && !!entityAddress && !!jurisdiction && !!ein
  }, [selectedEntity, accountType])

  const onView = (fileURL: string) => {
    window.open(fileURL, '_blank')
  }

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSaveEntity = async (data: IEntityData[]) => {
    setLoading(true)
    await updateSelfUserData(profile.id, { entities: data })

    dispatch(
      authUpdateProfile({
        ...profile,
        entities: data,
      }),
    )
    setLoading(false)
  }

  const onSubmit = () => {
    if (isCompany && !selectedEntity) return
    const newInputs = cloneDeep(inputs)
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])

      if (newInputs[key].error) hasError = true
    })
    setInputs(newInputs)
    if (hasError) return false

    const data = {
      entityName: selectedEntity?.entityName || '',
      type: certFormationTypes[0],
    }

    setLoading(true)
    uploadCertFormation(data, Array.from(newInputs['certificateFormation'].value))
      .then((res) => {
        dispatch(
          authUpdateProfile({
            ...profile,
            entities: res,
          }),
        )
        getCertFormations(certFormationTypes).then(({ data }) => {
          setData(data)
          setLoading(false)
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const renderCertTable = useMemo(() => {
    if (!data || !data.length) return <></>

    const tableData = data.map((item, index) => {
      const targetEntity = profile.entities.find((ett) => ett.certIDs.includes(item.id))

      const rlt = [
        index + 1,
        <p className="text-blue-800">
          {item.fileName.length > 30 ? item.fileName.substring(0, 30) + '...' : item.fileName}
        </p>,
        <span className={`${CertFormatStatusStyles[item.status]} px-2 py-1 rounded`}>
          {CertFormatStatusText[item.status]}
        </span>,
        formatTime(item.createdAt),
        <div className="flex items-center gap-2 w-32">
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
            <Tooltip content={`View History`}>
              <ClockIcon className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200" />
            </Tooltip>
          </div>

          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onView(item.fileURL)}>
            <Tooltip content={`View File`}>
              <EyeIcon className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200" />
            </Tooltip>
          </div>
        </div>,
      ]
      if (isCompany) rlt.splice(1, 0, targetEntity?.entityName || '')
      return rlt
    })

    const header = ['No', 'File', 'Status', 'Created At', 'Action']
    if (isCompany) header.splice(1, 0, 'Entity Name')
    return (
      <div className="mb-6">
        <PlainTable
          tdClass="text-sm px-2 py-2"
          header={header}
          classNames={Array(10)
            .fill(0)
            .map(() => 'px-2 py-3')}
          data={tableData}
        />
      </div>
    )
  }, [selectedEntity, data])

  const renderUploadSection = () => {
    return (
      <>
        <div className="my-4">
          <p className="text-xl font-medium">
            Upload {isCompany ? 'Certificate of Formation' : isTrust ? 'Trust Document' : 'LLC Document'}
          </p>
        </div>
        <div className="flex justify-center">
          {RenderInput({
            input: inputs['certificateFormation'],
            Key: 'certificateFormation',
            onChange,
          })}
        </div>
      </>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <CertFormatHistoryModal createdBy history={item.history} onClose={() => setHistoryId(0)} />
  }

  if (!accreditationPassed)
    return (
      <span
        className="font-medium py-3.5 px-6 border border-yellow-400 text-yellow-500 rounded-lg mx-auto cursor-pointer hover:bg-yellow-400 hover:text-white hover:shadow transition-all duration-200"
        onClick={() => navigate(`/profile#${isNonUS ? ProfileMenuTitles.TAX_CERT : ProfileMenuTitles.INVEST_READY}`)}
      >
        Please complete {isNonUS ? 'Non U.S Investor Tax Forms' : 'Accredited Investor Verification'}
      </span>
    )

  return (
    <div>
      {renderCertTable}

      {isCompany && (
        <Entities
          user={profile}
          isLoading={isLoading}
          onSaveEntity={onSaveEntity}
          setSelectedEntity={setSelectedEntity}
        />
      )}

      {(selectedEntity || !isCompany) && (
        <>
          {!entityCompleted && (
            <div className="flex justify-center mb-2">
              <div className="border border-yellow-500 bg-yellow-50 text-yellow-500 text-center p-3 rounded min-w-[300px] w-1/2">
                Please fill out all fields.
              </div>
            </div>
          )}

          {entityCompleted && (
            <>
              {renderUploadSection()}

              <div className="max-w-[16rem] mx-auto">
                <Button full onClick={onSubmit} loading={isLoading}>
                  Upload
                </Button>
              </div>
            </>
          )}
        </>
      )}

      {renderHistoryModal()}
    </div>
  )
}
