import { CurrencyDollarIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { type InputType, MinTrxAmount } from 'config'
import { cloneDeep } from 'lodash'
import { Institution, TransferIntentCreateMode } from 'plaid'
import { useEffect, useState } from 'react'
import { getBalances, getPlaidInstitution } from 'services/apis'
import { Button } from 'stories/components'
import { normalizeNumber, RenderInput, validateInputs } from 'utils'

import { ContentHeader } from './ContentHeader'
import { renderBankAccount } from './util'

const defaultInputs = (direction: TransferIntentCreateMode): Record<string, InputType> => {
  const inputAmount: InputType = {
    title: `Input Amount to ${direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'} (USD)`,
    inputType: 'Text0',
    type: 'thousandSep',
    required: true,
    hasIcon: true,
    icon: <CurrencyDollarIcon className="w-4 h-4" />,
    autoFocus: true,
  }
  if (direction == TransferIntentCreateMode.Payment) return { amount: inputAmount }

  return {
    routingNumber: {
      inputType: 'text0',
      type: 'number',
      title: 'Routing Number',
      required: true,
      minLength: 9,
      maxLength: 12,
      autoFocus: true,
    },
    bankName: {
      inputType: 'text0',
      type: 'text',
      title: 'Bank Name',
      required: true,
    },
    accountNumber: {
      inputType: 'text0',
      type: 'number',
      title: 'Account Number',
      required: true,
      minLength: 9,
      maxLength: 17,
    },
    subType: {
      inputType: 'radio',
      options: {
        checking: 'Checking',
        savings: 'Saving',
      },
      title: 'Type',
      required: true,
      className: 'mb-2',
    },
    amount: {
      ...inputAmount,
      autoFocus: false,
    },
  }
}

export const WireTransferContent = ({
  direction,
  defaultData,
  onNext,
  onBack,
}: {
  direction: TransferIntentCreateMode
  defaultData: any
  onNext: Function
  onBack: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs(direction))
  const [institution, setInstitution] = useState<Institution | null>(null)
  const [available, setAvailable] = useState(0)

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    if (newInputs.bankName) {
      newInputs.bankName.visible = !institution
      if (institution) newInputs.bankName.value = ''
    }
    setInputs(newInputs)
  }, [institution])

  useEffect(() => {
    const newInputs = cloneDeep(inputs)
    if (defaultData) {
      Object.keys(newInputs).forEach((key) => {
        if (defaultData[key] !== undefined) newInputs[key].value = defaultData[key]
        newInputs[key].error = ''
      })
    }

    getBalances()
      .then(({ available }) => {
        if (direction == TransferIntentCreateMode.Disbursement) {
          newInputs.amount.title = `${newInputs.amount.title} (Available: $${normalizeNumber(available, 2)})`
          setInputs(newInputs)
          setAvailable(available)
        }
      })
      .then(() => defaultData && setInstitution(defaultData.institution))
  }, [defaultData])

  const onChangeValue = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onBlur = async (key: string) => {
    if (key != 'routingNumber') return

    const routingNumber = inputs.routingNumber.value
    if (institution && institution.routing_numbers && institution.routing_numbers.includes(routingNumber)) return

    if (!routingNumber) {
      setInstitution(null)
      return
    }

    setLoading(true)
    getPlaidInstitution(routingNumber)
      .then((data) => setInstitution(data.institution))
      .finally(() => setLoading(false))
  }

  const onSubmit = () => {
    let { hasError, data, newInputStates } = validateInputs(inputs)
    if (Number(data.amount) < MinTrxAmount) {
      hasError = true
      newInputStates.amount.error = `This amount should be more than ${MinTrxAmount}.`
    }
    const amountValue = Number(String(data.amount).replace(/,/g, ''))
    if (direction == TransferIntentCreateMode.Disbursement && !hasError && amountValue > Number(available)) {
      hasError = true
      newInputStates.amount.error = `The amount should not exceed your available balance of $${normalizeNumber(
        available,
        2,
      )}`
    }
    if (hasError) {
      setInputs(newInputStates)
      return
    }
    if (institution) data.bankName = institution.name
    data.institution = institution
    data.amount = Number(String(data.amount).replace(/,/g, ''))

    onNext(data)
  }

  return (
    <div className="rounded-lg bg-white divide-y">
      <ContentHeader description="Please fill the below form for Wire Transfer." onBack={onBack} />

      <div className="pt-4 text-sm text-gray-600">
        {Object.keys(inputs).map((key) => {
          const input = inputs[key]
          if (key == 'bankName' && isLoading)
            return (
              <div className="input-container mb-4" key={key}>
                <p className="font-medium mb-4">Bank Name *</p>
                <Loading />
              </div>
            )
          if (key == 'bankName' && institution) {
            return (
              <div className="input-container mb-2" key={key}>
                <p className="font-medium mb-2">Bank Name *</p>
                {renderBankAccount(null, institution, false)}
              </div>
            )
          }
          return <RenderInput key={key} Key={key} input={input} onChange={onChangeValue} onBlur={onBlur} />
        })}
      </div>

      <div className="pt-4">
        <Button full color="green" onClick={onSubmit} disabled={isLoading}>
          Review
        </Button>
      </div>
    </div>
  )
}
