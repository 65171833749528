import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useState } from 'react'
import { updateTaskStatus } from 'services/apis'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, RenderInput } from 'utils'

import { DbTask, renderDescription, TaskStatus } from '../type'

const defaultInputs = (): Record<string, InputType> => {
  return {
    comment: {
      inputType: 'textarea',
      type: 'text',
      title: 'Comment',
      value: '',
      span: 2,
      required: false,
    },
  }
}

export const MarkTaskModal = ({ task, onClose }: { task: DbTask; onClose: Function }) => {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const newStatus: TaskStatus = (
    {
      [TaskStatus.COMPLETE]: TaskStatus.ACTIVE,
      [TaskStatus.ACTIVE]: TaskStatus.COMPLETE,
    } as any
  )[task.status]

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    updateTaskStatus(task.id, { status: newStatus, comment: data.comment })
      .then(() => {
        onClose(true, newStatus)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Modal
      title="Are you sure to Mark as ..."
      titleOkay="Confirm"
      loading={loading}
      onClose={() => onClose()}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900 max-w-xl">
        <p dangerouslySetInnerHTML={{ __html: renderDescription(task.description) }} />
        <p className="mt-3">
          Are you sure to Mark as{' '}
          <span className="uppercase">
            {newStatus == TaskStatus.COMPLETE ? (
              <span className="text-green-500 font-bold">COMPLETED</span>
            ) : (
              <span className="text-red-800 font-bold">INCOMPLETED</span>
            )}
          </span>
          ?
        </p>
        <div className="md:w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3 mt-2">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
