import { BuildingLibraryIcon, CurrencyDollarIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { itemCountPerPage } from 'config'
import { EnvelopeColorMap } from 'pages/Invest'
import type { DBInvestment } from 'pages/Payment'
import { InvestDetails } from 'pages/Payment/modal'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import type { RootState } from 'reducers'
import { getDocuments, getEnvelopeUrl, getInvestment, openSubAgreement } from 'services/apis'
import { ButtonGroup, ResponsiveTable } from 'stories/components'
import { formatTime, useTitle } from 'utils'

interface IDocuments {
  loanId?: number
  investId?: number
  type: string
  status: string
  envelopeId: string
  date: Date
}

const documentTypes: Record<string, string> = {
  subAgreement: 'Subscription Agreement',
  invest: 'Investment Document',
  retrade: 'Retrade Document',
}

const documentIcons: Record<string, any> = {
  subAgreement: <DocumentTextIcon className="w-6 h-6 rounded-full" />,
  invest: <BuildingLibraryIcon className="w-6 h-6 rounded-full" />,
  retrade: <CurrencyDollarIcon className="w-6 h-6 rounded-full" />,
}

export const MyDocuments = () => {
  useTitle('My Documents')

  const { profile } = useSelector((state: RootState) => state.auth)
  const { subAgreementAt } = profile

  const [documents, setDocuments] = useState<IDocuments[]>([])
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [selectedInvest, setSelectedInvest] = useState()
  const [filters, setFilters] = useState<Record<string, any>>({
    type: 'All',
    pageNum: 0,
  })

  useEffect(() => {
    refetch(filters)
  }, [])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (documents.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getDocuments({
      ...filters,
      count: itemCountPerPage,
    })
      .then(({ total, data }) => {
        if (_pageNum === 0) setDocuments(data)
        else setDocuments([...documents, ...data])

        if (subAgreementAt) setTotal(total)
        else setTotal(0)
      })
      .finally(() => setLoading(false))
  }

  const onOpenInvestDetail = (investId: number | undefined) => {
    if (!investId) return
    setLoading(true)
    getInvestment(investId)
      .then((data) => {
        setSelectedInvest(data)
      })
      .finally(() => setLoading(false))
  }

  const onOpenDocument = (index: number) => {
    if (documents[index]?.loanId) {
      const doc = documents[index]

      setLoading(true)
      getEnvelopeUrl(doc.loanId as number, doc.envelopeId)
        .then((res) => {
          if (res?.url) window.open(res.url, '_blank')
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(true)
      openSubAgreement()
        .then(({ redirectUrl }) => window.open(redirectUrl, '_blank'))
        .finally(() => setLoading(false))
    }
  }

  const onChangeFilter = (key: 'type' | 'pageNum', value: any, needRefetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value

    refetch(newFilters, needRefetch ? 0 : -1)
  }

  const renderItem = (item: IDocuments, index: number) => {
    return [
      <div className="flex items-center lg:justify-start p-2 gap-2">
        {index + 1}.
        <div className={`p-2 ${EnvelopeColorMap[item.status]} rounded-full`}>{documentIcons[item.type]}</div>
        {documentTypes[item.type]}
        {item?.investId && (
          <div
            className="font-bold text-indigo-500 hover:underline"
            onClick={(e) => {
              e.preventDefault()
              onOpenInvestDetail(item.investId)
            }}
          >
            <span>#{item.investId}</span>
          </div>
        )}
      </div>,
      <div className="flex justify-center p-2">
        <div className={`${EnvelopeColorMap[item.status]} w-28 py-1.5 rounded-md text-center capitalize`}>
          {item.status}
        </div>
      </div>,
      <div className="flex p-2 justify-center lg:justify-end">
        <div>{formatTime(item.date)}</div>
      </div>,
    ]
  }

  const renderDocuments = useMemo(() => {
    if (!documents.length)
      return (
        <div className="w-full flex justify-center items-center mt-20">
          <div className="flex flex-col items-center gap-1">
            <DocumentTextIcon className="w-12 h-12 " />
            <span className="">No Documents</span>
          </div>
        </div>
      )

    return (
      <div className="min-h-[8rem] relative rounded overflow-hidden">
        <LayoutLoading show={isLoading} />
        <InfiniteScroll
          dataLength={documents.length}
          next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
          hasMore={documents.length < total}
          loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
        >
          <ResponsiveTable
            header={[]}
            headerKeys={[]}
            headerClassName="hidden lg:grid mb-2"
            contentClassName="lg:grid grid-cols-12 rounded-lg text-sm p-2 border hover:ring-2 hover:ring-inset hover:ring-gray-200 focus:bg-gray-100 cursor-pointer"
            size={[4, 4, 4]}
            onClickItem={onOpenDocument}
            data={documents.map(renderItem)}
          />
        </InfiniteScroll>
      </div>
    )
  }, [subAgreementAt, documents, isLoading, total, filters])

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <p className="text-3xl font-semibold mb-4 flex gap-4 items-center">
        My Documents ({total}) {isLoading && <Loading />}
      </p>

      <div className="mb-4">
        <ButtonGroup
          title={['All', 'Subscription Agreement', 'Investment', 'Retrade']}
          value={filters.type}
          onChange={(v) => onChangeFilter('type', v)}
        />
      </div>

      {renderDocuments}

      {selectedInvest && (
        <InvestDetails
          data={selectedInvest as DBInvestment}
          onClose={() => {
            setSelectedInvest(undefined)
          }}
        />
      )}
    </div>
  )
}
