import { API_EXCEPTION_ID, API_GET_EXCEPTIONS } from 'config'
import Api from 'services/api'

export const getExceptions = (filters: Record<string, any>) => {
  return Api.get(API_GET_EXCEPTIONS, filters)
}

export const removeMultipleExceptions = (ids: number[]) => {
  return Api.post(API_GET_EXCEPTIONS, { ids })
}

export const deleteException = (id: number) => {
  return Api.delete(API_EXCEPTION_ID, {}, { id })
}
