import { EyeIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addResource, getResources, openS3Document, removeResource, updateResource } from 'services/apis'
import { Button } from 'stories/components'
import { confirm, formatDate, useTitle } from 'utils'

import { ResourceDetail, ResourceDocumentOptions } from './modal/ResourceDetail'

export enum ResourceDocumentType {
  PPM = 'ppm',
  WireTransfer = 'WireTransfer',
}

export interface IResourceDocument {
  id?: number
  name: string
  type: ResourceDocumentType | string
  fileKey: string
  date: string
  createdAt?: Date
  updatedAt: Date
}

export const Resources = () => {
  useTitle('Admin Tools / Resources')

  const [loanding, setLoading] = useState('')
  const [data, setData] = useState<IResourceDocument[]>([])
  const [selectedItem, setSelecteditem] = useState<IResourceDocument | undefined>(undefined)
  const [showDetail, setShowDetail] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading('loading')
      const res = await getResources()
      setData(res)
      setLoading('')
    })()
  }, [])

  const onAdd = async (data: Record<string, string | File>) => {
    try {
      setLoading('add')

      const res = await addResource(data)

      setData(res)
      toast('New Resource successfully added', { type: 'success' })
      setShowDetail(false)
    } catch (error) {}
    setLoading('')
  }

  const onUpdate = async (data: Record<string, string | File>) => {
    try {
      setLoading('add')

      const res = await updateResource(selectedItem?.id as number, data)

      setData(res)
      toast('Successfully updated', { type: 'info' })
      setShowDetail(false)
    } catch (error) {}
    setLoading('')
  }

  const onRemove = async (item: IResourceDocument) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this resource?
        <br />
        <span className="text-gray-600">Name: {item.name}</span>
      </div>
    )

    const result = await confirm(content)
    if (!result) return

    setLoading('loading')

    await removeResource(item.id as number)

    const nData = cloneDeep(data)

    const newData = nData.filter((v) => v.id !== item.id)

    setData(newData)
    setLoading('')
  }

  return (
    <div>
      <div className="flex justify-end">
        <Button
          loading={loanding === 'loading'}
          size="[14px]"
          onClick={() => {
            setSelecteditem(undefined)
            setShowDetail(true)
          }}
        >
          <div className="flex justify-center items-center gap-1">
            <PlusIcon className="w-4 h-4" />
            <span>Add</span>
          </div>
        </Button>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-md">
        <LayoutLoading show={loanding === 'loading'} />

        <table className="w-full text-sm text-left text-gray-900">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100">
            <tr>
              <th className="px-3 py-2">No</th>
              <th className="px-3 py-2">Name</th>
              <th className="px-3 py-2">Type</th>
              <th className="px-3 py-2">Path</th>
              <th className="px-3 py-2">Date</th>
              <th className="px-3 py-2">Action</th>
            </tr>
          </thead>

          {!!data.length && (
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index} className={`border-t border-b ${index % 2 !== 0 ? 'bg-slate-50' : ''}`}>
                    <td className="px-3 py-2">{index + 1}</td>
                    <td className="px-3 py-2">{item.name}</td>
                    <td className="px-3 py-2">{ResourceDocumentOptions[item.type]}</td>
                    <td className="px-3 py-2">{item.fileKey}</td>
                    <td className="px-3 py-2 whitespace-nowrap">{formatDate(item.date, 'MM/DD/YYYY')}</td>
                    <td className="px-3 py-2">
                      <div className="flex justify-start items-center gap-1">
                        <PencilSquareIcon
                          className="w-6 h-6 cursor-pointer transition-all duration-200 text-indigo-500 hover-shadow1 p-1"
                          onClick={() => {
                            setSelecteditem(item)
                            setShowDetail(true)
                          }}
                        />
                        <EyeIcon
                          className="w-6 h-6 cursor-pointer transition-all duration-200 text-indigo-500 hover-shadow1 p-1"
                          onClick={() => openS3Document(item.fileKey)}
                        />
                        <TrashIcon
                          className="w-6 h-6 cursor-pointer transition-all duration-200 text-red-600 hover-shadow1 p-1"
                          onClick={() => onRemove(item)}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )}
        </table>
      </div>

      {showDetail && (
        <ResourceDetail
          data={selectedItem as IResourceDocument}
          loading={loanding}
          onClose={() => setShowDetail(false)}
          onAdd={onAdd}
          onUpdate={onUpdate}
        />
      )}
    </div>
  )
}
