import { type InputType, states } from 'config'
import { LoanPropertyTypeOptions, LoanPurposeOptions, LoanStatusText } from 'pages/Marketplace'

export const OrderAppraisalVendorOptions = [
  'AEI Consultants',
  'Appraisal Nation',
  'BBG Real Estate Services',
  'Bowery Valuations',
  'CBRE Commercial Real Estate Services',
  'CFSI Management',
  'Clear Capital',
  'Colliers',
  'Cushman & Wakefield',
  'Elite Valuations',
  'Hillman Consulting',
  'IRR Integra Realty Resources',
  'KOW Building Consultants',
  'Land Gorilla',
  'Newmark Knight Frank',
  'Partners ESI',
  'Richer Values',
  'Trinity',
]

export const BorrowerTypeOptions = ['LLC', 'LLP', 'Corp', 'Partnership', 'Revocable Trust', 'Individual']

export const defaultInputs = (): Record<string, InputType> => {
  const data: Record<string, InputType> = {
    baseSection: {
      title: 'Base Properties',
      inputType: 'section',
      span: 'full',
    },
    status: {
      title: 'Status',
      inputType: 'select',
      options: LoanStatusText,
    },
    // progress: {
    //   title: 'Progress',
    //   inputType: 'MultiRange',
    //   min: 0,
    //   max: 100,
    //   value: 0,
    // },
    // investors: {
    //   title: 'Investors',
    //   inputType: 'text',
    //   type: 'number',
    // },

    propertyAddress: {
      title: 'Address',
      inputType: 'map',
    },
    propertyCounty: {
      title: 'Property County',
      inputType: 'text',
    },
    apnNo: {
      title: 'APN No',
      inputType: 'text',
      type: 'text',
    },
    interestRate: {
      title: 'Interest Rate',
      inputType: 'text',
      type: 'number',
      prefix: '%',
    },
    lenderSpread: {
      title: 'Lender Spread',
      inputType: 'text',
      type: 'number',
    },
    yieldeasySpread: {
      title: 'Finresi Spread',
      inputType: 'text',
      type: 'number',
    },
    ytm: {
      title: 'Investor Yield',
      inputType: 'text',
      type: 'number',
      prefix: '%',
      readOnly: true,
    },
    loanAmount: {
      title: 'Loan Amount',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    yldInvestment: {
      title: 'Finresi Investment',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    availableInvestmentAmount: {
      title: 'Available Investment Amount',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
      readOnly: true,
      history: false,
    },
    gurantee: {
      title: 'Guarantee',
      inputType: 'text',
    },
    ltv: {
      title: 'LTV',
      inputType: 'text',
      type: 'number',
      prefix: '%',
    },
    fciLoanNumber: {
      title: 'FCI Loan Number',
      inputType: 'text',
      type: 'text',
    },
    displayNote: {
      title: 'Display Note',
      inputType: 'text',
      type: 'text',
    },

    originalLoanInfoSection: {
      title: 'Original Loan Information',
      inputType: 'section',
      span: 'full',
    },
    originalDebtor: {
      title: 'Original Debtor',
      inputType: 'text',
      type: 'text',
    },
    originalRecordingDate: {
      title: 'Original Recording Date',
      inputType: 'text',
      type: 'text',
    },
    originalInstrumentNumber: {
      title: 'Original Instrument Number',
      inputType: 'text',
      type: 'text',
    },
    originalCounty: {
      title: 'Original County',
      inputType: 'text',
      type: 'text',
    },
    originalState: {
      title: 'Original State',
      inputType: 'select',
      options: states,
    },
    originalRecordingInformation: {
      title: 'Original Recording Information',
      inputType: 'checkbox',
    },

    note: {
      title: 'Note',
      inputType: 'textarea',
      span: 'full',
      isLegacy: false,
    },
    isFeatured: {
      title: 'Featured?',
      inputType: 'togglebutton',
    },

    imageSection: {
      title: 'Image',
      inputType: 'section',
      span: 'full',
    },
    images: {
      title: 'Images',
      inputType: 'filetable',
      filePath: 'loan/images',
      acceptFileTypes: 'image/*',
      span: 'full',
      sort: true,
    },
    imagePreview: {
      title: 'Preview',
      inputType: 'custom',
      render: () => {},
      span: 'full',
    },

    docSection: {
      title: 'Documents',
      inputType: 'section',
      span: 'full',
    },
    documents: {
      title: 'Documents',
      inputType: 'filetable',
      filePath: 'loan/documents',
      acceptFileTypes: 'application/pdf',
      span: 'full',
      required: false,
      options: [
        {
          title: 'Category',
          key: 'category',
          options: ['', 'Valuation', 'Inspection', 'Permit', 'Plans and Specs', 'Exhibit A'],
        },
      ],
      sort: true,
      moreControls: null,
    },

    /**
     * Investment Overview
     */
    investmentSection: {
      title: 'Investment Overview',
      inputType: 'section',
      span: 'full',
    },
    isInterestOnly: {
      title: 'Interest Only',
      inputType: 'togglebutton',
    },
    lienType: {
      title: 'Lien Type',
      inputType: 'text',
    },
    originationDate: {
      title: 'Origination Date',
      inputType: 'text',
      type: 'date',
    },

    // Purpose & Strategy
    purposeSection: {
      title: 'Purpose & Strategy',
      inputType: 'section',
      span: 'full',
    },
    loanPurpose: {
      title: 'Loan Purpose',
      inputType: 'select',
      options: LoanPurposeOptions,
      hasDefaultOption: true,
    },
    rehabBudget: {
      title: 'Rehab Budget',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    loanStrategy: {
      title: 'Loan Strategy',
      inputType: 'text',
    },
    cashIntoClosing: {
      title: 'Cash into Closing',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    propertyPurchasePrice: {
      title: 'Property Purchase Price',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    // constructionReserveRemaining: {
    //   title: 'Construction Reserve Remaining',
    //   inputType: 'text',
    //   type: 'thousandSep',
    //   prefix: '$',
    // },

    // Debt Stack
    // totalSeniorPositions: number // = loanAmount

    // Leverage Details
    leverageSection: {
      title: 'Leverage Details',
      inputType: 'section',
      span: 'full',
    },
    pariPassu: {
      title: 'Pari Passu',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    subordinatePos: {
      title: 'Subordinate Positions',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    // firstLienAmount: number // = loanAmount
    asIsLTV: {
      title: 'As Is LTV',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '%',
    },
    day1LoanAmount: {
      title: 'Day 1 Loan Amount',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    // purchasePrice: number // = propertyPurchasePrice
    afterRepairLTV: {
      title: 'After Repair LTV',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '%',
    },
    asIsValue: {
      title: 'As Is Value',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },
    ltc: {
      title: 'LTC',
      inputType: 'text',
      type: 'number',
      prefix: '%',
    },
    afterRepairValue: {
      title: 'After Repair Value',
      inputType: 'text',
      type: 'thousandSep',
      prefix: '$',
    },

    /**
     * Valuation Table
     */
    // valuations: Record<string, any>[] // {propertyAddress, appraisalDate, asIsValue, arv}
    appraisalDate: {
      title: 'Appraisal Date',
      inputType: 'text',
      type: 'date',
    },

    /**
     * Payment Term
     */
    paymentSection: {
      title: 'Payment Term',
      inputType: 'section',
      span: 'full',
    },
    originalLoanTerm: {
      title: 'Original Loan Term',
      inputType: 'text',
      type: 'number',
    },
    maturityDate: {
      title: 'Maturity Date',
      inputType: 'text',
      type: 'date',
    },
    borrowerExtension: {
      title: 'Borrower Extension',
      inputType: 'text',
    },
    // prepayable: boolean
    prepaymentPenalty: {
      title: 'Prepayment Penalty',
      inputType: 'text',
    },
    // lockOut: string

    /**
     * Borrower
     */
    borrowerSection: {
      title: 'Borrower',
      inputType: 'section',
      span: 'full',
    },
    borrowerName: {
      title: 'Borrower Name',
      inputType: 'text',
    },
    borrowerType: {
      title: 'Borrower Type',
      inputType: 'select',
      options: BorrowerTypeOptions,
      required: true,
      hasDefaultOption: true,
    },
    borrower: {
      title: 'Borrower',
      inputType: 'text',
    },
    creditScore: {
      title: 'Credit Score',
      inputType: 'text',
      type: 'number',
    },
    // guarantor: string
    trackRecord: {
      title: 'Track Record',
      inputType: 'text',
      type: 'number',
    },

    /**
     * Property Details/Overview
     */
    propertySection: {
      title: 'Property Details/Overview',
      inputType: 'section',
      span: 'full',
    },
    propertyType: {
      title: 'Property Type',
      inputType: 'select',
      options: LoanPropertyTypeOptions,
      hasDefaultOption: true,
    },
    squareFeet: {
      title: 'Current SQFT',
      inputType: 'text',
      type: 'thousandSep',
    },
    afterRehabSQFT: {
      title: 'After Rehab SQFT',
      inputType: 'text',
      type: 'thousandSep',
    },
    bedrooms: {
      title: 'Bedrooms',
      inputType: 'text',
      type: 'thousandSep',
    },
    bathrooms: {
      title: 'Bathrooms',
      inputType: 'text',
      type: 'thousandSep',
    },
    units: {
      title: 'Units',
      inputType: 'text',
      type: 'thousandSep',
    },
    lotSize: {
      title: 'Lot Sqft',
      inputType: 'text',
      type: 'thousandSep',
    },
  }

  Object.keys(data).forEach((key) => {
    if (key !== 'displayNote') data[key].required = true
  })

  return data
}
