import { SettingKey } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSettingValue, updateSetting } from 'services/apis'
import { Button, Input2 } from 'stories/components'
import { useTitle } from 'utils'

export const SettingsPage = () => {
  useTitle('Admin Tools / Settings')

  const [action, setAction] = useState('')
  const [fundingEmail, setFundingEmail] = useState('')
  const [adminEmail, setAdminEmail] = useState('')

  useEffect(() => {
    setAction('setting')
    getSettingValue(SettingKey.FundingEmail)
      .then(async (value) => setFundingEmail(value))
      .finally(() => setAction(''))

    getSettingValue(SettingKey.AdminEmail)
      .then(async (value) => setAdminEmail(value))
      .finally(() => setAction(''))
  }, [])

  const onChangeFunding = (value: string) => {
    setFundingEmail(value)
  }

  const onChangeAdmin = (value: string) => {
    setAdminEmail(value)
  }

  const onSubmit = async () => {
    setAction('setting')
    await Promise.all([
      updateSetting(SettingKey.FundingEmail, fundingEmail),
      updateSetting(SettingKey.AdminEmail, adminEmail),
    ])
    setAction('')
    toast('Emails have been saved.', { type: 'info' })
  }

  return (
    <div>
      <div className="relative mb-6">
        <div className="mb-4">
          <Input2
            title="Funding Email"
            type="email"
            required
            value={fundingEmail}
            onChange={(value) => onChangeFunding(value)}
          />
        </div>

        <div className="mb-4">
          <Input2
            title="Admin Email"
            type="email"
            required
            value={adminEmail}
            onChange={(value) => onChangeAdmin(value)}
          />
        </div>

        <Button className="w-[250px]" loading={action == 'setting'} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </div>
  )
}
