import { ClockIcon, EyeIcon } from '@heroicons/react/24/outline'
import { authUpdateProfile } from 'actions'
import { AccountType, InputType } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { CertFormatHistoryModal } from 'pages/User/modal/CertFormatHistory'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from 'reducers'
import { getCertFormations, submitTrustDetails } from 'services/apis'
import { Button, PlainTable } from 'stories/components'
import { formatTime, InputConvert, InputValidate, RenderInput } from 'utils'

import { accountDetailsDefaultInputs } from './AccountDetails'
import { CertFormat, CertFormationType, CertFormatStatusStyles, CertFormatStatusText } from './CertFormatType'

const defaultInputs = (): Record<string, InputType> => {
  return {
    certificateFormation: {
      inputType: 'file',
      title: '',
      required: true,
      multiple: true,
    },
  }
}

export const TrustDetails = () => {
  const { profile } = useSelector((state: RootState) => state.auth)

  const certFormationTypes = [CertFormationType.TrustDocument]

  const dispatch = useDispatch()

  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<CertFormat[]>()
  const [historyId, setHistoryId] = useState(0)

  useEffect(() => {
    getCertFormations(certFormationTypes).then(({ data }) => {
      setData(data)
    })
  }, [])

  useEffect(() => {
    const newInputs: Record<string, InputType> = {
      ...accountDetailsDefaultInputs(AccountType.Trust),
      ...defaultInputs(),
    }
    const { accountDetails } = profile
    Object.keys(newInputs).forEach((key) => {
      if (newInputs[key].inputType === 'text0') newInputs[key].inputType = 'text'
      if ((accountDetails as any)[key]) newInputs[key].value = (accountDetails as any)[key]
    })
    newInputs['certificateFormation'].required = false
    setInputs(newInputs)
  }, [profile])

  const onView = (fileURL: string) => {
    window.open(fileURL, '_blank')
  }

  const onChange = async (key: string, value: any) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onSubmit = () => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value || ''
    })
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    submitTrustDetails(values, Array.from(newInputs['certificateFormation']?.value || []))
      .then((res) => {
        dispatch(
          authUpdateProfile({
            ...profile,
            ...res,
          }),
        )
        getCertFormations(certFormationTypes).then(({ data }) => {
          setData(data)
          setLoading(false)
        })

        toast(`Successfully submitted`, { type: 'success' })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const renderCertTable = useMemo(() => {
    if (!data || !data.length) return <></>

    const tableData = data.map((item, index) => {
      const rlt = [
        index + 1,
        <p className="text-blue-800">
          {item.fileName.length > 30 ? item.fileName.substring(0, 30) + '...' : item.fileName}
        </p>,
        <span className={`${CertFormatStatusStyles[item.status]} px-2 py-1 rounded`}>
          {CertFormatStatusText[item.status]}
        </span>,
        formatTime(item.createdAt),
        <div className="flex items-center gap-2 w-32">
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
            <Tooltip content={`View History`}>
              <ClockIcon className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200" />
            </Tooltip>
          </div>

          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onView(item.fileURL)}>
            <Tooltip content={`View File`}>
              <EyeIcon className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1 transition-all duration-200" />
            </Tooltip>
          </div>
        </div>,
      ]
      return rlt
    })

    const header = ['No', 'File', 'Status', 'Created At', 'Action']

    return (
      <div className="mb-6">
        <PlainTable
          tdClass="text-sm px-2 py-2"
          header={header}
          classNames={Array(10)
            .fill(0)
            .map(() => 'px-2 py-3')}
          data={tableData}
        />
      </div>
    )
  }, [data])

  const renderUploadSection = () => {
    if (!inputs['certificateFormation']) return <></>
    return (
      <>
        <div className="my-4">
          <p className="font-medium">Upload Trust Document</p>
        </div>
        <div className="flex justify-center">
          {RenderInput({
            input: inputs['certificateFormation'],
            Key: 'certificateFormation',
            onChange,
          })}
        </div>
      </>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <CertFormatHistoryModal createdBy history={item.history} onClose={() => setHistoryId(0)} />
  }

  return (
    <div>
      {renderCertTable}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          if (key === 'certificateFormation') return <></>

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>

      {renderUploadSection()}

      <div className="max-w-[16rem] mx-auto">
        <Button full onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </div>

      {renderHistoryModal()}
    </div>
  )
}
