import { IServiceArticle } from 'pages/ServiceArticle/types'
import { Link } from 'react-router-dom'

interface OtherArticleItemProps {
  article: IServiceArticle

  linkHref: string
}

const OtherArticleItem: React.FC<OtherArticleItemProps> = ({ article, linkHref }) => {
  return (
    <Link to={linkHref}>
      <div className="flex w-full cursor-pointer items-center justify-between py-5 text-left rtl:text-right">
        <div className="flex items-start gap-4">
          <img
            alt={article?.title ?? 'resource'}
            src={article?.imageURL ?? ''}
            width={100}
            height={40}
            className="mt-2"
          />
          <div className="grid gap-1">
            <h2 className="text-lg font-semibold font-inter">{article.title}</h2>
            <p>{article.description}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default OtherArticleItem
