import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useMemo, useState } from 'react'
import { getStatisticsSummary } from 'services/apis'
import { Accordion } from 'stories/components'
import { getPrice3decimal, useTitle } from 'utils'

// ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

// const fullYear = new Date().getFullYear()

interface IStatisticsSummary {
  users: number
  activeLoans: number
  pastLoans: number
  totalDeposited: number
  totalWithdrawal: number
  activeInvestedAmount: number
  activeInvestedLoans: number
  totalInvestedAmount: number
  totalInvestedLoans: number
  approvedDistribution: number
  pendingDistribution: number
  retradedFee: number
}

export const StatisticsPage = () => {
  useTitle('Admin Tools / Statistics')

  const [data, setData] = useState<IStatisticsSummary | null>(null)
  // const [payments, setPayments] = useState<PaymentStatistics[]>([])
  const [action, setAction] = useState('')
  // const [year, setYear] = useState(`${fullYear}`)

  useEffect(() => {
    setAction('loading')
    getStatisticsSummary()
      .then((res) => {
        setData(res)
      })
      .finally(() => setAction(''))
  }, [])

  const renderStatistics = useMemo(() => {
    if (!data) return <></>

    const renderData: Record<string, any> = {
      'Active Loans': getPrice3decimal(data.activeLoans),
      'Past Loans': getPrice3decimal(data.pastLoans),
      'Total Deposited': `$ ${getPrice3decimal(data.totalDeposited)}`,
      'Total Withdrawal': `$ ${getPrice3decimal(data.totalWithdrawal)}`,
      'Active Invested Loans': getPrice3decimal(data.activeInvestedLoans),
      'Active Invested Amount': `$ ${getPrice3decimal(data.activeInvestedAmount)}`,
      'Total Invested Loans': getPrice3decimal(data.totalInvestedLoans),
      'Total Invested Amount': `$ ${getPrice3decimal(data.totalInvestedAmount)}`,
      'Approved Distributions': `$ ${getPrice3decimal(data.approvedDistribution)}`,
      'Pending Distributions': `$ ${getPrice3decimal(data.pendingDistribution)}`,
      'Retraded Fee': `$ ${getPrice3decimal(data.retradedFee)}`,
      Users: getPrice3decimal(data.users),
    }

    return <Accordion data={renderData} />
  }, [data])

  // useEffect(() => {
  //   setAction('payment')
  //   getPaymentStatistics(year)
  //     .then(setPayments)
  //     .finally(() => setAction(''))
  // }, [year])

  // const payStatistics = useMemo(() => {
  //   const deposits: number[] = []
  //   const withdraws: number[] = []

  //   for (let i = 0; i < 12; i++) {
  //     deposits.push(0)
  //     withdraws.push(0)
  //   }

  //   payments.forEach(({ month, direction, amount }) => {
  //     if (direction == TransferIntentCreateMode.Payment) deposits[month - 1] = amount
  //     else withdraws[month - 1] = amount
  //   })

  //   return {
  //     labels: moment.monthsShort(),
  //     datasets: [
  //       {
  //         label: 'Deposit',
  //         data: deposits,
  //         backgroundColor: '#6687EA',
  //       },
  //       {
  //         label: 'Withdraw',
  //         data: withdraws,
  //         backgroundColor: '#FF87EA',
  //       },
  //     ],
  //   }
  // }, [payments])

  return (
    <div className="w-full relative">
      <LayoutLoading show={action == 'loading'} />

      {renderStatistics}
      {/* <div className="bg-indigo-800 w-full absolute top-0 h-196 md:h-144 lg:h-96" />
      <div className="fullContent relative">
        <p className="text-4xl font-semibold mb-4 text-white">Statistics</p>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mb-4">
          <div className="lg:col-span-3 bg-white p-4 rounded-lg border">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold mb-2">Payment Overview</p>
              <ButtonGroup
                title={[fullYear - 2, fullYear - 1, fullYear].map((v) => `${v}`)}
                value={year}
                onChange={setYear}
              />
            </div>
            <div className="w-full">
              <Bar options={{ responsive: true, aspectRatio: 1.5 }} data={payStatistics} className="w-full" />
            </div>
          </div>

          <div className="py-4 bg-white rounded-lg border">
            <p className="text-lg font-bold mb-2 px-4">Total</p>
            {data && (
              <div className="divide-y">
                {[
                  ['Users', data.user.count, 0],
                  ['Active Loans', data.loan.active || 0, 0],
                  ['Past Loans', data.loan.past || 0, 0],
                  ['Total Invested', data.invest.amount, 1],
                  ['Active Invests', data.invest.active],
                  ['Inactive Invests', data.invest.total - data.invest.active],
                  ['Profits', data.profit.total, 1],
                  ['Deposit', data.payment.deposit, 1],
                  ['Withdraw', data.payment.withdraw, 1],
                ].map((item) => (
                  <div className="flex items-center justify-between px-4 py-2" key={item[0]}>
                    <p className="text-md lg:text-sm text-gray-500 mb-1">{item[0]}</p>
                    <p className="text-3xl lg:text-xl font-semibold lg:font-medium">
                      {item[2] ? `$${normalizeNumber(Number(item[1]))}` : item[1]}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div> */}
    </div>
  )
}
