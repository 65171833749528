import type { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    image: {
      inputType: 'file',
      title: 'Photo',
      required: true,
      multiple: false,
      acceptFileTypes: 'image/*',
    },
    imagePreview: {
      inputType: 'custom',
      title: 'Photo Preview',
    },

    altImage: {
      inputType: 'text',
      title: 'Alt text for image',
      required: false,
    },
    title: {
      inputType: 'text',
      title: 'Title',
      required: true,
    },

    tags: {
      inputType: 'text',
      title: 'Tags',
      required: false,
      placeholder: 'Input tag with comma.',
    },

    articleType: {
      inputType: 'Select',
      title: 'Type',
      required: true,
      options: ['Article', 'Blog'],
      hasDefaultOption: true,
    },
    link: {
      inputType: 'text',
      title: 'Link',
      required: true,
      visible: false,
      span: 'full',
      type: 'url',
    },

    pageTitle: {
      inputType: 'text',
      title: 'Page Title',
      required: false,
      visible: false,
    },

    metaDescription: {
      inputType: 'text',
      title: 'Meta Description',
      required: false,
      visible: false,
    },

    description: {
      inputType: 'Ckeditor',
      isLegacy: false,
      title: 'Description',
      required: true,
      span: 'full',
      visible: false,
    },
  }
}
