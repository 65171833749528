import { onApplicationError } from 'actions/application.action'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { isString } from 'lodash'
import { toast } from 'react-toastify'

import { store } from '../reducers'

class Api {
  static loanNumber: string
  static error: any = {}

  static setLoanNumber(num: string) {
    this.loanNumber = num
  }

  static getLoanNumber() {
    return this.loanNumber
  }

  static get(route: string, data: any = {}, params: any = {}, options: any = {}, catchException = true) {
    return this.xhr(route, data, params, 'get', options, catchException)
  }

  static put(route: string, data: any = {}, params: any = {}) {
    return this.xhr(route, data, params, 'put')
  }

  static post(route: string, data: any = {}, params: any = {}, options: any = {}) {
    return this.xhr(route, data, params, 'post', options)
  }

  static delete(route: string, data: any = {}, params: any = {}) {
    return this.xhr(route, data, params, 'delete')
  }

  static replaceVariables(route: string, params: any) {
    Object.keys(params).forEach((key) => {
      route = route.replace(`:${key}`, params[key])
    })
    return route
  }

  static async wrapApiErrors(error: any = {}) {
    try {
      let { status, data } = error.response || {}
      if (!status) {
        throw new Error('Connection with API server is broken')
      }
      if (status === 401) {
        const state = store.getState()
        const {
          auth: { token },
        } = state
        if (token) {
          store.dispatch({ type: 'AUTH_LOGOUT' })
          throw new Error('Unauthorized')
        }
      }
      if (data instanceof Blob) {
        data = await data.text()
        if (data.startsWith('{')) data = JSON.parse(data)
      }

      const { message } = data
      if (!message) {
        throw new Error(data)
      }

      if (isString(message)) {
        throw new Error(message)
      }
      if (status === 400) {
        let _message = ''
        message.map((item: string) => {
          _message += `${item}\n`
        })
        throw new Error(_message)
      }
      throw new Error('Unknown error')
    } catch (e: any) {
      console.log('API error', e)
      if (this.error !== e.message) {
        this.error = e.message
        setTimeout(() => (this.error = {}), 3000)
        store.dispatch(onApplicationError(e))
      }
      throw e
    }
  }

  static xhr(route: string, data = {}, params = {}, method: string, defaultOptions: any = {}, catchException = true) {
    const state = store.getState()

    const sendRequest = (axiosInstance: AxiosInstance) => {
      const url = Api.replaceVariables(route, params)
      const headers: any = {
        'Content-Type': 'application/json',
        'loan-number': this.loanNumber,
      }

      if (state.auth.token || (data as any).token) {
        headers.Authorization = `Bearer ${state.auth.token || (data as any).token}`
      }

      const options: any = {
        baseURL: process.env.REACT_APP_API_URL,
        url,
        method,
        headers,
        timeout: 25000,
        ...defaultOptions,
      }

      if (method === 'get') {
        options.params = data
      } else {
        options.data = data
      }

      return axiosInstance(options)
        .then((res) => {
          const { success, message } = res.data

          if (success !== undefined && message) {
            toast(message, { type: success ? 'success' : 'error' })
          }
          return res.data
        })
        .catch(async (err) => {
          return catchException && Api.wrapApiErrors(err)
        })
    }
    return sendRequest(axios.create())
  }

  static uploadFiles(route: string, data: any = {}, params: any = {}, files: Array<File>) {
    const state = store.getState()

    const sendRequest = (axiosInstance: AxiosInstance) => {
      const url = Api.replaceVariables(route, params)
      const headers: any = {
        'Content-Type': 'multipart/form-data',
        'loan-number': this.loanNumber,
      }

      if (state.auth.token || (data as any).token) {
        headers.Authorization = `Bearer ${state.auth.token || (data as any).token}`
      }

      var formData = new FormData()
      files.forEach((file, index) => formData.append(`files[${index}]`, file))
      Object.keys(data).forEach((key) => formData.append(key, data[key]))

      const options: AxiosRequestConfig<any> = {
        baseURL: process.env.REACT_APP_API_URL,
        url,
        method: 'post',
        headers,
        // timeout: 15000,
        data: formData,
      }

      return axiosInstance(options)
        .then((res) => res.data)
        .catch((err) => {
          return Api.wrapApiErrors(err)
        })
    }
    return sendRequest(axios.create())
  }
}

export default Api
