// import { AUTH_TOKEN_SET } from 'actions'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import * as LayoutLoading from 'components/LayoutLoading'
import { AccountType } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { RootState } from 'reducers'
import { finalUserSignup } from 'services/apis'
import { Step } from 'stories/components'
import { isEmpty, useTitle } from 'utils'

import {
  AccreditedInvestorSection,
  CheckPhoneSection,
  CompanySection,
  CreateAccountSection,
  FinalSection,
  IndividualSection,
  InvestableAssetsSection,
  InvestmentRangeSection,
  InvestorTypeSection,
  IRASecion,
} from './Section'
import { SignUpSteps } from './types'

const RequireAdditionalAccounts = [AccountType.Individual, AccountType.Trust, AccountType.Company, AccountType.Ira]

export * from './types'

export const SignUpPage = () => {
  useTitle('Sign Up')

  const location = useLocation()
  const state = location.state
  const search = useLocation().search
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { utmData } = useSelector((state: RootState) => state.application)

  const [token, setToken] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<Record<string, any>[]>([
    {
      country: 'US',
      // firstName: 'Yield',
      // lastName: 'Easy',
      // email: 'test@g.com',
      // password: 'test@g@A1.com',
      // phone: '+1 951 290 3880',
      // city: 'New',
      // state: 'NY',
    },
  ])
  const [step, setStep] = useState<SignUpSteps>(SignUpSteps.CreateAccount)
  const urlParams: any = useParams()
  const { step: urlStep } = urlParams

  useEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  useEffect(() => {
    if (Number(urlStep) == step) return
    if (urlStep && step < Number(urlStep)) navigate(`/auth/signUp`)
    else if (step && step != SignUpSteps.CheckPhone) navigate(`/auth/signUp/${step}`)
  }, [step, urlStep])

  useEffect(() => {
    if (!search) return

    const email = new URLSearchParams(search).get('email')

    setData([
      {
        email: email,
        country: 'US',
      },
    ])
  }, [search])

  useEffect(() => {
    if (!state) return

    const { authStep, token } = state

    if (!isEmpty(authStep)) setStep(authStep)
    if (!isEmpty(token)) setToken(token)
  }, [state])

  const accountType = useMemo(() => {
    if (!data || !data[1]) return null
    return data[1].accountType as AccountType
  }, [data])

  const totalSteps = useMemo(() => {
    if (!accountType) return 5
    if (RequireAdditionalAccounts.includes(accountType)) return 6
    return 5
  }, [accountType])

  const stepComponent = useMemo(() => {
    return (
      <div>
        {step >= SignUpSteps.AccreditedInvestor && ![SignUpSteps.Final].includes(step) && (
          <p
            className="flex gap-1 items-center mb-4 text-sm text-indigo-500 cursor-pointer hover:text-indigo-700"
            onClick={() => {
              if (step == SignUpSteps.AccreditedInvestor) setStep(SignUpSteps.InvestorType)
              else setStep(step - 1)
            }}
          >
            <ArrowLeftIcon className="w-4 h-4" /> Back
          </p>
        )}
        <Step
          title="Step"
          step={step}
          totalStep={totalSteps}
          progress={((step - SignUpSteps.InvestorType + 1) / totalSteps) * 100}
        />
      </div>
    )
  }, [accountType, step, totalSteps])

  const postUserData = (newData: Record<string, any>[]) => {
    // setStep(SignUpSteps.Final)
    // return
    setLoading(true)
    finalUserSignup(
      {
        ...newData[1],
        token,
      },
      newData[1].certificateFormation ? Array.from(newData[1].certificateFormation) : [],
    )
      .then((userData) => {
        const newData = cloneDeep(data)
        newData[2] = userData
        setData(newData)
        setStep(SignUpSteps.Final)
      })
      .finally(() => setLoading(false))
  }

  const renderContent = () => {
    switch (step) {
      case SignUpSteps.CreateAccount:
        return (
          <CreateAccountSection
            step={stepComponent}
            data={data[0]}
            loading={isLoading}
            setLoading={setLoading}
            onSubmit={(data: Record<string, any>) => {
              setData([
                {
                  ...data,
                  utmData,
                },
              ])
              setStep(SignUpSteps.CheckPhone)
              setLoading(false)
            }}
          />
        )

      case SignUpSteps.CheckPhone:
        return (
          <CheckPhoneSection
            data={data[0]}
            loading={isLoading}
            setLoading={setLoading}
            onBack={() => setStep(SignUpSteps.CreateAccount)}
            onSubmit={(token: string) => {
              setToken(token)
              setStep(SignUpSteps.InvestorType)
            }}
          />
        )

      case SignUpSteps.InvestorType:
        return (
          <InvestorTypeSection
            step={stepComponent}
            onSubmit={(accountType: AccountType) => {
              const newData = cloneDeep(data)
              newData[1] = { accountType }
              setData(newData)
              setStep(SignUpSteps.AccreditedInvestor)
            }}
          />
        )

      case SignUpSteps.AccreditedInvestor:
        return (
          <AccreditedInvestorSection
            step={stepComponent}
            onSubmit={(accountData: {
              // isIndividualIncome: '0' | '1'
              // isCurrentNetWorth: '0' | '1'
              // isHoldSeries: '0' | '1'
              isAccreditedInvestor: '0' | '1'
            }) => {
              const newData = cloneDeep(data)
              newData[1] = {
                ...newData[1],
                ...accountData,
              }
              setData(newData)
              setStep(SignUpSteps.TotalAssets)
            }}
          />
        )

      case SignUpSteps.TotalAssets:
        return (
          <InvestableAssetsSection
            step={stepComponent}
            onSubmit={(netWorth: string) => {
              const newData = cloneDeep(data)
              newData[1].netWorth = netWorth
              setData(newData)
              setStep(SignUpSteps.InvestmentRange)
            }}
          />
        )

      case SignUpSteps.InvestmentRange:
        return (
          <InvestmentRangeSection
            step={stepComponent}
            onSubmit={async (investmentRange: string) => {
              const newData = cloneDeep(data)
              newData[1].investmentRange = investmentRange
              setData(newData)

              if (accountType && RequireAdditionalAccounts.includes(accountType)) setStep(SignUpSteps.Additional)
              else postUserData(newData)
            }}
          />
        )
      case SignUpSteps.Additional: {
        if (!accountType) return null
        const Component =
          accountType == AccountType.Individual
            ? IndividualSection
            : accountType == AccountType.Ira
            ? IRASecion
            : CompanySection
        return (
          <Component
            accountType={accountType}
            step={stepComponent}
            onSubmit={(newProps: Record<string, any>) => {
              const newData = cloneDeep(data)
              newData[1] = {
                ...newData[1],
                ...newProps,
              }
              setData(newData)

              postUserData(newData)
            }}
          />
        )
      }

      case SignUpSteps.Final:
        return (
          <FinalSection
            data={data[0]}
            accountType={accountType!}
            onSubmit={() => {
              dispatch({ type: AUTH_TOKEN_SET, token, user: data[2] })
              navigate('/marketplace')
            }}
          />
        )

      default:
        return null
    }
  }

  return (
    <div className="max-w-4xl">
      <LayoutLoading.LayoutLoading show={isLoading} />

      {renderContent()}
      {/* {step == 1 && <CompleteYourProfile />} */}
      {/* {isDisclaimer && <DisclaimerModal data={data[0]} onClose={onCloseDisclaimer} />} */}
    </div>
  )
}
