import { AccountType, InputType } from 'config'
import { cloneDeep } from 'lodash'
import { accountDetailsInputs } from 'pages/Auth/SignUp/AccountType'
import { IRAType } from 'pages/Auth/SignUp/Section'
// import { investorProfileInputs } from 'pages/Auth/SignUp/InvestorProfile'
import { accountBaseInputs } from 'pages/Profile/AccountDetails'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { firstName, InputConvert, lastName, RenderInput, validateInputs } from 'utils'

import type { User } from './type'

export const UserDetails = ({
  data,
  readOnly = false,
  onClose,
  onSubmit: _onSubmit = () => {},
}: {
  data: User | null
  readOnly?: boolean
  onSubmit?: Function
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, Record<string, InputType>>>({})

  useEffect(() => {
    const baseInputs = accountBaseInputs()
    const detailsInputs = accountDetailsInputs(data ? data.accountType : null, true)
    // const investorInputs = investorProfileInputs()

    if (data) {
      ;(data as any).firstName = firstName(data.name)
      ;(data as any).lastName = lastName(data.name)
      Object.keys(baseInputs).forEach((key) => {
        baseInputs[key].value = (data as any)[key]
      })

      Object.keys(detailsInputs).forEach((key) => {
        if ((data.accountDetails as any)[key] === undefined) return
        detailsInputs[key].value = (data.accountDetails as any)[key]
      })
      detailsInputs.accountType.value = data.accountType

      baseInputs.country.value = data.country
      baseInputs.state.value = data.accountDetails.state
      baseInputs.city.value = data.accountDetails.city

      baseInputs.state.visible = data.country == 'US'
      // Object.keys(investorInputs).forEach((key) => {
      //   investorInputs[key].value = (data.investorProfile as any)[key]
      // })

      if (detailsInputs.netWorth) {
        detailsInputs.netWorth.visible = data.accountType !== AccountType.ArticleManager
      }
      if (detailsInputs.investmentRange) {
        detailsInputs.investmentRange.visible = data.accountType !== AccountType.ArticleManager
      }
    }

    checkAccountDetailsVisible(detailsInputs)

    setInputs({
      base: baseInputs,
      detail: detailsInputs,
      // investor: investorInputs,
    })
  }, [])

  const checkAccountDetailsVisible = (nInputs: Record<string, InputType>) => {
    if (nInputs.accountType.value === AccountType.Ira) {
      if (nInputs.IRA_Type.value === IRAType.LLC) {
        nInputs.LLC_Name.visible = true
        nInputs.LLC_EIN.visible = true
        nInputs.CustodianName.visible = false
        nInputs.CustodianContact.visible = false
        nInputs.IRA_Account_Number.visible = false
        nInputs.CustodianAddress.visible = false
      } else {
        nInputs.LLC_Name.visible = false
        nInputs.LLC_EIN.visible = false
        nInputs.CustodianName.visible = true
        nInputs.CustodianContact.visible = true
        nInputs.IRA_Account_Number.visible = true
        nInputs.CustodianAddress.visible = true
      }
    }
  }

  const onChange = (type: string, key: string, value: string) => {
    if (readOnly) return

    let newState = cloneDeep(inputs)
    newState[type][key].value = InputConvert(newState[type][key], value)
    newState[type][key].error = ''

    if (type == 'detail' && key == 'accountType') {
      const detailsInputs = accountDetailsInputs(value as AccountType, true)
      detailsInputs.accountType.value = value
      Object.keys(detailsInputs).forEach((key) => {
        if (!newState.detail[key]) return
        detailsInputs[key].value = newState.detail[key].value
      })
      newState.detail = detailsInputs

      newState.detail.netWorth.visible = value !== AccountType.ArticleManager
      newState.detail.investmentRange.visible = value !== AccountType.ArticleManager
    }

    if (type == 'base' && key == 'country') newState.base.state.visible = value == 'US'

    checkAccountDetailsVisible(newState.detail)
    setInputs(newState)
  }

  const onSubmit = async () => {
    let entireHasError = false

    const newInputs: any = {}
    let newData: any = {}

    // 'investor'
    ;['base', 'detail'].map((key) => {
      const { hasError, data, newInputStates } = validateInputs(inputs[key])
      entireHasError ||= hasError
      newInputs[key] = newInputStates
      if (key == 'base') newData = { ...newData, ...data }
      else if (key == 'detail') newData.accountDetails = data
      // else if (key == 'investor') newData.investorProfile = data
    })

    if (entireHasError) {
      setInputs(newInputs)
      return
    }

    newData.name = `${newData.firstName} ${newData.lastName}`
    delete newData.firstName
    delete newData.lastName

    Object.keys(newData.accountDetails).forEach((key) => {
      if (typeof newData.accountDetails[key] != 'boolean') return
      newData.accountDetails[key] = newData.accountDetails[key] ? '1' : '0'
    })

    newData.accountType = newData.accountDetails.accountType
    newData.accountDetails.country = newData.country
    newData.accountDetails.state = newData.state
    newData.accountDetails.city = newData.city
    newData.isForeignInvestor = newData.country == 'US' ? '0' : '1'

    delete newData.state
    delete newData.city

    setLoading(true)
    await _onSubmit(newData)
    setLoading(false)
  }

  return (
    <Modal
      isOpen
      title={readOnly ? 'User Details' : data ? 'Edit User Details' : 'Add new User'}
      titleOkay={readOnly ? '' : 'Ok'}
      loading={isLoading}
      onClose={() => onClose()}
      onOk={() => !readOnly && onSubmit()}
    >
      <div className="text-gray-600 text-md max-w-[768px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((type, index) => (
            <div className={type == 'base' ? 'col-span-full' : ''} key={index}>
              <div className={`border-b-2 border-indigo-500 w-full mb-2`}>
                <p className="capitalize">{type}</p>
              </div>
              <div className={type == 'base' ? 'grid grid-cols-1 sm:grid-cols-2 gap-x-4' : ''}>
                {Object.keys(inputs[type]).map((key) =>
                  inputs[type][key].visible === false
                    ? null
                    : RenderInput({
                        input: inputs[type][key],
                        Key: key,
                        onChange: (key: string, value: any) => onChange(type, key, value),
                      }),
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}
