export type IR_Certificate =
  | IR_Certificate_Income
  | IR_Certificate_3rdParty
  | IR_Certificate_Trust
  | IR_Certificate_Asset

import type { User } from 'pages/User'

interface IR_Certificate_Income extends IR_Certificate_Base {
  type: 'income'
  reason: string
}

interface IR_Certificate_Asset extends IR_Certificate_Base {
  type: 'asset'
  reason: string
}

interface IR_Certificate_3rdParty extends IR_Certificate_Base {
  type: '3rd party'
  reason: { [key: string]: string }[]
}

interface IR_Certificate_Trust extends IR_Certificate_Base {
  type: 'Trust/Enitity'
  entity: { [key: string]: string }[]
  reason: { [key: string]: string }[]
}

interface IR_Certificate_Base {
  created_on: string // datetime string
  status: {
    code: number
    message: string
  }
  metadata: any[]

  amount?: string
  certificate?: string
  expires_on?: string // datetime string
}

export interface IR_Bill {
  fullname: string
  amount: string
  billed: number
}

export interface IR_Response<Type> {
  success: number
  message: string
  data: Type
}

export interface IR_FormElementConditional {
  listen: string
  action: 'addRemove'
  target: string
  test: {
    value: string
    method: '+' | '-'
    dom: IR_FormElement[]
  }[]
}

export interface IR_FormBaseElement {
  name: string
  class: string
  label: string
  placeholder?: string
  required: boolean

  conditional?: IR_FormElementConditional
}

interface IR_FormInputElement extends IR_FormBaseElement {
  value?: string | null
  input: 'text' | 'email'
}

interface IR_FormPasswordElement extends IR_FormBaseElement {
  input: 'password' | 'hidden'
}

interface IR_FormSelectElement extends IR_FormBaseElement {
  input: 'select'
  options: Record<string, string>
  container_addon?: string
}

interface IR_FormFileElement extends IR_FormBaseElement {
  input: 'file'
}

export interface IR_FormTemplateElement extends IR_FormBaseElement {
  template_ident: string
}

export interface IR_FormPropertyElement extends IR_FormBaseElement {
  input: 'property'
  template: IR_FormTemplate
}

export interface IR_FormTableElement {
  id: string
  class: string
  label: string
  notes: string
  input: 'tabel'
  thead: string[]
  cta: string
  template: IR_FormTemplate
}

export interface IR_FormContainerElement {
  id: string
  class: string
  label: string
  notes: string
  input: 'container'
  cta: string
  template: IR_FormTemplate
}

export interface IR_FormTemplate {
  key: string
  value: IR_FormTemplateElement[]
}

interface IR_FormCanvasElement {
  input: 'canvas'
  required: boolean
  DOM: string
}

export type IR_FormElement =
  | IR_FormInputElement
  | IR_FormPasswordElement
  | IR_FormSelectElement
  | IR_FormFileElement
  | IR_FormPropertyElement
  | IR_FormCanvasElement
  | IR_FormTableElement
  | IR_FormContainerElement

export interface IR_FormSection {
  label: string
  notes: string
  elements: IR_FormElement[]
}

export interface IR_FormResponse {
  section: IR_FormSection[]
  terms: string
}

export enum IR_QuestionSetType {
  // IncomeRequest = 'income_request',
  IncomeUpload = 'income_upload',
  Asset = 'asset',
  ThirdPartyRequest = 'thirdparty_request',
  ThirdPartyUpload = 'thirdparty_upload',
  // EntityTrustRequest = 'entity_request',
  EntityTrustUpload = 'entity_upload',
}

export const IR_QuestionSetTypeOptions: Record<IR_QuestionSetType, string> = {
  // [IR_QuestionSetType.IncomeRequest]: 'Income Request',
  [IR_QuestionSetType.IncomeUpload]: 'Income Upload',
  [IR_QuestionSetType.Asset]: 'Asset Upload',
  [IR_QuestionSetType.ThirdPartyRequest]: 'Third Party Request',
  [IR_QuestionSetType.ThirdPartyUpload]: 'Third Party Upload',
  // [IR_QuestionSetType.EntityTrustRequest]: 'Entity Trust Request',
  [IR_QuestionSetType.EntityTrustUpload]: 'Letter Document',
}

export enum InvestReadyStatus {
  Failed = -2, // Failed in investready side
  Rejected = -1, // Resubmittable. rejected by admin
  Pending = 0,
  Submitted = 1,
  Approved = 2,
  Expired = 3,
}

export const InvestReadyStatusOptions: Record<InvestReadyStatus, string> = {
  [InvestReadyStatus.Failed]: 'Failed',
  [InvestReadyStatus.Rejected]: 'Rejected',
  [InvestReadyStatus.Pending]: 'Under Review',
  [InvestReadyStatus.Submitted]: 'Pending',
  [InvestReadyStatus.Approved]: 'Approved',
  [InvestReadyStatus.Expired]: 'Expired',
}

export const InvestReadyStatusStyles: Record<InvestReadyStatus, string> = {
  [InvestReadyStatus.Failed]: 'bg-red-100',
  [InvestReadyStatus.Rejected]: 'bg-yellow-200',
  [InvestReadyStatus.Pending]: 'bg-gray-100',
  [InvestReadyStatus.Submitted]: 'bg-indigo-400 text-white',
  [InvestReadyStatus.Approved]: 'bg-green-500 text-white',
  [InvestReadyStatus.Expired]: 'bg-gray-400 text-white',
}

export interface InvestReadyHistory {
  status: InvestReadyStatus
  reason: string
  createdBy: string
  createdAt: Date
}

export interface InvestReadyFormData {
  id: number
  type: IR_QuestionSetType
  form: IR_Response<IR_FormResponse>
}

export interface InvestReadyCert {
  id: number
  form: InvestReadyFormData
  user: User
  type: IR_QuestionSetType
  data: Record<string, any>
  expiresOn: string
  status: InvestReadyStatus
  history: InvestReadyHistory[]
  certificate: IR_Certificate
  createdAt: Date
  updatedAt: Date
}
