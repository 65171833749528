import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { type InputType } from 'config'
import { Tooltip } from 'flowbite-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { completeCustodianInvestment } from 'services/apis'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    envelope: {
      inputType: 'file',
      title: '',
      required: true,
    },
  }
}

interface IProps {
  userId: number
  loanId: number
  investId: number
}

export const CompleteCustodianInvest = (props: IProps) => {
  const { userId, loanId, investId } = props

  const [isLoading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  const onOpen = () => {
    setInputs(defaultInputs())
  }

  const onClose = () => {}

  const onChangeInput = async (key: string, value: any) => {
    let temp = cloneDeep(inputs)
    temp[key].value = InputConvert(temp[key], value)
    temp[key].error = ''
    setInputs(temp)
  }

  const onSubmit = async () => {
    let hasError = false
    let temp = cloneDeep(inputs)

    Object.keys(inputs).map((key) => {
      const error = InputValidate(inputs[key])
      if (!!error) {
        temp[key].error = error
        hasError = true
      }
    })

    setInputs(temp)
    if (hasError) return
    setLoading(true)
    await completeCustodianInvestment(userId, loanId, investId, [inputs['envelope'].value])
    toast(`A custodian investment has been successfully completed.`, { type: 'success' })
    setLoading(false)
    setIsOpen(false)
    setLastUpdatedAt(Date.now())
  }

  return (
    <Modal
      button={
        <Tooltip content="Complete Investment">
          <div className="p-1 shadow hover-shadow1 cursor-pointer rounded text-indigo-500 transition-all duration-200">
            <ArrowUpTrayIcon className="w-4 h-4" />
          </div>
        </Tooltip>
      }
      title={'Upload a signed document by a custodian.'}
      titleOkay="Upload"
      isOpen={isOpen}
      lastUpdatedAt={lastUpdatedAt}
      onClose={onClose}
      onOk={onSubmit}
      onOpen={onOpen}
      loading={isLoading}
    >
      <div className="w-[480px] h-fit">
        <div className="grid grid-cols-1 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div key={index} className={`md:col-span-${input.span}`}>
                <RenderInput input={input} Key={key} onChange={onChangeInput} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
