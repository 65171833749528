// import { ArrowUpRightIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline'
import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import { BankAccount } from 'components/BankAccount'
// import { LayoutLoading } from 'components/LayoutLoading'
// import { TransactionColorMap } from 'components/TransactionStatus'
// import { itemCountPerPage } from 'config'
// import { cloneDeep } from 'lodash'
// import { LayoutLoading } from 'components/LayoutLoading'
// import { TransactionColorMap } from 'components/TransactionStatus'
// import { itemCountPerPage } from 'config'
// import { cloneDeep } from 'lodash'
import type { Institution } from 'plaid' // TransferIntentCreateMode, TransferStatus

// TransferIntentCreateMode, TransferStatus
// import { useEffect, useState } from 'react'
// import InfiniteScroll from 'react-infinite-scroll-component'
// import { getTransactions } from 'services/apis'
// import { ButtonGroup, ResponsiveTable } from 'stories/components'
// import { formatDate, normalizeNumber } from 'utils'
import {
  DbPlaidAccount,
  DbTransaction,
  PlaidTransactionData,
  TransactionMethod,
  WireTransactionData,
} from './constants'
// import { TransactionDetails } from './modal'

export const renderAccount = ({
  item,
  accounts,
  institutions,
}: {
  item: DbTransaction
  accounts: DbPlaidAccount[]
  institutions: Institution[]
}) => {
  switch (item.method) {
    case TransactionMethod.Plaid: {
      const data: PlaidTransactionData = item.data as PlaidTransactionData
      const foundItem = accounts.find((v) => v.accountId == data.accountId)
      if (!foundItem) return <></>
      const { account, institution } = foundItem
      return (
        <BankAccount
          accountName={account.name}
          logo={institution.logo}
          bankName={institution.name}
          accountNumber={account.mask}
        />
      )
    }
    case TransactionMethod.Wire: {
      const data = item.data as WireTransactionData
      if (!data) return <BankAccount accountName="Wire" bankName="Finresi" />

      const { institutionId } = data
      const institution = institutionId ? institutions.find((v) => v.institution_id == institutionId) : null

      return (
        <BankAccount
          accountName="Wire"
          logo={institution && institution.logo}
          bankName={data.bankName}
          accountNumber={data.accountNumber}
        />
      )
    }
    case TransactionMethod.Manual: {
      return (
        <div className="flex items-center gap-2 justify-between lg:justify-start p-2">
          <p className="text-desc truncate mx-2">{(item.data as any).note}</p>

          <div className="flex items-center gap-1">
            <BuildingLibraryIcon className="w-4 h-4 text-gray-600" />
            <p className="text-desc capitalize">Finresi</p>
          </div>
        </div>
      )
    }
  }
}
/*
export const TransactionsTab = ({ accounts, defaultTrxId }: { accounts: DbPlaidAccount[]; defaultTrxId: number }) => {
  const [filters, setFilters] = useState<Record<string, any>>({
    duration: '-3',
    pageNum: 0,
  })
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [transactions, setTransactions] = useState<DbTransaction[]>([])
  const [institutions, setInstitutions] = useState<Institution[]>([])
  const [selectedTrx, setSelectedTrx] = useState<DbTransaction | null>(null)

  useEffect(() => {
    refetch(filters)
  }, [])

  useEffect(() => {
    if (!defaultTrxId) return
    const index = transactions.findIndex((trx) => trx.id == defaultTrxId)
    onOpenTransaction(index)
  }, [transactions, defaultTrxId])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (transactions.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getTransactions({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ total, data, institutions: _institutions }) => {
        if (_pageNum == 0) setTransactions(data)
        else {
          const newTrxes = cloneDeep(transactions)
          newTrxes.push(...data)
          setTransactions(newTrxes)
        }

        const newInstitutions = cloneDeep(institutions)
        newInstitutions.push(..._institutions)
        setInstitutions(newInstitutions)

        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onOpenTransaction = (index: number) => {
    setSelectedTrx(transactions[index] || null)
  }

  const onChangeFilter = (key: 'duration' | 'pageNum', value: any, needRefetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    refetch(newFilters, needRefetch ? 0 : -1)
  }

  const renderMark = (amount: number, dir: TransferIntentCreateMode, status: TransferStatus) => {
    const mark = dir == TransferIntentCreateMode.Payment ? '+' : '-'
    const Icon = dir == TransferIntentCreateMode.Payment ? PlusIcon : ArrowUpRightIcon
    return (
      <div className="flex items-center justify-between lg:justify-start p-2 gap-2">
        <div className={`p-2 ${TransactionColorMap[status]} inline-block rounded-full`}>
          <Icon className="w-6 h-6 rounded-full" />
        </div>
        {mark} ${normalizeNumber(amount)}
      </div>
    )
  }

  return (
    <div className="relative">
      <LayoutLoading show={isLoading && transactions.length == 0} />
      <div className="flex items-center mb-4 justify-between">
        <p className="text-white text-xl">Histories ({total})</p>
        <ButtonGroup
          title={{ '-3': '1 - 3 months', '3-12': '3 months - 1 year', '12-': '1+ year' }}
          value={filters.duration}
          onChange={(v) => onChangeFilter('duration', v)}
        />
      </div>

      <InfiniteScroll
        dataLength={transactions.length}
        next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
        hasMore={transactions.length < total}
        loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
      >
        <ResponsiveTable
          header={[]}
          headerKeys={['', 'Yield', 'ltv', 'originalLoanTerm', 'loanAmount', 'progress']}
          headerClassName="hidden lg:grid mb-2"
          contentClassName="lg:grid grid-cols-12 rounded-lg text-sm p-2 border hover:bg-gray-100 focus:bg-gray-100 cursor-pointer"
          size={[2, 7, 3]}
          orderBy={filters.orderBy}
          orderDir={filters.orderDir}
          onClickItem={onOpenTransaction}
          data={transactions.map((item) => [
            renderMark(item.amount, item.direction, item.status),
            renderAccount({ item, accounts, institutions }),
            <div className="flex items-center p-2 gap-2 justify-between">
              <div className="flex-1">
                <p className={`capitalize ${TransactionColorMap[item.status]} inline-block px-2 py-1 rounded text-sm`}>
                  {item.direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'} {item.status}
                </p>
              </div>
              <div>{formatDate(item.createdAt)}</div>
              <ChevronRightIcon className="hidden lg:inline-block w-4 h-4" />
            </div>,
          ])}
        />
      </InfiniteScroll>
      {selectedTrx && (
        <TransactionDetails
          data={selectedTrx}
          bankComponent={renderAccount({ item: selectedTrx, accounts, institutions })}
          onClose={() => setSelectedTrx(null)}
        />
      )}
    </div>
  )
}
*/
