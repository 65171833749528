import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { slugify } from 'utils'

interface DropdownProps {
  title: string
  headerType: string
  sourceList: string[]
}

const Dropdown: React.FC<DropdownProps> = ({ title, headerType, sourceList }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }

  return (
    <div
      className="relative inline-block cursor-pointer m-0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`py-3 my-2 text-sm font-semibold px-2 hover:bg-gray-200 rounded-lg text-${
          headerType ? 'dark' : 'gray-900'
        }`}
      >
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      {headerType === 'white' ? (
        <div
          className={`absolute left-0 -mt-4 w-64 bg-white border rounded-lg shadow-2xl transition-all duration-300 opacity-0 sh  translate-y-4 ease-in-out ${
            isOpen ? 'opacity-100 translate-y-1' : 'pointer-events-none'
          } p-2 text-gray-800`}
        >
          {sourceList.map((item, index) => (
            <div key={index} className="px-4 py-2 hover:bg-gray-200 hover:rounded-sm cursor-pointer text-sm">
              <Link to={`/${slugify(item)}`}>{item}</Link>
            </div>
          ))}
        </div>
      ) : (
        <div
          className={`absolute left-0 -mt-4 w-64 bg-white border rounded-lg shadow-2xl transition-all duration-300 opacity-0 sh  translate-y-4 ease-in-out ${
            isOpen ? 'opacity-100 translate-y-1' : 'pointer-events-none'
          } p-2`}
        >
          {sourceList.map((item, index) => (
            <div key={index} className="px-4 py-2 hover:bg-gray-200 hover:rounded-sm cursor-pointer text-sm">
              <Link to={`/${slugify(item)}`}>{item}</Link>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown
