import { authUpdateProfile } from 'actions'
import { AccountStatus, AccountType, IRADetails } from 'config'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { Loan, LoanOriginPrefixMap } from 'pages/Marketplace'
import { RootState, store } from 'reducers'
import { getInvestReadyExpiresOn, getTaxCerts } from 'services/apis'

import { getSimplifiedAddress } from './convertor'

export const loanIdentifier = (loan: Loan) => {
  return `${LoanOriginPrefixMap[loan.origin]}/${loan.loanNumber}`
}

export const isInvestableProfile = async (isWithdraw: boolean = false): Promise<boolean> => {
  const state = store.getState() as RootState
  const { profile } = state.auth
  const { status, accreditedExpiresOn } = profile
  let { country, taxCertPassed, certFormationPassed, accountType, accountDetails } = profile

  if (status < AccountStatus.KycPassed) return false

  const isNonUS = country && country != 'US'

  if (isNonUS && !taxCertPassed) {
    ;({ country, taxCertPassed } = await getTaxCerts())
    store.dispatch(
      authUpdateProfile({
        ...profile,
        country,
        taxCertPassed,
      }),
    )

    if (country && country != 'US' && !taxCertPassed) return false
  }

  if (isWithdraw) return true

  if (!certFormationPassed && [AccountType.Company, AccountType.Trust].includes(accountType)) return false
  if (
    !certFormationPassed &&
    [AccountType.Ira].includes(accountType) &&
    (accountDetails as IRADetails)?.IRA_Type === IRAType.LLC
  )
    return false
  if (isNonUS) return true

  if (accreditedExpiresOn && new Date(accreditedExpiresOn) > new Date()) {
    return true
  }

  return new Promise((resolve) => {
    getInvestReadyExpiresOn()
      .then(({ expiresOn: accreditedExpiresOn }) => {
        store.dispatch(
          authUpdateProfile({
            ...profile,
            accreditedExpiresOn: accreditedExpiresOn ? new Date(accreditedExpiresOn) : null,
            country,
            taxCertPassed,
          }),
        )

        const expiresOn = new Date(accreditedExpiresOn)
        if (!accreditedExpiresOn || expiresOn < new Date()) {
          resolve(false)
        } else resolve(true)
      })
      .catch(() => resolve(false))
  })
}

export const addTitleToLoans = (loans: Loan[]): Loan[] => {
  const data: Loan[] = loans.map((loan: Record<string, any>) => {
    const title = getSimplifiedAddress(loan.propertyAddress)
    return {
      ...loan,
      title,
      description: loan.propertyType,
    } as Loan
  })
  return data
}
