import { imgCongratulations } from 'assets'
import { WireDepositExpireDays } from 'config'
import { TransferIntentCreateMode } from 'plaid'
import { useMemo } from 'react'
import { Button } from 'stories/components'
import { normalizeNumber } from 'utils'

import { ContentHeader } from './ContentHeader'

export const CongratulationsContent = ({
  direction,
  data,
  onNext,
}: {
  direction: TransferIntentCreateMode
  data: Record<number, any>
  onNext: Function
}) => {
  const isDeposit = direction == TransferIntentCreateMode.Payment
  const isWire = data[0] == 1
  const strDirection = useMemo(() => (isDeposit ? 'deposited' : 'withdrawn'), [isDeposit])

  const header = useMemo(() => {
    if (isDeposit && isWire) return 'You have requested a deposit via Wire Transfer.'

    return `Congratulations! Your Money is on its way to ${isDeposit ? 'Finresi' : 'your account'}.`
  }, [isDeposit, isWire])

  const title = useMemo(() => {
    if (isDeposit && isWire) return 'Commited amount'
    return `You've ${strDirection}`
  }, [isDeposit, isWire])

  return (
    <div className="rounded-lg bg-white relative overflow-hidden">
      <img src={imgCongratulations} className="absolute w-full" />
      <div className="relative">
        <ContentHeader description={header} />

        <div className="py-4 px-2 text-center">
          <p className="text-green-400 text-sm mb-2">{title}</p>
          <p className="text-5xl text-gray-800 font-semibold mb-2">$ {normalizeNumber(data[1].amount, 2)}</p>
          <p className="text-gray-600 font-semibold mb-4">Transaction ID: #{data[2]}</p>

          <p className="text-gray-600 text-sm">
            {isDeposit &&
              isWire &&
              `We've sent an email with guide. Please follow it to deposit in ${WireDepositExpireDays} days.`}
            {!isDeposit &&
              isWire &&
              `We've received your withdraw request and it will be proceed in up to 5 business days.`}
            {!isWire && `Your ${isDeposit ? 'deposit' : 'withdraw'} will be processed within 5 business days.`}
          </p>
        </div>

        <div className="pt-4">
          <Button full color="green" onClick={() => onNext()}>
            Go to Transactions
          </Button>
        </div>
      </div>
    </div>
  )
}
