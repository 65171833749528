import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import type { Institution } from 'plaid'
import type { PlaidAccount } from 'react-plaid-link'

export const renderBankAccount = (account: PlaidAccount | null, institution: Institution | null, isMain: boolean) => {
  return (
    <div className={`flex gap-2 items-center ${isMain ? 'sm:w-92 w-full' : 'sm:w-100 w-full'}`}>
      <div className="rounded-full border">
        {institution && institution.logo ? (
          <img src={`data:image/png;base64, ${institution.logo}`} className="w-10 h-10 object-contain" />
        ) : (
          <BuildingLibraryIcon className="w-6 h-6 m-2 text-gray-600" />
        )}
      </div>
      {account ? (
        <div className="flex-1 text-left max-w-[13rem]">
          {institution && <p className="truncate">{institution.name}</p>}
          <p className="text-desc truncate">{account.name}</p>
        </div>
      ) : institution ? (
        <p className="flex-1 text-left max-w-[13rem]">{institution.name}</p>
      ) : (
        <p className="flex-1 text-left">New Bank Account</p>
      )}
      {account && <p className="text-desc">Ending with {account.mask}</p>}
    </div>
  )
}
