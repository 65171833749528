import { ArrowRightIcon, ClockIcon, EyeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { Tooltip } from 'flowbite-react'
import { type InvestReadyCert, InvestReadyCertStatus, IR_QuestionSetTypeOptions } from 'pages/Profile'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUserInvestReady, getUserInvestReadyExpiresOn } from 'services/apis'
import { formatTime } from 'utils'

import { InvestReadyViewer } from './InvestReadyViewer'
import { InvestReadyHistoryModal } from './modal'
import type { User } from './type'

export const InvestReadyDetails = ({ user, onClose }: { user: User; onClose: Function }) => {
  const [searchParams] = useSearchParams()
  const defaultInvestReadyId = Number(searchParams.get('investready') || 0)

  const [data, setData] = useState<InvestReadyCert[] | null>(null)
  const [expiresOn, setExpiresOn] = useState('')
  const [loading, setLoading] = useState(false)
  const [activeId, setActiveId] = useState(defaultInvestReadyId)
  const [historyId, setHistoryId] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (defaultInvestReadyId) navigate('/manageUsers')
  }, [])

  useEffect(() => {
    refresh()
  }, [user])

  const refresh = () => {
    setLoading(true)
    getUserInvestReady(user.id)
      .then((data) => setData(data))
      .finally(() => setLoading(false))

    getUserInvestReadyExpiresOn(user.id).then(({ expiresOn }) => {
      setExpiresOn(expiresOn)
      if (!expiresOn) return
    })
  }

  const onCloseActive = (needRefresh: boolean) => {
    needRefresh && refresh()
    setActiveId(0)
  }

  const renderList = () => {
    if (!data) return null

    const tableData = data.map((item, index) => [
      index + 1,
      IR_QuestionSetTypeOptions[item.type],
      `${item.data['accredify[person][first_name]']} ${item.data['accredify[person][last_name]']}`,
      <InvestReadyCertStatus data={item} />,
      formatTime(item.updatedAt),
      formatTime(item.expiresOn),
      <div className="flex items-center gap-2 w-32">
        <Tooltip content="View History">
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
            <ClockIcon className="w-4 h-4 text-indigo-600" />
          </div>
        </Tooltip>

        {item.certificate && item.certificate.certificate && (
          <Tooltip content="Open Certificate">
            <a
              className="flex items-center gap-1 hover:underline cursor-pointer"
              href={item.certificate.certificate}
              target="_blank"
            >
              <EyeIcon className="w-4 h-4 text-indigo-600" />
            </a>
          </Tooltip>
        )}

        <Tooltip content="View Certificate">
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setActiveId(item.id)}>
            <ArrowRightIcon className="w-4 h-4 text-indigo-600" />
          </div>
        </Tooltip>
      </div>,
    ])

    return (
      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {['No', 'Type', 'Name', 'Status', 'Updated At', 'Expires On', ''].map((th, index) => (
              <th className={`px-3 py-3 ${index == 0 && 'w-12'}`} key={index}>
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <>
              <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                {tableData[index].map((value, index2) => (
                  <td className="pl-3 py-2" key={index2}>
                    {value}
                  </td>
                ))}
              </tr>
              {activeId == item.id && (
                <tr>
                  <td />
                  <td colSpan={6}>
                    <InvestReadyViewer data={item} refresh={refresh} onClose={onCloseActive} />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <InvestReadyHistoryModal createdBy history={item.history} onClose={() => setHistoryId(0)} />
  }

  return (
    <div className="relative my-2 border py-2 px-3 rounded bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
          <span className="text-base">Accredited Details of this User</span>
          {!!expiresOn ? <span className="text-sm font-normal">(Expires On: {formatTime(expiresOn)})</span> : null}
          {loading && <Loading />}
        </h2>
        <span
          className="text-sm flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
          onClick={() => onClose()}
        >
          Close
          <XMarkIcon className="w-4 h-4" />
        </span>
      </div>

      {renderList()}
      {renderHistoryModal()}
    </div>
  )
}
