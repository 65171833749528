const SocialButtons: React.FC = () => {
  return (
    <>
      <div className="gap-3 mx-auto justify-center mt-12 hidden lg:flex">
        <a href={'https://www.facebook.com/tryfinresi/'} rel="nofollow">
          <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.7611 35.8595C28.5704 35.8595 36.5223 27.9075 36.5223 18.0983C36.5223 8.28909 28.5704 0.337158 18.7611 0.337158C8.95194 0.337158 1 8.28909 1 18.0983C1 27.9075 8.95194 35.8595 18.7611 35.8595Z"
              fill="white"
              fillOpacity="0.5"
              stroke="#B9C9E4"
              strokeWidth="0.65782"
            />
            <path
              d="M19.7936 27.1432V18.8914H22.3954L22.7854 15.6759H19.7945V13.6227C19.7945 12.6911 20.0366 12.057 21.2908 12.057L22.8907 12.0561V9.17974C22.1166 9.09252 21.3385 9.05028 20.5601 9.05311C18.2531 9.05311 16.6744 10.5519 16.6744 13.3043V15.6759H14.0659V18.8914H16.6744V27.1432H19.7936Z"
              fill="#7B8BA5"
            />
          </svg>
        </a>
        <a href={'https://www.linkedin.com/company/nexys-llc'} rel="nofollow">
          <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.2254 18.0981C36.2254 27.72 28.2792 35.5304 18.4643 35.5304C8.64936 35.5304 0.703125 27.72 0.703125 18.0981C0.703125 8.47626 8.64936 0.665894 18.4643 0.665894C28.2792 0.665894 36.2254 8.47626 36.2254 18.0981Z"
              fill="white"
              fillOpacity="0.5"
              stroke="#B9C9E4"
              strokeWidth="0.65782"
            />
            <mask id="mask0_6910_682" maskUnits="userSpaceOnUse" x="10" y="9" width="17" height="17">
              <path d="M26.7872 9.87549H10.9995V25.6632H26.7872V9.87549Z" fill="white" />
            </mask>
            <g mask="url(#mask0_6910_682)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.7872 25.6632H23.6297V20.1383C23.6297 18.6227 22.9611 17.7772 21.762 17.7772C20.4571 17.7772 19.6828 18.6582 19.6828 20.1383V25.6632H16.5252V15.4012H19.6828V16.5553C19.6828 16.5553 20.6734 14.817 22.9058 14.817C25.139 14.817 26.7872 16.1795 26.7872 18.9992V25.6632ZM12.9272 13.76C12.6731 13.7591 12.4216 13.7081 12.1872 13.61C11.9527 13.5119 11.7399 13.3684 11.5609 13.1881C11.3819 13.0076 11.2402 12.7938 11.1439 12.5585C11.0475 12.3234 10.9985 12.0715 10.9995 11.8174C10.9986 11.5633 11.0477 11.3115 11.1441 11.0764C11.2405 10.8413 11.3822 10.6275 11.5612 10.4472C11.7402 10.2669 11.953 10.1236 12.1874 10.0255C12.4217 9.92739 12.6732 9.87641 12.9272 9.87549C13.4402 9.87759 13.9314 10.0833 14.2927 10.4475C14.654 10.8116 14.856 11.3044 14.8541 11.8174C14.8553 12.0715 14.8063 12.3233 14.7101 12.5585C14.6139 12.7937 14.4722 13.0076 14.2932 13.188C14.1143 13.3684 13.9016 13.5118 13.6672 13.6099C13.4327 13.7081 13.1813 13.7591 12.9272 13.76ZM10.9995 25.6632H14.9464V15.4012H10.9995V25.6632Z"
                fill="#7B8BA5"
              />
            </g>
          </svg>
        </a>
      </div>
    </>
  )
}

export default SocialButtons
