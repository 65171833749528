import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { signUpWithPhoneCode, validatePhone } from 'services/apis'
import { Button } from 'stories/components'
import { InputConvert, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    code: {
      title: '',
      placeholder: 'Enter code here',
      inputType: 'text0',
      type: 'number',
      maxLength: 6,
      error: '',
      required: true,
      autoFocus: true,
      className: '!bg-white',
    },
  }
}

export const CheckPhoneSection = ({
  data: initialData,
  onSubmit: _onSubmit,
  setLoading,
  onBack,
}: {
  data: Record<string, string>
  onSubmit: Function
  loading: boolean
  setLoading: Function
  onBack: Function
}) => {
  const [limit, setLimit] = useState(3)
  const [inputStates, setInputStates] = useState(defaultInputs())

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputStates(newState)
  }

  const onSendAgain = async () => {
    setLoading(true)
    try {
      await validatePhone({ email: initialData.email, phone: initialData.phone, checkDuplication: true })
      toast('Code is sent to your phone.', { type: 'info' })
    } catch (e) {}
    setLimit(limit - 1)
    setLoading(false)
  }

  const onActivate = async () => {
    // _onSubmit('token')
    // return
    setLoading(true)
    try {
      const { token } = await signUpWithPhoneCode({ ...initialData, code: inputStates['code'].value })
      _onSubmit(token)
    } catch (e) {}

    setLoading(false)
  }

  return (
    <div className="max-w-lg px-4 md:px-0 md:mx-auto w-full md:w-120 text-center">
      <div className="bg-black/5 rounded-lg px-8 py-6 text-sm mb-4">
        <p className="text-xl mb-4 font-medium">Please check your phone!</p>
        <p className="text-gray-600 mb-4 px-2">
          We sent a text to <span className="text-link">{initialData.phone}</span> with a code to activate your account
          below.
        </p>

        <p className="text-gray-600 mb-10">
          If you don't receive a text message within the next 3 minutes, please contact our support team at{' '}
          <span className="text-link">info@finresi.com</span>.
        </p>

        <div className="px-2">
          {RenderInput({
            input: inputStates['code'],
            Key: 'code',
            onChange,
          })}

          <div className="flex gap-2 justify-center">
            <p className="text-sm text-gray-600">Didn't receive a code?</p>
            <button
              className={`${limit == 0 ? 'text-indigo-300 cursor-not-allowed' : 'text-link cursor-pointer'} mb-2`}
              disabled={limit == 0}
              onClick={() => onSendAgain()}
            >
              Send again
            </button>
          </div>

          <Button full onClick={onActivate} className="py-4" disabled={inputStates['code'].value?.length != 6}>
            Activate Your Account
          </Button>
          <p className="text-link text-center cursor-pointer" onClick={() => onBack()}>
            Try again
          </p>
        </div>
      </div>

      <p className="text-gray-600 px-6 text-sm">
        By signing into your Finresi account and using Finresi services, you acknowledge and accept Finresi{' '}
        <Link to="/terms_of_service" className="text-link whitespace-nowrap">
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link to="/privacy_policy" className="text-link ml-1 whitespace-nowrap">
          Privacy Policy
        </Link>
        .
      </p>
    </div>
  )
}
