import { AccountStatus, isArticleManager, isManager, isTopManager } from 'config'
import { AdminToolPage, TaskPage } from 'pages/Admin'
import { ManageArticlePage } from 'pages/Article'
import { DashboardPage } from 'pages/Dashboard'
import { MyDocuments } from 'pages/Documents'
import { ManageExceptions } from 'pages/Exception'
import { InvestRetradeRedirectPage, ManageProfitsPage, ManageSignStatisticsPage } from 'pages/Invest'
import { Investments } from 'pages/Investments'
import { LoanDetailsPage, PipelinePage } from 'pages/Loan'
import { MarketplaceItem, MarketplacePage } from 'pages/Marketplace'
import { NotificationPage } from 'pages/Notification'
import { KYCPage, ManageTransactionsPage, PaymentPage } from 'pages/Payment'
import { AccreditedVerificationPage, ChangePasswordPage, MyProfilePage, TaxCertRedirectPage } from 'pages/Profile'
import { ManageServiceArticles } from 'pages/ServiceArticle'
import { ManageUsersPage } from 'pages/User'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import type { RootState } from 'reducers'

import { BasePages } from './base.routes'

const AdminRoutes = () => (
  <>
    <Route path="/pipeline" element={<PipelinePage />} />
    <Route path="/pipeline/:id" element={<LoanDetailsPage />} />
    <Route path="/investments" element={<Investments />} />
    <Route path="/tasks" element={<TaskPage />} />
    {/* <Route path="/importLoans" element={<ImportLoansPage />} /> */}
  </>
)

const FundingRoutes = () => (
  <>
    <Route path="/manageUsers" element={<ManageUsersPage />} />
    <Route path="/manageTransactions" element={<ManageTransactionsPage />} />
    {/* <Route path="/balances" element={<BalancesPage />} /> */}
    <Route path="/manageSignStatistics" element={<ManageSignStatisticsPage />} />
    <Route path="/manageProfits" element={<ManageProfitsPage />} />
    <Route path="/manageArticles" element={<ManageArticlePage />} />
    <Route path="/manageServices" element={<ManageServiceArticles />} />
    <Route path="/manageExceptions" element={<ManageExceptions />} />

    <Route path="/adminTools" element={<AdminToolPage />} />
    <Route path="/dashboard/manageUsers/:userId/:userName" element={<DashboardPage />} />
  </>
)

const ArticleManagerRoutes = () => (
  <>
    <Route path="/manageArticles" element={<ManageArticlePage />} />
    <Route path="/manageServices" element={<ManageServiceArticles />} />
  </>
)

export const MainRoutes = () => {
  const auth = useSelector((state: RootState) => state.auth)

  const { accountType } = auth.profile
  // const isAdmin = auth && accountType == AccountType.Admin
  // const isFundingManager = auth && accountType == AccountType.FundingManager
  const _isManager = auth && isManager(accountType)
  const _isTopManager = auth && isTopManager(accountType)
  const _isArticleManager = auth && isArticleManager(accountType)

  const isEmailNotVerified = auth && auth.profile.status == AccountStatus.Pending
  const isResetPassword = auth && auth.profile.isResetPassword

  if (isResetPassword)
    return (
      <Routes>
        <Route path="/auth/changePassword" element={<ChangePasswordPage />} />
        <Route path="*" element={<Navigate replace to="/auth/changePassword" />} />
      </Routes>
    )

  return (
    <Routes>
      <Route path="/profile/taxCert" element={<TaxCertRedirectPage />} />

      <Route path="/profile" element={<MyProfilePage />} />
      <Route path="/profile/:menu" element={<MyProfilePage />} />
      <Route path="/documents" element={<MyDocuments />} />

      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/dashboard/deposit/:depositId" element={<DashboardPage />} />
      <Route path="/dashboard/retrade/:id" element={<InvestRetradeRedirectPage />} />
      <Route path="/marketplace" element={<MarketplacePage />} />
      <Route path="/marketplace/:id" element={<MarketplaceItem />} />
      <Route path="/kyc" element={<KYCPage />} />
      <Route path="/accreditedVerification" element={<AccreditedVerificationPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/payment/account" element={<PaymentPage />} />
      <Route path="/payment/transaction" element={<PaymentPage tabIndex={1} />} />
      <Route path="/payment/myEarnings" element={<PaymentPage tabIndex={2} />} />

      <Route path="/notification" element={<NotificationPage />} />

      {/* <Route path="/signStatistics" element={<SignStatisticsPage />} /> */}

      {_isTopManager && AdminRoutes()}
      {_isManager && FundingRoutes()}
      {_isArticleManager && ArticleManagerRoutes()}

      {BasePages()}
      <Route path="*" element={<Navigate replace to={isEmailNotVerified ? '/profile' : '/dashboard'} />} />
    </Routes>
  )
}
