import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useState } from 'react'
import { Modal } from 'stories/components'
import { InputValidate, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    passwordType: {
      inputType: 'radio',
      title: '',
      options: {
        random: 'Random Password',
        custom: 'Custom Password',
      },
      required: true,
      span: 2,
      value: 'random',
    },
    newPassword: {
      inputType: 'text0',
      type: 'password',
      title: 'New password',
      error: '',
      hasIcon: true,
      required: true,
      visible: false,
      span: 2,
      autoComplete: 'new-password',
    },
    confirmPassword: {
      inputType: 'text0',
      type: 'password',
      title: 'Confirm Password',
      error: '',
      hasIcon: true,
      required: true,
      visible: false,
      span: 2,
    },
  }
}

export const CustomPassword = ({
  loading,
  name,
  onClose,
  onSubmit: _onSubmit = () => {},
}: {
  loading: boolean
  name: string
  onClose: Function
  onSubmit?: Function
}) => {
  const [inputs, setInputs] = useState(defaultInputs())

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    if (key === 'passwordType') updateVisibility(newInputs)
    setInputs(newInputs)
  }

  const updateVisibility = (newInputs: Record<string, InputType>) => {
    const passwordType = newInputs.passwordType.value
    if (passwordType === 'random') {
      newInputs['newPassword'].visible = false
      newInputs['confirmPassword'].visible = false
    } else {
      newInputs['newPassword'].visible = true
      newInputs['confirmPassword'].visible = true
    }
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    if (newInputs['passwordType'].value === 'custom') {
      if (newInputs['newPassword'].value !== newInputs['confirmPassword'].value) {
        newInputs['confirmPassword'].error = 'Password is not matched.'
        hasError = true
      }
    }
    setInputs(newInputs)
    if (hasError) return

    let passwordStr = ''
    if (newInputs['passwordType'].value === 'custom') {
      passwordStr = newInputs['newPassword'].value
    }
    _onSubmit(passwordStr)
  }

  return (
    <Modal
      title="Password Reset"
      titleOkay="Reset"
      loading={loading}
      onClose={() => onClose(null)}
      onOk={onSubmit}
      isOpen
    >
      <div className="text-gray-900">
        <div>
          User Name: <span className="font-semibold">{name}</span>
        </div>
        <div className="md:w-96 w-full grid gap-4 md:grid-cols-2 grid-cols-1 mb-2 mt-4 text-sm">
          {Object.keys(inputs).map((key, index) => {
            const input = inputs[key]
            if (input.visible === false) return null
            return (
              <div className={`input md:col-span-${input.span || 1}`} key={index}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
