import { BuildingLibraryIcon, BuildingOffice2Icon, CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
// import { openSubAgreement } from 'services/apis'
import { Button } from 'stories/components'

import { ContentHeader } from '../Withdraw/ContentHeader'

export const ChooseMethodContent = ({ defaultData, onNext }: { defaultData: any; onNext: Function }) => {
  const [method, setMethod] = useState(defaultData || 0)
  // const [loading, setLoading] = useState(false)

  // const onOpenAgreement = () => {
  //   setLoading(true)
  //   openSubAgreement()
  //     .then(({ redirectUrl }) => window.open(redirectUrl, '_blank'))
  //     .finally(() => setLoading(false))
  // }

  return (
    <div className="rounded-lg bg-white divide-y">
      <ContentHeader description="You'll get the following account details: Bank name, Bank code, Account." />

      <div
        className={`relative py-4 px-2 cursor-pointer hover:bg-slate-50 ${method == 0 && 'bg-slate-100'}`}
        onClick={() => setMethod(0)}
      >
        <div className="flex gap-2 items-center mb-2">
          <BuildingLibraryIcon className="w-6 h-6" />
          <p className="text-lg text-gray-800">Deposit via ACH</p>
        </div>
        <p className="text-desc ml-8">
          This transfer will be made via ACH using Plaid and may take up to 3 business days for funds to clear.
        </p>
        {method == 0 && <CheckIcon className="text-green-400 w-6 h-6 absolute right-4 top-4" />}
      </div>

      <div
        className={`relative py-4 px-2 mb-4 cursor-pointer hover:bg-slate-50 ${method == 1 && 'bg-slate-100'}`}
        onClick={() => setMethod(1)}
      >
        <div className="flex gap-2 items-center mb-2">
          <BuildingOffice2Icon className="w-6 h-6" />
          <p className="text-lg text-gray-800">Deposit via Wire Transfer</p>
        </div>
        <p className="text-desc ml-8">
          You will receive wire instructions and can invest once funds are cleared. Wires are typically same day
          transfer. Please note, some banks may charge a wire transfer fee.
        </p>
        {method == 1 && <CheckIcon className="text-green-400 w-6 h-6 absolute right-4 top-4" />}
      </div>

      <div>
        <Button full color="green" onClick={() => onNext(method)}>
          Next
        </Button>

        {/* <Button full size="sm" link onClick={() => onOpenAgreement()} className="py-0" loading={loading}>
          <p className="flex gap-2 items-center justify-center">
            Open Subscription Agreement
            <DocumentTextIcon className="w-4 h-4" />
          </p>
        </Button> */}
      </div>
    </div>
  )
}
