import { CheckIcon } from '@heroicons/react/24/outline'
import { ExceptionalTransactionStatus, NormalTransactionStatus, TransactionStatus } from 'components/TransactionStatus'
import { TransferEventType, TransferIntentCreateMode, TransferStatus } from 'plaid'
import { useMemo } from 'react'
import { Modal } from 'stories/components'
import { formatTime, normalizeNumber } from 'utils'

import { type DbTransaction, ManualTransactionData, TransactionMethod } from '../constants'

export const TransactionDetails = ({
  data,
  bankComponent,
  onClose,
}: {
  data: DbTransaction
  bankComponent: JSX.Element | null
  onClose: Function
}) => {
  const renderData = useMemo(() => {
    const strAmount = normalizeNumber(data.amount, 2)
    const isMannual = data.method == TransactionMethod.Manual
    let strDirection = data.direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'
    if (isMannual) strDirection = data.direction != TransferIntentCreateMode.Payment ? 'Debit' : 'Credit'

    const result: Record<string, any> = {
      Method: strDirection,
      'Bank Account': bankComponent,
      [`Amount to ${strDirection}`]: `$ ${strAmount}`,
      Status: <TransactionStatus status={data.status} />,
      Date: formatTime(data.createdAt),
    }
    if (isMannual) delete result['Bank Account']
    return result
  }, [data])

  const renderErrorDescription = () => {
    if (data.status == TransferStatus.Cancelled)
      return `Your transaction has been cancelled. For any questions, please contact support.`

    if (data.status == TransferStatus.Failed) {
      const event = data.events.find((event) => event.event_type == TransferEventType.Failed)
      if (!event) return ''
      return `This transfer was failed on ${formatTime(event.timestamp)}`
    }
    if (data.status == TransferStatus.Returned) {
      const event = data.events.find((event) => event.event_type == TransferEventType.Returned)
      if (!event) return ''
      return `We started your refund on ${formatTime(event.timestamp)}. Refunds usually take 3-5 besiness days.`
    }
  }

  const renderStep = (status: TransferStatus, index: number) => {
    const currentStep = NormalTransactionStatus.indexOf(data.status)
    const isLastStep = currentStep == NormalTransactionStatus.length - 1
    const isDeposit = data.direction == TransferIntentCreateMode.Payment
    const texts: any = {
      [TransferStatus.Pending]: [
        'You set up your transfer. Please wait up to 2-3 business days',
        'You set up your transfer',
      ],
      [TransferStatus.Posted]: [
        `Your money's being processed. It can take up to 5 business days for this transfer`,
        isDeposit ? `You paid out your USD` : 'We paid out your USD',
      ],
      [TransferStatus.Settled]: [
        isDeposit ? 'We receives your USD' : 'You receives your USD',
        `Your transfer's complete`,
      ],
    }
    let time = null
    const isCheck = index < currentStep || isLastStep
    if (isCheck || index == 0) {
      if (status == TransferStatus.Pending) time = data.createdAt
      else if (status == TransferStatus.Posted) {
        const postedEvent = data.events.find((v) => v.event_type == TransferEventType.Posted)
        time = postedEvent ? postedEvent.timestamp : data.events[0].timestamp
      } else if (status == TransferStatus.Settled) {
        const settledEvent = data.events.find((v) => v.event_type == TransferEventType.Settled)
        time = settledEvent ? settledEvent.timestamp : data.events[data.events.length - 1].timestamp
      }
    }
    const isActive = !isCheck && index == currentStep

    return (
      <div className="flex gap-2 w-full text-sm" key={`${status}-${index}`}>
        <div className="flex flex-col mt-1">
          <div className="">
            {isCheck ? (
              <CheckIcon className="w-4 h-4" />
            ) : (
              <div
                className={`m-0.5 w-3 h-3 ${
                  index == currentStep ? 'bg-gray-600' : 'border border-gray-600'
                } rounded-full`}
              />
            )}
          </div>
          {index != NormalTransactionStatus.length - 1 && <div className="border-l ml-2 flex-1" />}
        </div>
        <div className="text-desc w-full mb-3">
          <div className="text-sm mb-1 flex flex-wrap gap-2 items-center justify-between">
            <p className={isActive ? 'text-black font-medium' : isCheck ? 'text-gray-500' : 'text-gray-300'}>
              {isCheck ? texts[status][1] || texts[status][0] : texts[status][0]}
            </p>
          </div>
          {time && <p>{formatTime(time)}</p>}
        </div>
      </div>
    )
  }

  return (
    <Modal isOpen title={`Transaction #${data.id}`} titleOkay="" onClose={() => onClose()}>
      <div className={`text-gray-600 text-md min-w-[300px] md:w-96`}>
        {Object.keys(renderData).map((title) => (
          <div className="mb-2 flex gap-2 justify-between items-center" key={title}>
            <p className="text-desc">{title}</p>
            <div className="text-sm capitalize">{renderData[title]}</div>
          </div>
        ))}

        <div className="w-full border-b mb-2" />

        {data.method == TransactionMethod.Manual ? (
          <p className="text-sm">{(data.data as ManualTransactionData).note}</p>
        ) : ExceptionalTransactionStatus.includes(data.status) ? (
          <p className="text-sm">{renderErrorDescription()}</p>
        ) : (
          <>
            <p className="mb-2">Progress</p>
            {NormalTransactionStatus.map(renderStep)}
          </>
        )}
      </div>
    </Modal>
  )
}
