import type { SignUpSteps } from 'pages/Auth'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { IEntityData } from 'pages/User'

export enum AccountType {
  Individual = 'individual',
  Ira = 'ira',
  Trust = 'trust',
  Company = 'company',
  Admin = 'admin',
  ArticleManager = 'articleManager',
  FundingManager = 'fundingManager',
  OperationAdmin = 'operationAdmin',
}

export const AccountTypeText: Record<string, string> = {
  individual: 'Individual',
  ira: 'IRA',
  trust: 'Trust',
  company: 'Entity',
  admin: 'Admin',
  articleManager: 'Article Manager',
  fundingManager: 'Funding Manager',
  operationAdmin: 'Operation Admin',
}

export const NormalAccountTypes: Record<string, string> = {
  individual: 'Individual',
  ira: 'IRA',
  trust: 'Trust',
  company: 'Entity',
}

export enum AccountStatus {
  Deactive,
  Pending,
  EmailVerified,
  ProfileCompleted,
  KycPassed,
  // InvestreadyVerified, // Not Used
  TaxCert, // Not Used
  AccreditedVerified, // Not Used
  DocumentApproved, // Not Used
}

export const AccountFilterStatusText: Record<string, number> = {
  Deactive: 0,
  Pending: 1,
  EmailVerified: 2,
  ProfileCompleted: 3,
  KycPassed: 4,
  AccreditedVerified: 5,
  DocumentApproved: 6,
}

export const AccountStatusText = {
  [AccountStatus.Deactive]: 'Deactive',
  [AccountStatus.Pending]: 'Pending',
  [AccountStatus.EmailVerified]: 'Email Verified',
  [AccountStatus.ProfileCompleted]: 'Profile Completed',
  [AccountStatus.KycPassed]: 'Kyc Passed',
}

export interface AccountInvestorProfile {
  bonds: string
  stocks: string
  mutualFunds: string
  riskTolerance: string
  investmentObjective: string
}

export interface IndividualDetails {
  formOfOwnership: string
}

export interface TrustDetails {
  legalName: string
  typeOfTrust: string
  dateTrustCreated: string
  trustApproved: boolean
}

export interface IRADetails {
  IRA_Type: IRAType
  LLC_Name: string
  LLC_EIN: string
  CustodianName: string
  CustodianContact: string
  IRA_Account_Number: string
  CustodianAddress: string
}

export type AccountDetails = (IndividualDetails | TrustDetails | IRADetails) & {
  state: string
  city: string
  authStep: SignUpSteps

  netWorth: string
  investmentRange: string
}

export interface AccountProfile {
  id: number
  name: string
  phone: string
  isAccreditedInvestor: string
  isForeignInvestor: string
  email: string

  emailIsVerified: boolean
  phoneIsVerified: boolean

  accountType: AccountType
  entities: IEntityData[]
  accountDetails: AccountDetails
  companyDetails: Record<string, any>
  status: AccountStatus
  deleted: boolean
  isResetPassword: boolean
  subAgreementAt: Date
  subAgreement: string
  accreditedExpiresOn: Date | null
  country: string
  taxCertPassed: boolean
  certFormationPassed: boolean
  createdAt: string
}

export const ManagerAccountTypes = [AccountType.Admin, AccountType.FundingManager, AccountType.OperationAdmin]

export const isManager = (type: AccountType) => ManagerAccountTypes.includes(type)

export const isTopManager = (type: AccountType) => [AccountType.Admin, AccountType.FundingManager].includes(type)

export const isArticleManager = (type: AccountType) => [AccountType.ArticleManager].includes(type)
