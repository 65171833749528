import { Button } from 'stories/components'

import { OwnershipFormOptions } from '../AccountType'

export const IndividualSection = ({ step, onSubmit }: { step: JSX.Element; onSubmit: Function }) => {
  return (
    <div className="max-w-lg px-8 md:px-0 md:mx-auto w-full md:w-120">
      <div className="mb-4">{step}</div>
      <div className="mb-2">
        <p className="text-xl font-medium">Form of Ownership</p>
      </div>

      <p className="text-sm text-gray-700 mb-4">Do any one of the following apply to you?</p>

      {Object.keys(OwnershipFormOptions).map((v) => (
        <Button
          full
          color="white"
          key={v}
          onClick={() => onSubmit({ formOfOwnership: v })}
          className="py-4 !border-gray-300 font-medium"
        >
          {OwnershipFormOptions[v]}
        </Button>
      ))}
    </div>
  )
}
