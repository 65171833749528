interface PlainTableProps {
  /**
   * Header of Table
   */
  header: string[]
  /**
   * Table data
   */
  data?: Array<Array<string | number | JSX.Element | undefined> | null>

  containerClassName?: string

  classNames?: string[]

  widthClass?: string

  tdClass?: string
}

export const PlainTable = ({
  header = [],
  data = [],
  containerClassName = '',
  classNames = [],
  widthClass = 'w-full',
  tdClass = 'px-6 py-4 whitespace-nowrap',
}: PlainTableProps) => {
  return (
    <div className={`overflow-auto mb-6 shadow-md rounded ${containerClassName}`}>
      <table className={`${widthClass} text-sm text-left text-gray-900 dark:text-gray-400 pl-6`}>
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {header.map((item, index) => (
              <th scope="col" className={`${classNames[index] || 'px-6 py-3'}`} key={item}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-[14.5px] text-gray-900">
          {data.map((row, index) => (
            <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
              {row &&
                row.map((item: any, rIndex) => (
                  <td
                    className={`${tdClass} ${rIndex == 0 ? 'font-medium text-gray-900 dark:text-white' : ''}`}
                    key={rIndex}
                  >
                    {item}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
