import { BuildingLibraryIcon, MinusCircleIcon, PlusIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { AccountStatus } from 'config'
import { useEffect, useState } from 'react'
import { PlaidAccount, PlaidInstitution, usePlaidLink } from 'react-plaid-link'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState } from 'reducers'
import { addPlaidAccounts, deletePlaidAccount, getPlaidClientToken } from 'services/apis'
import { confirm, useTitle } from 'utils'

import type { DbPlaidAccount } from './constants'
import { renderBankAccount } from './Withdraw/util'

export const AccountsTab = ({
  accounts,
  updateAccounts,
  showVerificationProgress,
}: {
  accounts: DbPlaidAccount[]
  updateAccounts: Function
  showVerificationProgress: Function
}) => {
  const { pathname } = useLocation()
  pathname != '/dashboard' && useTitle('Accounts')

  const { profile } = useSelector((state: RootState) => state.auth)

  const [action, setAction] = useState('')
  const [clientToken, setClientToken] = useState('')

  const { open: openPlaid, ready: isPlaidReady } = usePlaidLink({
    token: clientToken,
    onSuccess: (public_token, metadata) => {
      console.log('success', public_token, metadata)
      metadata.institution && onLink(metadata.institution, metadata.accounts, public_token)
    },
    onEvent: (event, metadata) => {
      console.log('event', event, metadata)
    },
    onExit: (error, metadata) => {
      console.log('exit', error, metadata)
      setAction('')
    },
    onLoad: () => {
      console.log('onLoad')
    },
  })

  useEffect(() => {
    if (!clientToken || !isPlaidReady) return
    openPlaid()
  }, [clientToken, isPlaidReady])

  useEffect(() => {
    updateAccounts().finally(() => setAction(''))
  }, [])

  const onPlusAccount = () => {
    if (profile.status < AccountStatus.KycPassed) {
      showVerificationProgress()
      return
    }

    if (action) return

    if (clientToken && isPlaidReady) {
      openPlaid()
      return
    }

    setAction('link')
    getPlaidClientToken('bank')
      .then(({ link_token }) => setClientToken(link_token))
      .catch(() => setAction(''))
  }

  const onLink = async (institution: PlaidInstitution, accounts: PlaidAccount[], publicToken: string) => {
    setAction('link')
    await addPlaidAccounts(institution, accounts, publicToken)
    await updateAccounts()
    setAction('')
  }

  const onRemoveBank = async (account: DbPlaidAccount) => {
    const result = await confirm(
      <div className="flex flex-col items-center gap-2 mb-6">
        <div className="border p-2 rounded-md">{renderBankAccount(account.account, account.institution, true)}</div>
        <p className="text-lg">Are you sure you would like to remove this account?</p>
      </div>,
    )
    if (!result) return

    setAction(account.accountId)
    await deletePlaidAccount(account.accountId)
    updateAccounts().finally(() => setAction(''))
  }

  return (
    <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-4">
      {accounts
        .filter((account) => !account.deleted)
        .map((dbAccount) => {
          const { institution, account } = dbAccount
          return (
            <div className="relative group border bg-white px-4 py-3 text-gray-700 rounded-lg" key={account.id}>
              <LayoutLoading show={action == dbAccount.accountId} />
              <div className="flex items-center mb-2 justify-between">
                <p className="text-sm capitalize truncate">{institution.name}</p>
                <div
                  className="text-lg font-bold text-red-500 hover-shadow1-full cursor-pointer group-hover:opacity-100 opacity-5"
                  onClick={() => onRemoveBank(dbAccount)}
                >
                  <MinusCircleIcon className="w-5 h-5" />
                </div>
              </div>
              <div className="mb-12 flex gap-2 items-center">
                <div className="border rounded-full inline-block">
                  {institution.logo ? (
                    <img src={`data:image/png;base64, ${institution.logo}`} className="w-12 h-12 object-contain" />
                  ) : (
                    <BuildingLibraryIcon className="w-8 h-8 m-2 text-gray-600" />
                  )}
                </div>
                <p className="text-desc">{account.name}</p>
              </div>
              <p className="text-desc">Ending in {account.mask}</p>
              <p className="font-medium">USD {account.subtype} account</p>
            </div>
          )
        })}
      <div className="border bg-white px-4 py-3 text-gray-700 rounded-lg">
        <p className="text-sm capitalize mb-2">Add Bank</p>
        <div className="group flex gap-2 items-center mb-12 cursor-pointer" onClick={onPlusAccount}>
          {action && action != 'accounts' ? (
            <div className="p-3">
              <Loading />
            </div>
          ) : (
            <div className="text-lg font-bold bg-light-green text-white group-hover:bg-green-600 p-3 rounded-full inline-block">
              <PlusIcon className="w-6 h-6" />
            </div>
          )}
          <p className="text-desc group-hover:underline">Link bank account</p>
        </div>
        {/* <p className="text-desc">Link new bank</p>
        <p className="font-medium">Open new account</p> */}
      </div>
    </div>
  )
}
