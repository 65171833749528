import type { InputType } from 'config'
import { Button } from 'stories/components'

const defaultInputs = (): Record<string, InputType> => {
  return {
    isIndividualIncome: {
      title:
        'Your individual income is $200K or more (or $300K or more with a spouse or partner) for the last two years',
      placeholder: '',
      inputType: 'checkbox',
      error: '',
      fontClass: '!text-gray-600 !font-normal',
    },
    isCurrentNetWorth: {
      title: 'Your net worth is greater than $1M (excluding your primary residence)',
      placeholder: '',
      inputType: 'checkbox',
      error: '',
      fontClass: '!text-gray-600 !font-normal',
    },
    isHoldSeries: {
      title: 'You hold a FINRA Series 7, Series 65, or Series 82 license in good standing',
      placeholder: '',
      inputType: 'checkbox',
      error: '',
      fontClass: '!text-gray-600 !font-normal',
    },
  }
}

export const AccreditedInvestorSection = ({ step, onSubmit: _onSubmit }: { step: JSX.Element; onSubmit: Function }) => {
  const inputs = defaultInputs()

  const onSubmit = (isAccreditedInvestor: string) => {
    _onSubmit({
      isAccreditedInvestor,
    })
  }

  return (
    <div className="max-w-lg px-8 md:px-0 md:mx-auto w-full md:w-120">
      <div className="mb-4">{step}</div>
      <div className="mb-4">
        <p className="text-xl font-medium">Are you an accredited investor?</p>
        <p className="text-sm text-gray-600">
          Do any <span className="underline">one</span> of the following apply to you?
        </p>
      </div>

      <div className="p-8 bg-black/5 flex flex-col gap-4 rounded-lg mb-4">
        {Object.keys(inputs).map((key) => (
          <p className="text-sm text-gray-600">- {inputs[key].title}</p>
        ))}
      </div>

      <Button full color="white" onClick={() => onSubmit('1')} className="py-4 !border-gray-300 font-medium">
        Yes
      </Button>
      <Button full color="white" onClick={() => onSubmit('0')} className="py-4 !border-gray-300 font-medium">
        No
      </Button>
    </div>
  )
}
