import { CheckCircleIcon, PlusIcon, XCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { AccountProfile, InputType, states } from 'config'
import { Tooltip } from 'flowbite-react'
import { CompanyTypeOptions } from 'pages/Auth/SignUp/AccountType'
import { IEntityData, User } from 'pages/User'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Input2, Modal } from 'stories/components'
import { countries } from 'stories/components/Input/PhoneSelector'
import { confirm, InputConvert, RenderInput, validateInputs } from 'utils'

const entityDefaultInputs = (): Record<string, InputType> => {
  return {
    entityName: {
      title: 'Entity Name',
      placeholder: 'Entity Name',
      inputType: 'text',
      type: 'text',
      required: true,
    },
    entityType: {
      inputType: 'select',
      title: 'Entity Type',
      options: CompanyTypeOptions,
      hasDefaultOption: true,
      required: true,
    },
    entityAddress: {
      title: 'Entity Address',
      placeholder: 'Address',
      inputType: 'map',
      type: 'text',
      required: true,
      span: 2,
      className: '!bg-transparent',
    },
    jurisdiction: {
      title: 'Jurisdiction of registration',
      placeholder: 'Jurisdiction of registration',
      inputType: 'select',
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
      options: states,
      error: '',
      required: true,
      visible: true,
    },
    ein: {
      title: 'Entity EIN',
      type: 'ein',
      inputType: 'text',
      placeholder: 'XX-XXXXXXX',
      required: true,
    },
    isFundAccredit: {
      title: 'All owners of my company of fund are individually accredited',
      inputType: 'checkbox',
    },
    isDefault: {
      title: 'Make this entity my preferred investment entity',
      inputType: 'checkbox',
    },
  }
}

interface IProps {
  user: User | AccountProfile
  isLoading: boolean
  onSaveEntity: (data: IEntityData[]) => void
  setSelectedEntity?: (data: IEntityData | undefined) => void
}

export const Entities = (props: IProps) => {
  const { user, isLoading, onSaveEntity } = props

  const [entityInputs, setEntityInputs] = useState<Record<string, InputType>>({})
  const [entities, setEntities] = useState<IEntityData[]>([])
  const [selectedEntity, setSelectedEntity] = useState<IEntityData>()
  const [edit, setEdit] = useState(false)
  const [modal, setModal] = useState('')
  const [newEntityName, setNewEntityName] = useState('')

  useEffect(() => {
    const targetEntity = user.entities.find((item) => item.entityName === selectedEntity?.entityName)
    if (props?.setSelectedEntity)
      props.setSelectedEntity(!!targetEntity ? targetEntity : !!user.entities.length ? user.entities[0] : undefined)
    setSelectedEntity(!!targetEntity ? targetEntity : !!user.entities.length ? user.entities[0] : undefined)
    setEntities(user.entities)
  }, [user])

  useEffect(() => {
    if (!selectedEntity) return

    const newEntityInputs = cloneDeep(entityDefaultInputs())
    Object.keys(newEntityInputs).forEach((key) => {
      newEntityInputs[key].value = (selectedEntity as any)[key]
    })
    if (newEntityInputs.entityAddress.value.endsWith('USA')) newEntityInputs.jurisdiction.options = states
    else newEntityInputs.jurisdiction.options = countries

    setEntityInputs(newEntityInputs)
  }, [selectedEntity])

  const onChangeEntity = async (key: string, value: any) => {
    const newInputs = cloneDeep(entityInputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''

    if (key === 'entityAddress') {
      if (value.endsWith('USA')) newInputs.jurisdiction.options = states
      else newInputs.jurisdiction.options = countries
    }

    setEdit(true)
    setEntityInputs(newInputs)
  }

  const onAddEntity = async () => {
    if (!!entities.find((item) => item.entityName === newEntityName)) {
      toast(`The same entity already exists`, { type: 'error' })
      return
    }
    const newEntities = cloneDeep(entities)
    newEntities.push({
      entityName: newEntityName,
      entityType: '',
      entityAddress: '',
      jurisdiction: '',
      ein: '',
      isFundAccredit: false,
      // addedCert: false,
      certFormation: [],
      certIDs: [],
      isApproved: false,
      isDefault: false,
    })

    await onSaveEntity(newEntities)
    setSelectedEntity(newEntities[newEntities.length - 1])
    if (props?.setSelectedEntity) props.setSelectedEntity(newEntities[newEntities.length - 1])
    setEntities(newEntities)
    setModal('')
    setNewEntityName('')
  }

  const onSave = async () => {
    if (!selectedEntity) return

    const { hasError, data, newInputStates } = validateInputs(entityInputs)
    if (hasError) {
      setEntityInputs(newInputStates)
      return
    }

    const newEntities = cloneDeep(entities)
    let targetIndex = -1
    newEntities.forEach((item, index) => {
      if (item.entityName === selectedEntity.entityName) {
        targetIndex = index
        Object.keys(data).forEach((key) => {
          ;(item as any)[key] = data[key]
        })
      } else {
        if (data.isDefault) item.isDefault = false
      }
    })

    await onSaveEntity(newEntities)
    setEntities(newEntities)
    setEdit(false)
    setSelectedEntity(newEntities[targetIndex])
    if (props?.setSelectedEntity) props.setSelectedEntity(newEntities[targetIndex])
  }

  const onRemove = async () => {
    if (!selectedEntity) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this entity?
        <br />
        <span className="text-gray-600 text-base">Entity Name: {selectedEntity.entityName}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    let newEntities = cloneDeep(entities)
    newEntities = newEntities.filter((item) => item.entityName !== selectedEntity.entityName)

    await onSaveEntity(newEntities)
    setEntities(newEntities)
    setSelectedEntity(!!newEntities.length ? newEntities[0] : undefined)
    setEdit(false)
  }

  return (
    <div>
      <div className="flex items-center flex-wrap gap-2 my-3">
        {!!entities.length &&
          entities.map((item, index) => {
            return (
              <Button
                color={`${selectedEntity?.entityName === item.entityName ? 'blue' : 'gray'}`}
                className="text-[14px] pt-[6px] pb-[6px] transition-all duration-200"
                key={index}
                onClick={() => {
                  setSelectedEntity(item)
                  if (props?.setSelectedEntity) props.setSelectedEntity(item)
                  setEdit(false)
                }}
              >
                <div className="flex items-center gap-2">
                  {item.isApproved ? <CheckCircleIcon className="w-4 h-4" /> : <XCircleIcon className="w-4 h-4" />}
                  <span>{item.entityName}</span>
                </div>
              </Button>
            )
          })}

        <Tooltip content="Add an Entity">
          <Button
            color="gray"
            className="pt-[7px] pb-[7px] transition-all duration-200"
            onClick={() => setModal('addEntity')}
          >
            <PlusIcon className="w-5 h-5" />
          </Button>
        </Tooltip>
      </div>

      {!!selectedEntity && (
        <>
          <div className="flex justify-between items-center mb-2">
            <div>
              {edit && (
                <Button size="sm" className="py-1 px-2" onClick={onSave} loading={isLoading}>
                  Save
                </Button>
              )}
            </div>

            <Button size="sm" color="red" className="py-1 px-2" onClick={onRemove} loading={isLoading}>
              Remove
            </Button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {Object.keys(entityInputs).map((key, index) => {
              const input = entityInputs[key]

              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChangeEntity} />
                </div>
              )
            })}
          </div>
        </>
      )}

      {modal === 'addEntity' && (
        <Modal
          title="Add a new entity"
          titleOkay="Add"
          onClose={() => {
            setModal('')
            setNewEntityName('')
          }}
          isOpen={modal === 'addEntity'}
          onOk={onAddEntity}
          disabled={!newEntityName}
          loading={isLoading}
        >
          <div className="text-gray-900 sm:min-w-112 max-w-144">
            <Input2 title="Entity Name" value={newEntityName} onChange={(value) => setNewEntityName(value)} />
          </div>
        </Modal>
      )}
    </div>
  )
}
