import { CurrencyDollarIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { FciLoanStatus, isTopManager, itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import type { DbTask } from 'pages/Admin'
import type { DBInvestment } from 'pages/Payment'
import { InvestDetails } from 'pages/Payment/modal'
import type { User } from 'pages/User'
import { UserDetails } from 'pages/User/UserDetails'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { approveSelectedDistribution, findProfits, getInvestment, getUser } from 'services/apis'
import { Checkbox, Input2, MultiSelect, Pagination, Select2 } from 'stories/components'
import { confirm, formatDate, formatTime, getSimplifiedAddress, normalizeNumber, renderHeader, useTitle } from 'utils'

import { ExportProfitReport, ProfitMarkModal, ProfitStatusModal } from './modal'
import { FailedResultModal } from './modal/FailedResult'
import {
  ApproveFailedResult,
  type DBProfit,
  ProfitStatus,
  ProfitStatusColorMap,
  ProfitStatusHoverBorderColorMap,
  ProfitStatusHoverColorMap,
  ProfitStatusText,
} from './type'

export const ManageProfitsPage = () => {
  useTitle('Manage Distributions')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const status = searchParams.get('status') || ''
  const defaultQuery = query.replace(/_/g, '#')

  const defaultFciLoanStatus: Record<string, boolean> = {}
  Object.keys(FciLoanStatus).forEach((key) => {
    defaultFciLoanStatus[key] = false
  })

  const [filters, setFilters] = useState<Record<string, any>>({
    query: defaultQuery,
    fciLoanStatus: defaultFciLoanStatus,
    status: '',
    orderBy: 'id',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<DBProfit[]>([])
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [selectedInvestment, setSelectedInvestment] = useState<DBInvestment | null>(null)
  const [selectedItem, setSelectedItem] = useState<{ id: number; status: ProfitStatus | null } | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [failedApproveDistributions, setFailedApproveDistributions] = useState<ApproveFailedResult[]>([])
  const [selectedDistributions, setSelectedDistributions] = useState<number[]>([])
  const [initialDistributionIds, setInitialDistributionIds] = useState<number[]>([])
  const [approveFailedIds, setApproveFailedIds] = useState<number[]>([])
  const [updateStatus, setUpdateStatus] = useState<ProfitStatus>(ProfitStatus.Approved)

  const auth = useSelector((state: RootState) => state.auth)
  const { accountType } = auth.profile
  const _isManager = isTopManager(accountType)

  useEffect(() => {
    window.scrollTo(window.scrollX, 0)

    const newFilters = Object.assign({}, filters)
    if (status === 'all') newFilters.status = ''
    else if (!!status) newFilters.status = status
    else newFilters.status = ProfitStatus.Pending
    setFilters(newFilters)

    filterData(newFilters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [status])

  useEffect(() => {
    if (filters.query == defaultQuery) return

    onChangeFilter('query', defaultQuery)
  }, [defaultQuery])

  useEffect(() => {
    if (filterQuery.trim() === '') {
      const currentURL = window.location.pathname
      navigate(currentURL, { replace: true })
    }
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum

    setLoading(true)
    const fciLoanStatus: string[] = []
    Object.keys(FciLoanStatus).forEach((key) => {
      if (filters.fciLoanStatus[key] !== false) fciLoanStatus.push(FciLoanStatus[key as keyof typeof FciLoanStatus])
    })
    if (!filters.status) delete filters.status

    return findProfits({
      ...filters,
      fciLoanStatus: fciLoanStatus,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total, profitIds }) => {
        setTotal(total)
        setData(data)
        setInitialDistributionIds(profitIds)
      })
      .finally(() => setLoading(false))
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onOpenInvestment = (investId: number) => {
    getInvestment(investId).then((data) => {
      setSelectedInvestment(data)
    })
  }

  const onChangeFilter = (
    key: 'query' | 'envelopeStatus' | 'status' | 'orderBy' | 'orderDir' | 'pageNum' | 'fciLoanStatus',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const onOpenUser = (userId: number) => {
    getUser(userId).then((data) => {
      data && setSelectedUser(data)
    })
  }

  const onUpdateStatus = async (id: number, status: ProfitStatus) => {
    const item = data.find((item) => item.id == id)
    if (!item) return

    setUpdateStatus(status)
    setApproveFailedIds([id])
    setSelectedItem({ id, status })
  }

  const approveAll = async () => {
    if (isLoading) return
    if (!selectedDistributions.length) {
      toast(`Select the distributions`, { type: 'info' })
      return
    }

    const content = (
      <div className="text-gray-700 mb-4 text-[18px]">Do you want to approve all selected distributions?</div>
    )

    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    approveSelectedDistribution(selectedDistributions)
      .then((res) => {
        if (res.success) {
          filterData(filters)
          setSelectedDistributions([])
        } else {
          setFailedApproveDistributions(res.data)
          filterData(filters)
          setSelectedDistributions([])
        }
      })
      .finally(() => setLoading(false))
  }

  // Handle individual row checkbox change
  const handleCheckboxChange = (id: number) => {
    setSelectedDistributions((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((rowId) => rowId !== id) : [...prevSelected, id],
    )
  }

  // Handle select all checkbox change
  const handleSelectAllChange = (value: boolean) => {
    if (!value) {
      setSelectedDistributions([])
    } else {
      setSelectedDistributions(initialDistributionIds)
    }
  }

  const handleApproveFailedDistributions = (approveFailedIds: number[]) => {
    if (!approveFailedIds.length) {
      toast(`Select the distributions`, { type: 'info' })
    } else {
      setSelectedItem(null)
      setApproveFailedIds(approveFailedIds)
      setFailedApproveDistributions([])
    }
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <LayoutLoading show={isLoading} />
      <div className="sm:flex justify-between items-center pt-2 mb-0 sm:mb-10">
        <p className="text-4xl font-semibold mb-10 sm:mb-0 flex gap-4 items-center">Manage Distributions ({total})</p>

        <div className="flex items-center gap-2 justify-end">
          {_isManager && filters.status === ProfitStatus.Pending && (
            <Tooltip content={`Approve All`}>
              <div
                className={`font-bold text-green-500 transition-all duration-200 rounded cursor-pointer p-1 shadow hover-shadow1`}
                onClick={approveAll}
              >
                <CurrencyDollarIcon className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
          <ExportProfitReport />
        </div>
      </div>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <Select2
          id="status"
          title="Status"
          options={ProfitStatusText}
          value={filters.status}
          className="capitalize"
          hasDefaultOption
          defaultOptionText=""
          onChange={(value) => onChangeFilter('status', value)}
        />
        <MultiSelect
          id="fciLoanStatus"
          title="FCI Loan Status"
          options={Object.keys(FciLoanStatus)}
          value={filters.fciLoanStatus}
          className="capitalize"
          hasDefaultOption
          defaultOptionText=""
          onChange={(value) => onChangeFilter('fciLoanStatus', value, false)}
          onBlur={() => onChangeFilter('fciLoanStatus', filters.fciLoanStatus)}
        />
      </div>
      <div className="flex justify-between items-center pt-2">
        <p className="text-gray-500">
          {selectedDistributions.length > 0 && `Selected ${selectedDistributions.length} Distributions`}
        </p>
      </div>
      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {filters.status === ProfitStatus.Pending && (
                <div className="py-3 px-2">
                  <Checkbox
                    id="allApprove"
                    title="All"
                    value={selectedDistributions.length === total}
                    onChange={(value) => handleSelectAllChange(value)}
                  />
                </div>
              )}

              {_renderHeader('No', true, 'id')}
              {_renderHeader('Task', false, 'task')}
              {_renderHeader('Loan Details', true, 'loanId')}
              {_renderHeader('Investment', true, 'investmentId')}
              {_renderHeader('Name', true, 'name')}
              {_renderHeader('From', true, 'from')}
              {_renderHeader('To', true, 'to')}
              {_renderHeader('Amount', true, 'amount')}
              {_renderHeader('Status', true, 'status')}
              {_renderHeader('Created At', true, 'createdAt')}
              {_renderHeader('Actions', false, 'action')}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {data.map((item, index: number) => {
              const task = (item as any).task as DbTask
              return (
                <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                  {filters.status === ProfitStatus.Pending && (
                    <td className="pl-6">
                      <Checkbox
                        id={item.id.toString()}
                        value={selectedDistributions.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </td>
                  )}

                  <td className="pl-3 py-3 whitespace-nowrap">
                    {filters.pageNum * itemCountPerPage + index + 1} #{item.id}
                  </td>
                  <td className="px-2 py-2 font-bold text-indigo-500 cursor-pointer hover:underline">
                    {task && (
                      <Link
                        to={`/tasks?query=_${task.id}&taskType=${
                          task.status == 'active' ? 'allActive' : 'allCompleted'
                        }`}
                      >
                        #{task.id}
                      </Link>
                    )}
                  </td>
                  <td className="px-2 py-2">
                    <div className="flex gap-2 items-center">
                      <div className="hover:text-indigo-500 hover:underline">
                        <Link to={`/marketplace/${item.loan.id}`}>
                          {getSimplifiedAddress(item.loan.propertyAddress)}
                        </Link>
                      </div>

                      <div className="font-bold text-indigo-500 hover:underline cursor-pointer">
                        <Link to={`/manageSignStatistics?query=__${item.loan.id}`}>
                          <span>#{item.loan.id}</span>
                        </Link>
                      </div>
                    </div>

                    <p className="flex justify-between lg:justify-start font-medium items-center gap-2 text-gray-700">
                      <span className="text-desc">Maturity Date:</span> {formatDate(item.loan.maturityDate)}
                    </p>

                    <p className="flex justify-between lg:justify-start font-medium items-center gap-2 capitalize text-gray-700">
                      <span className="text-desc">Loan Status:</span>{' '}
                      {item.loan.fciLoan ? item.loan.fciLoan.status : 'N/A'}
                    </p>
                  </td>
                  <td
                    className="px-2 py-2 font-bold text-indigo-500 hover:underline cursor-pointer"
                    onClick={() => onOpenInvestment(item.investment.id)}
                  >
                    #{item.investment.id}
                  </td>
                  <td className="px-2 py-2">
                    <div
                      className="font-bold text-indigo-500 cursor-pointer hover:underline"
                      onClick={() => item.user.id && onOpenUser(item.user.id)}
                    >
                      <p className="whitespace-nowrap">{item.user.name}</p>
                    </div>
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatDate(item.from)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatDate(item.to)}</td>
                  <td
                    className={`px-2 py-2 ${
                      item.status == ProfitStatus.Approved && 'text-green-500 font-bold whitespace-nowrap'
                    }`}
                  >
                    $ {normalizeNumber(item.amount, 2)}
                  </td>
                  <td className="px-2 py-2 capitalize">
                    <div
                      className={`flex gap-2 items-center capitalize cursor-pointer transition-all duration-200 hover:${
                        ProfitStatusHoverColorMap[item.status]
                      } hover:underline hover:${ProfitStatusHoverBorderColorMap[item.status]}`}
                      onClick={() => setSelectedItem({ id: item.id, status: null })}
                    >
                      <span className={`w-2 h-2 rounded-full ${ProfitStatusColorMap[item.status]}`} />
                      {item.status}
                    </div>
                    {item.override && <p className="border-t border-gray-300 text-center text-gray-500">Override</p>}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">{formatTime(item.createdAt)}</td>
                  <td className="px-2 py-2">
                    <div className="flex gap-2">
                      {_isManager && [ProfitStatus.Pending, ProfitStatus.Rejected].includes(item.status) && (
                        <Tooltip content={`Approve`}>
                          <div
                            onClick={() => onUpdateStatus(item.id, ProfitStatus.Approved)}
                            className="font-bold text-green-500 transition-all duration-200 hover:underline cursor-pointer hover-shadow1 p-1"
                          >
                            <CurrencyDollarIcon className="w-4 h-4" />
                          </div>
                        </Tooltip>
                      )}

                      {_isManager && [ProfitStatus.Pending, ProfitStatus.Approved].includes(item.status) && (
                        <Tooltip content={`Reject`}>
                          <div
                            onClick={() => onUpdateStatus(item.id, ProfitStatus.Rejected)}
                            className="font-bold text-red-500 transition-all duration-200 hover:underline cursor-pointer hover-shadow1 p-1"
                          >
                            <XMarkIcon className="w-4 h-4" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>

      {selectedInvestment && (
        <InvestDetails data={selectedInvestment} isMine={false} onClose={() => setSelectedInvestment(null)} />
      )}

      {((selectedItem && selectedItem.status) || approveFailedIds.length > 0) && (
        <ProfitMarkModal
          ids={approveFailedIds}
          status={updateStatus}
          isBatch={selectedItem === null}
          onClose={(needRefresh: boolean) => {
            setApproveFailedIds([])
            setSelectedItem(null)
            needRefresh && filterData(filters)
          }}
        />
      )}
      {selectedItem && !selectedItem.status && (
        <ProfitStatusModal
          profit={data.find((item) => item.id == selectedItem.id)!}
          onClose={() => setSelectedItem(null)}
        />
      )}

      {failedApproveDistributions.length > 0 && (
        <FailedResultModal
          failedResults={failedApproveDistributions}
          onClose={() => setFailedApproveDistributions([])}
          onSubmit={(value) => handleApproveFailedDistributions(value)}
        />
      )}

      {selectedUser && <UserDetails readOnly data={selectedUser} onClose={() => setSelectedUser(null)} />}
    </div>
  )
}
