import _imgBetterLoans from './betterLoans.jpg'
import _imgConnectInvestor from './connectInvestor.jpg'
import _imgEarnMonthly from './earnMonthly.png'
import _imgInstitutionalGraph from './institutionalGraph.svg'
import _imgLoanPrincipalLock from './loanPrincipalLock.jpg'
import _imgLoanPrincipalPie from './loanPrincipalPie.png'
import _imgRealEstateGray from './realEstateGray.svg'
import _imgRealEstateGreen from './realEstateGreen.svg'
import _imgRealEstateTrx from './realEstateTrx.svg'
import _imgTopLeft from './topLeft.png'
import _imgUnderwritePurchase from './underwritePurchase.png'
import _imgWelcomeMarketplace from './welcomeMarketplace.jpg'

export const imgTopLeft = _imgTopLeft
export const imgBetterLoans = _imgBetterLoans
export const imgUnderwritePurchase = _imgUnderwritePurchase
export const imgWelcomeMarketplace = _imgWelcomeMarketplace
export const imgConnectInvestor = _imgConnectInvestor
export const imgEarnMonthly = _imgEarnMonthly

export const imgLoanPrincipalPie = _imgLoanPrincipalPie
export const imgLoanPrincipalLock = _imgLoanPrincipalLock

export const imgRealEstateTrx = _imgRealEstateTrx
export const imgInstitutionalGraph = _imgInstitutionalGraph
export const imgRealEstateGreen = _imgRealEstateGreen
export const imgRealEstateGray = _imgRealEstateGray
