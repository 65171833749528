import { EyeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { TransactionColorMap } from 'components/TransactionStatus'
import { Tooltip } from 'flowbite-react'
import type { ProfitStatus } from 'pages/Invest'
import { type InvestStatus, ProfitColorMap } from 'pages/Payment'
import type { User } from 'pages/User'
import { UserDetails } from 'pages/User/UserDetails'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDistributions, getInvestorsDetail, getUser } from 'services/apis'
import { Input2Read, PlainTable } from 'stories/components'
import { removeComma, renderHeader } from 'utils'

export interface IDistributionDetail {
  id: number
  from: string
  to: string
  amount: string
  status: ProfitStatus
}

interface IInvestmentDetail {
  ID: number
  userId: number
  investedDate: string
  investorName: string
  investedAmount: string
  status: InvestStatus
  approvedDistribution: string
  pendingDistribution: string
}

export interface IInvestorsDetail {
  loanAmount: string
  apy: number
  investedAmount: string
  totalInvestments: number
  approvedDistribution: string
  pendingDistribution: string
  retradedFees: string
  investments: IInvestmentDetail[]
  maturityDate: string
  fciLoanNumber: string
  fciLoanStatus: string
}

const lables: Record<string, string> = {
  loanAmount: 'Loan Amount',
  apy: 'APY',
  investedAmount: 'Active Funds',
  totalInvestments: 'Total Investments',
  approvedDistribution: 'Approved Distribution',
  pendingDistribution: 'Pending Distribution',
  retradedFees: 'Retraded Fees',
  maturityDate: 'Maturity Date',
  fciLoanNumber: 'FCI Loan Number',
  fciLoanStatus: 'FCI Loan Status',
}

const prefixes: Record<string, string> = {
  loanAmount: '$',
  apy: '%',
  investedAmount: '$',
  totalInvestments: '',
  approvedDistribution: '$',
  pendingDistribution: '$',
  retradedFees: '$',
  maturityDate: '',
  fciLoanNumber: '',
  fciLoanStatus: '',
}

export const InvestorsDetail = ({ loanId, onClose }: { loanId: number; onClose: Function }) => {
  const [data, setData] = useState<IInvestorsDetail | undefined>(undefined)
  const [distributions, setDistributions] = useState<IDistributionDetail[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedInvest, setSelectedInvest] = useState<number | undefined>(undefined)
  const [filters, setFilters] = useState<Record<string, any>>({
    orderBy: 'ID',
    orderDir: -1,
  })
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getInvestorsDetail(loanId)
      setData(res)
      onSort(filters, res)
      setLoading(false)
    })()
  }, [loanId])

  useEffect(() => {
    if (!selectedInvest) return
    ;(async () => {
      setLoading(true)
      const res = await getDistributions(loanId, selectedInvest)
      setDistributions(res)
      setLoading(false)
    })()
  }, [selectedInvest])

  const onSort = (filter: Record<string, any>, filterData: IInvestorsDetail | undefined = data) => {
    if (!data && !filterData) return

    const key = filter['orderBy']
    const order = filter['orderDir']

    const newData = cloneDeep(data ? data : filterData)

    newData?.investments.sort((x, y) => {
      let a
      let b

      switch (key) {
        case 'ID':
          a = x.ID
          b = y.ID
          break
        case 'investedDate':
          a = new Date(x.investedDate).getTime()
          b = new Date(y.investedDate).getTime()
          break
        case 'investorName':
          a = x.investorName.toLowerCase()
          b = y.investorName.toLowerCase()
          break
        case 'investedAmount':
          a = removeComma(x.investedAmount)
          b = removeComma(y.investedAmount)
          break
        default:
          a = 0
          b = 0
      }

      if (order === 1) {
        if (a < b) return -1
        if (a > b) return 1
      } else if (order === -1) {
        if (a < b) return 1
        if (a > b) return -1
      }

      return 0
    })

    setData(newData)
  }

  const onOpenUser = (userId: number) => {
    getUser(userId).then((data) => {
      data && setSelectedUser(data)
    })
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        onSort(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
      className: 'px-3',
    })
  }

  const renderDetail = useMemo(() => {
    if (data === undefined) return <></>

    return (
      <div>
        <div className="grid grid-cols-7 gap-4 capitalize">
          {Object.keys(data).map((key, index) => {
            if (key === 'investments') return <></>

            return <Input2Read key={index} prefix={prefixes[key]} title={lables[key]} value={(data as any)[key]} />
          })}
        </div>

        {!!data.investments.length && (
          <div className="border rounded-md shadow-md mt-4">
            <table className="w-full text-sm text-left text-gray-900 pl-6">
              <thead className="text-xs border-t border-b text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {_renderHeader('Investment', true, 'ID')}
                  {_renderHeader('Invested Date', true, 'investedDate')}
                  {_renderHeader('Investor Name', true, 'investorName')}
                  {_renderHeader('Invested Amount', true, 'investedAmount')}
                  {_renderHeader('Status', false, 'status')}
                  {_renderHeader('Distributions', false, 'distributions')}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {data.investments.map((item, index) => {
                  const distributionTableData = distributions.map((v, id) => [
                    <Link to={`/manageProfits?query=_${v.id}`} key={id}>
                      {id + 1}{' '}
                      {!!v.id && (
                        <span className="font-bold cursor-pointer text-indigo-500 hover:underline">#{v.id}</span>
                      )}
                    </Link>,
                    v.from,
                    v.to,
                    `$ ${v.amount}`,
                    <p
                      className={`capitalize ${
                        ProfitColorMap[v.status]
                      } inline-block px-2 py-1 rounded text-sm capitalize`}
                    >
                      {v.status}
                    </p>,
                  ])

                  const isExpand = selectedInvest === item.ID

                  return (
                    <Fragment key={index}>
                      <tr key={index} className={`border-t border-b ${index % 2 !== 0 ? 'bg-slate-50' : ''}`}>
                        <td className="px-3 py-2.5">
                          <Link to={`/manageSignStatistics?query=_${item.ID}`}>
                            <span className="font-bold cursor-pointer text-indigo-500 hover:underline">#{item.ID}</span>
                          </Link>
                        </td>
                        <td className="px-3 py-2.5">{item.investedDate}</td>
                        <td className="px-3 py-2.5">
                          <span
                            className="cursor-pointer font-bold text-indigo-500 hover:underline"
                            onClick={() => item.userId && onOpenUser(item.userId)}
                          >
                            {item.investorName}
                          </span>
                        </td>
                        <td className="px-3 py-2.5">$ {item.investedAmount}</td>
                        <td className="px-3 py-2.5">
                          <div className="flex gap-2 items-center capitalize">
                            <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
                            {item.status}
                          </div>
                        </td>
                        <td className="px-3 py-2.5">
                          <p className="flex gap-1 items-center">
                            <span className="text-green-500 font-bold">${item.approvedDistribution}</span>/
                            <span className="text-gray-500">${item.pendingDistribution}</span>
                          </p>
                        </td>
                        <td className="px-3 py-2.5">
                          <div className="flex justify-center">
                            <Tooltip content="Distributions">
                              <button
                                className="hover-shadow1 p-1 transition-all duration-200 cursor-pointer rounded"
                                onClick={() => {
                                  if (selectedInvest === undefined || selectedInvest !== item.ID)
                                    setSelectedInvest(item.ID)
                                  else setSelectedInvest(undefined)
                                }}
                              >
                                <EyeIcon className="w-4 h-4 text-indigo-500" />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>

                      {!!distributionTableData.length && isExpand && (
                        <tr>
                          <td />

                          <td colSpan={3} className="py-2">
                            <div className="flex justify-end">
                              <button
                                className="hover-shadow1 p-0.5 transition-all duration-200 cursor-pointer rounded"
                                onClick={() => setSelectedInvest(undefined)}
                              >
                                <XMarkIcon className="w-5 h-5 text-red-500 cursor-pointer" />
                              </button>
                            </div>

                            <p className="font-bold">Distributions</p>

                            <PlainTable
                              tdClass="text-sm px-6 py-1 whitespace-nowrap"
                              containerClassName="mt-2 mb-[10px] shadow rounded-sm"
                              header={['No', 'From', 'To', 'Amount', 'Status']}
                              data={distributionTableData}
                            />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }, [data, filters, distributions, selectedInvest])

  return (
    <div>
      <div className="flex justify-end mb-2">
        <button
          className="hover-shadow1 p-0.5 transition-all duration-200 cursor-pointer rounded"
          onClick={() => onClose()}
        >
          <XMarkIcon className="w-5 h-5 text-red-500 cursor-pointer" />
        </button>
      </div>

      <div className="relative px-4 py-5 border rounded-md">
        <LayoutLoading show={loading} />

        {renderDetail}
      </div>

      {selectedUser && <UserDetails readOnly data={selectedUser} onClose={() => setSelectedUser(null)} />}
    </div>
  )
}
