// import { ChevronDownIcon } from '@heroicons/react/24/outline'

interface AccordionProps {
  title?: string
  data: Record<string, string | number | JSX.Element>
}

export const Accordion = ({ title = '', data }: AccordionProps) => {
  return (
    <div className="mb-2">
      {title && (
        <div className="flex justify-between py-1 rounded-lg bg-indigo-150 items-center mb-4">
          <p className="text-sm">{title}</p>
          {/* <ChevronDownIcon className="w-4 h-4 text-black" /> */}
        </div>
      )}
      <div className={`grid grid-cols-2 gap-4`}>
        {Object.keys(data).map((key) => (
          <div key={key} className="font-semibold">
            <p className="text-desc font-normal">{key}</p>
            {typeof data[key] === 'string' ? <p className="text-sm">{data[key]}</p> : data[key]}
          </div>
        ))}
      </div>
    </div>
  )
}
