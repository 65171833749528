import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { confirmSellbackInvestment } from 'services/apis'

export const InvestRetradeRedirectPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const envelopeId = searchParams.get('envelopeId')
    const envelopeEvent = searchParams.get('event')

    if (!params.id || !envelopeId || envelopeEvent != 'signing_complete') {
      navigate('/dashboard')
      return
    }

    confirmSellbackInvestment(Number(params.id), envelopeId).then(() => {
      toast('The investment has been retraded to Finresi.', { type: 'info' })
      navigate('/dashboard')
    })
  }, [])

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <LayoutLoading show />
    </div>
  )
}
