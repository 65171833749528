import _imgAlumni from './alumni.svg'
import _imgBallard from './ballard.svg'
import _imgBen from './ben.svg'
import _imgBlog1 from './blog1.png'
import _imgBlog2 from './blog2.png'
import _imgBlog3 from './blog3.png'
import _imgBlog4 from './blog4.png'
import _imgBloomberg from './bloomberg.svg'
import _imgBlueSign from './blue-sign.svg'
import _imgBusinessInside from './business-insider.svg'
import _imgChris from './chris.png'
import _imgE from './e.svg'
import _imgEraci from './eraci.svg'
import _imgForbes from './forbes.svg'
import _imgJeff from './jeff.png'
import _imgKirk from './kirk.png'
import _imgLeftArrow from './left-arrow.svg'
import _imgLesEchos from './les-echo.svg'
import _imgPhila from './phila.png'
import _imgReallist from './reallist.png'
import _imgRightArrow from './right-arrow.svg'
import _imgRtt from './rtt.png'
import _imgSomer from './somer.svg'
import _imgTc from './techcrunch_logo.svg'
import _imgWorldMap from './world-map.svg'

export const imgWorldMap = _imgWorldMap
export const imgBlueSign = _imgBlueSign

export const imgSomer = _imgSomer
export const imgAlumni = _imgAlumni

export const imgE = _imgE
export const imgBen = _imgBen

export const imgBallard = _imgBallard
export const imgEraci = _imgEraci

export const imgRtt = _imgRtt
export const imgPhila = _imgPhila

export const imgReallist = _imgReallist

export const imgLeftArrow = _imgLeftArrow
export const imgRightArrow = _imgRightArrow

export const imgBlog1 = _imgBlog1
export const imgBlog2 = _imgBlog2
export const imgBlog3 = _imgBlog3
export const imgBlog4 = _imgBlog4

export const imgJeff = _imgJeff
export const imgKirk = _imgKirk
export const imgChris = _imgChris

export const imgBloomberg = _imgBloomberg
export const imgForbest = _imgForbes
export const imgTc = _imgTc
export const imgBusinessInsider = _imgBusinessInside
export const imgLesEchos = _imgLesEchos
