import { AUTH_LOGOUT, AUTH_PROFILE_UPDATE, AUTH_TOKEN_SET, ON_APPLICATION_ERROR, SIGNIN_USER_REQUEST } from 'actions'
import type { AccountProfile } from 'config'

import { createReducer } from '../utils'

export interface AuthReducerState {
  isAuthenticated: boolean
  token: string | null
  profile: AccountProfile
  fetchingToken: boolean
}

const initialState = (): AuthReducerState => {
  return {
    isAuthenticated: false,
    token: null,
    profile: {} as AccountProfile,
    fetchingToken: false,
  }
}

export const auth = createReducer(initialState(), {
  [SIGNIN_USER_REQUEST]: (state: AuthReducerState) => ({ ...state, fetchingToken: true }),
  [ON_APPLICATION_ERROR]: (state: AuthReducerState) => ({ ...state, fetchingToken: false }),
  [AUTH_TOKEN_SET]: (state: AuthReducerState, payload: any) => {
    const { token, user: profile } = payload
    return {
      ...state,
      isAuthenticated: true,
      token,
      profile,
      fetchingToken: false,
    }
  },
  [AUTH_PROFILE_UPDATE]: (state: AuthReducerState, payload: any) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        ...payload.profile,
      },
    }
  },
  [AUTH_LOGOUT]: () => initialState(),
})
