import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'

interface ResponsiveTableProps {
  header: string[]
  headerKeys?: string[] | null
  size?: number[]
  data: (string | number | JSX.Element | null)[][]
  orderBy?: string
  orderDir?: number
  containerClassName?: string
  headerClassName?: string
  contentClassName?: string
  classNames?: string[]
  gridFrom?: string
  showKeys?: boolean[]
  onClickHeader?: Function
  onClickItem?: Function
}

export const ResponsiveTable = ({
  header = [],
  headerKeys = null,
  size = [],
  data = [],
  orderBy = '',
  orderDir = 0,
  containerClassName = 'gap-2 text-md',
  headerClassName = 'grid',
  contentClassName = '',
  classNames = [],
  gridFrom = 'lg',
  showKeys = [],
  onClickHeader = () => {},
  onClickItem = () => {},
}: ResponsiveTableProps) => {
  const cols = size.reduce((a, b) => a + b, 0)

  const renderOrderIcon = () => {
    if (orderDir == 0) return null
    if (orderDir == 1) return <BarsArrowUpIcon className="w-3 h-3 ml-2" />
    else return <BarsArrowDownIcon className="w-3 h-3 ml-2" />
  }

  return (
    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ${gridFrom}:grid-cols-1 ${containerClassName}`}>
      {!!header.length && (
        <div className={`grid-cols-${cols} bg-indigo-150 py-2 rounded-lg text-sm ${headerClassName}`}>
          {header.map((v, index) => (
            <div
              className={`flex col-span-${size[index]} ${index == 0 && 'px-4'} cursor-pointer items-center`}
              onClick={() => onClickHeader && onClickHeader(headerKeys ? headerKeys[index] : v)}
              key={index}
            >
              <span dangerouslySetInnerHTML={{ __html: v }} />{' '}
              {headerKeys && orderBy == headerKeys[index] && renderOrderIcon()}
            </div>
          ))}
        </div>
      )}

      {data.map((item, itemIndex) => (
        <div
          className={`${gridFrom}:grid ${gridFrom}:grid-cols-${cols} bg-white items-center overflow-hidden divide-y lg:divide-y-0 ${contentClassName}`}
          key={`${itemIndex}`}
          onClick={(e) => !e.defaultPrevented && onClickItem && onClickItem(itemIndex)}
        >
          {item.map((value, index) =>
            typeof value === 'string' || showKeys[index] ? (
              <div
                className={`flex items-center justify-between px-4 ${gridFrom}:px-0 ${gridFrom}:col-span-${size[index]} py-1 ${gridFrom}:py-0`}
                key={index}
              >
                <p className={`${gridFrom}:hidden text-desc`}>{header[index].replace('<br />', ' ') || ''}</p>
                {typeof value === 'string' ? <p className={`${classNames[index] || 'font-medium'}`}>{value}</p> : value}
              </div>
            ) : (
              <div
                className={`col-span-1 ${gridFrom}:col-span-${size[index]} ${
                  classNames[index] || ''
                } py-1 ${gridFrom}:py-0`}
                key={index}
              >
                {value}
              </div>
            ),
          )}
        </div>
      ))}
    </div>
  )
}
