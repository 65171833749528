import { Tooltip } from 'flowbite-react'
import type { IDistributionDetail } from 'pages/Loan/Pipeline/InvestorsDetail'
import { type DBInvestment, ProfitColorMap } from 'pages/Payment'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDistributions } from 'services/apis'
import { Modal, PlainTable } from 'stories/components'

export const DistributionsModal = ({
  invest,
  isAdmin = false,
  onClose,
}: {
  invest: DBInvestment
  isAdmin?: boolean
  onClose: Function
}) => {
  const [loading, setLoading] = useState(false)
  const [distributions, setDistributions] = useState<IDistributionDetail[]>([])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getDistributions(invest.loan.id, invest.id)
      setDistributions(res)
      setLoading(false)
    })()
  }, [invest])

  const renderDistribution = useMemo(() => {
    const distributionTableData = distributions.map((v, id) => [
      <div className="flex gap-1" key={id}>
        <span>{id + 1}</span>
        {!v.id ? (
          <></>
        ) : isAdmin ? (
          <Tooltip content="Go to Distribution">
            <Link
              to={`/manageProfits?query=_${v.id}&status=${v.status}`}
              className="font-bold cursor-pointer text-indigo-500 hover:underline"
            >
              #{v.id}
            </Link>
          </Tooltip>
        ) : (
          <span className="font-bold cursor-pointer text-indigo-500 hover:underline">#{v.id}</span>
        )}
      </div>,
      v.from,
      v.to,
      `$ ${v.amount}`,
      <p className={`capitalize ${ProfitColorMap[v.status]} inline-block px-2 py-1 rounded text-sm capitalize`}>
        {v.status}
      </p>,
    ])

    return distributionTableData
  }, [distributions])

  return (
    <Modal isOpen title={`Distributions - #${invest.id}`} loading={loading} titleOkay="" onClose={() => onClose()}>
      <div>
        <PlainTable
          tdClass="text-sm px-6 py-0.5 whitespace-nowrap"
          containerClassName="mt-2 mb-[10px] shadow rounded-sm"
          header={['No', 'From', 'To', 'Amount', 'Status']}
          data={renderDistribution}
        />
      </div>
    </Modal>
  )
}
