import { authUpdateProfile } from 'actions'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from 'reducers'
import { getTaxCerts, signTaxCert } from 'services/apis'
import { Button, PlainTable } from 'stories/components'
import { formatTime } from 'utils'

import { type TaxCert, TaxCertFormNames, TaxCertStatus, TaxCertStatusStyles, TaxCertStatusText } from './TaxCertType'

export const TaxCertTab = () => {
  const { profile } = useSelector((state: RootState) => state.auth)

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<TaxCert[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    getTaxCerts().then(({ data, country, taxCertPassed }) => {
      setData(data)

      dispatch(
        authUpdateProfile({
          ...profile,
          country,
          taxCertPassed,
        }),
      )
    })
  }, [])

  const isNeedSign = useMemo(
    () =>
      data &&
      (data.length == 0 || [TaxCertStatus.Pending, TaxCertStatus.Rejected].includes(data[data.length - 1].status)),
    [data],
  )

  const isApproved = useMemo(
    () => data && !!data.length && data[data.length - 1].status == TaxCertStatus.Approved,
    [data],
  )

  const onSign = () => {
    setLoading(true)

    signTaxCert()
      .then(({ redirectUrl }) => {
        window.open(redirectUrl, '_self')
      })
      .catch(() => setLoading(false))
  }

  const renderDescription = () => {
    return (
      <div className="mb-10">
        <p className="font-medium text-base mb-4">
          The W-8BEN and W-8BEN-E forms are used by foreign individuals and entities to certify their non-U.S. status to
          the Internal Revenue Service (IRS) for various tax purposes.
        </p>

        <div className="mb-4">
          <p className="text-sm text-gray-700 mb-2">
            1. <b>W-8BEN Form</b>: This form is used by <b>non-U.S. individuals</b> (non-resident aliens) who receive
            income from U.S. sources, such as investments or business activities, and want to claim a reduced or exempt
            rate of withholding tax based on a tax treaty between their country of residence and the United States. It
            provides the necessary information for the payor to determine the appropriate withholding tax rate.
          </p>

          <p className="text-sm text-gray-700">
            2. <b>W-8BEN-E Form</b>: This form is used by <b>foreign entities</b>, such as corporations, partnerships,
            or foreign governments, that receive income from U.S. sources and want to claim treaty benefits to reduce or
            exempt their withholding tax. It is more comprehensive than the W-8BEN form and requires detailed
            information about the entity's structure, ownership, and operations.
          </p>
        </div>

        <p className="text-xs text-gray-700">
          Both forms require the foreign individual or entity to provide their identification details, address, country
          of residence, and tax identification number from their home country. These forms help the IRS track and
          regulate the tax obligations of non-U.S. individuals and entities receiving income from U.S. sources.
        </p>
      </div>
    )
  }

  const renderData = () => {
    if (!data || !data.length) return null
    const tableData = data.map((item, index) => [
      index + 1,
      TaxCertFormNames[item.type],
      // TaxCertStatusText[item.status],
      <span className={`${TaxCertStatusStyles[item.status]} px-2 py-1 rounded`}>{TaxCertStatusText[item.status]}</span>,
      formatTime(item.createdAt),
      item.status == TaxCertStatus.Rejected ? item.history[item.history.length - 1].reason : '',
    ])

    const isLastUnderReview = data[data.length - 1].status == TaxCertStatus.UnderReview

    return (
      <div className="mb-6">
        <PlainTable
          tdClass="text-sm px-2 py-2"
          header={['No', 'Type', 'Status', 'Created At', 'Reason']}
          classNames={Array(10)
            .fill(0)
            .map(() => 'px-2 py-3')}
          data={tableData}
        />
        {isLastUnderReview && (
          <p className="inline-block text-sm p-2 rounded-md border border-gray-400 text-gray-500 bg-gray-50">
            Your form has been successfully submitted. We will review it within 1-2 business days and notify you of the
            result.
          </p>
        )}
      </div>
    )
  }

  return (
    <div>
      {renderDescription()}
      {renderData()}

      {isNeedSign && (
        <div className="max-w-[16rem] mx-auto">
          <Button full className="font-medium py-3.5 px-6" onClick={onSign} loading={isLoading}>
            Submit Form
          </Button>
        </div>
      )}

      {isApproved && (
        <div className="justify-center text-center">
          <p className="inline-block font-medium py-3.5 px-6 border border-green-400 text-green-500 rounded-lg mx-auto">
            Congratulations. Passed.
          </p>
        </div>
      )}
    </div>
  )
}
