import {
  AboutUsPage,
  BlogItem,
  Blogs,
  FaqPage,
  HowItWorksPage,
  PrivacyPolicyPage,
  TermsOfServicePage,
} from 'pages/Base'
import { ChildServiceArticle } from 'pages/Base/ChildServiceArticle'
import { ParentServiceArticle } from 'pages/Base/ParentServiceArticle'
import { HomePage } from 'pages/Home'
import { MarketplacePage } from 'pages/Marketplace'
import { Navigate, Route, Routes } from 'react-router-dom'

export const BasePages = () => {
  // const { isAuthenticated } = useSelector((state: RootState) => state.auth)

  return (
    <>
      <Route path="/blogs/:page" element={<Blogs />} />
      <Route path="/blog/:slug" element={<BlogItem />} />
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/faq" element={<FaqPage />} />
      {/* {!isAuthenticated && <Route path="/lenders" element={<LendersPage />} />} */}
      <Route path="/how_it_works" element={<HowItWorksPage />} />
      <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms_of_service" element={<TermsOfServicePage />} />
      <Route path="/services/:slug" element={<ParentServiceArticle />} />
      <Route path="/services/:slug/:childslug" element={<ChildServiceArticle />} />
    </>
  )
}

export const BaseRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/home" element={<Navigate replace to="/" />} />
    <Route path="/opportunities" element={<MarketplacePage />} />
    {BasePages()}
    <Route path="*" element={<Navigate replace to="/" />} />
  </Routes>
)
