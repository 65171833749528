import { useState } from 'react'
import { Checkbox, Modal } from 'stories/components'

import { type ApproveFailedResult } from '../type'

export const FailedResultModal = ({
  failedResults,
  onClose,
  onSubmit,
}: {
  onClose: () => void
  onSubmit: (selectedIds: number[]) => void
  failedResults: ApproveFailedResult[]
}) => {
  const [selectedDistributions, setSelectedDistributions] = useState<number[]>([])

  // Handle select all checkbox change
  const handleSelectAllChange = (value: boolean) => {
    if (!value) {
      setSelectedDistributions([])
    } else {
      setSelectedDistributions(
        failedResults.map((result) => {
          return result.id
        }),
      )
    }
  }

  const handleCheckboxChange = (id: number) => {
    setSelectedDistributions((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((rowId) => rowId !== id) : [...prevSelected, id],
    )
  }

  const renderHistory = () => {
    const header = ['No', 'Distribution ID', 'Name', 'Reason']
    return (
      <div className="-mb-6">
        <div className={`overflow-auto mb-6 shadow-md rounded`}>
          <table className={`w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6`}>
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className={`${'px-6 py-3'}`}>
                  <Checkbox
                    id="allApprove"
                    title="All"
                    value={selectedDistributions.length === failedResults.length}
                    onChange={(value) => handleSelectAllChange(value)}
                  />
                </th>
                {header.map((item, index) => (
                  <th scope="col" className={`${'px-6 py-3'}`} key={index}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-[14.5px] text-gray-900">
              {failedResults.map((row, index) => (
                <tr key={index} className={`${index % 2 ? 'bg-gray-50' : ''}`}>
                  <td className="pl-6">
                    <Checkbox
                      id={row.id.toString()}
                      value={selectedDistributions.includes(row.id)}
                      onChange={() => handleCheckboxChange(row.id)}
                    />
                  </td>

                  <td className={`px-6 py-4 whitespace-nowrap ${'font-medium text-gray-900 dark:text-white'}`}>
                    {index + 1}
                  </td>
                  <td className={`px-6 py-4 whitespace-nowrap`}>{row.id}</td>
                  <td className={`px-6 py-4 whitespace-nowrap`}>{row.name}</td>
                  <td className={`px-6 py-4 whitespace-nowrap`}>
                    <pre>{row.message}</pre>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const title = `Failed Distributions`

  return (
    <Modal title={title} titleOkay="Override" onClose={onClose} onOk={() => onSubmit(selectedDistributions)} isOpen>
      <div className="max-w-screen-xl">
        <div className="w-full">{renderHistory()}</div>
      </div>
    </Modal>
  )
}
