import type { DbPlaidAccount } from 'pages/Payment'
import { AccountsTab } from 'pages/Payment/Accounts'
import { useEffect, useState } from 'react'
import { getPlaidAccounts } from 'services/apis'

export const AccountsSection = (props: { setLoading: Function; showVerificationProgress: Function }) => {
  const [accounts, setAccounts] = useState<DbPlaidAccount[]>([])

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    props.setLoading(true)
    getPlaidAccounts()
      .then((data) => setAccounts(data.filter((account: DbPlaidAccount) => !account.deleted)))
      .finally(() => props.setLoading(false))
  }

  const updateAccounts = () => {
    return getPlaidAccounts().then((data) => setAccounts(data))
  }

  return (
    <>
      <p className="font-bold text-lg mb-4">My accounts</p>
      <AccountsTab
        updateAccounts={updateAccounts}
        accounts={accounts}
        showVerificationProgress={() => props.showVerificationProgress(true)}
      />
    </>
  )
}
