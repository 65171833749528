import { bgAuthThank } from 'assets/auth'
import { AccountType } from 'config'

export const FinalSection = ({
  data,
  accountType,
  onSubmit,
}: {
  data: Record<string, any>
  accountType: AccountType
  onSubmit: Function
}) => {
  return (
    <div className="max-w-3xl mx-auto px-8 md:px-0">
      <p className="text-center text-xl font-medium mb-8">Thank you for signing up!</p>

      <div className="relative rounded-2xl p-8 xs:px-14 xs:py-10 mb-4 overflow-hidden">
        <img src={bgAuthThank} className="absolute w-full left-0 right-0 bottom-0 top-0 h-full object-cover" />

        <p className="text-white relative">
          Dear <span className="font-semibold">{data.firstName}</span>,
          <br />
          <br />
          At Finresi, our focus is on simplifying real estate investment through high-quality real estate loans. Our
          experienced team meticulously sources and vets these individual loan opportunities to ensure their
          credibility.
          <br />
          <br />
          Through our platform, you gain direct access to a curated selection of real estate loans handpicked by
          industry experts. Our technology ensures transparency with comprehensive reporting at the asset level.
          Additionally, our unique secondary market offers potential liquidity options, making your loan investments
          more flexible.
          <br />
          <br />
          We're confident that your experience with Finresi will be rewarding. If you ever need guidance or have
          questions, our team is just a phone call away. Your trust means everything to us, and we're excited to welcome
          you to the Finresi community.
          <br />
          <br />
          Sincerely,
          <br />
          <br />
          <span className="font-sig text-4xl">Jeff Gopshtein</span>
          <br />
          <p className="font-semibold mt-4 mb-1">Jeff Gopshtein</p>
          <p>CEO</p>
        </p>
      </div>

      <div className="mb-8" />

      <div className="flex justify-center">
        <p
          className="block px-6 py-3 text-center bg-indigo-500 rounded-full text-white hover:bg-white hover:text-gray-600 font-semibold cursor-pointer"
          onClick={() => onSubmit()}
        >
          {accountType == AccountType.Company ? `Continue Account Setup` : `Browse Deals`}
        </p>
      </div>
    </div>
  )
}
