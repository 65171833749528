import { BellAlertIcon, CogIcon, PresentationChartBarIcon, Square3Stack3DIcon } from '@heroicons/react/24/outline'
import { useEffect, useMemo, useState } from 'react'
import { SideMenu } from 'stories/components'

import { BroadcastPage } from './Broadcast'
import { Resources } from './Resources'
import { SettingsPage } from './Settings'
import { StatisticsPage } from './Statistics'

const sideMenus = [
  {
    icon: BellAlertIcon,
    title: 'Broadcast',
  },
  {
    icon: CogIcon,
    title: 'Settings',
  },
  {
    icon: PresentationChartBarIcon,
    title: 'Statistics',
  },
  {
    icon: Square3Stack3DIcon,
    title: 'Resources',
  },
]

export const AdminToolPage = () => {
  const [activeMenu, setMenu] = useState('')

  useEffect(() => {
    if (!location.hash) {
      setMenu(sideMenus[0].title)
      return
    }

    const menu = location.hash.replaceAll('#', '').replaceAll('%20', ' ')

    let menus = sideMenus.map((v) => v.title)
    if (menus.includes(menu)) setMenu(menu)
  }, [location.hash])

  const renderContent = useMemo(() => {
    const pageMap = {
      [sideMenus[0].title]: <BroadcastPage />,
      [sideMenus[1].title]: <SettingsPage />,
      [sideMenus[2].title]: <StatisticsPage />,
      [sideMenus[3].title]: <Resources />,
    }
    return pageMap[activeMenu]
  }, [activeMenu])

  const renderMenu = useMemo(() => {
    if (!activeMenu) return <></>

    return <SideMenu size="base" defaultMenu={activeMenu} menus={sideMenus} onClick={(menu: string) => setMenu(menu)} />
  }, [activeMenu])

  return (
    <div className="fullContent pt-16">
      <p className="text-4xl font-semibold mb-10">Admin Tools</p>

      <div className="lg:grid flex flex-col lg:grid-cols-4 gap-6">
        <div>{renderMenu}</div>
        <div className="col-span-3 p-6 bg-white rounded-lg flex flex-col gap-4 border">
          <p className="text-lg font-semibold">{activeMenu}</p>
          {renderContent}
        </div>
      </div>
    </div>
  )
}
