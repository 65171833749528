import { BaseFile, isAppDev, s3BucketUrl, TimeZone } from 'config'
import { default as humanFormat } from 'human-format'
import { parsePhoneNumber } from 'libphonenumber-js'
import { isArray, isObject } from 'lodash'
import moment from 'moment-timezone'
import { IServiceData } from 'pages/ServiceArticle/types'

import { thisTime } from './time'
// import type { BusinessHour } from 'pages/SecondaryMarketing/BusinessHours'

const addressParser = require('parse-address')

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const isOnlyDateString = (v: Date | string | number) => typeof v == 'string' && v.length == 10

export const formatTime = (time: Date | string | number = 'now', format: string = 'MMM D, YYYY h:mm A') => {
  let rlt = ''
  if (time === 'now') rlt = moment().tz(TimeZone).format(format)
  else rlt = moment(time).tz(TimeZone, isOnlyDateString(time)).format(format) // .tz(TimeZone)
  if (rlt === 'Invalid date') {
    if (typeof time === 'string' && time.length > 0) {
      rlt = moment(Number(time)).tz(TimeZone).format(format) // .tz(TimeZone)
    }
    if (rlt === 'Invalid date') rlt = ''
  }
  return rlt
}

// MM/DD/YYYY
export const formatDateMDY = (time: Date | string | number = 'now', dateFormat = 'MM/DD/YYYY') => {
  return formatDate(time, dateFormat)
}
export const formatDate = (time: Date | string | number = 'now', dateFormat = 'MMM D, YYYY') => {
  if (!time) return ''
  if (typeof time == 'number' || (!isNaN(Number(time)) && Number(time) > Math.pow(10, 10)))
    time = new Date(Number(time))

  let rlt = ''
  if (time === 'now') rlt = moment().tz(TimeZone).format(dateFormat)
  else rlt = moment(time).tz(TimeZone, isOnlyDateString(time)).format(dateFormat) // .tz(TimeZone)
  if (rlt === 'Invalid date') {
    if (typeof time === 'string' && time.length > 0) {
      rlt = moment(Number(time)).tz(TimeZone).format(dateFormat) // .tz(TimeZone)
    }
    if (rlt === 'Invalid date') rlt = ''
  }
  return rlt
}

export const formatDateYMD = (time: Date | string | number = 'now') => {
  return formatDate(time, 'YYYY-MM-DD')
}

export const formatDateLong = (time: Date | string | number = 'now') => {
  return formatDate(time, 'MMM D, YYYY')
}

export const formatTimeLong = (time: Date | string | number = 'now') => {
  return formatDate(time, 'MMM D, YYYY h:mm A')
}

// Return "true" if "Today" <= "date"
export const isBeforeOrEqualToday = (date: Date | string | number) => {
  return thisTime().isBefore(moment(new Date(date)).add(1, 'days').tz(TimeZone))
}

export const getEarliestDate = (dataArray: { startDate?: Date }[]): Date | null => {
  const dates = dataArray
    .filter((item) => item.startDate) // Ensure startDate exists
    .map((item) => {
      // Convert valid startDate to Date object
      const date = typeof item.startDate === 'string' ? new Date(item.startDate) : item.startDate
      return date instanceof Date && !isNaN(date.getTime()) ? date : null
    })
    .filter((date): date is Date => date !== null) // Filter out invalid dates

  if (dates.length === 0) {
    return null // Return null if no valid dates are found
  }

  return new Date(Math.min(...dates.map((date) => date.getTime())))
}

export const getLatestDate = (dataArray: { startDate?: Date }[]): Date | null => {
  const dates = dataArray
    .filter((item) => item.startDate) // Ensure startDate exists
    .map((item) => {
      // Convert valid startDate to Date object
      const date = typeof item.startDate === 'string' ? new Date(item.startDate) : item.startDate
      return date instanceof Date && !isNaN(date.getTime()) ? date : null
    })
    .filter((date): date is Date => date !== null) // Filter out invalid dates

  if (dates.length === 0) {
    return null // Return null if no valid dates are found
  }

  return new Date(Math.max(...dates.map((date) => date.getTime())))
}

// export const isInBusinessHour = (businessHour: BusinessHour) => {
//   if (businessHour.isHoliday) return false

//   const currentTime = moment(new Date()).tz(TimeZone)
//   const weekDay = currentTime.weekday()
//   if (weekDay < businessHour.startDay || weekDay > businessHour.endDay) return false

//   const offset = currentTime.utcOffset() / 60
//   const offsetStr = `${offset > 0 ? '+' : '-'}0${Math.abs(offset)}`
//   const date = formatDateYMD()

//   const startTimeDate = new Date(`${date} ${businessHour.startTime}:00 ${offsetStr}00`)
//   const endTimeDate = new Date(`${date} ${businessHour.endTime}:00 ${offsetStr}00`)
//   const startTime = moment(startTimeDate).tz(TimeZone)
//   const endTime = moment(endTimeDate).tz(TimeZone)

//   return currentTime.isBetween(startTime, endTime)
// }

export const getDaysDiff = (date1: any, date2: any) => {
  return moment(date1).tz(TimeZone).diff(moment(date2).tz(TimeZone), 'days')
}

const removePrefix0 = (val: string) => {
  let rlt = ''
  if (val === '0') return '0'
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] >= '0' && val[i] <= '9') {
      if (rlt.length === 0 && val[i] === '0') {
      } else rlt += val[i]
    }
  }
  return rlt
}

// const removeSuffix0 = (val: string) => {
//   if (val === '0') return '0'
//   const decimalPos = val.indexOf('.')
//   if (decimalPos === -1) return val

//   let index = val.length - 1
//   while (index > 0) {
//     if (val[index] === '0') index -= 1
//     else break
//   }
//   if (val[index] === '.') index -= 1
//   return val.substring(0, index + 1)
// }

export const getOnlyNumber = (val: string) => {
  try {
    let rlt = ''
    let data = val.toString()
    for (let i = 0; i < data.length; i += 1) {
      if (data[i] >= '0' && data[i] <= '9') rlt += data[i]
    }
    return rlt
  } catch {
    return ''
  }
}

export const humanize = (val: number) => {
  return humanFormat(val)
}

export const normalizeNumber = (value: number | null, decimalCount = 0) => {
  if (value === null) return 0
  if (decimalCount == 0) return thousandSeperator(Math.floor(Number(value)))
  else {
    const pow = Math.pow(10, decimalCount)
    value = Math.round(Number(value * pow)) / pow
    return thousandSeperator(Number(value).toFixed(decimalCount))
  }
}

export const thousandSeperator = (param: any, withoutDecimal = false, decimalCount = 2) => {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value: any = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      value = value.split('')
      value.splice(caret + 1, 0, ',')
      value = value.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, decimalCount)
      value += '.' + decimal
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const phoneConvertor = (val: any) => {
  if (!val) return ''
  val = String(val).replace(/[^+\d\s]/g, '')

  if (val.length < 10) return val

  if (!val.startsWith('+')) val = `+${val}`
  try {
    const parsedNum = parsePhoneNumber(val, 'US')
    if (parsedNum.isValid()) return parsedNum.formatInternational()

    if (val.replace(/[^\d]/g, '').length > 11) {
      const oldParsedNum = parsePhoneNumber(val.substring(0, val.length - 1), 'US')
      if (oldParsedNum.isValid()) return oldParsedNum.formatInternational()
    }

    return parsedNum.formatInternational().substring(0, 18)
  } catch (e) {
    return val
  }
}

export const creditScoreConvertor = (val: any) => {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 10) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length > 3) {
      rlt = rlt.substring(0, 3)
    }
    return rlt
  } catch {
    return ''
  }
}

export function ssnConvertor(val: any) {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 9) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 9) {
      rlt = `${rlt.substring(0, 3)}-${rlt.substring(3, 5)}-${rlt.substring(5, 9)}`
    }
    return rlt
  } catch {
    return ''
  }
}

export function einConvertor(val: any) {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 9) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 9) {
      rlt = `${rlt.substring(0, 2)}-${rlt.substring(2, 9)}`
    }
    return rlt
  } catch {
    return ''
  }
}

function entityTaxIDConvertor(val: any) {
  try {
    let rlt = ''
    val = val.toString()
    for (let i = 0; i < val.length; i += 1) {
      if (rlt.length < 9) {
        if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      }
    }
    if (rlt.length === 9) {
      rlt = `${rlt.substring(0, 2)}-${rlt.substring(2, 9)}`
    }
    return rlt
  } catch {
    return ''
  }
}

function countDecimal(param: number | string, decimalStep: number) {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    if (decimal !== undefined) {
      decimal = getOnlyNumber(decimal).substring(0, decimalStep)
      value += '.' + decimal
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const InputConvert = function (data: any, value: any) {
  try {
    if (data.type === 'phone') {
      value = phoneConvertor(value)
    }
    if (data.type === 'thousandSep') {
      value = thousandSeperator(value)
    }
    if (data.type === 'thousandSepNoDecimal') {
      value = thousandSeperator(value, true)
    }
    if (data.type === 'ssn') {
      value = ssnConvertor(value)
    }
    if (data.type === 'ein') {
      value = einConvertor(value)
    }
    // if (data.length) {
    //   value = `${value}`.substring(0, data.length)
    // }
    if (data.maxLength) {
      value = `${value}`.substring(0, data.maxLength)
    }
    if (data.type === 'number' && data.decimalStep) {
      value = countDecimal(value, data.decimalStep)
    }
    if (data.type === 'creditScore') {
      value = creditScoreConvertor(value)
    }
    if (data.type === 'entityTaxID') {
      value = entityTaxIDConvertor(value)
    }
    if (data.type === 'email') {
      value = value.toLowerCase()
    }
    if (data.type === 'date') {
      value = formatDateYMD(value)
    }
    if (data.inputType === 'text' || data.inputType === 'text0') {
      let { title = '', placeholder = '' } = data
      title = title.replace(/\s/g, '').toLowerCase()
      placeholder = placeholder.replace(/\s/g, '').toLowerCase()
      const nameTitles = ['firstname', 'middlename', 'lastname', 'fullname']
      for (const nameTitle of nameTitles) {
        if (title.includes(nameTitle) || placeholder.includes(nameTitle)) {
          value = capitalize(value)
          break
        }
      }
    }
  } catch {}
  return value
}

export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())

export const sortByKey = (arr: Array<any>, key: string, sortOrder: 1 | -1 = 1) => {
  return arr.sort((a: any, b: any) => {
    const valueA = (a[key] || '').toString().toUpperCase() // ignore upper and lowercase
    const valueB = (b[key] || '').toString().toUpperCase() // ignore upper and lowercase
    if (valueA < valueB) return -sortOrder
    if (valueA > valueB) return sortOrder

    return 0
  })
}

export function getPrice3decimal(param: string | number, withoutDecimal = false) {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      const values = value.split('')
      values.splice(caret + 1, 0, ',')
      value = values.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, 3)
      value += '.' + decimal
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const removeComma = function (val: number | string) {
  try {
    val = val.toString()
    let sign = val[0]
    let rlt = ''
    for (let i = 0; i < val.length; i += 1) {
      if (val[i] >= '0' && val[i] <= '9') rlt += val[i]
      if (val[i] === '.') rlt += val[i]
    }
    let nRlt = Number(rlt)
    if (sign === '-') nRlt *= -1
    return nRlt
  } catch {
    return 0
  }
}

export function getPrice2decimal(param: number | string, withoutDecimal = false, mustdecimal = false) {
  try {
    param = param.toString()
    let sign = param[0]
    let no_decimal = removePrefix0(getOnlyNumber(param.split('.')[0]))
    let decimal = param.split('.')[1]
    let value = no_decimal.replace(/,/g, '')
    let caret = value.length - 1
    while (caret - 3 > -1) {
      caret -= 3
      const values = value.split('')
      values.splice(caret + 1, 0, ',')
      value = values.join('')
    }
    if (decimal !== undefined && !withoutDecimal) {
      decimal = getOnlyNumber(decimal).substring(0, 2)
      if (decimal.length === 1) decimal += '0'
      if (decimal !== '00' || mustdecimal) value += '.' + decimal
    }
    if (decimal === undefined && mustdecimal) {
      value += '.00'
    }
    if (sign === '-') value = '-' + value
    return value
  } catch {
    return ''
  }
}

export const fileTobase64 = function (file: File, withExtention = false) {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader()

    reader.onload = async () => {
      resolve({
        name: withExtention ? file.name : file.name.replace('.pdf', ''),
        base64: reader.result,
      })
    }

    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

export const blobToBase64 = function (blob: any) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const base64ToBlob = function (base64str: string) {
  return new Promise(async (resolve, reject) => {
    try {
      base64str = base64str.split('base64,')[1]
      // decode base64 string, remove space for IE compatibility
      var binary = atob(base64str.replace(/\s/g, ''))
      var len = binary.length
      var buffer = new ArrayBuffer(len)
      var view = new Uint8Array(buffer)
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i)
      }

      // create the blob object with content-type "application/pdf"
      var blob = new Blob([view], { type: 'application/pdf' })

      resolve(blob)
    } catch (error) {
      reject(error)
    }
  })
}

export function getItemsFromFullAddress(fullAddress: string): {
  number: string
  prefix: string
  street: string
  type: string
  city: string
  state: string
  zip: string
} {
  return addressParser.parseLocation(fullAddress) || {}
}

export function getSimplifiedAddress(fullAddress: string) {
  const addressObj = getItemsFromFullAddress(fullAddress)
  return `${addressObj.city}, ${addressObj.state}`
}

export function validateGoogleAddress(fullAddress: string): Promise<any> {
  return new Promise(async (resolve) => {
    let rlt = {
      street_address1: '',
      city: '',
      state: '',
      postal_code: '',
    }
    try {
      const res = getItemsFromFullAddress(fullAddress) as Record<string, any>
      Object.keys(rlt).map((key) => {
        if (res[key] !== undefined && res[key] !== null) (rlt as any)[key] = res[key]
        return true
      })
      resolve(rlt)
    } catch {
      resolve(rlt)
    }
  })
}

export function borrowerFullName(borrower: Record<string, string>) {
  return [borrower.firstName, borrower.middleName, borrower.lastName].join(' ').trim().replace('  ', ' ')
}

export function firstName(fullName: string) {
  return fullName.split(' ').slice(0, -1).join(' ')
}

export function lastName(fullName: string) {
  return fullName.split(' ').slice(-1).join(' ')
}

export const dayToMSec = (days: number) => {
  return days * 24 * 60 * 60 * 1000
}

export const getUniqueFileName = (fileName: string, fileNames: string[]) => {
  if (!fileNames.includes(fileName)) return fileName

  let newFilename = fileName
  if (!fileName.match(/ \((\d){1,}\)./)) newFilename = fileName.replace('.', ' (1).')

  let index = 1
  while (true) {
    newFilename = newFilename.replace(/ \((\d){1,}\)./, ` (${index}).`)
    if (!fileNames.includes(newFilename)) return newFilename
    index += 1
    if (index >= 1000) break
  }

  return fileName.replace('.', ` (${Date.now()}).`)
}

export const solveDecimalJavascriptSum = (list: Array<number>, positions: number = 3) => {
  const factor = Math.pow(10, positions)
  let rlt = 0
  list.map((item) => {
    rlt += Number(item.toFixed(positions)) * factor
  })
  return rlt / factor
}

export const filterObject = (values: Record<string, boolean>) => {
  return Object.keys(values).filter((key) => values[key])
}

export const serialize = (obj: Record<string, any>, prefix = ''): string => {
  var str = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]
      str.push(
        v !== null && typeof v === 'object' ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      )
    }
  }
  return str.join('&')
}

export const getFileName = (fileKey: string) => {
  return fileKey.replace(/^.*[\\\/]/, '')
}

export const fileJsonConvert = (data: Record<string, string | string[] | File>) => {
  const files: File[] = []
  Object.keys(data).forEach((key) => {
    if (data[key] && (data[key] as any).size) {
      files.push(data[key] as any)
      delete data[key]
    }
  })
  return { data, files }
}

export const toS3Link = (fileKey: string | BaseFile[] | BaseFile | null, defaultValue: string = '') => {
  if (isArray(fileKey)) fileKey = fileKey[0] || null
  if (fileKey && isObject(fileKey)) fileKey = fileKey.fileKey
  if (!fileKey) return defaultValue
  return `${s3BucketUrl}/${fileKey}`
}

export const getEndDate = (date: string | Date, months: number) => {
  const m = moment(date)
  return m.add(months, 'months').toDate()
}

// date1 - date2
export const dateDiffDays = (date1: string | Date | null, date2: string | Date | null = null) => {
  if (!date1) return 0
  const m1 = moment(date1).tz(TimeZone, isOnlyDateString(date1)).hour(0).minute(0).second(0).millisecond(0)
  const m2 = date2
    ? moment(date2).tz(TimeZone, isOnlyDateString(date2)).hour(0).minute(0).second(0).millisecond(0)
    : moment().tz(TimeZone).hour(0).minute(0).second(0).millisecond(0)

  return m1.diff(m2, 'days')
}

export const addDays = (date: Date, days = 1) => {
  return moment(date).tz(TimeZone).add(days, 'days').toDate()
}

export const dateDiffHumanize = (date1: string | Date | null, date2: string | Date | null = null) => {
  if (!date1) return null
  const days = dateDiffDays(date1, date2)
  return moment.duration(days, 'days').humanize()
}

export const hasPasswordError = (value: string) => {
  const reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/
  if (!reg.test(value))
    return 'Password should include up to 1 number, 1 capital and 1 special character. (6 ~ 16 length)'
  return false
}

export const monthlyProfit = (amount: number, ytm: number, months = 1) => {
  return Number(((Number(amount) * Number(ytm)) / 100 / 12).toFixed(2)) * Math.floor(Number(months))
}

export const dayProfit = (amount: number, ytm: number, days = 1) => {
  return Number(((monthlyProfit(amount, ytm) / 30) * Math.abs(days)).toFixed(2))
}

export const renderTable = (lines: string[][]) => {
  const headerCls =
    'border-bottom: 1px solid #f2f2f2; font-size: 13px; color: #333; line-height: 1.4; text-align: left; padding-left: 10px; text-transform: uppercase; padding: 5px; '
  const tdCls =
    ' border-bottom: 1px solid #f2f2f2; text-align: left; font-size: 13px; padding-left: 10px; padding: 5px; color: #444; '

  return `<table width="100%">
    <thead style="background-color: #eee">
    <tr>${lines[0].map((v) => `<th style="${headerCls}" >${v}</th>`).join('')}</tr>
    </thead>
    <tbody>
    ${lines
      .map((line, index) => {
        if (index == 0) return ''
        if (line[0] == 'PlaidId')
          return `<tr>
            <td style="${tdCls}" >Link</td>
            <td style="${tdCls}" ><a href="${plaidIdentityLink(
            line[1],
          )}" class="text-link" target="_blank">Open Plaid</a></td></tr>`
        return `<tr>${line.map((v) => `<td style="${tdCls}" >${v}</td>`).join('')}</tr>`
      })
      .join('')}</tbody></table>`
}

export const swapObject = (data: Record<string, string>) => {
  const ret: Record<string, string> = {}
  for (var key in data) {
    ret[data[key]] = key
  }
  return ret
}

export const flagLink = (code: string) => `https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`

export const plaidIdentityLink = (id: string) =>
  `https://dashboard.plaid.com${isAppDev ? '/sandbox' : ''}/identity_verification/sessions/flwses_${id.replace(
    'idv_',
    '',
  )}`

export const numberArrayToString = (numArr: number[]) => {
  if (numArr.length > 0) return numArr.join(',')

  return ''
}

export const sortedArray = (data: IServiceData[]) => {
  return data.sort((a, b) => {
    if (a.orderNum === b.orderNum) {
      return a.id - b.id // Sort by id if orderNum is the same
    }
    return a.orderNum - b.orderNum // Otherwise, sort by orderNum
  })
}

export const slugify = (title: string) => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}
export function deslugify(slug: string): string {
  // Assuming your slugification process replaces spaces with hyphens and lowercases everything
  return slug.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
}
