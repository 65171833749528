import { PasswordValidation, usePasswordValid } from 'components'
import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { checkPassport, resetPassword } from 'services/apis'
import { Button } from 'stories/components'
import { RenderInput, useTitle } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    password: {
      title: 'New Password',
      inputType: 'text0',
      type: 'password',
      error: '',
      hasIcon: true,
      required: true,
      autoFocus: true,
    },
    confirm: {
      title: 'Confirm Password',
      inputType: 'text0',
      type: 'password',
      error: '',
      hasIcon: true,
      required: true,
    },
  }
}

export function ResetPasswordPage() {
  useTitle('Reset Password')

  const [isLoading, setLoading] = useState(false)
  const [inputStates, setInputStates] = useState(defaultInputs)
  const urlParams: any = useParams()
  const { selector, token } = urlParams

  const isValidPassword = usePasswordValid({
    firstPassword: inputStates.password.value,
    secondPassword: inputStates.confirm.value,
  })

  const auth = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.isAuthenticated || !selector || !token) {
      navigate('/auth/signIn')
      return
    }
    setLoading(true)
    checkPassport(selector, token)
      .then(() => {
        setLoading(false)
      })
      .catch(() => navigate('/auth/signIn'))
  }, [])

  const onSubmit = async () => {
    if (!selector || !token) return

    setLoading(true)
    resetPassword(selector, token, inputStates.password.value)
      .then(() => {
        toast('Password has been reset.', { type: 'info' })
        navigate('/auth/signIn')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputStates)

    newState[key].error = ''
    newState[key].value = value
    setInputStates(newState)
  }

  return (
    <div className="max-w-md px-4 py-6 md:px-10 md:py-10 bg-white rounded-xl">
      <div className="p-1">
        {Object.keys(inputStates).map((key, index) => {
          let input = inputStates[key]

          return (
            <div key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}

        <PasswordValidation
          firstPassword={inputStates.password.value}
          secondPassword={inputStates.confirm.value || ''}
        />

        <div className="my-7"></div>

        <Button
          className="mt-1"
          disabled={!isValidPassword}
          color="indigo"
          full
          bold
          onClick={onSubmit}
          loading={isLoading}
        >
          Reset
        </Button>

        <Link to="/auth/signIn">
          <p className="block mt-3 hover:text-gray-900 hover:underline text-gray-500">Back to Login</p>
        </Link>
      </div>
    </div>
  )
}
