import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import type { User } from 'pages/User'
import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { createManualTransaction, getTransactionById } from 'services/apis'
import { Modal } from 'stories/components'
import { InputConvert, InputValidate, normalizeNumber, removeComma, RenderInput } from 'utils'

import type { DbTransaction, ManualTransactionData } from '../constants'

const defaultInputs = (): Record<string, InputType> => ({
  direction: {
    inputType: 'radio',
    title: 'Direction',
    options: {
      [TransferIntentCreateMode.Payment]: 'Credit', // Deposit
      [TransferIntentCreateMode.Disbursement]: 'Debit', // Withdraw
    },
    required: true,
  },
  amount: {
    inputType: 'text',
    title: 'Amount',
    type: 'thousandSepNoDecimal',
    prefix: '$',
    required: true,
  },
  note: {
    inputType: 'textarea',
    isLegacy: false,
    title: 'Note (For User)',
    required: true,
  },
  comment: {
    inputType: 'textarea',
    isLegacy: false,
    title: 'Internal Comment',
    required: false,
  },
})

export const MannualTransaction = ({ id = 0, user, onClose }: { id?: number; user: User; onClose: Function }) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())
  const [data, setData] = useState<DbTransaction | null>(null)

  const renderData = useMemo(() => {
    const _data: Record<string, string> = {
      name: user.name,
      email: user.email,
    }
    if (data) {
      _data.direction = data.direction != TransferIntentCreateMode.Payment ? 'Debit' : 'Credit'
      _data.amount = `$${normalizeNumber(data.amount)}`
      _data.createdBy = data.events[0].actor
      _data.note = (data.data as ManualTransactionData).note
      _data.comment = data.comment
    }
    if ((user as any).available) _data['Current Balance'] = `$${normalizeNumber((user as any).available, 2)}`
    return _data
  }, [data])

  useEffect(() => {
    if (id == 0) return
    setLoading(true)
    getTransactionById(id)
      .then((data: DbTransaction) => setData(data))
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    let hasError = false

    let newInputs = cloneDeep(inputs)
    const data: Record<string, any> = {}
    for (const key in inputs) {
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    }
    setInputs(newInputs)
    if (hasError) return

    setLoading(true)
    data.userId = (user as any).userId ? (user as any).userId : user.id
    data.amount = Number(removeComma(data.amount))

    createManualTransaction(data)
      .then(() => onClose(true))
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      isOpen
      title={id == 0 ? 'New Manual Transaction' : `Manual Transaction #${id}`}
      titleOkay={id == 0 ? 'Submit' : ''}
      onOk={onSubmit}
      onClose={() => onClose(false)}
      loading={isLoading}
    >
      <div className={`text-gray-600 text-md md:w-128`}>
        <div className="mb-2">
          {Object.keys(renderData).map((title) => (
            <div
              className={`mb-2 flex gap-x-4 gap-y-2 justify-between items-start ${
                ['note', 'comment'].includes(title) ? 'flex-col' : ''
              }`}
              key={title}
            >
              <p className="text-desc capitalize">{title}</p>
              <div className="text-sm">{renderData[title]}</div>
            </div>
          ))}
        </div>

        {id == 0 && (
          <div className="flex flex-col gap-2 border-t mb-2">
            {Object.keys(inputs).map((key, index) => {
              let input = inputs[key]
              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Modal>
  )
}
