import cloneDeep from 'clone-deep'
import { AccountProfile, InputType } from 'config'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { activatePhone, validatePhone } from 'services/apis'
import { Button, Modal } from 'stories/components'
import { InputConvert, RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    code: {
      title: '',
      placeholder: 'Enter code here',
      inputType: 'text0',
      type: 'number',
      maxLength: 6,
      error: '',
      required: true,
      autoFocus: true,
      className: '!bg-white',
    },
  }
}

export const PhoneVerification = ({
  data,
  onSuccess,
  isOpen,
  onClose,
}: {
  data: AccountProfile
  isOpen: boolean
  onSuccess: () => void
  onClose: () => void
}) => {
  const [limit, setLimit] = useState(3)
  const [inputStates, setInputStates] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputStates(newState)
  }

  const onSendAgain = async () => {
    setLoading(true)
    try {
      await validatePhone({ email: data.email, phone: data.phone, checkDuplication: false })
      toast('Code is sent to your phone.', { type: 'info' })
    } catch (e) {}
    setLimit(limit - 1)
    setLoading(false)
  }

  const onActivate = async () => {
    setLoading(true)
    try {
      await activatePhone({ phone: data.phone, code: inputStates['code'].value })
      onSuccess()
    } catch (e) {}

    setLoading(false)
  }

  return (
    <Modal title="Verify Your Phone" isOpen={isOpen} loading={isLoading} titleCancel="" titleOkay="" onClose={onClose}>
      <div className="max-w-lg px-4 md:px-0 md:mx-auto w-full md:w-120 text-center">
        <p className="text-xl mb-4 font-medium">Please check your phone!</p>
        <p className="text-gray-600 mb-4 px-2">
          We sent a text to <span className="text-link">{data.phone}</span> with a code to activate your phone.
        </p>

        <p className="text-gray-600 mb-10">
          If you don't receive a text message within the next 3 minutes, please contact our support team at{' '}
          <span className="text-link">info@finresi.com</span>.
        </p>

        <div className="px-2">
          {RenderInput({
            input: inputStates['code'],
            Key: 'code',
            onChange,
          })}

          <div className="flex gap-2 justify-center items-center mb-2">
            <p className="text-sm text-gray-600">Didn't receive a code?</p>
            <button
              className={`${limit == 0 ? 'text-indigo-300 cursor-not-allowed' : 'text-link cursor-pointer'}`}
              disabled={limit == 0}
              onClick={() => onSendAgain()}
            >
              Send again
            </button>
          </div>

          <Button full onClick={onActivate} disabled={inputStates['code'].value?.length != 6}>
            Activate
          </Button>
        </div>
      </div>
    </Modal>
  )
}
