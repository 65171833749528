import { InvestReadyCert, InvestReadyStatus, InvestReadyStatusOptions, InvestReadyStatusStyles } from './type'

export const InvestReadyCertStatus = ({ data }: { data: InvestReadyCert }) => {
  let status = data.status

  if (status == InvestReadyStatus.Approved && new Date(data.expiresOn) < new Date()) status = InvestReadyStatus.Expired

  return (
    <span className={`${InvestReadyStatusStyles[status]} px-2 py-1 rounded`}>{InvestReadyStatusOptions[status]}</span>
  )
}
