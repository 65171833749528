// import { AuthOutFooter } from 'pages/Auth/Components'
import { useLocation } from 'react-router-dom'
import { AuthHeader, Footer } from 'stories/layouts'

export const AuthLayout = (props: any) => {
  const { children } = props
  const { pathname } = useLocation()
  const isSignUp = pathname.startsWith('/auth/signUp')

  return (
    <div className="relative bg-white">
      <div className={`relative lg:w-full h-full-screen min-h-[100vh] bg-gray-100 flex flex-col justify-between`}>
        <AuthHeader />
        <div className={`w-full relative flex ${isSignUp ? 'min-h-[85vh]' : 'min-h-[90vh]'}`}>
          {/* {isSignUp && (
            <div className="absolute w-96 top-128 left-[calc(50vw-41rem)]">
              <AuthReview />
            </div>
          )} */}

          <main className="mx-auto main py-4 relative">
            <div className="relative mb-4">{children}</div>

            {/* <AuthOutFooter /> */}
          </main>
        </div>
        <Footer />
      </div>
    </div>
  )
}
