import { isArray } from 'lodash'
import { JSXElementConstructor, useEffect, useState } from 'react'

export interface SideMenuItem {
  title: string
  body?: string | JSX.Element
  icon?: JSXElementConstructor<any> | null
  right?: JSX.Element
  mark?: boolean
  description?: boolean
}

export const SideMenu = ({
  defaultMenu = '',
  title = '',
  menus = [],
  size = 'xs',
  activeColor = '',
  onClick = () => {},
}: {
  defaultMenu?: string
  title?: string
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
  menus: Record<string, SideMenuItem> | SideMenuItem[] | string[]
  onClick?: Function
  activeColor?: string
}) => {
  const [activeMenu, setActiveMenu] = useState(defaultMenu)

  useEffect(() => {
    setActiveMenu(defaultMenu)
  }, [defaultMenu])

  let menuMaps: Record<string, SideMenuItem> = {}
  if (!isArray(menus)) menuMaps = menus
  else
    menus.forEach((menu) => {
      if (typeof menu == 'string')
        menuMaps[menu] = {
          title: menu,
          icon: null,
        }
      else menuMaps[menu.title] = menu
    })

  return (
    <div className="rounded-lg w-full">
      {title && <p className={`block text-gray-400 text-${size} p-2`}>{title}</p>}
      {Object.keys(menuMaps).map((key) => {
        const menuItem = menuMaps[key]
        const MenuIcon = menuItem.icon
        const isActive = activeMenu == key
        const isDescription = menuItem.description
        return (
          <a
            href={isDescription ? '#' : `#${key}`}
            key={key}
            className={`relative flex gap-2 w-full p-2 rounded-lg ${
              isDescription ? 'cursor-default' : activeColor ? `hover:bg-${activeColor}-100` : 'hover:bg-white'
            } text-left items-center ${isActive ? (activeColor ? `bg-${activeColor}-100` : 'bg-white') : ''}`}
            onClick={(e) => {
              if (isDescription) {
                e.preventDefault()
                return false
              }
              setActiveMenu(key)
              onClick(key)
            }}
          >
            {MenuIcon && <MenuIcon className={`${isActive ? 'text-gray-800' : 'text-gray-400'} w-6 h-6`} />}
            {menuItem.mark && <div className="absolute w-1.5 h-1.5 bg-red-500 rounded-full top-2 left-7" />}
            <p
              className={`text-${size} font-medium flex-1 ${
                isActive ? 'text-gray-800 font-semibold' : 'text-gray-400'
              }`}
            >
              {menuItem.body || menuItem.title}
            </p>
            {menuItem.right}
          </a>
        )
      })}
    </div>
  )
}
