/**
 * Tax Certificate
 */
export enum TaxCertType {
  Individual = 'individual',
  Company = 'company',
}

export enum TaxCertStatus {
  Rejected = -1,
  Pending = 0,
  UnderReview = 1,
  Approved = 2,
}

export interface TaxCertHistory {
  status: TaxCertStatus
  reason: string
  createdBy: string
  createdAt: Date
}
export interface TaxCert {
  id: number
  type: TaxCertType
  envelopeId: string
  envelopeStatus: string
  status: TaxCertStatus
  history: TaxCertHistory[]
  createdAt: Date
  updatedAt: Date
}

export const TaxCertFormNames: Record<TaxCertType, string> = {
  [TaxCertType.Individual]: 'W-8BEN',
  [TaxCertType.Company]: 'W-8BEN-E',
}

export const TaxCertStatusText: Record<TaxCertStatus, string> = {
  [TaxCertStatus.Rejected]: 'Rejected',
  [TaxCertStatus.Pending]: 'Pending',
  [TaxCertStatus.UnderReview]: 'Under Review',
  [TaxCertStatus.Approved]: 'Approved',
}

export const TaxCertStatusStyles: Record<TaxCertStatus, string> = {
  [TaxCertStatus.Rejected]: 'bg-yellow-200',
  [TaxCertStatus.Pending]: 'bg-gray-100',
  [TaxCertStatus.UnderReview]: 'bg-indigo-400 text-white',
  [TaxCertStatus.Approved]: 'bg-green-500 text-white',
}
