import type { InputType } from 'config'

import { LoanPropertyTypeOptions, LoanPurposeOptions } from './types'

export const defaultFilterInputs = (): Record<string, InputType> => {
  return {
    query: {
      inputType: 'text0',
      title: '',
      placeholder: 'Type to search',
      className: 'bg-white',
    },
    state: {
      inputType: 'select0',
      options: {},
      title: '',
      hasDefaultOption: true,
      sort: true,
      placeholder: '- State -',
      defaultOptionText: '- State -',
      className: '!bg-white',
    },
    ltv: {
      inputType: 'select0',
      title: '',
      options: {
        '0-50': 'Up to 50%',
        '50-70': '50% - 70%',
        '70-100': 'More than 70%',
      },
      hasDefaultOption: true,
      placeholder: '- LTV -',
      defaultOptionText: '- LTV -',
      className: '!bg-white',
    },
    termLeft: {
      inputType: 'select0',
      title: '',
      options: {
        '0-3': 'Up to 3 months',
        '3-6': '3 - 6 months',
        '6-9': '6 - 9 months',
        '9-12': '9 - 12 months',
        '12-999': 'More than 1 year',
      },
      hasDefaultOption: true,
      placeholder: '- Term Left -',
      defaultOptionText: '- Term Left -',
      className: '!bg-white',
    },
    propertyType: {
      inputType: 'select0',
      title: '',
      options: LoanPropertyTypeOptions,
      hasDefaultOption: true,
      placeholder: '- Property Type -',
      defaultOptionText: '- Property Type -',
      className: '!bg-white',
    },
    loanPurpose: {
      inputType: 'select0',
      title: '',
      options: LoanPurposeOptions,
      hasDefaultOption: true,
      placeholder: '- Loan Type -',
      defaultOptionText: '- Loan Type -',
      className: '!bg-white',
    },
    closed: {
      inputType: 'toggle',
      title: 'Closed',
    },
  }
}
