import { Modal } from 'stories/components'
import { PlainTable } from 'stories/components/PlainTable'
import { formatTime } from 'utils'

import { type DBProfit, ProfitStatusColorMap } from '../type'

export const ProfitStatusModal = ({ profit, onClose }: { onClose: any; profit: DBProfit }) => {
  const renderHistory = () => {
    const header = ['No', 'Status', 'By', 'Date', 'Comment', 'Override']
    const data = profit.histories.map((item, index) => [
      index + 1,
      <div className="flex gap-2 items-center justify-end lg:px-0 lg:justify-start capitalize">
        <span className={`w-2 h-2 rounded-full ${ProfitStatusColorMap[item.status]}`} />
        {item.status}
      </div>,
      item.updatedBy,
      formatTime(item.createdAt),
      <pre>{item.comment}</pre>,
      <p>{item.override && 'Override'}</p>,
    ])
    return (
      <div className="-mb-6">
        <PlainTable header={header} data={data} />
      </div>
    )
  }

  const title = `Distribution History - #${profit.id}`

  return (
    <Modal title={title} titleOkay="" onClose={onClose} isOpen>
      <div className="max-w-screen-xl">
        <div className="w-full">{renderHistory()}</div>
      </div>
    </Modal>
  )
}
