import {
  ArrowLeftIcon,
  ArrowTrendingUpIcon,
  ArrowUpRightIcon,
  BuildingOffice2Icon,
  ChartBarIcon,
  CircleStackIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  EyeIcon,
  HomeIcon,
  InformationCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { TransactionColorMap } from 'components/TransactionStatus'
import { AccountType, FciLoanNotInstructStatus, FciLoanStatus, IRADetails, isTopManager, PenaltyPercent } from 'config'
import { cloneDeep } from 'lodash'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { type DBInvestment, DepositModal, InvestStatus } from 'pages/Payment'
import { InvestDetails } from 'pages/Payment/modal'
import { isValidElement, JSXElementConstructor, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RootState } from 'reducers'
import {
  getInvestments,
  getLoan,
  getLoanFciStatus,
  openS3Document,
  signSellBackInvestment,
  updateEnvelopeStatus,
} from 'services/apis'
import { Accordion, Button, GoogleMap, ResponsiveTable, SideMenu } from 'stories/components'
import {
  confirm,
  formatDateLong,
  getSimplifiedAddress,
  isInvestableProfile,
  monthlyProfit,
  normalizeNumber,
  thousandSeperator,
  toS3Link,
  useTitle,
} from 'utils'

import { ConstructionLedger } from './ConstructionLedger'
import { FundedBy } from './FundedBy'
import { remainingLoanTerm } from './InvestCard'
import { CompleteVerificationProgress, OpenForInvestment, UnsignedInvestmentModal } from './modal'
import { type Loan, MultiFamilyPropertyTypes } from './types'

const _sideMenus: { icon: JSXElementConstructor<any>; title: string; mark?: boolean }[] = [
  {
    icon: HomeIcon,
    title: 'Investment Overview',
  },
  {
    icon: ChartBarIcon,
    title: 'Purpose & Strategy',
  },
  {
    icon: CircleStackIcon,
    title: 'Debt Stack',
  },
  {
    icon: ArrowTrendingUpIcon,
    title: 'Leverage Details',
  },
  {
    icon: CurrencyDollarIcon,
    title: 'Valuation',
  },
  {
    icon: CreditCardIcon,
    title: 'Payment Term',
  },
  {
    icon: UserIcon,
    title: 'Borrower',
  },
  {
    icon: InformationCircleIcon,
    title: 'Property Details',
  },
  {
    icon: BuildingOffice2Icon,
    title: 'Construction Ledger',
  },
  {
    icon: ClipboardDocumentListIcon,
    title: 'Property Documents',
  },
]

export const MarketplaceItem = () => {
  const profile = useSelector((state: RootState) => state.auth.profile)
  const params = useParams()
  const location = useLocation()

  const [loan, setLoan] = useState<Loan | null>(null)
  const [invests, setInvests] = useState<DBInvestment[]>([])
  const [selectedInvest, setSelectedInvest] = useState<DBInvestment | null>(null)
  const [isIRAInvest, setIRAInvest] = useState(false)
  const [isOpenForInvestment, openForInvestment] = useState(false)
  const [unsignedInvestment, setUnsignedInvestment] = useState<DBInvestment | null>(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const envelopeId = searchParams.get('envelopeId')
  const envelopeEvent = searchParams.get('event')
  const [imageIndex, setImageIndex] = useState(0)

  const [activeMenu, setActiveMenu] = useState('Investment Overview')
  const [isVerificationProgress, showVerificationProgress] = useState(false)
  const [fciLoanStatus, showFciLoanStatus] = useState<FciLoanStatus | null>(null)

  const [isDepositModal, showDepositModal] = useState(false)

  const [retradeId, setRetradeId] = useState(0)

  const [sideMenus, setSideMenus] = useState(_sideMenus)

  const refLeftMenu = useRef<any>()
  const refRightMenu = useRef<any>()

  const valuationDoc = useMemo(() => {
    return loan ? loan.documents.filter((doc) => doc.category == 'Valuation') : []
  }, [loan])

  useEffect(() => {
    setLoading(true)
    getLoanFciStatus(`${params.id}`)
      .then((fciLoanStatus) => showFciLoanStatus(fciLoanStatus))
      .finally(() => setLoading(false))
  }, [])

  const noneInvestableByFci = useMemo(
    () => !!fciLoanStatus && FciLoanNotInstructStatus.includes(fciLoanStatus),
    [fciLoanStatus],
  )

  useEffect(() => {
    if (!location.hash) {
      setActiveMenu(sideMenus[0].title)
      return
    }

    const menu = location.hash.replaceAll('#', '').replaceAll('%20', ' ')

    let menus = sideMenus.map((v) => v.title)

    if (menus.includes(menu)) setActiveMenu(menu)
  }, [location.hash])

  const renderMenu = useMemo(() => {
    if (!activeMenu) return <></>

    return <SideMenu menus={sideMenus} defaultMenu={activeMenu} onClick={setActiveMenu} />
  }, [activeMenu, sideMenus])

  const handleScroll = () => {
    refLeftMenu.current.style.marginTop = `${window.scrollY}px`
    refRightMenu.current.style.height = `${window.scrollY}px`
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const hasValuationDoc = !!valuationDoc.length
    const newMenus = cloneDeep(sideMenus)
    newMenus[4].mark = hasValuationDoc
    setSideMenus(newMenus)
  }, [loan])

  const data = useMemo<Record<string, any>>(() => {
    if (!loan) return {}
    return {
      'Investment Overview': (
        <>
          <Accordion
            // title="Overview"
            data={{
              // Originator: 'Central Florida Lending Fund',
              'Repayment Type': loan.isInterestOnly ? 'Interest-Only' : 'None',
              'Lien Type': loan.lienType, //'First',
              'Origination Date': formatDateLong(loan.originationDate),
              'Maturity Date': formatDateLong(loan.maturityDate),
              'Loan Amount': `$ ${normalizeNumber(loan.loanAmount)}`,
              'Currently Available': `$ ${normalizeNumber(
                loan.loanAmount - loan.yldInvestment - loan.funds - loan.constructionBalance,
              )}`,
            }}
          />
          {loan.note && (
            <div>
              <p className="text-desc">Note</p>
              <p className="text-sm font-semibold whitespace-pre-wrap">{loan.note}</p>
            </div>
          )}
        </>
      ),
      'Purpose & Strategy': {
        'Loan Purpose': loan.loanPurpose,
        'Rehab Budget': `$ ${thousandSeperator(loan.rehabBudget)}`,
        'Loan Strategy': loan.loanStrategy,
        // 'Cash into Closing (includes costs and fees)': `$ ${thousandSeperator(loan.cashIntoClosing)}`,
        'Property Purchase Price': (
          <div className="flex gap-1 items-center">
            <p className="text-sm bg-green-400 px-2 py-1 rounded-lg">
              $ {thousandSeperator(loan.propertyPurchasePrice)}
            </p>
            {/* <p className="text-desc">In Oct 2022</p> */}
          </div>
        ),
        'Construction Reserve Remaining': <div className="flex items-center">{loan.constructionBalance}</div>,
      },
      'Debt Stack': {
        'Finresi Investment': `$ ${thousandSeperator(loan.yldInvestment)}`,
        'Total Senior Positions': `$ ${thousandSeperator(loan.loanAmount)}`,
        'Pari Passu': `$ ${thousandSeperator(loan.pariPassu)}`,
        'Subordinate Positions': (
          <div className="flex gap-1 items-center">
            <p className="text-sm bg-gray-100 px-2 py-1 rounded-lg">$ {thousandSeperator(loan.subordinatePos)}</p>
            <p className="text-desc">Held by Originator</p>
          </div>
        ),
        ' ': '',
        'Total First Lien': `$ ${thousandSeperator(loan.loanAmount)}`,
      },
      'Leverage Details': {
        'First Lien Amount': `$ ${thousandSeperator(loan.loanAmount)}`,
        'As-Is LTV': `${loan.asIsLTV || 0}%`,
        'Day 1 Loan Amount': `$ ${thousandSeperator(loan.day1LoanAmount)}`,
        'Purchase Price': (
          <div className="flex gap-1 items-center">
            <p className="text-sm bg-gray-100 px-2 py-1 rounded-lg">
              $ {thousandSeperator(loan.propertyPurchasePrice)}
            </p>
          </div>
        ),
        'After Repair LTV': `${loan.afterRepairLTV || 0}%`,
        'As-Is Value': (
          <div className="flex gap-1 items-center flex-wrap">
            <p className="text-sm bg-gray-100 px-2 py-1 rounded-lg">$ {thousandSeperator(loan.asIsValue)}</p>
            {/* <p className="text-desc">Purchase Price as of October 19,2022</p> */}
          </div>
        ),
        LTC: `${loan.ltc}%`,
        'After Repair Value': (
          <div className="flex gap-1 items-center flex-wrap">
            <p className="text-sm bg-gray-100 px-2 py-1 rounded-lg">
              $ {thousandSeperator(loan.afterRepairValue || 0)}
            </p>
            {/* <p className="text-desc">Estimated Value as of September 14, 2022</p> */}
          </div>
        ),
        // 'As-Is Value to Median Home Price': '47%', // clearcapital
        // 'Median Home Price': `$ ${thousandSeperator(169896)}`, // clearcapital
      },
      Valuation: (
        <div>
          <div className="grid grid-cols-2 mb-2 gap-4">
            <div>
              <p className="text-desc">Property</p>
              <div className="flex gap-1 items-center col-span-2">
                <div className="bg-green-400 rounded-full p-1">
                  <ArrowUpRightIcon className="w-3 h-3" />
                </div>
                <p className="break-words">{loan.title}</p>
              </div>
            </div>

            <div>
              <p className="text-desc">Date</p>
              <p className="whitespace-nowrap">{formatDateLong(loan.appraisalDate)}</p>
            </div>

            <div>
              <p className="text-desc">As-is Value</p>
              <p className="text-green-400 whitespace-nowrap">$ {thousandSeperator(loan.asIsValue)}</p>
            </div>

            <div>
              <p className="text-desc">After Repair Value</p>
              <p className="whitespace-nowrap">$ {thousandSeperator(loan.afterRepairValue)}</p>
            </div>
          </div>

          {!!valuationDoc.length && (
            <>
              <p className="mt-4 mb-2 text-lg">Documents</p>

              <div className="grid grid-cols-7 gap-2 w-full pb-2 px-2">
                <p className="text-desc">No</p>
                <p className="text-desc col-span-5">File Name</p>
                <p className="text-desc">Action</p>
              </div>
              {valuationDoc.map((doc, index) => (
                <div className="grid grid-cols-7 gap-2 w-full py-2 border-t px-2" key={index}>
                  <p className="text-sm">{index + 1}</p>
                  <p className="text-sm col-span-5">{doc.name}</p>
                  <div>
                    <button
                      className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                      onClick={() => openS3Document(doc.fileKey)}
                    >
                      <EyeIcon className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      ),
      'Payment Term': (
        <div className="divide-y">
          <div className="flex justify-between items-center py-2">
            <p className="text-desc">Original Loan Term</p>

            <div className="flex gap-1 items-center">
              <p className="text-sm bg-gray-100 px-2 py-1 rounded-lg">{loan.originalLoanTerm} Months</p>
              <p className="text-desc">from {formatDateLong(loan.originationDate)}</p>
            </div>
          </div>
          {[
            ['Original Maturity Date', formatDateLong(loan.maturityDate)],
            ['Borrower Extension Option', loan.borrowerExtension],
            // ['Prepayable', 'Yes'],
            ['Prepayment Penalty', loan.prepaymentPenalty || 'None'],
            // ['Lock-Out', 'None'],
          ].map((item) => (
            <div className="flex justify-between items-center py-2" key={item[0]}>
              <p className="text-desc">{item[0]}</p>
              <p className="text-sm">{item[1]}</p>
            </div>
          ))}
        </div>
      ),
      Borrower: (
        <div className="divide-y">
          <div className="flex justify-between items-center py-2">
            <p className="text-desc">Borrower Type</p>
            <p className="text-sm">{loan.borrowerType}</p>
          </div>
          <div className="flex justify-between items-center py-2">
            <p className="text-desc">Credit Score</p>

            <div className="flex gap-1 items-center">
              <p className="text-sm bg-gray-100 px-2 py-1 rounded-lg">{loan.creditScore}</p>
              {/* <p className="text-desc">Guarantor</p> */}
            </div>
          </div>
          <div className="flex justify-between items-center py-2">
            <p className="text-desc">Track Record</p>
            <p className="text-sm">{loan.trackRecord}</p>
          </div>
        </div>
      ),
      'Property Details': (
        <>
          <Accordion
            // title="Overview"
            data={
              MultiFamilyPropertyTypes.includes(loan.propertyType)
                ? {
                    'Property Type': loan.propertyType,
                    'Current SQFT': thousandSeperator(loan.squareFeet),
                    'After Rehab SQFT': thousandSeperator(loan.afterRehabSQFT),
                    'Lot Sqft': loan.lotSize,
                    Units: loan.units,
                  }
                : {
                    'Property Type': loan.propertyType,
                    'Current SQFT': thousandSeperator(loan.squareFeet),
                    'After Rehab SQFT': thousandSeperator(loan.afterRehabSQFT),
                    Bedrooms: loan.bedrooms,
                    'Lot Sqft': loan.lotSize,
                    Bathrooms: loan.bathrooms,
                  }
            }
          />

          {/* <Accordion
            title="Location"
            data={{
              Location: 'Philadelphia, PA',
              'Nearest Metro': 'Philadelphia (5 miles)',
              'ZIP Code': 19131,
            }}
          />

          <Accordion
            title="Home Price Index"
            data={{
              'YoY Change': '+7.59%',
              'Forecast Next 24 Mo.': '+14.33%',
              'Forecast Next 12 Mo.': '+6.93%',
            }}
          />

          <Accordion
            title="Downside Scenarios"
            data={{
              'Worst 9-month price decline': '-11.38%',
              'Stressed After Repair LTV': '51%',
              'Stressed LTV': '73%',
            }}
          /> */}
        </>
      ),
      'Construction Ledger': <ConstructionLedger documents={loan.documents} loanNumber={loan.loanNumber} />,
      'Property Documents': (
        <div>
          <div className="grid grid-cols-7 gap-2 w-full pb-2 px-2">
            <p className="text-desc">No</p>
            <p className="text-desc col-span-5">File Name</p>
            <p className="text-desc">Action</p>
          </div>
          {loan.documents
            .filter((doc) => !['Valuation', 'Exhibit A'].includes(doc.category))
            .map((doc, index) => (
              <div className="grid grid-cols-7 gap-2 w-full py-2 border-t px-2 h-9" key={index}>
                <p className="text-sm">{index + 1}</p>
                <p className="text-sm col-span-5">{doc.name}</p>
                <div>
                  <button
                    className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                    onClick={() => openS3Document(doc.fileKey)}
                  >
                    <EyeIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      ),
    }
  }, [loan])

  useEffect(() => {
    if (!params.id) return
    window.scrollTo(window.scrollX, 0)

    refetchLoan(Number(params.id))
  }, [params.id])

  const refetchLoan = (loanId: number) => {
    getLoan(loanId).then(async ({ loan, invests }) => {
      const title = getSimplifiedAddress(loan.propertyAddress)
      loan.title = `${title} #${loan.id}`
      setLoan(loan)

      useTitle(loan.title)

      setInvests(invests)
      if (envelopeId) {
        if (envelopeEvent == 'signing_complete') onEnvelopeEvent()
        else {
          await updateEnvelopeStatus(loan.id, envelopeId)
          navigate(`/marketplace/${params.id}`)
        }
      }
    })
  }

  const onInvestNow = async () => {
    const { accountType, accountDetails } = profile
    if (accountType === AccountType.Ira && (accountDetails as IRADetails)?.IRA_Type === IRAType.Custodian) {
      toast(`A custodian-controlled SDIRA account should be managed by an admin. Please contact support.`, {
        type: 'error',
      })
      return
    }

    const isInvestable = await isInvestableProfile()

    if (!isInvestable) {
      showVerificationProgress(true)
      return
    }
    checkPendingInvestment()
  }

  const checkPendingInvestment = async () => {
    setLoading(true)
    getInvestments({
      status: [InvestStatus.Pending],
    })
      .then(({ total, data }) => {
        if (!total) {
          openForInvestment(true)
          return
        }
        setUnsignedInvestment(data[0])
      })
      .finally(() => setLoading(false))
  }

  const onEnvelopeEvent = () => {
    openForInvestment(true)
  }

  const onBack = () => {
    navigate('/marketplace')
  }

  const onSellBack = async (data: DBInvestment, event: React.MouseEvent<any>) => {
    event.preventDefault()

    const result = await confirm(
      <p className="mb-4">
        There is a{' '}
        <b>
          {100 * PenaltyPercent}% ($ {normalizeNumber(data.amount * PenaltyPercent)})
        </b>{' '}
        transaction fee to exit the investment prior to payoff. Please confirm you wish to proceed.
      </p>,
    )
    if (!result) return

    setRetradeId(data.id)
    signSellBackInvestment(data.id)
      .then(({ redirectUrl }) => window.open(redirectUrl, '_self'))
      .catch(() => setRetradeId(0))
  }

  const currentImage = loan && loan.images[imageIndex]

  const imageSection = useMemo(() => {
    if (!loan || !currentImage) return null

    return (
      <div>
        <div className="rounded-2xl overflow-hidden relative mb-4">
          <Image src={toS3Link(currentImage)} className="absolute w-full h-full aspect-[5/2] object-cover" />
          <div className="absolute w-full h-full backdrop-blur-md bg-white/50" />
          <Image src={toS3Link(currentImage)} className="relative w-full aspect-[5/2] object-contain" />

          <div className="absolute top-2 right-2 flex gap-1">
            {loan.images.map((image, index) => (
              <div
                className="bg-black rounded-md overflow-hidden"
                onClick={() => setImageIndex(index)}
                key={image.fileKey}
              >
                <Image
                  src={toS3Link(image)}
                  className={`w-12 aspect-[3/2] object-cover hover:opacity-70 ${imageIndex != index && 'opacity-50'}`}
                />
              </div>
            ))}
          </div>

          {loan.isInvested && (
            <p className="absolute bottom-0 right-0 p-2 bg-blue-500 text-white rounded-lg">Invested</p>
          )}
        </div>

        <div className="rounded-2xl overflow-hidden h-48 w-full bg-slate-200 mb-4">
          {loan && <GoogleMap markers={[{ title: loan.title || '', lat: loan.lat, lng: loan.lng }]} />}
        </div>

        {!!valuationDoc.length && (
          <p className="text-desc">Additional photos are available in the downloadable appraisal report.</p>
        )}
      </div>
    )
  }, [loan, imageIndex, currentImage])

  const investSection = useMemo(() => {
    if (!loan) return null

    return (
      <>
        <div className="bg-white p-4 rounded-lg mt-2 mb-8">
          <FundedBy loan={loan} />
        </div>

        <div className="grid grid-cols-3 text-sm mb-8 text-center">
          <div>
            <p className="font-semibold">{loan.ytm}%</p>
            <p className="text-desc">APY</p>
          </div>
          <div>
            <p className="font-semibold">{normalizeNumber(loan.ltv)}%</p>
            <p className="text-desc">LTV</p>
          </div>
          <div>
            <p className="font-semibold">{remainingLoanTerm(loan.maturityDate)}</p>
            <p className="text-desc">TERM</p>
          </div>
        </div>

        <div>
          <Button
            color="green"
            full
            loading={loading}
            onClick={onInvestNow}
            disabled={loan.closed || noneInvestableByFci}
          >
            {
              loan.closed
                ? 'Closed'
                : noneInvestableByFci
                ? 'Opportunity not available'
                : loan.isInvested
                ? 'Invest More'
                : 'Invest Now' /* isKycPassed ? 'Invest Now' : 'Pass KYC'*/
            }
          </Button>
        </div>

        {isTopManager(profile.accountType) && (
          <div className="mt-4">
            <Button
              full
              loading={loading}
              onClick={() => {
                openForInvestment(true)
                setIRAInvest(true)
              }}
              disabled={loan.closed || noneInvestableByFci}
            >
              Invest for IRA Custodian
            </Button>
          </div>
        )}
      </>
    )
  }, [loan, loading])

  return (
    <div className="w-full">
      <div className="fullContent min-h-[20rem]">
        {loan == null ? (
          <LayoutLoading show />
        ) : (
          <div className="lg:grid grid-cols-4 gap-4">
            <div className="col-span-3 flex flex-col gap-4 py-4">
              <div className="lg:ml-52">
                <Button link onClick={onBack}>
                  <p className="flex items-center gap-2 text-sm">
                    <ArrowLeftIcon className="w-3 h-3" />
                    Back to Marketplace
                  </p>
                </Button>
                <p className="text-3xl font-bold mb-2">{loan.title}</p>
                {!loan.closed ? (
                  <p className="text-xs text-gray-500">
                    <span className="bg-light-green text-black px-2 py-1 rounded-md">Open</span> Active Property for
                    Investment
                  </p>
                ) : (
                  <p className="text-xs text-gray-500">
                    <span className="bg-yellow-300 text-black px-2 py-1 rounded-md">Closed</span> Inactive Property for
                    Investment
                  </p>
                )}
              </div>

              <div className="flex gap-4">
                <div className="w-48 hidden lg:block mt-0" ref={refLeftMenu}>
                  {renderMenu}
                </div>

                <div className="flex-1 flex flex-col gap-y-8">
                  {imageSection}

                  <div className="flex gap-2 justify-between text-sm">
                    <div>
                      <p className="text-desc">Property Type</p>
                      <p className="font-semibold">{loan.propertyType}</p>
                    </div>
                    <div>
                      <p className="text-desc">Loan Strategy</p>
                      <p className="font-semibold">{loan.loanStrategy}</p>
                    </div>
                    <div>
                      <p className="text-desc">Location</p>
                      <p className="font-semibold">{loan.propertyAddress}</p>
                    </div>
                  </div>

                  <div className="grid lg:hidden items-center md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-8">
                    {investSection}
                  </div>

                  {!!loan && !!invests.length && (
                    <div id="myInvestments">
                      <p className="text-base font-semibold mb-2">My Investments ({invests.length})</p>
                      <ResponsiveTable
                        header={[
                          'ID',
                          'Status',
                          'Method',
                          'Amount',
                          'Interest (MO)',
                          'Invested Date',
                          'Distribution',
                          'Action',
                        ]}
                        headerClassName="hidden lg:grid"
                        containerClassName={`text-sm rounded-lg overflow-hidden gap-2 'divide-y lg:gap-0`}
                        contentClassName={`lg:grid grid-cols-12 text-sm py-2 cursor-pointer`}
                        size={[1, 1, 1, 1, 1, 1, 1, 1]}
                        onClickItem={(index: number) => setSelectedInvest(invests[index] || null)}
                        data={invests.map((item) => [
                          `#${item.id}`,
                          <div className="flex gap-2 items-center justify-end px-4 lg:px-0 lg:justify-start capitalize">
                            <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
                            {item.status}
                          </div>,
                          item.method,
                          `$ ${normalizeNumber(item.amount)}`,
                          `$ ${monthlyProfit(item.amount, loan.ytm)}`,
                          item.startDate ? formatDateLong(item.startDate) : '',
                          item.status === InvestStatus.Settled ? `+ $ ${normalizeNumber(item.profit || 0, 2)}` : '',
                          retradeId == item.id ? (
                            <Loading />
                          ) : item.status === InvestStatus.Settled ? (
                            <span className="text-link" onClick={(e) => onSellBack(item, e)}>
                              Retrade
                            </span>
                          ) : (
                            <></>
                          ),
                        ])}
                        classNames={['pl-4', '', '', '', 'text-green-400', '', 'text-green-400 font-bold']}
                      />
                    </div>
                  )}

                  {loan && selectedInvest && (
                    <InvestDetails
                      data={{ ...selectedInvest, loan }}
                      onClose={(needRefresh: boolean) => {
                        setSelectedInvest(null)
                        needRefresh && refetchLoan(loan.id)
                      }}
                    />
                  )}
                  {/* <div className="px-6 py-4 bg-white rounded-lg border border-gray-100">
                  <p className="bg-yellow-300 rounded-lg text-xs inline-block px-2 py-1 mb-1">Early Access loan</p>
                  <p className="mb-2 text-sm font-medium">
                    Loan is currently in the review process and funding is dependent on investor demand. It is estimated
                    to close within 10 days.
                  </p>
                  <p className="text-desc">What are Early Access loans?</p>
                </div> */}

                  <div className="flex flex-col divide-y gap-8">
                    {Object.keys(data).map((activeMenu, index) => (
                      <div key={index} className="lg:col-span-3 flex flex-col gap-6">
                        <div className="rounded-lg flex flex-col gap-4 min-h-full">
                          <p className="text-base font-semibold mt-8" id={activeMenu}>
                            {activeMenu}
                          </p>

                          {isValidElement(data[activeMenu]) ? data[activeMenu] : <Accordion data={data[activeMenu]} />}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="py-4">
                    <div className="text-desc grid gap-2 mb-14">
                      <p>
                        Data provided by Clear Capital based on property prices in the zip code as of February 2023.
                      </p>
                      <p>
                        The worst 9-month price decline is the worst price decline in the zip code over the last 20
                        years according to data provided by Clear Capital as of February 2023.
                      </p>
                      <p>
                        The Stressed LTV is the LTV that would be the result of applying the worst 9-month price decline
                        to the subject property valuation.
                      </p>
                      <p>
                        The Stressed After Repair LTV is the LTV that would be the result of applying the worst 9-month
                        price decline to the subject property valuation.
                      </p>
                    </div>

                    <div className="text-xs grid gap-2 mb-6 text-black">
                      <p>* At the time this loan was published.</p>
                      <p>
                        DISCLAIMER: The information provided in this site, including, without limitation, the Projected
                        Return Calculator, should not be construed as advice on any subject matter whatsoever. You
                        should not act or refrain from acting on the basis of any content included in this site without
                        seeking legal or other professional advice. This information is provided strictly for
                        informational purposes only and is subject to change from time to time without notice to you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-200/70 p-4 pt-36 lg:block hidden">
              <div className="w-full h-0" ref={refRightMenu} />
              {investSection}
            </div>
          </div>
        )}
      </div>
      {isOpenForInvestment && loan && (
        <OpenForInvestment
          loan={loan}
          onClose={() => openForInvestment(false)}
          onDeposit={() => {
            showDepositModal(true)
            openForInvestment(false)
          }}
          isIRAInvest={isIRAInvest}
        />
      )}
      {isVerificationProgress && <CompleteVerificationProgress onClose={() => showVerificationProgress(false)} />}
      {isDepositModal && (
        <DepositModal
          onClose={() => {
            showDepositModal(false)
            openForInvestment(true)
          }}
        />
      )}
      {unsignedInvestment && (
        <UnsignedInvestmentModal
          invest={unsignedInvestment}
          onClose={(result: boolean) => {
            setUnsignedInvestment(null)
            if (result) checkPendingInvestment()
          }}
        />
      )}
    </div>
  )
}
