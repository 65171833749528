import { ConfirmEmailPage, ForgetPasswordPage, ResetPasswordPage, SignInPage, SignUpPage } from 'pages/Auth'
import { Navigate, Route, Routes } from 'react-router-dom'

export const AuthRoutes = () => (
  <Routes>
    <Route path="/auth/signUp" element={<SignUpPage />} />
    <Route path="/auth/signUp/:step" element={<SignUpPage />} />
    <Route path="/auth/signIn" element={<SignInPage />} />
    <Route path="/auth/confirmEmail/:selector/:token" element={<ConfirmEmailPage />} />
    <Route path="/auth/forgetPassword" element={<ForgetPasswordPage />} />
    <Route path="/auth/resetPassword/:selector/:token" element={<ResetPasswordPage />} />
    <Route path="*" element={<Navigate replace to="/home" />} />
  </Routes>
)
