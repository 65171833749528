import { imgAccDocument, imgAccIncomeAsset, imgAccThirdParty } from 'assets'

export const AccreditedType: Record<string, any> = {
  incomeAsset: {
    title: 'Income or Asset Verification',
    icon: imgAccIncomeAsset,
  },
  thirdParty: {
    title: 'Third Party Verification',
    icon: imgAccThirdParty,
  },
  letter: {
    title: 'Letter Documentation',
    icon: imgAccDocument,
  },
}

export const AccreditedTypeSection = ({ onSubmit }: { onSubmit: Function }) => {
  return (
    <div className="text-center mx-auto max-w-3xl">
      <p className="uppercase text-sm mb-2">Verification</p>
      <p className="text-lg font-medium mb-8">Accredited Investor Verification</p>

      <div className="grid 2xs:grid-cols-2 sm:grid-cols-3 gap-3 mx-4">
        {Object.keys(AccreditedType).map((type) => {
          const data = AccreditedType[type]
          return (
            <div
              className="rounded-md aspect-square border border-gray-300 p-4 flex items-center hover:ring-4 hover:ring-gray-200 cursor-pointer"
              key={type}
              onClick={() => onSubmit(type)}
            >
              <div className="w-full">
                <img src={data.icon} className="w-14 aspect-square object-contain mx-auto mb-10" />
                <p className="text-sm font-medium mb-2">{data.title}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
