import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { addTask, updateTask } from 'services/apis'
import { Modal } from 'stories/components'
import { filterObject, formatDateYMD, InputConvert, InputValidate, RenderInput, validateInputs } from 'utils'

import type { DbTask } from '../type'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    title: {
      inputType: 'text',
      title: 'Title',
      value: '',
      required: true,
    },
    assignedTo: {
      inputType: 'multiselect',
      type: 'text',
      title: 'Assigned To',
      value: { admin: true },
      required: true,
      options: {},
    },
    dueDate: {
      inputType: 'text',
      type: 'date',
      title: 'Due Date',
      value: '',
      required: true,
    },
    // status: {
    //   inputType: 'select',
    //   title: 'Status',
    //   options: ['active', 'complete'],
    //   hasDefaultOption: false,
    //   required: true,
    //   value: 'active',
    // },
    description: {
      inputType: 'textarea',
      type: 'text',
      title: 'Description',
      value: '',
      span: 2,
      required: false,
    },
    note: {
      inputType: 'textarea',
      type: 'text',
      title: 'Notes',
      value: '',
      span: 2,
      required: false,
    },
    files: {
      inputType: 'filetable',
      filePath: 'task/new',
      title: 'Files',
      span: 2,
      required: false,
    },
  }
}

export const TaskDetails = ({
  data,
  assignedToList,
  onClose,
}: {
  data: DbTask | null
  assignedToList: Record<string, string>
  onClose: Function
}) => {
  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    setLoading(false)

    let newInputs = cloneDeep(inputs)
    if (data) {
      ;(newInputs.files as any).filePath = `task/${data.id}`
      Object.keys(newInputs).forEach((key) => {
        if ((data as any)[key]) newInputs[key].value = (data as any)[key]
      })
      newInputs.assignedTo.value = {}
      newInputs.dueDate.value = formatDateYMD(data.dueDate)
      Object.keys(assignedToList).forEach((v) => {
        newInputs.assignedTo.value[v] = data.assignedTo.includes(v)
      })
    }
    ;(newInputs.assignedTo as any).options = assignedToList
    setInputs(newInputs)
  }, [data])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate({ ...newInputs[key], value })
    newInputs[key].value = value
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const { hasError, data: inputData, newInputStates } = validateInputs(inputs)
    if (hasError) {
      setInputs(newInputStates)
      return
    }
    const assignedTo = filterObject(inputData.assignedTo)
    inputData.assignedTo = assignedTo

    if (data) {
      updateTask(data.id, inputData)
        .then(() => onClose(true))
        .catch(() => setLoading(false))
    } else
      addTask(inputData)
        .then(() => onClose(true))
        .catch(() => setLoading(false))
  }

  return (
    <Modal
      isOpen
      title={!data ? 'Add Task' : `Task Edit - ${data.id}`}
      titleOkay={data ? 'Update' : 'Save'}
      loading={isLoading}
      onClose={() => onClose()}
      onOk={onSubmit}
    >
      <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]

          return (
            <div className={`input md:col-span-${input.span || 1}`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
