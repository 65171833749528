import { accountTypeOptions } from '../AccountType'

export const InvestorTypeSection = ({ step, onSubmit }: { step: JSX.Element; onSubmit: Function }) => {
  return (
    <div className="text-center mx-4">
      <div className="mb-4">{step}</div>
      <p className="uppercase text-sm mb-2">Investor Profile</p>
      <p className="text-lg font-medium mb-16">What type of investor are you?</p>

      <div className="grid xs:grid-cols-2 sm:grid-cols-4 gap-3">
        {Object.keys(accountTypeOptions).map((accountType) => {
          const data = accountTypeOptions[accountType]
          return (
            <div
              className="rounded-md aspect-square border border-gray-300 p-4 flex items-center hover:ring-4 hover:ring-gray-200 cursor-pointer"
              key={accountType}
              onClick={() => onSubmit(accountType)}
            >
              <div className="w-full">
                <img src={data.icon} className="w-14 aspect-square mx-auto mb-6" />
                <p className="text-sm font-medium mb-2">{data.title}</p>
                <p className="text-2xs text-gray-500">{data.description}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
