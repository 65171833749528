import { IServiceData } from 'pages/ServiceArticle/types'
import React from 'react'

interface ArticleBoxProps {
  articleItem: IServiceData
}

const ArticleBox: React.FC<ArticleBoxProps> = ({ articleItem }) => {
  return (
    <>
      <div className="py-6" id={articleItem.id.toString()}>
        <h2 className="text-2xl font-bold mb-6 text-black font-inter">{articleItem.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: articleItem?.content ?? '' }} className="ck-content !min-h-0" />
        {articleItem.imgSrc.length > 0 && (
          <img
            src={articleItem.imageURL}
            alt={articleItem.title}
            width={856}
            height={476}
            className="rounded-2xl mt-12"
          />
        )}
      </div>
    </>
  )
}

export default ArticleBox
