import { ShieldCheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { AUTH_TOKEN_SET } from 'actions'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { checkPassport, confirmUserEmail } from 'services/apis'
import { Button } from 'stories/components'
import useSyncSetState from 'use-sync-set-state'
import { useTitle } from 'utils'

export function ConfirmEmailPage() {
  useTitle('Confirm Email')

  const [isLoading, setLoading] = useState(true)
  const [email, setEmail] = useState<string | null>(null)
  const [authData, setAuthData] = useSyncSetState('authData', null)
  const [isConfirmed, setConfirmed] = useState(false)

  const dispatch = useDispatch()

  console.log('authData', authData)

  const urlParams: any = useParams()
  const { selector, token } = urlParams
  const navigate = useNavigate()

  useEffect(() => {
    if (!selector || !token) {
      navigate(-1)
      return
    }

    setLoading(true)
    checkPassport(selector, token)
      .then(({ email }) => {
        setEmail(email)
        onSubmit()
      })
      .catch(() => navigate('/auth/signIn'))
  }, [])

  const onSubmit = async () => {
    if (!selector || !token) return

    setLoading(true)

    confirmUserEmail(selector, token)
      .then(({ user, token }) => {
        setAuthData({
          user,
          token,
        })
        dispatch({ type: AUTH_TOKEN_SET, token, user })
        setConfirmed(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="mb-10">
      <div className="flex justify-center pt-8">
        {!isConfirmed ? (
          <div className="max-w-md px-4 py-6 md:px-10 md:py-10 bg-white rounded-xl">
            <div className="p-1">
              <p className="font-medium text-center text-xl mb-2">{email}</p>
              <p className="text-sm mb-2">We are confirming your email now. Please wait few seconds...</p>
              <Button className="mt-1" color="indigo" full bold onClick={onSubmit} loading={isLoading}>
                Please wait
              </Button>
            </div>
          </div>
        ) : (
          <div className="max-w-md px-4 py-6 md:px-10 md:py-10 bg-white rounded-xl relative">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white absolute top-4 right-4"
              onClick={() => navigate('/marketplace')}
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
            <div className="p-1 justify-center">
              <ShieldCheckIcon className="w-20 h-20 text-green-500 mb-3 mx-auto" />
              <p className="font-medium text-center text-xl mb-4">{email}</p>
              <p className="text-sm mb-2">
                Your email address has been verified. You may now continue completing your profile.
              </p>
              <p className="text-desc">
                Please click the X at the top right of this window to navigate to opportunities.
              </p>

              {/* <Button className="mt-1" color="indigo" full bold onClick={() => navigate('/dashboard')}>
                Go to Dashboard
              </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
