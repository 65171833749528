interface ProgressBarProps {
  color?: string
  progress?: number
  height?: number
  className?: string
}

export const ProgressBar = ({ color = 'indigo', progress = 0, height = 2, className = '' }: ProgressBarProps) => {
  return (
    <div className={`w-full bg-gray-300 rounded-sm overflow-hidden ${className}`}>
      <div className={`bg-${color}-500 h-${height}`} style={{ width: `${progress}%` }} />
    </div>
  )
}
