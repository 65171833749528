import { imgAuthLeft } from 'assets/auth'
import { PasswordValidation, usePasswordValid } from 'components'
import { Image } from 'components/Image'
import { type InputType, states } from 'config'
import { getCountryCallingCode } from 'libphonenumber-js'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { validateEmailDuplication, validatePhone } from 'services/apis'
import { Button } from 'stories/components'
import { countries } from 'stories/components/Input/PhoneSelector'
import { InputConvert, RenderInput, validateInputs } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  return {
    firstName: {
      title: 'First name',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
      autoFocus: true,
    },
    lastName: {
      title: 'Last name',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
    },
    email: {
      title: 'Email address',
      placeholder: '',
      inputType: 'text0',
      type: 'email',
      error: '',
      required: true,
    },
    password: {
      title: 'Password',
      placeholder: '',
      inputType: 'text0',
      type: 'password',
      error: '',
      hasIcon: true,
      required: true,
    },
    phone: {
      title: 'Mobile',
      placeholder: '',
      inputType: 'text0',
      type: 'phone',
      required: true,
    },
    country: {
      title: 'Country',
      placeholder: '',
      inputType: 'select0',
      options: countries,
      error: '',
      required: true,
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
    },
    state: {
      title: 'State',
      placeholder: 'Select',
      inputType: 'select0',
      hasDefaultOption: true,
      allowDefaultOption: false,
      defaultOptionText: 'Select',
      options: states,
      error: '',
      required: true,
      visible: true,
    },
    city: {
      title: 'City',
      placeholder: '',
      inputType: 'text0',
      error: '',
      required: true,
    },
    sms: {
      inputType: 'checkbox',
      title: `By checking this box, you agree to receive SMS communications from Finresi for account authentication and security. We may occasionally contact users for customer support and other related updates. You can opt-out anytime by replying ‘STOP’ to any message.`,
      required: true,
      fontClass: '!font-normal',
    },
  }
}

export const CreateAccountSection = ({
  step,
  data: initialData,
  onSubmit: _onSubmit,
  loading,
  setLoading,
}: {
  step: JSX.Element
  data: Record<string, string>
  onSubmit: Function
  loading: boolean
  setLoading: Function
}) => {
  const [inputStates, setInputStates] = useState(defaultInputs())
  const isValidPassword = usePasswordValid({
    firstPassword: inputStates.password ? inputStates.password.value : '',
    secondPassword: null,
  })

  useEffect(() => {
    if (!initialData) return
    const newState = cloneDeep(inputStates)
    Object.keys(newState).forEach((key) => {
      newState[key].value = InputConvert(newState[key], initialData[key])
    })
    setInputStates(newState)
  }, [initialData])

  const renderInput = (key: string) => {
    if (key == 'state' && inputStates.country.value != 'US') return null

    return RenderInput({
      input: inputStates[key],
      Key: key,
      onChange,
    })
  }

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    if (key == 'country') {
      newState.state.visible = value == 'US'
      const callingCode = getCountryCallingCode(value as any)
      if (callingCode && !newState.phone.value.startsWith(`+${callingCode}`)) newState.phone.value = `+${callingCode}`
    }

    setInputStates(newState)
  }

  const onSubmit = async () => {
    const { hasError, data, newInputStates } = validateInputs(inputStates)
    if (hasError) {
      setInputStates(newInputStates)
      return
    }
    delete data.sms
    // _onSubmit(data)
    // return

    setLoading(true)
    validateEmailDuplication(data.email)
      .then(async ({ success }) => {
        if (!success) {
          toast('The same email already exists.', { type: 'warning' })
          return
        }
        await validatePhone({ email: data.email, phone: data.phone, checkDuplication: true })
        _onSubmit(data)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="flex flex-col-reverse gap-y-8 md:flex-row">
      <div className="md:w-[356px] object-cover mx-8 md:mx-0">
        <div className="bg-[#22262A] rounded-lg overflow-hidden">
          <p className="text-white p-6 md:p-12 text-lg mb-8">
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.23005 0H12.065V5.94H9.36505C7.61005 5.94 6.66505 6.345 6.66505 8.775V12.015H11.93V30.645H0.0500488V8.91001C0.0500488 2.97 3.42505 0 9.23005 0ZM27.8601 0H30.695V5.94H27.9951C26.105 5.94 25.295 6.345 25.295 8.775V12.015H30.5601V30.645H18.68V8.91001C18.68 2.97 22.055 0 27.8601 0Z"
                fill="white"
              />
            </svg>
            <br />
            “Finresi’s high-yield real estate loan investments provide me true passive income with complete control and
            none of the real estate ownership headaches. It’s seamless and straightforward.”
          </p>
          <Image src={imgAuthLeft} className="w-full" />
        </div>
      </div>

      <div className="px-8 py-1 flex-1">
        {step}

        <p className="text-xl font-medium mb-2">Welcome to Finresi!</p>
        <p className="text-sm text-gray-600 mb-4">
          As the first step of your onboarding process, we'd like to get to know you so we can better understand your
          objectives to help you identify the right investments.
        </p>

        <div className="grid grid-cols-2 gap-x-2 mb-4">
          {renderInput('firstName')}
          {renderInput('lastName')}

          <div className="col-span-2">{renderInput('email')}</div>
          <div className="col-span-2">
            {renderInput('password')}
            <PasswordValidation firstPassword={inputStates.password.value} secondPassword={null} />
          </div>

          {renderInput('phone')}
          {renderInput('country')}
          {renderInput('city')}
          {renderInput('state')}
          <div className="col-span-2">{renderInput('sms')}</div>
        </div>

        <Button loading={loading} disabled={!isValidPassword} color="indigo" onClick={onSubmit}>
          Continue
        </Button>
      </div>
    </div>
  )
}
