import {
  imgAlumni,
  imgBallard,
  imgBen,
  imgChris,
  imgE,
  imgEraci,
  imgJeff,
  imgKirk,
  imgLaptop,
  imgPhila,
  imgReallist,
  imgRtt,
  imgSomer,
} from 'assets'
import { Image } from 'components/Image'
import { DashboardDemo } from 'pages/Home/DashboardDemo'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { useTitle } from 'utils'

import { FaqFooter } from './faqFooter'
export const AboutUsPage = () => {
  useTitle('About Us')

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <div className="w-full bg-white">
          <div className="flex flex-col content">
            <div className="text-center my-8 md:my-16 ">
              <p className="md:text-5xl text-2xl font-semibold p-4 inline-block rounded-lg mb-4 max-w-4xl  text-gray-800">
                Finresi unlocks the world of real estate loans for investors portfolios.
              </p>
              <div>
                <Link
                  className="py-3 px-6 bg-light-green rounded-full text-black hover:bg-white hover:text-gray-600 font-semibold"
                  to="/auth/signUp"
                >
                  Sign Up
                </Link>
              </div>
            </div>
            <div className="flex justify-center mt-4 md:mt-0">
              <div className="relative w-3/4 md:w-1/2">
                <div className="absolute aspect-[961/657] left-[-22%] top-[-5%] right-[-17.5%]">
                  <img src={imgLaptop} className="object-contain w-full h-full" />
                </div>
                <div className="w-full aspect-[686/437] overflow-hidden">
                  <DashboardDemo />
                </div>
              </div>
            </div>
            <div className="text-center mt-12 md:mt-20">
              <p className="uppercase font-normal text-gray-600 text-sm mb-12 tracking-widest">
                backed by institutional investors
              </p>
              <div className="flex flex-col gap-6 justify-center md:flex-row">
                <Image src={imgSomer} className="h-8 md:h-fit" />
                <Image src={imgAlumni} className="h-8 md:h-fit" />
                <Image src={imgE} className="h-8 md:h-fit" />
                <Image src={imgBen} className="h-8 md:h-fit" />
              </div>
            </div>
            <div className="text-center mt-16">
              <p className="uppercase font-normal text-gray-600 text-sm mb-12 tracking-widest">service partners</p>
              <div className="flex gap-6 justify-center">
                <Image src={imgBallard} className="h-8 md:h-fit" />
                <Image src={imgEraci} className="h-8 md:h-fit" />
              </div>
            </div>
            <div className="text-center mt-16 mb-32">
              <p className="uppercase font-normal text-gray-600 text-sm mb-12 tracking-widest"> as seen in </p>
              <div className="flex flex-col gap-6 justify-center md:flex-row">
                <Image src={imgRtt} className="object-contain h-8 md:h-fit" />
                <Image src={imgPhila} className="object-contain h-8 md:h-fit" />
                <Image src={imgReallist} className="object-contain h-8 md:h-fit" />
              </div>
            </div>
          </div>
          <div className="w-full bg-zinc-100 pt-24 pb-24">
            <div className="flex flex-col content">
              {/* <p className="text-black font-semibold text-5xl mb-4">Our Blogs/News</p>
            <p className="text-black font-normal text-sm mb-3">Check out Finresi content available for all users</p>
            <HorzScrollContainer>
              <div className="pt-12 pb-2">
                <div className="flex gap-5">
                  {articles.map((article, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
                      style={{ minWidth: 320, width: 320 }}
                      onClick={() => selectArticle(article)}
                    >
                      <img
                        src={article.imageURL}
                        className="w-full h-48 object-cover"
                        alt={article?.altImage && article?.altImage.length > 0 ? article?.altImage : article?.title}
                      />
                      <div className="p-4">
                        <div className="flex space-x-2 mb-2">
                          {article.tags.split(',').map((tag, index1) => (
                            <span
                              key={index1}
                              className="bg-green-100 text-light-green text-xs font-semibold mr-1 px-1 py-0.5 rounded"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                        <h2 className="text-gray-900 font-medium text-sm">{article.title}</h2>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </HorzScrollContainer> */}

              <div className="">
                <div className="w-fit mx-auto">
                  <p className="font-semibold text-black text-2xl mb-4">Our Mission</p>
                  <p className="font-medium text-black text-4xl mb-4 uppercase">How Finresi came about</p>
                  <p className="font-normal text-gray-700 text-sm md:w-160 leading-6">
                    At Finresi, our mission is to revolutionize the investment landscape by providing a secure,
                    transparent, and accessible online platform for debt investments. We strive to empower our investors
                    with innovative financial solutions, backed by stringent underwriting criteria and high-quality real
                    estate offerings. Our commitment to excellence and integrity ensures that every investment
                    opportunity is meticulously curated to deliver consistent returns and foster financial growth. Join
                    us in reshaping the future of alternative real estate investing!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col content pt-36 md:px-24">
            <p className="font-semibold text-4xl text-center mb-16">Executive team</p>
            <div className="grid grid-cols-12 gap-2 md:gap-12">
              <div className="col-span-12 md:col-span-4 mx-auto">
                <Image src={imgJeff} className="w-52 h-52 mx-auto md:w-80 md:h-80 object-contain" />
                <p className="font-medium text-2xl text-center mt-2 md:mt-12">Jeff Gopshtein</p>
                <p className="font-normal text-sm text-gray-700 text-center my-2">Co Founder and CEO</p>
              </div>
              <div className="col-span-12 md:col-span-4 mx-auto">
                <Image src={imgKirk} className="w-52 h-52 mx-auto md:w-80 md:h-80 object-contain" />
                <p className="font-medium text-2xl text-center mt-2 md:mt-12">Kirk Ayzenberg</p>
                <p className="font-normal text-sm text-gray-700 text-center my-2">Co Founder</p>
              </div>
              <div className="col-span-12 md:col-span-4 mx-auto">
                <Image src={imgChris} className="w-52 h-52 mx-auto md:w-80 md:h-80 object-contain" />
                <p className="font-medium text-2xl text-center mt-2 md:mt-12">Christian Leon</p>
                <p className="font-normal text-sm text-gray-700 text-center my-2">Head of Investor Relations</p>
              </div>
            </div>
            {/* <div className="rounded-lg bg-[#5B79D1] pt-16 px-12 mt-40 pb-32 mb-32">
            <p className="font-bold text-white text-5xl">In the news</p>
            <div className="grid grid-cols-12 md:gap-12 mt-12 gap-4">
              {lastArticles.map((article, index) => (
                <div className="col-span-12 md:col-span-4" key={index}>
                  {article.link.includes('technical.ly') ? (
                    <Image src={imgTc} />
                  ) : article.link.includes('bloomberg') ? (
                    <Image src={imgBloomberg} />
                  ) : article.link.includes('forbes') ? (
                    <Image src={imgForbest} />
                  ) : article.link.includes('business') ? (
                    <Image src={imgBusinessInsider} />
                  ) : article.link.includes('lesechos') ? (
                    <Image src={imgLesEchos} />
                  ) : (
                    <p className="font-bold text-2xl text-white">{convertToValidUrl(article.link)}</p>
                  )}
                  <p className="font-semibold text-md text-white mt-4">{article.title}</p>
                </div>
              ))}
            </div>
          </div> */}
          </div>
          <div className="w-full relative lg:pt-24 md:pt-14 pt-10 pb-8 bg-[#22262A] md:mt-88 mt-64">
            <div className="absolute left-0 top-0 bottom-0 right-0 w-full h-full bg-[url('./assets/home/bgGray.png')]" />

            <div className="content relative">
              <div className="bg-[#15181A] relative md:p-16 px-6 py-10 rounded-xl overflow-hidden md:-mt-88 -mt-64 md:mb-20 mb-14">
                <div className="h-128 w-128 absolute -left-64 -top-64 bg-gradient-to-br from-indigo-500 to-indigo-500/0 blur-2xl rounded-full" />
                <div className="h-128 w-128 absolute -right-64 -bottom-64 bg-gradient-to-br from-indigo-500/50 to-indigo-500/0 blur-3xl rounded-full" />
                <p className="md:text-5xl xs:text-4xl text-3xl text-center relative text-white font-semibold max-w-4xl mb-8 mx-auto">
                  Explore wealth creation through a resilient asset class. Sign up.
                </p>
                <div className="flex justify-center md:mb-10 mb-2">
                  <Link
                    to="/auth/signUp"
                    className="py-3 px-8 bg-indigo-500 rounded-full text-white hover:bg-black hover:text-white font-semibold border border-white/0 hover:border-indigo-500"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>

              <FaqFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
