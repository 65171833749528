import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { InputConvert, RenderInput } from 'utils'

export const SelectModal = ({
  options,
  value,
  title,
  ...props
}: {
  title: string
  options: Record<string, string>
  value: string[]
  onSubmit: Function
  onClose: Function
}) => {
  const [inputs, setInputs] = useState<Record<string, InputType>>({})

  useEffect(() => {
    const tempInputs: Record<string, InputType> = {}
    Object.keys(options).forEach((key) => {
      tempInputs[key] = {
        inputType: 'CheckBox',
        title: options[key],
        value: value.includes(key),
      }
    })
    setInputs(tempInputs)
  }, [options])

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = InputConvert(newInputs[key], value)
    setInputs(newInputs)
  }

  const onSubmit = async () => {
    const value = Object.keys(inputs)
      .map((key) => {
        if (inputs[key].value) return key
        return null
      })
      .filter((value) => !!value)

    props.onSubmit(value)
  }

  const onClose = () => {
    props.onClose()
  }

  return (
    <Modal title={title} titleOkay="Confirm" onClose={onClose} onOk={onSubmit} isOpen>
      <div className="w-144 grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
        {Object.keys(inputs).map((key, index) => {
          let input = inputs[key]
          return (
            <div className={`input md:col-span-${input.span || 1} border-b`} key={index}>
              <RenderInput input={input} Key={key} onChange={onChange} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
