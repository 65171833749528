import cn from 'classnames'
import ReactSlider, { ReactSliderProps } from 'react-slider'

export const RangeSlider = <T extends number | readonly number[]>(
  _props: ReactSliderProps<T> & { title: string; error?: string },
) => {
  const isVertical = _props.orientation === 'vertical'

  return (
    <div className="mb-10">
      <p className="font-medium mb-2">{_props.title}</p>
      <ReactSlider
        {..._props}
        value={_props.value}
        minDistance={1}
        renderThumb={(props, state) => (
          <div
            {...props}
            className={cn({
              'h-full': !isVertical,
              'w-full': isVertical,
              'aspect-square rounded-full bg-indigo-500 text-xs text-white flex items-center justify-center cursor-grab relative':
                true,
            })}
          >
            <p className="absolute top-4 bg-indigo-500 p-0.5 rounded-lg">{state.valueNow}</p>
          </div>
        )}
        renderTrack={(props, state) => {
          const points = Array.isArray(state.value) ? state.value.length : null
          const isMulti = points && points > 0
          const isLast = isMulti ? state.index === points : state.index != 0
          const isFirst = state.index === 0
          return (
            <div
              {...props}
              className={cn({
                'h-1/4 top-1/2 -translate-y-1/2': !isVertical,
                'w-1/4 left-1/2 -translate-x-1/2': isVertical,
                'rounded-full': true,
                'bg-gray-200': isMulti ? isFirst || isLast : isLast,
                'bg-indigo-500': isMulti ? !isFirst && !isLast : isFirst,
              })}
            ></div>
          )
        }}
        renderMark={(props) => {
          return (
            <div
              {...props}
              className={cn({
                'top-1/2 -translate-y-1/2': !isVertical,
                'left-1/2 -translate-x-1/2': isVertical,
                'h-1 w-1': true,
                'rounded-full bg-indigo-500': true,
              })}
            ></div>
          )
        }}
      />
      {_props.error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1">{_props.error}</p>}
    </div>
  )
}
