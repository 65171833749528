import { XMarkIcon } from '@heroicons/react/24/outline'
import { LogoBlue } from 'assets'
import { Link } from 'react-router-dom'

import { SignInContent } from '../SignIn'

interface IProps {
  onClose: () => void
}

export const SignInModal = (props: IProps) => {
  return (
    <>
      <div
        tabIndex={-1}
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[198] md:inset-0 h-modal md:h-full justify-center flex"
      >
        <div className="relative">
          <div className="absolute top-10 right-10 z-10 hover:cursor-pointer" onClick={props.onClose}>
            <XMarkIcon className="w-6 h-6 text-gray-700" />
          </div>
          <div className={`relative p-4 w-full h-full md:h-auto max-w-xl`}>
            <div className="relative bg-white rounded-xl p-8 overflow-hidden border">
              <div className="flex items-center justify-center w-full md:w-auto mb-12">
                <Link to="/home">
                  <span className="sr-only">Finresi</span>
                  <LogoBlue />
                </Link>
              </div>
              <SignInContent />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-50 backdrop-blur-sm bg-white/30"></div>
    </>
  )
}
