import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { baseUrl, type InputType, INVALID_ALL_INPUTS } from 'config'
import { Tooltip } from 'flowbite-react'
import { ProfitStatus } from 'pages/Payment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { checkProfitReport } from 'services/apis'
import { Modal } from 'stories/components'
import { InputValidate, RenderInput, serialize, thisTime } from 'utils'

import { ProfitStatusText } from '../type'

const defaultInputs = (): Record<string, InputType> => {
  // const today = thisTime().format('YYYY-MM-DD')
  const from = thisTime().date(1).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD')
  const to = thisTime().endOf('month').format('YYYY-MM-DD')
  return {
    status: {
      inputType: 'select',
      title: 'Status',
      options: ProfitStatusText,
      value: ProfitStatus.Approved,
      required: true,
      span: 2,
    },
    sortType: {
      inputType: 'checkbox',
      title: 'Total Distributions',
      value: false,
      span: 2,
    },
    from: {
      inputType: 'text',
      type: 'date',
      title: 'From',
      value: from,
      error: '',
      required: true,
      disabled: false,
      span: 1,
    },
    to: {
      inputType: 'text',
      type: 'date',
      title: 'To',
      value: to,
      error: '',
      required: true,
      disabled: false,
      span: 1,
    },
  }
}

export const ExportProfitReport = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  const auth = useSelector((state: RootState) => state.auth)

  const onOpen = () => {
    setInputs(defaultInputs())
  }

  const onClose = () => {}

  const onChangeInput = async (key: string, value: any) => {
    const error = InputValidate({ ...inputs[key], value })
    let temp = cloneDeep(inputs)
    temp[key].value = value
    temp[key].error = error
    if (key === 'sortType') updateDisability(temp)
    setInputs(temp)
  }

  const updateDisability = (newInputs: Record<string, InputType>) => {
    const sortType = newInputs.sortType.value

    newInputs['from'].disabled = sortType
    newInputs['to'].disabled = sortType
    newInputs['from'].required = !sortType
    newInputs['to'].required = !sortType
  }

  const onExportExcel = () => {
    let data: any = {}
    Object.keys(inputs).map((key) => {
      if (key !== 'sortType') {
      }
      data[key] = inputs[key].value
    })
    if (inputs['sortType'].value) {
      delete data.from
      delete data.to
    }
    checkProfitReport(data).then((res) => {
      if (res) {
        const url = `${baseUrl}/payment/report/profit?${serialize(data)}&token=${auth.token}`

        const downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = 'Distribution_report.xml' // You can set a filename here if desired
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      } else {
        toast(`The matched distributions are empty.`, { type: 'warning' })
      }
    })
  }

  const onSubmit = () => {
    let hasError = false
    let data: any = {}
    let temp = cloneDeep(inputs)

    Object.keys(inputs).map((key) => {
      data[key] = inputs[key].value
      const error = InputValidate(inputs[key])
      if (error.length > 0) {
        temp[key].error = error
        hasError = true
      }
    })

    setInputs(temp)
    if (hasError) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    setIsOpen(false)
    setLastUpdatedAt(Date.now())
    onExportExcel()
  }

  return (
    <Modal
      button={
        <Tooltip content="Export Report">
          <div className="p-1 shadow hover-shadow1 cursor-pointer rounded text-indigo-500 transition-all duration-200">
            <ArrowDownTrayIcon className="w-5 h-5" />
          </div>
        </Tooltip>
      }
      title={'Distribution Report'}
      titleOkay="Export"
      isOpen={isOpen}
      lastUpdatedAt={lastUpdatedAt}
      onClose={onClose}
      onOk={onSubmit}
      onOpen={onOpen}
    >
      <div className="w-[480px] h-fit">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            return (
              <div key={index} className={`md:col-span-${input.span}`}>
                <RenderInput input={input} Key={key} onChange={onChangeInput} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
