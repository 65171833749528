import _imgAuthLeft from './authLeft.png'
import _bgAuthThank from './bgAuthThank.jpg'
import _imgCompany from './company.png'
import _imgIndividual from './individual.png'
import _imgIRA from './ira.png'
import _imgTrust from './trust.png'

export const imgCompany = _imgCompany
export const imgIndividual = _imgIndividual
export const imgIRA = _imgIRA
export const imgTrust = _imgTrust
export const imgAuthLeft = _imgAuthLeft
export const bgAuthThank = _bgAuthThank
