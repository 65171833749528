import { CheckCircleIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { InputType, INVALID_ALL_INPUTS } from 'config'
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { confirm, formatDate, getPrice3decimal, InputConvert, InputValidate, isHTML, RenderInput } from 'utils'

import { Button } from '../Button/Button'

export interface FormTableHeader {
  key: string
  title: string | JSX.Element
  value?: Function
}

interface FormTableProps {
  /**
   * Header of Table
   */
  header: Array<FormTableHeader>
  /**
   * Table data
   */
  data?: Array<Record<string, any>> | null
  /**
   * Table default data
   */
  defaultData?: Array<Record<string, any>> | null
  /**
   * Inputs
   */
  addText?: string
  type?: string
  inputs?: Function | Record<string, InputType>
  cols?: number
  permission?: number // 1: Full Permission, 2: Can Not Delete, Can Not Update But Can do Submit, 3: Disable Action with Can Not Submit
  renderAdditionalActions?: ((data: Record<string, any>, index: number) => JSX.Element) | null
  initRow?: ((data: Record<string, any>, index: number) => Record<string, any>) | null
  onSubmit?: ((id: any, data: Record<string, any>, finishEditing?: boolean) => Record<string, any>) | null
  onRemove?: ((id: any, index: number) => void) | null
  onChange?: ((data: Record<string, any>[]) => void) | null
  inputLogic?:
    | ((data: Record<string, InputType>, currentId?: number, currentKey?: string) => Record<string, any>)
    | null
}

const _FormTable = (
  {
    header = [],
    data: _data = null,
    defaultData = null,
    inputs: _inputs = {},
    cols = 2,
    permission = 1,
    initRow = null,
    inputLogic = null,
    addText = '',
    type = '',
    renderAdditionalActions = null,
    onSubmit: _onSubmit = null,
    onRemove: _onRemove = null,
    onChange: _onChange = null,
  }: FormTableProps,
  ref: React.Ref<any>,
) => {
  const [loading, setLoading] = useState(false)
  const [currentId, setCurrentId] = useState(0)
  const [data, setData] = useState<Array<Record<string, any>>>([])
  const [inputs, setInputs] = useState<Record<string, InputType>>({})
  const [status, setStatus] = useState(0)
  const currentIndex = useMemo(() => {
    if (!currentId) return null
    const index = data.findIndex((item) => item.id == currentId)
    if (index == -1) return null
    return index
  }, [_data, currentId])

  const initInputs = () => {
    if (typeof _inputs === 'function') setInputs(_inputs())
    else setInputs(_inputs)
  }

  useEffect(() => {
    if (addText.length > 0) setStatus(-1)
  }, [addText])

  useEffect(() => {
    initInputs()
  }, [_inputs])

  useEffect(() => {
    if (!defaultData || data.length) return
    if (initRow) {
      defaultData = defaultData.map((item, index) => {
        return initRow(item, index)
      })
    }
    setData(defaultData)
  }, [defaultData])

  useEffect(() => {
    if (!_data) return
    if (initRow) {
      _data = _data.map((item, index) => {
        return initRow(item, index)
      })
    }
    setData(_data)
  }, [_data])

  useImperativeHandle(ref, () => ({
    submit() {
      return onSubmit(false)
    },
  }))

  const resetInputs = (values: Record<string, any> = {}, id: number) => {
    let newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = InputConvert(newInputs[key], values[key])
      newInputs[key].error = ''
    })
    if (inputLogic !== null) newInputs = inputLogic(newInputs, id)
    setInputs(newInputs)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    value = InputConvert(newInputs[key], value)

    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    if (inputLogic !== null) newInputs = inputLogic(newInputs, currentId, key)
    setInputs(newInputs)
  }

  const onRemove = async (id: any, index: number) => {
    const content = (
      <div className="mb-4 text-[18px]">
        Do you want to remove <span className="font-bold">No.{index + 1}</span> item?
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    _onRemove && (await _onRemove(id, index))
    if (!_data) {
      const newData = cloneDeep(data)
      newData.splice(index, 1)
      setData(newData)
      _onChange && _onChange(newData)
    }
    onCancelEdit()
  }

  const onEdit = (id: number, index: number) => {
    if (currentId && id === currentId) {
      onCancelEdit()
      return
    }
    setCurrentId(id)
    setStatus(0)
    resetInputs(data[index], id)
  }

  const onSubmit = async (finishEditing = true) => {
    const newInputs = cloneDeep(inputs)
    let values: Record<string, any> = {}
    let hasError = false
    Object.keys(inputs).forEach((key) => {
      if (inputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      const { value, error } = newInputs[key]
      if (error) hasError = true
      values[key] = value
    })
    setInputs(newInputs)
    if (hasError) {
      toast(INVALID_ALL_INPUTS, { type: 'error' })
      return false
    }

    setLoading(true)
    if (_onSubmit) values = await _onSubmit(currentId, values, finishEditing)
    else
      values = {
        id: Date.now(),
        ...values,
      }
    if (finishEditing) {
      setCurrentId(0)
      initInputs()
    } else setCurrentId(values.id)

    setLoading(false)
    if (currentId) {
      if (!_data) {
        const newData = cloneDeep(data)
        const index = newData.findIndex((item) => item.id == currentId)
        newData[index] = {
          id: currentId,
          ...values,
        }
        setData(newData)
        _onChange && _onChange(newData)
      }
      finishEditing && setStatus(2)
    } else {
      if (!_data) {
        const newData = cloneDeep(data)
        newData.push(values)
        setData(newData)
        _onChange && _onChange(newData)
      }
      finishEditing && setStatus(1)
    }
    return values.id
  }

  const onCancelEdit = () => {
    setCurrentId(0)
    initInputs()
    if (addText.length > 0) {
      setStatus(-1)
    }
  }

  const renderAction = () => {
    if (permission === 3) return null
    if (currentId == 0) {
      return (
        <>
          <Button onClick={onSubmit} className="px-10" loading={loading}>
            Submit
          </Button>
          {addText.length > 0 && (
            <Button onClick={onCancelEdit} color="white" className="px-10 py-1">
              Cancel
            </Button>
          )}
        </>
      )
    }
    return (
      <>
        <Button onClick={onSubmit} className="px-10 py-1" loading={loading} disabled={permission !== 1}>
          Update
        </Button>
        <Button onClick={onCancelEdit} color="white" className="px-10 py-1">
          Cancel
        </Button>
      </>
    )
  }

  const headerActionFragment = useMemo(() => {
    if (permission === 3) return null
    return (
      <th scope="col" className="px-2 py-3">
        Action
      </th>
    )
  }, [permission])

  const renderActionTD = (noRowSpans: number, row: any, index: number) => {
    if (permission === 3) return null
    return (
      <td className="px-2 py-2" rowSpan={noRowSpans}>
        <div className="flex gap-1">
          {permission === 1 && (
            <span
              className="text-red-800 cursor-pointer hover-shadow1 p-1 rounded"
              onClick={() => onRemove(row.id, index)}
            >
              <TrashIcon className="w-4 h-4"></TrashIcon>
            </span>
          )}
          <span
            className="text-shade-blue cursor-pointer hover-shadow1 p-1 rounded"
            onClick={() => onEdit(row.id, index)}
          >
            <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
          </span>
          {renderAdditionalActions && renderAdditionalActions(row, index)}
        </div>
      </td>
    )
  }

  const renderEditSection = () => {
    if (status === -1) {
      if (addText.length > 0) {
        return (
          <div
            className="flex flex-wrap items-center px-2 py-1 rounded shadow w-fit text-shade-blue hover:underline cursor-pointer mx-4 gap-2"
            onClick={() => setStatus(0)}
          >
            <PlusIcon className="w-4 h-4"></PlusIcon>
            <span className="text-[14px]">{addText}</span>
          </div>
        )
      }
    }

    if (status === 1) {
      //After Adding Success
      return (
        <div
          className="mx-2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[15px] flex items-center"
          role="alert"
        >
          <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
          <span className="ml-1">Successfully Added!</span>
          <span className="font-variation-settings-600 ml-3 mr-1 cursor-pointer underline" onClick={() => setStatus(0)}>
            Click Here
          </span>
          to create new item.
        </div>
      )
    }

    if (status === 2) {
      //After Update Success
      return (
        <div
          className="mx-2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-[15px] flex items-center"
          role="alert"
        >
          <CheckCircleIcon className="w-6 h-6"></CheckCircleIcon>
          <span className="ml-1">Successfully Updated!</span>
          <span className="font-variation-settings-600 ml-3 mr-1 cursor-pointer underline" onClick={() => setStatus(0)}>
            Click Here
          </span>
          to create new item.
        </div>
      )
    }

    if (status === 0) {
      return (
        <div>
          {currentIndex !== null ? (
            <p className="text-md mb-3 border-b text-shade-blue ml-2">Updating No. {currentIndex + 1}</p>
          ) : null}

          <div className="px-4">
            {permission !== 3 && (
              <div className={`grid gap-4 md:grid-cols-${cols} grid-cols-1 mb-3`}>
                {Object.keys(inputs).map((key, index) => {
                  const input: any = cloneDeep(inputs[key])
                  if (input.visible === false) return null

                  if (input.inputType === 'divider') {
                    return (
                      <div className={`input md:col-span-${cols} -mx-2`} key={index}>
                        <div className="border p-2 rounded bg-gray-100 font-variation-settings-600 text-[14px]">
                          {input.title}
                        </div>
                      </div>
                    )
                  }
                  if (input.inputType === 'alert') {
                    return (
                      <div className={`input md:col-span-${cols}`} key={index}>
                        <div
                          className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative text-[15px]"
                          role="alert"
                        >
                          <span dangerouslySetInnerHTML={{ __html: input.value }} />
                        </div>
                      </div>
                    )
                  }
                  if (permission === 2 && currentIndex !== null) input.disabled = true
                  return (
                    <div className={`input md:col-span-${input.span || 1}`} key={index}>
                      <RenderInput input={input} Key={key} onChange={onChange} />
                    </div>
                  )
                })}
              </div>
            )}
            <div className="block text-center flex justify-center mt-4">{renderAction()}</div>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="parties-container overflow-auto mb-6 shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3">
                No
              </th>
              {header.map((item) => (
                <th scope="col" className="px-2 py-3" key={item.key}>
                  {item.title}
                </th>
              ))}
              {headerActionFragment}
            </tr>
          </thead>
          <tbody className="text-[14px] text-gray-900">
            {data.map((row, index: number) => {
              let noRowSpans = 1
              let showTrackRecordNote = false
              if (type === 'TrackRecord') {
                if (row.additionalNotes?.length > 0) {
                  showTrackRecordNote = true
                }
              }
              if (showTrackRecordNote) noRowSpans = 2
              let trBorderColor = currentIndex === index ? 'bg-gray-100' : ''
              return [
                <tr key={index} className={`border-b ${trBorderColor} ${row.tdClass}`}>
                  <td
                    rowSpan={noRowSpans}
                    className="px-2 py-2 pl-3 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {index + 1}
                  </td>
                  {header.map((header, rIndex) => {
                    let rlt = header.value ? header.value(row) : row[header.key]
                    if (inputs[header.key]?.type === 'thousandSep' && rlt !== undefined) rlt = getPrice3decimal(rlt)
                    if (inputs[header.key]?.type === 'date' && rlt !== undefined) rlt = formatDate(rlt)
                    if (typeof rlt == 'boolean') rlt = rlt ? 'Yes' : 'No'

                    return (
                      <td className="px-2 py-2" key={rIndex}>
                        {isHTML(rlt) ? (
                          <span className="dangerouslySetInnerHTML">
                            <div dangerouslySetInnerHTML={{ __html: rlt }}></div>
                          </span>
                        ) : (
                          <span className="">{rlt}</span>
                        )}
                      </td>
                    )
                  })}
                  {renderActionTD(noRowSpans, row, index)}
                </tr>,
                showTrackRecordNote && (
                  <tr className={`border-b ${trBorderColor}`} key={`1-${index}`}>
                    <td className="px-2 py-2" colSpan={header.length}>
                      <span className="italic">{row.additionalNotes}</span>
                    </td>
                  </tr>
                ),
              ]
            })}
          </tbody>
        </table>
      </div>

      {renderEditSection()}
    </div>
  )
}

export const FormTable = forwardRef(_FormTable)
