import { AUTH_TOKEN_SET } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signInRequest } from 'services/apis'
import { InputConvert, isEmpty, RenderInput, useTitle, validateInputs } from 'utils'

import { AuthFooter } from './Components'
import { SignUpSteps } from './SignUp'

const defaultInputs = (): Record<string, InputType> => {
  return {
    email: {
      title: '',
      placeholder: 'name@example.com',
      inputType: 'text0',
      type: 'email',
      error: '',
      required: true,
      autoFocus: true,
    },
    password: {
      title: '',
      placeholder: 'Password',
      inputType: 'text0',
      type: 'password',
      error: '',
      hasIcon: true,
      required: true,
    },
  }
}

export const SignInContent = () => {
  const [inputStates, setInputStates] = useState(defaultInputs())
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const refs: Record<string, any> = {
    email: useRef(null),
    password: useRef(null),
  }

  const onChange = (key: string, value: string) => {
    let newState = cloneDeep(inputStates)
    newState[key].value = InputConvert(newState[key], value)
    newState[key].error = ''
    setInputStates(newState)
  }

  const renderInput = (key: string) => {
    return RenderInput({
      input: {
        ...inputStates[key],
        onKeyDown: (e: any) => {
          if (e.keyCode != 13) return
          // if (key == 'email') refs['password']?.current?.focus()
          else onSubmit()
        },
        ref: refs[key],
      },
      Key: key,
      onChange,
    } as any)
  }

  const onSubmit = () => {
    const { hasError, data, newInputStates } = validateInputs(inputStates)
    if (hasError) {
      setInputStates(newInputStates)
      return
    }
    setLoading(true)

    signInRequest(data)
      .then(({ user, token }) => {
        if (isEmpty(user.isAccreditedInvestor)) {
          navigate('/auth/signUp', { state: { authStep: SignUpSteps.InvestorType, token } })
        } else {
          dispatch({ type: AUTH_TOKEN_SET, token, user })

          navigate('/dashboard')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <LayoutLoading show={isLoading} />
      <div className="p-1">
        <p className="text-sm mb-4">Please sign in or create an account to browse our offerings.</p>
        <p className="font-medium mb-2">Email</p>
        {renderInput('email')}

        <p className="font-medium mb-2">Password</p>
        {renderInput('password')}

        <AuthFooter />

        <button
          type="button"
          className="w-full text-white bg-indigo-500 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-md text-md px-5 py-2.5 mr-2 mb-6"
          onClick={onSubmit}
        >
          Log In
        </button>

        <div className="text-center text-md flex gap-2 items-center">
          <p className="text-sm">Don’t have an account?</p>
          <Link to="/auth/signUp" className="text-link mx-1">
            Get Started.
          </Link>
        </div>

        <div className="text-center text-md flex flex-wrap gap-2 items-center whitespace-nowrap">
          <p className="text-sm">Don't remember your password?</p>
          <Link to="/auth/forgetPassword" className="text-link mx-1">
            Forgot Password
          </Link>
        </div>
      </div>
    </>
  )
}

export const SignInPage = () => {
  useTitle('Login')

  return (
    <div className="max-w-md px-4 py-6 md:px-10 md:py-10 bg-white rounded-xl">
      <SignInContent />
    </div>
  )
}
