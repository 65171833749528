import { iconBathroom, iconBedroom } from 'assets'
import { Image } from 'components/Image'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import type { RootState } from 'reducers'
import { ProgressBar } from 'stories/components'
import { getDaysDiff, thousandSeperator, toS3Link } from 'utils'

import { FundedBy } from './FundedBy'
import { LoanStatusSpan } from './Status'
import type { Loan } from './types'

export interface FeaturedInvestProps {
  title: string
  bedrooms: number
  bathrooms: number
  ytm: number
  ltv: number
  term: number
  progress: number
  investors: number
  image: string
}

export interface OtherInvestProps {
  title: string
  description: string
  valuationDate: string
  yield: number
  ltv: number
  term: number
  amount: number
  progress: number
  investors: number
  image: string
}

export interface InvestCardMiniProps {
  city: string
  description: string
  progress: number
  offering: number
  arv: number
  image: string
}

export const FeaturedInvestCard = ({ data }: { data: Loan }) => {
  const auth = useSelector((state: RootState) => state.auth)
  const { isAuthenticated } = auth
  const routeTo = isAuthenticated ? `/marketplace/${data.id}` : '/auth/signUp'

  return (
    <div className="border border-gray-100 rounded-lg bg-white">
      <div className="w-full rounded-t-lg aspect-video relative">
        <Image src={toS3Link(data.images)} className="w-full rounded-t-lg aspect-video object-cover" />
        <LoanStatusSpan loan={data} isFeatured={false} />
      </div>
      <div className="divide-y divide-gray-200">
        <div className="px-4 py-2">
          <p className="text-sm text-gray-700 font-bold truncate mb-1 uppercase">
            {data.title} #{data.id}
          </p>
          <p className="font-medium truncate mb-1">{data.loanPurpose}</p>
          <p className="text-desc mb-1">{data.description}</p>
          <div className="flex text-desc gap-2 items-center">
            <img src={iconBedroom} className="w-6 h-6" />
            <p>{data.bedrooms} Bedrooms</p>
            <img src={iconBathroom} className="w-6 h-6" />
            <p>{data.bathrooms} Full Bath</p>
          </div>
        </div>
        <div className="flex px-4 py-2 items-center gap-2">
          <div className="bg-indigo-400 w-6 h-6 rounded-full" />
          <p className="text-xs flex-1 text-gray-500">Annual Yield</p>
          <p className="text-md font-medium">{data.ytm}%</p>
        </div>
        <div className="flex px-4 py-2 items-center gap-2">
          <div className="bg-indigo-400 w-6 h-6 rounded-full" />
          <p className="text-xs flex-1 text-gray-500">LTV</p>
          <p className="text-md font-medium">{data.ltv}%</p>
        </div>
        <div className="flex px-4 py-2 items-center gap-2">
          <div className="bg-indigo-400 w-6 h-6 rounded-full" />
          <p className="text-xs flex-1 text-gray-500">Term Remaining</p>
          <p className="text-md font-medium">{remainingLoanTerm(data.maturityDate)}</p>
        </div>
        <div className="px-4 py-2">
          <FundedBy loan={data} small />
          <ProgressBar className="mb-2" progress={data.progress} />
          {data.closed || data.funds == data.loanAmount - data.yldInvestment ? (
            <Link
              to={routeTo}
              className="block text-center w-full focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 underline"
            >
              View Details
            </Link>
          ) : (
            <Link
              to={routeTo}
              className="block text-center w-full text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"
            >
              Invest Now
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export const InvestCardMini = (props: InvestCardMiniProps) => {
  return (
    <div className="border rounded-lg shadow-lg">
      <img src={props.image} className="w-full rounded-t-lg aspect-video" />
      <div className="flex flex-col p-3 gap-2 text-center">
        <p className="text-md font-medium">{props.city}</p>
        <div className="text-xs h-12">{props.description}</div>
        <div className="w-full bg-gray-300 rounded-sm">
          <div className="bg-indigo-500 w-[60%] h-1" />
        </div>
        <div className="text-center text-xs">
          <p>{props.progress}% funded</p>
        </div>
        <div className="flex justify-between text-xs">
          <p>Offering</p>
          <p>ARV</p>
        </div>
        <div className="flex justify-between text-xs">
          <p>${thousandSeperator(props.offering)}</p>
          <p>{props.arv}%</p>
        </div>
      </div>
    </div>
  )
}

export const remainingLoanTerm = (maturityDate: Date) => {
  const remainingDays = getDaysDiff(maturityDate, Date.now())
  if (remainingDays < 0) return 'Past'
  const remainingTerm = Math.round(remainingDays / 30)

  return remainingTerm <= 1
    ? [0, 1].includes(remainingDays)
      ? remainingDays + ' Day'
      : remainingDays + ' Days'
    : remainingTerm + ' Months'
}
