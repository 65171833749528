export * from './api.status.constants'
export * from './api.url.constants'
export * from './fci.type'
export * from './input.type.constants'
export * from './loan.constants'
export * from './map.style.constants'
export * from './setting.constants'
export * from './states.constants'
export * from './user.constants'

export const TimeZone = 'America/New_York'

export const daysOfWeek: Record<number, string> = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export interface BaseFile {
  id: number
  name: string
  fileKey: string
  createdAt: number
  [key: string]: any
}

export const itemCountPerPage = 10

export const s3BucketUrl = 'https://yieldeasy-document-storage.s3.us-east-2.amazonaws.com'

export const smsTermsUrl = '/yieldeasy/public/Finresi%20SMS%20Terms%20of%20Service.docx'

export const googleMapApiKey = 'AIzaSyD6ulHDzur6UDnpyAAWyaqtWJNjv_bHzbg'

export const WireDepositExpireDays = 15

export const PenaltyPercent = 0.1

export const MinInvestmentAmount = 5000

export const MinInvestmentDays = 30

export const MinTrxAmount = 10
