import { LayoutLoading } from 'components/LayoutLoading'
import { TransactionColorMap } from 'components/TransactionStatus'
import { itemCountPerPage } from 'config'
import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'
import type { DBInvestment } from 'pages/Payment'
import { InvestDetails } from 'pages/Payment/modal'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { getInvestments } from 'services/apis'
import { ButtonGroup, ResponsiveTable } from 'stories/components'
import { formatDateLong, normalizeNumber } from 'utils'

export const EnvelopeColorMap: Record<string, string> = {
  sent: 'bg-indigo-100 text-gray-800',
  delivered: 'bg-indigo-600 text-white',
  completed: 'bg-green-500 text-white',
  declined: 'bg-red-500 text-white',
  voided: 'bg-yellow-200 text-gray-800',
  signed: 'bg-green-100 text-gray-600',
  created: 'bg-green-100 text-gray-600',
}

// Not Use
export const SignStatisticsPage = () => {
  const [filters, setFilters] = useState<Record<string, any>>({
    duration: '-3',
    pageNum: 0,
  })
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [data, setData] = useState<DBInvestment[]>([])
  const [selectedItem, setSelectedItem] = useState<DBInvestment | null>(null)

  useEffect(() => {
    refetch(filters)
  }, [])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (data.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getInvestments({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ total, data: _data }) => {
        if (_pageNum == 0) setData(_data)
        else {
          const newData = cloneDeep(data)
          newData.push(..._data)
          setData(newData)
        }
        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (key: 'duration' | 'pageNum', value: any, needRefetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    refetch(newFilters, needRefetch ? 0 : -1)
  }

  return (
    <div className="w-full relative py-4 md:py-10 min-h-[100vh]">
      <LayoutLoading show={isLoading && data.length == 0} />
      <div className="bg-indigo-800 w-full absolute top-0 h-196 md:h-144 lg:h-96" />
      <div className="fullContent relative">
        <div className="flex-1 p-4 lg:p-0 text-white mb-2 lg:mb-10">
          <p className="text-4xl mb-2">Sign and Agreements</p>
        </div>

        <div className="flex items-center mb-4 justify-between">
          <p className="text-white text-xl">Histories ({total})</p>
          <ButtonGroup
            title={{ '-3': '1 - 3 months', '3-12': '3 months - 1 year', '12-': '1+ year' }}
            value={filters.duration}
            onChange={(v) => onChangeFilter('duration', v)}
          />
        </div>

        <InfiniteScroll
          dataLength={data.length}
          next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
          hasMore={data.length < total}
          loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
        >
          <ResponsiveTable
            header={['Loan', 'Method', 'Amount', 'APY', 'Sign Status', 'Invest Status', 'Requested At', 'Expires At']}
            headerClassName="hidden lg:grid"
            containerClassName={`text-sm rounded-lg overflow-hidden gap-2`}
            contentClassName={`lg:grid grid-cols-12 text-sm py-4 border rounded-lg hover:bg-gray-100 cursor-pointer`}
            size={[4, 1, 1, 1, 1, 1, 1, 1]}
            orderBy={filters.orderBy}
            orderDir={filters.orderDir}
            onClickItem={(index: number) => setSelectedItem(data[index])}
            data={data.map((item, index) => [
              <div className="flex items-center gap-2" key={index}>
                <p>{index + 1}.</p>
                <p>{item.loan.propertyAddress}</p>
                <div className="font-bold text-indigo-500 hover:underline cursor-pointer">
                  <Link to={`/marketplace/${item.loan.id}`}>
                    <span>#{item.loan.id}</span>
                  </Link>
                </div>
              </div>,
              item.method,
              `$${normalizeNumber(item.amount)}`,
              `${item.loan.ytm}%`,
              <div className="flex gap-2 items-center justify-end px-4 lg:px-0 lg:justify-start capitalize">
                <span className={`w-4 h-4 rounded-full ${EnvelopeColorMap[item.envelopeStatus]}`} />
                {item.envelopeStatus}
              </div>,
              <p className={`capitalize ${TransactionColorMap[item.status]} inline-block px-2 py-1 rounded text-sm`}>
                {item.status}
              </p>,
              formatDateLong(item.createdAt),
              formatDateLong(moment(item.createdAt).add(120, 'days').toDate()),
            ])}
            classNames={['pl-4', '', '', '', '']}
          />
        </InfiniteScroll>

        {selectedItem && <InvestDetails data={selectedItem as DBInvestment} onClose={() => setSelectedItem(null)} />}
      </div>
    </div>
  )
}
