import { CheckIcon, ClockIcon, EyeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { type TaxCert, TaxCertFormNames, TaxCertStatus, TaxCertStatusText } from 'pages/Profile/TaxCertType'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUserTaxCert, getUserTaxCertEnvelopeUrl, updateUserTaxCert } from 'services/apis'
import { confirm, formatTime } from 'utils'

import { TaxCertHistoryModal, TaxCertReasonModal } from './modal'
import type { User } from './type'

export const TaxCertDetails = ({ user, onClose }: { user: User; onClose: Function }) => {
  const [searchParams] = useSearchParams()
  const defaultTaxCertId = Number(searchParams.get('taxCert') || 0)
  const [data, setData] = useState<TaxCert[] | null>(null)
  const [loading, setLoading] = useState(false)
  const [historyId, setHistoryId] = useState(0)
  const [loadingId, setLoadingId] = useState('')
  const [rejectId, setRejectId] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (defaultTaxCertId) navigate('/manageUsers')
  }, [])

  useEffect(() => {
    refresh()
  }, [user])

  const refresh = () => {
    setLoading(true)
    getUserTaxCert(user.id)
      .then(({ data }) => setData(data))
      .finally(() => setLoading(false))
  }

  const onView = (envelopeId: string) => {
    if (loadingId) return

    setLoadingId(envelopeId)
    getUserTaxCertEnvelopeUrl(user.id, envelopeId)
      .then(({ url }) => window.open(url, '_blank'))
      .finally(() => setLoadingId(''))
  }

  const onRejectClose = async (reason: string | null) => {
    const id = rejectId
    setRejectId(0)

    if (!reason) return

    setLoading(true)
    updateUserTaxCert(user.id, {
      id,
      status: TaxCertStatus.Rejected,
      reason,
    })
      .then(() => refresh())
      .finally(() => setLoading(false))
  }

  const onApprove = async (id: number) => {
    const result = await confirm('Are you sure to approve this form?')
    if (!result) return

    setLoading(true)

    updateUserTaxCert(user.id, {
      id,
      status: TaxCertStatus.Approved,
      reason: 'Approved',
    })
      .then(() => refresh())
      .finally(() => setLoading(false))
  }

  const renderList = () => {
    if (!data) return null

    const tableData = data.map((item, index) => [
      index + 1,
      TaxCertFormNames[item.type],
      TaxCertStatusText[item.status],
      formatTime(item.createdAt),
      <div className="flex items-center gap-2 w-32">
        <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setHistoryId(item.id)}>
          <ClockIcon className="w-4 h-4 text-indigo-600" />
          <p className="text-sm text-indigo-600">History</p>
        </div>
        <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onView(item.envelopeId)}>
          {loadingId == item.envelopeId ? <Loading /> : <EyeIcon className="w-4 h-4 text-indigo-600" />}
          <p className="text-sm text-indigo-600">View</p>
        </div>
      </div>,
      item.status == TaxCertStatus.UnderReview && (
        <div className="flex items-center gap-2 w-32">
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => onApprove(item.id)}>
            <CheckIcon className="w-4 h-4 text-green-400" />
            <p className="text-sm text-green-600">Approve</p>
          </div>
          <div className="flex items-center gap-1 hover:underline cursor-pointer" onClick={() => setRejectId(item.id)}>
            <XMarkIcon className="w-4 h-4 text-red-600" />
            <p className="text-sm text-red-600">Reject</p>
          </div>
        </div>
      ),
    ])

    return (
      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {['No', 'Type', 'Status', 'Created At', '', ''].map((th, index) => (
              <th className={`px-3 py-3 ${index == 0 && 'w-12'}`} key={index}>
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
              {tableData[index].map((value, index) => (
                <td className="pl-3 py-2" key={index}>
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderHistoryModal = () => {
    if (!historyId || !data) return null

    const item = data.find((item) => item.id == historyId)
    if (!item) return null

    return <TaxCertHistoryModal createdBy history={item.history} onClose={() => setHistoryId(0)} />
  }

  return (
    <div className="relative my-2 border py-2 px-3 rounded bg-white">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold mb-2 flex items-center gap-2">
          <span className="text-base">Forms W-8BEN/W-8BEN-E of this User</span>
          {loading && <Loading />}
        </h2>
        <span
          className="text-sm flex items-center ml-2 gap-1 text-red-800 hover:underline cursor-pointer"
          onClick={() => onClose()}
        >
          Close
          <XMarkIcon className="w-4 h-4" />
        </span>
      </div>

      {renderList()}
      {renderHistoryModal()}
      {!!rejectId && <TaxCertReasonModal forUser={false} onClose={onRejectClose} />}
    </div>
  )
}
