import { authUpdateProfile, onSetDepositData } from 'actions'
import { LayoutLoading } from 'components/LayoutLoading'
import { cloneDeep } from 'lodash'
import { IResourceDocument, ResourceDocumentType } from 'pages/Admin/Tools/Resources'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import {
  downloadBlankSubAgreement,
  getLatestResources,
  openS3Document,
  openSubAgreement,
  signSubAgreement,
  updateSubAgreement,
} from 'services/apis'
import { Button, Checkbox } from 'stories/components'
import { formatDate } from 'utils'

import { TransactionMethod } from '../constants'

export const DepositConfirmation = ({
  isDeposit,
  data,
  method,
  isLoading: _isLoading,
  onSubmit: _onSubmit,
}: {
  isDeposit: boolean
  data: Record<number, any>
  method: TransactionMethod
  isLoading: boolean
  onSubmit: Function
}) => {
  const [signStatus, setSignStatus] = useState<Record<string, boolean>>({})
  const [resources, setResources] = useState<IResourceDocument[]>([])
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const { depositData } = useSelector((state: RootState) => state.application)
  const { profile } = useSelector((state: RootState) => state.auth)
  const { subAgreementAt } = profile

  useEffect(() => {
    ;(async () => {
      if (depositData) {
        setSignStatus({
          rule501: true,
          ppm: true,
          termsof: true,
        })
        dispatch(onSetDepositData(null, 0))
        return
      }

      setLoading(true)
      const res = await getLatestResources()
      setResources(res)
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    const id = searchParams.get('envelopeId')
    const event = searchParams.get('event')

    if (!id || !event) return

    // navigate('/deposit')
    if (event != 'signing_complete') return

    setLoading(true)
    updateSubAgreement(id)
      .then(() => {
        // toast('The Subscription Agreement has been successfully signed.', { type: 'success' })
        onSignSuccess({ subAgreement: id, subAgreementAt: new Date().toString() })
      })
      .finally(() => setLoading(false))
  }, [])

  const isAllChecked = useMemo(() => {
    if (!isDeposit) return true
    return signStatus['rule501'] && signStatus['ppm'] && signStatus['termsof']
  }, [isDeposit, signStatus, subAgreementAt])

  const strDirection = useMemo(() => (isDeposit ? 'Deposit' : 'Withdraw'), [isDeposit])

  const onChangeSign = (key: string, v: boolean) => {
    const newStatus = cloneDeep(signStatus)
    newStatus[key] = v
    setSignStatus(newStatus)
  }

  const openDocument = async (type: ResourceDocumentType) => {
    setLoading(true)
    const targetResource = resources.find((item) => item.type === type)

    if (!targetResource) {
      toast(`This Resource document doesn't exist`, { type: 'error' })
      return
    }

    await openS3Document(targetResource.fileKey, false, targetResource.name + '.pdf')

    setLoading(false)
  }

  const onOpenAgreement = () => {
    if (isLoading) return
    setLoading(true)
    openSubAgreement()
      .then(({ redirectUrl }) => window.open(redirectUrl, '_blank'))
      .finally(() => setLoading(false))
  }

  const onDownloadBlankAgreement = () => {
    if (isLoading) return
    setLoading(true)
    downloadBlankSubAgreement(method).finally(() => setLoading(false))
  }

  const onSubmit = () => {
    if (isLoading) return

    if (!subAgreementAt && isDeposit) {
      setLoading(true)
      signSubAgreement([method]).then(({ data: subAgreeData, redirectUrl, depositId }) => {
        if (subAgreeData) {
          onSignSuccess(subAgreeData)
          return
        }

        dispatch(onSetDepositData(data, depositId))
        setTimeout(() => {
          window.open(redirectUrl, '_self')
        }, 500)
      })
    } else _onSubmit()
  }

  const onSignSuccess = (data: { subAgreement: string; subAgreementAt: string }) => {
    setLoading(false)
    dispatch(
      authUpdateProfile({
        ...profile,
        subAgreement: data.subAgreement,
        subAgreementAt: new Date(data.subAgreementAt),
      }),
    )
    _onSubmit()
  }

  return (
    <>
      {isDeposit && (
        <div className="py-4 flex flex-col gap-2">
          <LayoutLoading show={isLoading || _isLoading} />

          <Checkbox
            id="rule501"
            title={
              <p className="font-normal">
                I am an Accredited Investor according to
                <a
                  href="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501"
                  target="_blank"
                  className="text-link mx-1"
                >
                  Rule 501
                </a>
              </p>
            }
            value={signStatus['rule501']}
            onChange={(v) => onChangeSign('rule501', v)}
          />

          <Checkbox
            id="ppm"
            title={
              <p className="font-normal">
                I have read and understood the
                <span
                  className="mx-1 text-link cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    openDocument(ResourceDocumentType.PPM)
                    return false
                  }}
                >
                  Private Placement Memorandum
                </span>
                , including without limitation, all <b className="font-medium mx-1">risk factors</b> and
                <b className="font-medium mx-1">conflicts of interest</b> set forth therein.
              </p>
            }
            value={signStatus['ppm']}
            onChange={(v) => onChangeSign('ppm', v)}
          />

          <Checkbox
            id="termsof"
            title={
              <p className="font-normal">
                I have read and agree to the terms of the
                <span
                  className="ml-1 text-link cursor-pointer"
                  onClick={(e) => {
                    if (subAgreementAt) onOpenAgreement()
                    else onDownloadBlankAgreement()
                    e.preventDefault()
                    return false
                  }}
                >
                  Subscription Agreement
                </span>
                {subAgreementAt ? <> signed by me on {formatDate(subAgreementAt)}.</> : '.'}
              </p>
            }
            value={signStatus['termsof']}
            onChange={(v) => onChangeSign('termsof', v)}
          />
        </div>
      )}

      <div className="pt-4">
        <Button full color="green" onClick={onSubmit} loading={isLoading || _isLoading} disabled={!isAllChecked}>
          {subAgreementAt || !isDeposit ? <>Confirm {strDirection}</> : 'Sign Agreement and Confirm Deposit'}
        </Button>

        {method == TransactionMethod.Plaid && (
          <p className="my-1 text-sm text-yellow-500 p-2 rounded-md border border-yellow-400 bg-yellow-50">
            For security reasons, your bank may require you to re-enter your banking credentials.
          </p>
        )}
      </div>
    </>
  )
}
