import { ON_APPLICATION_DEPOSIT_DATA, ON_APPLICATION_ERROR } from './types'

export function onApplicationError(error: any, toastDuration: number = 500) {
  return {
    type: ON_APPLICATION_ERROR,
    error: error || null,
    toastDuration,
  }
}

export function onSetDepositData(data: Record<number, any> | null, depositId: number) {
  return {
    type: ON_APPLICATION_DEPOSIT_DATA,
    depositData: data,
    depositId,
  }
}
