import {
  BellAlertIcon,
  BuildingLibraryIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  GlobeAltIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { AccountStatus, AccountType, IRADetails } from 'config'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { RootState } from 'reducers'
import { SideMenu, SideMenuItem } from 'stories/components'

import { AccountDetails } from './AccountDetails'
import { CertFormationTab } from './CertFormationTab'
import { ChangePassword } from './ChangePassword'
import { InvestReadyTab } from './InvestReady'
import { KYCStatus } from './KYCStatus'
import { Notifications } from './Notifications'
import { TaxCertTab } from './TaxCertTab'

const notpassedIcon = <p className="border rounded-full inline-block border-black w-5 h-5"></p>
const passedIcon = (
  <p className="bg-blue-500 rounded-full">
    <CheckIcon className="text-white w-5 h-5" />
  </p>
)

export const ProfileMenuTitles = {
  PROFILE: 'Account Information',
  KYC: 'Identity Verification',
  INVEST_READY: 'Accredited Investor Verification',
  TAX_CERT: 'Non U.S Investor Tax Forms',
  CERT_FORMATION: 'Entity Setup',
  TRUST_DOCUMENT: 'Trust Document',
  LLC_DOCUMENT: 'LLC Document',
  CHANGE_PASSWORD: 'Change Password',
  Notifications: 'Notifications',
}

export const MyProfilePage = () => {
  const location = useLocation()
  const { profile } = useSelector((state: RootState) => state.auth)

  const [activeMenu, setMenu] = useState('')

  const sideMenus: SideMenuItem[] = useMemo(
    () =>
      [
        {
          title: 'Complete Profile',
          body: (
            <div className="border-b pb-2">
              <p className="text-base mb-2">Complete your profile</p>
              <p className="text-desc">Few things to start investment</p>
            </div>
          ),
          description: true,
        },
        {
          title: ProfileMenuTitles.PROFILE,
          icon: UserIcon,
          right: profile.status >= AccountStatus.ProfileCompleted ? passedIcon : notpassedIcon,
        },
        {
          title: ProfileMenuTitles.KYC,
          icon: ShieldCheckIcon,
          right: profile.status >= AccountStatus.KycPassed ? passedIcon : notpassedIcon,
        },
        {
          title: ProfileMenuTitles.INVEST_READY,
          icon: BuildingLibraryIcon,
          right:
            profile.status >= AccountStatus.KycPassed &&
            profile.accreditedExpiresOn &&
            new Date(profile.accreditedExpiresOn) > new Date()
              ? passedIcon
              : notpassedIcon,
          visible: !profile.country || (profile.country && profile.country === 'US'),
        },
        {
          title: ProfileMenuTitles.TAX_CERT,
          icon: GlobeAltIcon,
          right: profile.taxCertPassed ? passedIcon : notpassedIcon,
          visible: profile.status >= AccountStatus.KycPassed && profile.country && profile.country != 'US',
        },
        {
          title: ProfileMenuTitles.CERT_FORMATION,
          icon: ClipboardDocumentListIcon,
          right:
            [AccountType.Company].includes(profile.accountType) && profile.certFormationPassed
              ? passedIcon
              : notpassedIcon,
          visible: [AccountType.Company].includes(profile.accountType),
        },
        {
          title: ProfileMenuTitles.TRUST_DOCUMENT,
          icon: ClipboardDocumentListIcon,
          right: profile.accountType === AccountType.Trust && profile.certFormationPassed ? passedIcon : notpassedIcon,
          visible: [AccountType.Trust].includes(profile.accountType),
        },
        {
          title: ProfileMenuTitles.LLC_DOCUMENT,
          icon: ClipboardDocumentListIcon,
          right: profile.accountType === AccountType.Ira && profile.certFormationPassed ? passedIcon : notpassedIcon,
          visible:
            [AccountType.Ira].includes(profile.accountType) &&
            (profile.accountDetails as IRADetails)?.IRA_Type === IRAType.LLC,
        },
        {
          title: '_Settings',
          body: (
            <div className="border-b w-full mt-4 pb-2">
              <p className="text-base">Account Settings</p>
              {/* <p className="text-desc">Few things to start investment</p> */}
            </div>
          ),
          description: true,
        },
        { title: ProfileMenuTitles.CHANGE_PASSWORD, icon: LockClosedIcon },
        { title: ProfileMenuTitles.Notifications, icon: BellAlertIcon },
      ].filter((menu) => menu.visible !== false),
    [profile],
  )

  useEffect(() => {
    if (!location.hash) {
      setMenu(ProfileMenuTitles.PROFILE)
      return
    }

    const menu = location.hash.replaceAll('#', '').replaceAll('%20', ' ')
    let menus = sideMenus.map((v) => v.title)

    if (menus.includes(menu)) setMenu(menu)
  }, [location.hash])

  const renderMenu = useMemo(() => {
    if (!activeMenu) return <></>

    return (
      <SideMenu
        defaultMenu={activeMenu}
        menus={sideMenus}
        activeColor="blue"
        onClick={(menu: string) => setMenu(menu)}
      />
    )
  }, [activeMenu, profile])

  const renderContent = useMemo(() => {
    const pageMap = {
      [ProfileMenuTitles.PROFILE]: <AccountDetails />,
      [ProfileMenuTitles.KYC]: <KYCStatus />,
      [ProfileMenuTitles.INVEST_READY]: <InvestReadyTab />,
      [ProfileMenuTitles.CERT_FORMATION]: <CertFormationTab />,
      [ProfileMenuTitles.TRUST_DOCUMENT]: <CertFormationTab />,
      [ProfileMenuTitles.LLC_DOCUMENT]: <CertFormationTab />,
      [ProfileMenuTitles.TAX_CERT]: <TaxCertTab />,
      [ProfileMenuTitles.CHANGE_PASSWORD]: <ChangePassword />,
      [ProfileMenuTitles.Notifications]: <Notifications />,
    }
    return pageMap[activeMenu]
  }, [activeMenu])

  const activeMenuData = useMemo(() => {
    return sideMenus.find((v) => v.title == activeMenu)
  }, [activeMenu, profile])

  const ActiveIcon = activeMenuData?.icon

  return (
    <div className="py-4 md:py-10 min-h-[80vh] relative">
      {/* <div className="bg-indigo-800 w-full absolute top-0 h-88" /> */}
      {/* <p className="text-4xl font-semibold mb-10">My Profile</p> */}

      <div className="fullContent relative">
        <div className="lg:grid flex flex-col lg:grid-cols-4 gap-6">
          <div>
            <div className="px-4 py-4 bg-white rounded-lg border">{renderMenu}</div>
          </div>
          {activeMenuData && (
            <div className="col-span-3 p-6 bg-white rounded-lg flex flex-col gap-4 border min-h-[50vh] relative">
              <p className="flex text-2xl font-semibold items-center gap-2">
                {ActiveIcon && <ActiveIcon className="w-8 h-8" />} {activeMenu}
                {/* {activeMenuData && activeMenuData.right} */}
              </p>
              {renderContent}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
