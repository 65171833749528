import { ArrowUpTrayIcon, ClockIcon, CloudArrowUpIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { TargetBox } from 'components/DragDrop'
import { PlainInput } from 'components/PlainInput'
import type { BaseFile } from 'config'
import { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
// import { toast } from 'react-toastify'
import { openS3Document, uploadFiles } from 'services/apis'
import svgLoading from 'stories/assets/loading.svg'
import { confirm, formatTime } from 'utils'

import { Select } from '../Select/Select'
import { Tooltip } from '../Tooltip/Tooltip'

export interface FileTableOption {
  key: string
  title: string
  options?: string[]
}

interface InputFileTableProps {
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string

  filePath: string
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Icon component
   */
  icon?: string | JSX.Element | null

  multiple?: boolean

  acceptFileTypes?: string

  history?: boolean

  value?: BaseFile[]

  options?: FileTableOption[]

  sort?: boolean

  moreControls?: JSX.Element | null

  onChange: (e: BaseFile[]) => void
  showHistory?: () => void
}

export const InputFileTable = ({
  tooltip = '',
  title = '',
  name = '',
  error = '',
  className = '',
  filePath = '',
  required,
  multiple,
  acceptFileTypes = 'application/pdf',
  history,
  value = [],
  options = [],
  sort = false,
  moreControls = null,
  onChange = () => {},
  showHistory = () => {},
}: InputFileTableProps) => {
  if (!name) name = `file-input-${Date.now()}`

  const [isLoading, setLoading] = useState(false)
  // const [filePath, setFilePath] = useState(_filePath)

  // useEffect(() => {
  //   setLoading(false)
  //   if (_filePath) setFilePath(filePath)
  //   else {
  //     const loanNumber = setLoanNumber()
  //     setFilePath(`loan/${loanNumber}/document`)
  //   }
  // }, [_filePath])

  const onDropDocument = async (document: any) => {
    if (document.files) {
      uploadDocumentFiles(document.files)
    }
  }

  const onUploadNewfile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    if (!files || files.length == 0) return
    const arrFile: Array<File> = []
    for (let i = 0; i < files.length; i++) arrFile.push(files[i])

    uploadDocumentFiles(arrFile)
  }

  const uploadDocumentFiles = (files: File[]): Promise<any> => {
    return new Promise((resolve) => {
      const data = {
        path: filePath,
      }
      let acceptedFiles: any = []
      files.map((file) => {
        const { name } = file
        const params = name.toLowerCase().split('.')
        const length = params.length
        const ext = params[length - 1]
        console.log('ext', ext)
        // if (['pdf', 'xml'].indexOf(ext) !== -1) {
        acceptedFiles.push(file)
        // } else {
        //   toast(`${name} is not .pdf or .xml`, { type: 'error' })
        // }
      })
      setLoading(true)

      uploadFiles(data, acceptedFiles).then(async (keys: Array<string>) => {
        const newData = cloneDeep(value)
        const newDocs: BaseFile[] = []
        const id = Date.now()
        keys.forEach((key, index) => {
          newDocs.push({
            id: id + index,
            name: acceptedFiles[index].name,
            fileKey: key,
            createdAt: Date.now(),
          })
        })
        newData.push(...newDocs)
        setLoading(false)
        onChange(newData)
        resolve(true)
      })
    })
  }

  const onUpdateDocumentProp = async (index: number, key: string, newName: string) => {
    const newData = cloneDeep(value)
    newData[index][key] = newName
    onChange(newData)
  }

  const onUpdateDocumentSort = (index: number, newOrder: number) => {
    if (index == newOrder) return
    const newData = cloneDeep(value)
    const movingData = newData.splice(index, 1)
    newData.splice(newOrder, 0, ...movingData)
    onChange(newData)
  }

  const onRemoveDocument = async (document: BaseFile, index: number) => {
    const content = (
      <div className="text-gray-900 mb-4 text-md">
        Are you sure want to delete this document?
        <div className="text-gray-800 flex flex-col text-left text-sm">
          <span>File Name: {document.name}</span>
          <span>Uploaded Date: {formatTime(new Date(document.createdAt))}</span>
        </div>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    const newData = cloneDeep(value)
    newData.splice(index, 1)
    onChange(newData)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={`flex flex-col gap-2 ${className}`}>
        <TargetBox types={[NativeTypes.FILE]} onDrop={(document: any) => onDropDocument(document)}>
          <div className={`relative group border-b`}>
            <div className="relative w-full rounded p-2 text-sm text-left">
              <div
                className={`absolute right-[calc(50%-135px)] w-full h-fit ${moreControls ? 'sm:top-1 top-8' : 'top-1'}`}
              >
                <div className="absolute top-0 right-0 border-dashed border-2 border-gray-300 px-4 pt-1 text-gray-300 w-[270px] text-center">
                  <CloudArrowUpIcon className="w-10 h-10 mx-auto" />
                  <span>Drag and drop files.</span>
                </div>
              </div>
              <div className="flex border-b pb-1 gap-1">
                <p className="flex flex-auto items-center">
                  {title}
                  {required && '*'}({value.length}){tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
                  {history && (
                    <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
                      <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
                    </span>
                  )}{' '}
                </p>
                {isLoading && <img src={svgLoading} className="inline w-5 h-5 text-white animate-spin" />}
                {moreControls}
                <label className="text-gray-700 p-1 hover-shadow1 cursor-pointer rounded" htmlFor={name}>
                  <ArrowUpTrayIcon className="w-4 h-4"></ArrowUpTrayIcon>
                </label>

                <input
                  className="hidden"
                  id={name}
                  type="file"
                  accept={acceptFileTypes}
                  multiple={multiple}
                  required
                  onChange={(event) => onUploadNewfile(event)}
                />
              </div>
              <div className="overflow-auto">
                <table className="w-full text-[13px]">
                  <thead className="font-medium text-gray-600 bg-gray-100">
                    <tr>
                      <th className="px-2 py-1">No</th>
                      <th className="px-2 py-1">File Name</th>
                      {options.map((option, index) => (
                        <th key={index} className="px-2">
                          {option.title}
                        </th>
                      ))}
                      {sort && <th className="px-2">Order</th>}
                      <th className="px-2">Uploaded Date</th>
                      <th className="px-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-900 text-[14px]">
                    {value.map((document: any, index: number) => (
                      <tr className={`hover:bg-slate-100 ${index % 2 && 'bg-gray-50'}`} key={index}>
                        <td className="px-2">{index + 1}</td>
                        <td className="px-2">
                          <PlainInput
                            value={document.name}
                            content={document.name}
                            onChange={(newName: string) => onUpdateDocumentProp(index, 'name', newName)}
                          />
                        </td>
                        {options.map(({ key, options }, id) => (
                          <td key={`${index}-${id}`} className="px-2 min-w-[120px] w-60">
                            {options ? (
                              <Select
                                id={`side-document-${key}-${document.id}`}
                                size={3}
                                className="-mb-4 bg-white"
                                options={options}
                                value={document[key]}
                                onChange={(value) => onUpdateDocumentProp(index, key, value)}
                                hasDefaultOption
                              />
                            ) : (
                              document[key]
                            )}
                          </td>
                        ))}
                        {sort && (
                          <td className="px-2 min-w-[70px] w-24">
                            <Select
                              id={`side-document-sort-${document.id}`}
                              size={3}
                              className="-mb-4 bg-white"
                              options={[...Array(value.length).keys()].map((v) => String(Number(v) + 1))}
                              value={`${index + 1}`}
                              onChange={(value) => onUpdateDocumentSort(index, Number(value) - 1)}
                            />
                          </td>
                        )}
                        <td className="px-2 w-36 whitespace-nowrap">{formatTime(new Date(document.createdAt))}</td>
                        <td className="px-2 w-20 py-1">
                          <button
                            className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded"
                            onClick={() => openS3Document(document.fileKey)}
                          >
                            <EyeIcon className="w-4 h-4" />
                          </button>
                          <button
                            className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded"
                            onClick={() => onRemoveDocument(document, index)}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TargetBox>
        {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mt-2">{error}</p>}
      </div>
    </DndProvider>
  )
}
