import { imgBloomberg, imgBusinessInsider, imgForbest, imgLesEchos, imgTc } from 'assets'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { ArticleType, IArticle } from 'pages/Article/types'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllArticles, getLastArticles } from 'services/apis/articles'
import { Pagination } from 'stories/components'
import { useTitle } from 'utils'

export const Blogs = () => {
  useTitle('Blog')
  const { page } = useParams()
  const navigate = useNavigate()

  const [articles, setArticles] = useState<IArticle[]>([])
  const [lastArticles, setLastArticles] = useState<IArticle[]>([])
  const [pageNum, setPageNum] = useState(0)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    getLastArticles().then((data) => {
      setLastArticles(data)
    })
  }, [])

  useEffect(() => {
    setPageNum(Number(page) - 1)
    fetchData(Number(page) - 1)
  }, [page])

  const fetchData = (page: number = pageNum) => {
    setLoading(true)
    getAllArticles(page)
      .then((data) => {
        setTotal(data.totalCount)
        setArticles(data.tmpList)
      })
      .finally(() => setLoading(false))
  }

  const selectArticle = (article: IArticle) => {
    if (article.articleType === ArticleType.ARTICLE) {
      window.open(article.link, '_blank', 'noopener')
    } else {
      navigate('/blog/' + article.articleSlug)
    }
  }

  const convertToValidUrl = (input: string) => {
    try {
      // Attempt to create a URL object which will throw an error if the URL is invalid
      const url = new URL(input)
      return url.hostname // Returns the normalized URL
    } catch (error) {
      // If URL is invalid, handle accordingly (e.g., sanitize or default behavior)
      return ' ' // or handle default URL
    }
  }

  const onPageNavigate = (num: number) => {
    // fetchData(num)
    // setPageNum(num)
    navigate(`/blogs/${num + 1}`)
  }

  return (
    <div>
      <div className="w-full bg-zinc-100 py-24">
        <div className="flex flex-col content">
          <p className="text-black font-semibold text-5xl mb-4">Our Blogs/News</p>
          <p className="text-black font-normal text-sm mb-3">Check out Finresi content available for all users</p>

          <div className="relative grid grid-cols-12 gap-6 my-4">
            <LayoutLoading show={isLoading} />

            {articles.map((article, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-4 hover:scale-105 transition-all duration-200"
                onClick={() => selectArticle(article)}
              >
                <img
                  src={article.imageURL}
                  className="w-full h-48 object-cover"
                  alt={article?.altImage && article?.altImage.length > 0 ? article?.altImage : article?.title}
                />
                <div className="p-4">
                  <div className="flex space-x-2 mb-2">
                    {article.tags.split(',').map((tag, index1) => (
                      <span
                        key={`${index}-${index1}`}
                        className="bg-green-100 capitalize text-light-green text-xs font-semibold mr-1 px-1 py-0.5 rounded"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <h2 className="text-gray-900 font-medium text-sm">{article.title}</h2>
                </div>
              </div>
            ))}
          </div>

          <Pagination totalCount={total} itemCountPerPage={9} onNavigate={onPageNavigate} pageNum={pageNum} />
        </div>
      </div>

      <div className="flex flex-col content md:px-24">
        <div className="rounded-lg bg-[#5B79D1] pt-16 px-12 mt-40 pb-24 mb-24">
          <p className="font-bold text-white text-5xl">In the news</p>
          <div className="grid grid-cols-12 md:gap-12 mt-12 gap-4">
            {lastArticles.map((article, index) => (
              <div className="col-span-12 md:col-span-4" key={index}>
                {article.link.includes('technical.ly') ? (
                  <Image src={imgTc} />
                ) : article.link.includes('bloomberg') ? (
                  <Image src={imgBloomberg} />
                ) : article.link.includes('forbes') ? (
                  <Image src={imgForbest} />
                ) : article.link.includes('business') ? (
                  <Image src={imgBusinessInsider} />
                ) : article.link.includes('lesechos') ? (
                  <Image src={imgLesEchos} />
                ) : (
                  <p className="font-bold text-2xl text-white">{convertToValidUrl(article.link)}</p>
                )}
                <p
                  className="font-semibold text-md text-white mt-4 hover:cursor-pointer hover:text-gray-300 transition-all duration-200"
                  onClick={() => selectArticle(article)}
                >
                  {article.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
