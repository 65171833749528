import { useEffect, useState } from 'react'

export const PlainInput = (props: any) => {
  const { value, content, onChange, type = 'text', className = '' } = props

  const [isEditing, setEditing] = useState(false)
  const [editingValue, setEditingValue] = useState('')

  useEffect(() => {
    setEditingValue(value.toString())
  }, [value])

  useEffect(() => setEditing(props.isEditing), [props.isEditing])

  const onUpdate = () => {
    setEditing(false)
    setEditingValue(editingValue.trim())
    onChange(editingValue.trim())
  }

  return isEditing ? (
    <input
      autoFocus
      type={type}
      value={editingValue}
      onChange={(e) => setEditingValue(e.target.value)}
      className="w-full leading-4 p-1 block rounded-t border-gray-300 focus:border-sky-600 text-[14px] focus:outline-none focus:ring-0"
      onBlur={onUpdate}
    />
  ) : (
    <p
      className={`cursor-pointer p-1 text-black font-variation-settings-600 ${className}`}
      onClick={() => setEditing(true)}
    >
      {content}
    </p>
  )
}
