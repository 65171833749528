export enum SignUpSteps {
  CreateAccount = 0,
  CheckPhone = 0.5,
  InvestorType = 1,
  AccreditedInvestor = 2,
  TotalAssets = 3,
  InvestmentRange = 4,
  Additional = 5,
  Final = 6,
}
