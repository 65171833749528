export const StepProgressBar = ({ options, index = -1 }: { options: string[]; index: number }) => {
  return (
    <div className="relative text-xs">
      <div className="h-[2px] absolute w-[99%] bg-gray-300 top-1.5">
        <div className="bg-green-400 h-[2px]" style={{ width: `${(index / (options.length - 1)) * 100}%` }} />
      </div>
      <div className="flex w-full justify-between">
        {options.map((title, i) => {
          const isActive = i <= index
          const isFirst = i == 0
          const isLast = i == options.length - 1
          return (
            <div className="relative h-14" key={i}>
              <div className={`w-4 h-4 rounded-full ${isActive ? 'bg-green-400' : 'bg-gray-300'}`} />
              <p
                className={`absolute ${isFirst && 'left-0'} ${isLast && 'right-0 text-right'} ${
                  !isFirst && !isLast && 'left-[50%] -translate-x-[50%] text-center'
                } xs:w-32 top-6 ${isActive ? 'text-black font-semibold' : 'text-gray-400'}`}
              >
                {title}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
