import { TransferIntentCreateMode } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { toast } from 'react-toastify'
import { getPlaidClientToken, transferPlaid } from 'services/apis'
import { normalizeNumber } from 'utils'

import { TransactionMethod } from '../constants'
import { DepositConfirmation } from '../Deposit/DepositConfirmation'
import { ContentHeader } from './ContentHeader'
import { renderBankAccount } from './util'

export const ConfirmationBankContent = ({
  direction,
  data,
  onNext,
  onBack,
}: {
  direction: TransferIntentCreateMode
  data: Record<number, any>
  onNext: Function
  onBack: Function
}) => {
  const isDeposit = direction == TransferIntentCreateMode.Payment
  const [isLoading, setLoading] = useState(false)
  const [clientToken, setClientToken] = useState('')
  const [transferIntentId, setTransferIntentId] = useState('')

  const { open, ready } = usePlaidLink({
    token: clientToken,
    onSuccess: (public_token, metadata) => {
      console.log('SUCCESS', public_token, metadata)
      if (metadata.transfer_status != 'COMPLETE') {
        setLoading(false)
        return
      }

      setLoading(true)
      transferPlaid({
        direction,
        amount: data[1].amount,
        institution: metadata.institution,
        account: metadata.accounts[0],
        transferIntentId,
        linkSessionId: metadata.link_session_id,
        publicToken: public_token,
      })
        .then((res) => onNext(res.id))
        .catch(() => setLoading(false))
    },
    onEvent: (event, metadata) => {
      console.log('event', event, metadata)
      if (event == 'ERROR' && metadata?.error_message) {
        const firstLetter = metadata.error_message[0].toUpperCase()
        let message: string = ''

        for (let i = 0; i < metadata.error_message.length; i++) {
          if (i === 0) message += firstLetter
          else message += metadata.error_message[i]
        }
        setClientToken('')
        toast(message, { type: 'error' })
      }
    },
    onExit: (error, metadata) => {
      console.log('exit', error, metadata)
      setClientToken('')
      setLoading(false)
    },
  })

  const strDirection = useMemo(
    () => (direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'),
    [direction],
  )

  const strDirectionToFrom = useMemo(
    () => (direction == TransferIntentCreateMode.Payment ? 'Deposit from' : 'Withdraw to'),
    [direction],
  )

  const confirmedData: Record<string, any> = useMemo(() => {
    const strAmount = normalizeNumber(data[1].amount, 2)
    return {
      Method: 'Linked Bank Account',
      [`Amount to ${strDirection} (USD)`]: `$ ${strAmount}`,
      [strDirectionToFrom]: renderBankAccount(data[1].account, data[1].institution, false),
    }
  }, [data])

  useEffect(() => {
    if (!clientToken || !ready) return
    open()
  }, [clientToken, ready])

  const onConfirm = () => {
    setLoading(true)
    if (clientToken && ready) {
      open()
      return
    }
    getPlaidClientToken(strDirection.toLowerCase() as any, { amount: data[1].amount, accountId: data[1].accountId })
      .then(({ link_token, transferIntentId }) => {
        setClientToken(link_token)
        setTransferIntentId(transferIntentId)
      })
      .catch(() => setLoading(false))
  }

  return (
    <div className="rounded-lg bg-white divide-y relative">
      <ContentHeader description="Please confirm the below details." onBack={onBack} />

      <div className="pt-4 px-2">
        {Object.keys(confirmedData).map((title) => (
          <div className="mb-4" key={title}>
            <p className="text-desc mb-2">{title}</p>
            <div>{confirmedData[title]}</div>
          </div>
        ))}
      </div>

      <DepositConfirmation
        isDeposit={isDeposit}
        data={data}
        method={data[0] == 0 ? TransactionMethod.Plaid : TransactionMethod.Wire}
        isLoading={isLoading}
        onSubmit={onConfirm}
      />
    </div>
  )
}
