import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  BanknotesIcon,
  BuildingLibraryIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { TransactionColorMap, TransactionStatus } from 'components/TransactionStatus'
import { itemCountPerPage } from 'config'
import { cloneDeep } from 'lodash'
import { Institution, TransferIntentCreateMode, TransferStatus } from 'plaid'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { getEnvelopeUrl, getHistories, getInvestment } from 'services/apis'
import { ButtonGroup, ResponsiveTable } from 'stories/components'
import { formatDate, formatTime, normalizeNumber, useTitle } from 'utils'

import {
  DBInvestment,
  DbPlaidAccount,
  DbProfit,
  DbTransaction,
  InvestStatus,
  PayHistory,
  PayHistoryType,
  PayTrxTypes,
  TransactionMethod,
} from './constants'
import { InvestDetails, TransactionDetails } from './modal'
import { renderAccount } from './Transactions'

export const HistoriesTab = ({ accounts, defaultTrxId }: { accounts: DbPlaidAccount[]; defaultTrxId: number }) => {
  useTitle('Transactions')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dataType = searchParams.get('dataType')
  const [filters, setFilters] = useState<Record<string, any>>({
    type: dataType || 'All',
    duration: '-3',
    pageNum: 0,
    transactionId: -1,
    investId: -1,
    returnedInvestId: -1,
    profitId: -1,
  })
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [histories, setHistories] = useState<PayHistory[]>([])
  const [institutions, setInstitutions] = useState<Institution[]>([])
  const [selectedItem, setSelectedItem] = useState<PayHistory | null>(null)
  const [activeSignId, setActiveSignId] = useState(0)

  useEffect(() => {
    refetch(filters)
  }, [])

  useEffect(() => {
    if (!defaultTrxId) return
    const index = histories.findIndex((trx) => trx.id == defaultTrxId)
    if (index == -1) return
    onOpenTransaction(index)
    navigate('/payment/transaction')
  }, [histories, defaultTrxId])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (histories.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getHistories({
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ total, data, institutions: _institutions }) => {
        if (_pageNum == 0) setHistories(data)
        else {
          const newItems = cloneDeep(histories)
          // data.forEach((item: any) => {
          //   const isExist = newItems.find((v) => v.dataType === item.dataType && v.id === item.id)

          //   if (!isExist) newItems.push(item)
          // })
          newItems.push(...data)
          setHistories(newItems)
        }

        const newInstitutions = cloneDeep(institutions)
        newInstitutions.push(..._institutions)
        setInstitutions(newInstitutions)

        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onOpenTransaction = (index: number) => {
    setSelectedItem(histories[index] || null)
  }

  const onOpenInvestment = (investId: number) => {
    getInvestment(investId).then((data) => {
      setSelectedItem({
        ...data,
        dataType: PayHistoryType.Investments,
      })
    })
  }
  const onSignToContinue = (item: DBInvestment) => {
    if (activeSignId) return

    setActiveSignId(item.id)
    getEnvelopeUrl(item.loan.id, item.envelopeId)
      .then((res) => {
        if (res?.url) window.open(res.url, '_blank')
      })
      .finally(() => setActiveSignId(0))
  }

  const onChangeFilter = (key: 'type' | 'duration' | 'pageNum', value: any, needRefetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    if (needRefetch) {
      newFilters.transactionId = -1
      newFilters.investId = -1
      newFilters.returnedInvestId = -1
      newFilters.profitId = -1
    } else {
      const trxes = histories.filter((v) => PayTrxTypes.includes(v.dataType))
      newFilters.transactionId = trxes.length ? trxes[trxes.length - 1].id : -1

      const invests = histories.filter((v) => v.dataType == PayHistoryType.Investments)
      newFilters.investId = invests.length ? invests[invests.length - 1].id : -1

      const returnedInvests = histories.filter((v) => v.dataType == PayHistoryType.ReturnedInvestments)
      newFilters.returnedInvestId = returnedInvests.length ? returnedInvests[returnedInvests.length - 1].id : -1

      const profits = histories.filter((v) => v.dataType == PayHistoryType.Distributions)
      newFilters.profitId = profits.length ? profits[profits.length - 1].id : -1
    }
    refetch(newFilters, needRefetch ? 0 : -1)
  }

  const renderItem = (item: PayHistory, index: number) => {
    const { dataType } = item
    if (PayTrxTypes.includes(dataType)) return renderTrx(item as DbTransaction, index)
    if ([PayHistoryType.Investments, PayHistoryType.ReturnedInvestments].includes(dataType))
      return renderInvest(item as DBInvestment, index)
    if (dataType == PayHistoryType.Distributions) return renderProfit(item as DbProfit, index)
    return null
  }

  const getMethod = (item: DbTransaction) => {
    if (item.method != TransactionMethod.Manual)
      return item.direction == TransferIntentCreateMode.Payment ? 'Deposit' : 'Withdraw'

    return item.direction != TransferIntentCreateMode.Payment ? 'Debit' : 'Credit'
  }

  const renderTrx = (item: DbTransaction, index: number) => {
    const isDeposit = item.direction == TransferIntentCreateMode.Payment
    const Icon = isDeposit ? ArrowDownOnSquareIcon : ArrowUpOnSquareIcon

    return [
      <div className="flex items-center lg:justify-start p-2 gap-2">
        {index + 1}.
        <div className={`p-2 ${TransactionColorMap[item.status]} inline-block rounded-full`}>
          <Icon className="w-6 h-6 rounded-full" />
        </div>
        {getMethod(item)}
        <div className="font-bold text-indigo-500">
          <span>#{item.id}</span>
        </div>
      </div>,
      <p
        className={
          isDeposit && item.status == TransferStatus.Settled
            ? 'text-green-500 font-bold'
            : `text-gray-500 ${item.status == TransferStatus.Settled ? 'font-bold' : ''}`
        }
      >
        {isDeposit ? '+' : '-'} $ {normalizeNumber(item.amount, 2)}
      </p>,
      <div className="-mx-2">{renderAccount({ item, accounts, institutions })}</div>,
      <div className="flex items-center p-2 gap-2 justify-between">
        <div className="flex-1">
          <TransactionStatus status={item.status} />
          {/* <p className={`capitalize ${TransactionColorMap[item.status]} inline-block px-2 py-1 rounded text-sm`}>
          {item.status}
        </p> */}
        </div>
        <div>{formatTime(item.createdAt)}</div>
        <ChevronRightIcon className="hidden lg:inline-block w-4 h-4" />
      </div>,
    ]
  }

  const renderInvest = (item: DBInvestment, index: number) => {
    const { dataType } = item as PayHistory
    const isReturned = dataType == PayHistoryType.ReturnedInvestments

    return [
      <div className="flex items-center lg:justify-start p-2 gap-2">
        {index + 1}.
        <div
          className={`p-2 ${
            TransactionColorMap[isReturned ? InvestStatus.Settled : item.status]
          } inline-block rounded-full`}
        >
          <BuildingLibraryIcon className="w-6 h-6 rounded-full" />
        </div>
        Investment
        <div className="font-bold text-indigo-500">
          <span>#{item.id}</span>
        </div>
      </div>,
      <div className="flex gap-2 items-center text-gray-500">
        <span
          className={`${item.status == InvestStatus.Settled || isReturned ? 'font-bold' : ''} whitespace-nowrap ${
            isReturned ? 'text-green-500' : ''
          }`}
        >
          {!isReturned ? '-' : '+'} $ {normalizeNumber(item.amount)}
        </span>
        {(!!item.profit || !!item.penalty) && isReturned && (
          <>
            |{' '}
            <div>
              {!!item.profit && (
                <p className={`text-green-500 font-bold whitespace-nowrap text-sm`}>
                  + $ {normalizeNumber(item.profit, 2)}
                </p>
              )}
              {!!item.penalty && (
                <p className={`text-red-500 whitespace-nowrap font-bold text-sm`}>
                  - $ {normalizeNumber(item.penalty)}
                </p>
              )}
            </div>
          </>
        )}
      </div>,
      <div className="flex items-center gap-2">
        <p>
          {item.loan.propertyAddress}
          <span className="font-bold text-indigo-500 hover:underline cursor-pointer pl-2">
            <Link to={`/marketplace/${item.loan.id}`}>#{item.loan.id}</Link>
          </span>
        </p>
      </div>,
      <div className="flex items-center p-2 gap-2 justify-between">
        <div className="flex-1">
          {/* <p className={`capitalize ${TransactionColorMap[item.status]} inline-block px-2 py-1 rounded text-sm`}>
          {item.status}
        </p> */}
          <TransactionStatus status={item.status} />

          {item.status == InvestStatus.Pending &&
            (activeSignId == item.id ? (
              <div className="mt-1">
                <Loading />
              </div>
            ) : (
              <p
                className="inline-block text-xs mt-1 px-2 py-1 bg-yellow-400 text-white rounded-full"
                onClick={(e) => {
                  e.preventDefault()
                  onSignToContinue(item)
                  return false
                }}
              >
                Sign to Continue
              </p>
            ))}
        </div>
        <div>{formatTime(isReturned ? item.endDate! : item.createdAt)}</div>
        <ChevronRightIcon className="hidden lg:inline-block w-4 h-4" />
      </div>,
    ]
  }

  const renderProfit = (item: DbProfit, index: number) => [
    <div className="flex items-center lg:justify-start p-2 gap-2">
      {index + 1}.
      <div className={`p-2 ${TransactionColorMap[TransferStatus.Settled]} inline-block rounded-full`}>
        <BanknotesIcon className="w-6 h-6 rounded-full" />
      </div>
      Distribution
      <div className="font-bold text-indigo-500">
        <span>#{item.id}</span>
      </div>
    </div>,
    <p className="text-green-500 font-bold">+ $ {normalizeNumber(item.amount, 2)}</p>,
    <div className="flex flex-col xl:flex-row xl:items-center gap-2">
      <p>
        Distributed by Investment
        <span
          className="font-bold text-indigo-500 pl-2 hover:underline"
          onClick={() => onOpenInvestment(item.investment.id)}
        >
          #{item.investment.id}
        </span>
      </p>
      <div>
        <p className="flex items-center text-desc">
          {item.loan.propertyAddress}
          <div className="font-bold text-indigo-300 hover:underline cursor-pointer text-sm pl-2">
            <Link to={`/marketplace/${item.loan.id}`}>
              <span>#{item.loan.id}</span>
            </Link>
          </div>
        </p>
        <p className="text-desc">
          {formatDate(item.from, 'MM/DD/YYYY')} - {formatDate(item.to, 'MM/DD/YYYY')}
        </p>
      </div>
    </div>,
    <div className="flex items-center p-2 gap-2 justify-between">
      <div className="flex-1">
        {/* <p className={`capitalize ${ProfitColorMap[item.status]} inline-block px-2 py-1 rounded text-sm capitalize`}>
          {item.status}
        </p> */}
        <TransactionStatus status={item.status} />
      </div>
      <div>{formatTime(item.createdAt)}</div>
      <p className="hidden lg:inline-block w-4 h-4" />
    </div>,
  ]

  return (
    <div>
      <div className="flex flex-col lg:flex-row lg:items-center mb-4 gap-2">
        <p className="text-xl flex-1">Histories ({total})</p>
        <ButtonGroup
          title={['All', 'Investments', 'Withdrawals', 'Deposits', 'Distributions']}
          value={filters.type}
          onChange={(v) => onChangeFilter('type', v)}
        />
        {/* <ButtonGroup
          title={{ '-3': '1 - 3 months', '3-12': '3 months - 1 year', '12-': '1+ year' }}
          value={filters.duration}
          onChange={(v) => onChangeFilter('duration', v)}
        /> */}
      </div>

      <div className="min-h-[8rem] relative rounded overflow-hidden">
        <LayoutLoading show={isLoading && histories.length == 0} />
        <InfiniteScroll
          dataLength={histories.length}
          next={() => onChangeFilter('pageNum', filters.pageNum + 1, false)}
          hasMore={histories.length < total}
          loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
        >
          <ResponsiveTable
            header={[]}
            headerKeys={[]}
            headerClassName="hidden lg:grid mb-2"
            contentClassName="lg:grid grid-cols-12 rounded-lg text-sm p-2 border hover:ring-2 hover:ring-inset hover:ring-gray-200 focus:bg-gray-100 cursor-pointer"
            size={[2, 2, 5, 3]}
            orderBy={filters.orderBy}
            orderDir={filters.orderDir}
            onClickItem={onOpenTransaction}
            data={histories.map(renderItem).filter((v) => v != null) as any}
            classNames={['', 'font-medium text-lg px-2 lg:px-2', 'text-base px-2 lg:px-2']}
          />
        </InfiniteScroll>
      </div>

      {selectedItem && PayTrxTypes.includes(selectedItem.dataType) && (
        <TransactionDetails
          data={selectedItem as DbTransaction}
          bankComponent={renderAccount({ item: selectedItem as DbTransaction, accounts, institutions })}
          onClose={() => setSelectedItem(null)}
        />
      )}

      {selectedItem &&
        [PayHistoryType.Investments, PayHistoryType.ReturnedInvestments].includes(selectedItem.dataType) && (
          <InvestDetails
            data={selectedItem as DBInvestment}
            onClose={(needRefresh: boolean) => {
              setSelectedItem(null)
              needRefresh &&
                refetch(
                  {
                    ...filters,
                    pageNum: 0,
                    transactionId: -1,
                    investId: -1,
                    returnedInvestId: -1,
                    profitId: -1,
                  },
                  0,
                )
            }}
          />
        )}
    </div>
  )
}
