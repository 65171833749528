import { LayoutLoading } from 'components/LayoutLoading'
import { type InputType, s3BucketUrl, smsTermsUrl } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { getProfile, updateNotifications } from 'services/apis'
import { RenderInput } from 'utils'

const defaultInputs = (): Record<string, InputType> => ({
  sms: {
    inputType: 'toggle',
    title: 'Accept SMS notifications',
  },
})

export const Notifications = () => {
  const [loading, setLoading] = useState(true)
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    setLoading(true)
    getProfile()
      .then(({ notifications }) => {
        let newInputs = cloneDeep(inputs)
        Object.keys(notifications).forEach((key) => (newInputs[key].value = notifications[key]))
        setInputs(newInputs)
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    setInputs(newInputs)

    const data: Record<string, any> = {}
    Object.keys(newInputs).forEach((key) => (data[key] = newInputs[key].value))
    setLoading(true)
    updateNotifications(data).finally(() => setLoading(false))
  }

  return (
    <div className="text-base text-gray-600">
      <LayoutLoading show={loading} />
      <div className="mb-4">
        {Object.keys(inputs).map((key) =>
          RenderInput({
            input: inputs[key],
            Key: key,
            onChange: (key: string, v: any) => onChange(key, v),
          }),
        )}
      </div>

      <a className="text-link text-sm" href={`${s3BucketUrl}${smsTermsUrl}`} target="_blank">
        See SMS terms and conditions
      </a>
    </div>
  )
}
