import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { TransactionColorMap } from 'components/TransactionStatus'
import { itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { DistributionsModal } from 'pages/Dashboard/DistributionsModal'
import { LoanLink } from 'pages/Loan'
import type { Loan } from 'pages/Marketplace'
import { remainingLoanTerm } from 'pages/Marketplace/InvestCard'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import { getInvestments } from 'services/apis'
import { ButtonGroup, Input, ResponsiveTable } from 'stories/components'
import { formatDate, monthlyProfit, normalizeNumber, toS3Link, useTitle } from 'utils'

import { type DBInvestment, InvestStatus } from '../Payment/constants'
import { InvestDetails } from '../Payment/modal'

const LoanDetailsComponent = ({ loan }: { loan: Loan }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-x-4 gap-y-2 lg:items-center">
      <div className="w-full aspect-video lg:aspect-square lg:w-32 lg:h-32 object-cover rounded-lg relative">
        <Image
          src={toS3Link(loan.images)}
          className="w-full aspect-video lg:aspect-square lg:w-32 lg:h-32 object-cover rounded-lg"
        />
      </div>
      <div className="flex flex-col gap-x-1 gap-y-[2px]">
        <Tooltip content="Go to Loan Details">
          <LoanLink loan={loan} simple goToPipeline />
        </Tooltip>
        <p className="flex justify-between lg:justify-start font-medium items-center gap-2">
          <span className="text-desc">Annual Yield:</span>
          <span className="text-green-400 font-semibold">{loan.ytm}%</span>
        </p>

        <p className="flex justify-between lg:justify-start font-medium items-center gap-2">
          <span className="text-desc">Maturity Date:</span> {formatDate(loan.maturityDate)}
        </p>

        <p className="flex justify-between lg:justify-start font-medium items-center gap-2">
          <span className="text-desc">Borrower Next Due Date:</span>
          {loan.fciLoan ? formatDate(loan.fciLoan.nextDueDate) : 'N/A'}
        </p>
        <p className="flex justify-between lg:justify-start font-medium items-center gap-2 capitalize">
          <span className="text-desc">Loan Status:</span> {loan.fciLoan ? loan.fciLoan.status : 'N/A'}
        </p>
        <p className="flex justify-between lg:justify-start font-medium items-center gap-2 capitalize">
          <span className="text-desc">Term Remaining:</span> {remainingLoanTerm(loan.maturityDate)}
        </p>
      </div>
    </div>
  )
}

const InvestTable = ({
  filters,
  invests: data,
  refetch,
}: {
  filters: Record<string, any>
  invests: DBInvestment[]
  refetch: Function
}) => {
  const [invests, setInvests] = useState<DBInvestment[]>([])
  const [selectedItem, setSelectedItem] = useState<DBInvestment | null>(null)
  const [selectedInvest, setSelectedInvest] = useState<DBInvestment | undefined>(undefined)

  useEffect(() => {
    setInvests(data)
  }, [data])

  const onOpenItem = (index: number) => {
    setSelectedItem(invests[index] || null)
  }

  const onSort = (orderBy: string) => {
    if (!orderBy) return

    const newFilters = Object.assign({}, filters)

    if (newFilters.orderBy == orderBy) newFilters.orderDir = 0 - Number(newFilters.orderDir)

    newFilters.orderBy = orderBy

    refetch(newFilters)
  }

  return (
    <>
      <ResponsiveTable
        header={[
          'No',
          'Investor',
          'Loan Detail',
          'Status',
          'Amount',
          'Monthly<br />Interest',
          'Investment<br />Date',
          'Earnings',
        ]}
        headerKeys={['id', '', 'status', 'amount', '', '', 'startDate', '']}
        showKeys={[true, false, true, true, true, true, true, true, true, true]}
        onClickHeader={(v: string) => onSort(v)}
        headerClassName="hidden lg:grid lg:text-xs"
        containerClassName={`text-sm rounded-lg overflow-hidden gap-2`}
        contentClassName={`lg:grid grid-cols-12 text-sm py-2 lg:py-3 cursor-pointer border rounded-lg`}
        size={[1, 1, 4, 1, 1, 1, 1, 1]}
        orderBy={filters.orderBy}
        orderDir={filters.orderDir}
        onClickItem={onOpenItem}
        data={invests.map((item, index) => [
          <div className="font-bold text-indigo-500 hover:underline cursor-pointer pl-3" key={index}>
            <Tooltip content="Go to Distributions">
              <Link to={`/manageProfits?query=__${item.id}&status=all`}>#{item.id}</Link>
            </Tooltip>
          </div>,
          <div className="text-indigo-500 font-bold hover:underline cursor-pointer">
            <Tooltip content="Go to Investments">
              <Link to={`/manageSignStatistics?query=${item.user.name}`}>{item.user.name}</Link>
            </Tooltip>
          </div>,
          <LoanDetailsComponent loan={item.loan} />,
          <div className="text-right">
            <div className="flex gap-2 items-center justify-end lg:justify-start">
              <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
              <span className="capitalize">{item.status}</span>
            </div>
          </div>,
          `$${normalizeNumber(item.amount)}`,
          `$${monthlyProfit(item.amount, item.loan.ytm)}`,

          item.startDate ? formatDate(item.startDate) : '',

          <div
            className="flex justify-between items-center gap-1 lg:w-full lg:pr-4"
            onClick={() => setSelectedInvest(item)}
          >
            <Tooltip content="View Distributions">
              <div className="flex flex-auto lg:flex-col items-center gap-1">
                <span className="text-green-400 font-bold">${normalizeNumber(item.profit || 0, 2)}</span>
                <span className="lg:hidden">/</span>
                <span className="text-gray-500">${normalizeNumber(item.pendingProfit || 0, 2)}</span>
              </div>
            </Tooltip>
          </div>,
        ])}
        classNames={['pl-4', 'px-4 lg:px-0', '', '', '', '', '', '', '', 'capitalize']}
      />

      {selectedItem && !selectedInvest && (
        <InvestDetails
          data={selectedItem}
          isAdmin
          isMine={false}
          onClose={(needRefresh: boolean) => {
            setSelectedItem(null)
            needRefresh && refetch()
          }}
        />
      )}

      {selectedInvest && (
        <DistributionsModal
          invest={selectedInvest}
          isAdmin
          onClose={() => {
            setSelectedItem(null)
            setSelectedInvest(undefined)
          }}
        />
      )}
    </>
  )
}

export const Investments = () => {
  useTitle('Investments')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    duration: '-',
    orderBy: 'id',
    status: [InvestStatus.Pending, InvestStatus.Posted, InvestStatus.Settled],
    orderDir: -1,
    pageNum: 0,
    isAll: true,
  })
  const [isLoading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [invests, setInvests] = useState<DBInvestment[]>([])

  useEffect(() => {
    refetch(filters)
  }, [])

  const refetch = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    if (invests.length == 0) _pageNum = 0

    filters.pageNum = _pageNum
    setFilters(filters)

    setLoading(true)
    getInvestments({
      ...filters,
      // status,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ total, data }) => {
        if (_pageNum == 0) setInvests(data)
        else {
          const newTrxes = cloneDeep(invests)
          newTrxes.push(...data)
          setInvests(newTrxes)
        }

        setTotal(total)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (
    key: 'status' | 'orderBy' | 'orderDir' | 'pageNum' | 'query',
    value: any,
    needRefetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)

    if (key === 'pageNum') refetch(newFilters, value)
    else if (needRefetch) refetch(newFilters, needRefetch ? 0 : -1)
  }

  return (
    <div className="w-full bg-gray-100/50 pt-8">
      <div className="fullContent pb-4 relative">
        <LayoutLoading show={isLoading && invests.length == 0} />

        <p className="text-3xl font-semibold mb-6">Investments ({total})</p>
        <div className="flex items-end gap-2 flex-wrap mb-2 justify-between">
          <Input
            type="search"
            placeholder="Type to search"
            hasIcon
            icon={<MagnifyingGlassIcon className="w-4 h-4" />}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value, false)}
            onBlur={() => onChangeFilter('query', filters.query)}
            className="mb-[-16px]"
          />

          <ButtonGroup
            title={Object.values(InvestStatus)}
            value={filters.status}
            className="capitalize"
            multiple
            showCheck
            onChange={(v) => onChangeFilter('status', v)}
          />
        </div>

        <InfiniteScroll
          dataLength={invests.length}
          next={() => onChangeFilter('pageNum', filters.pageNum + 1)}
          hasMore={invests.length < total}
          loader={<div className="relative h-10">{isLoading && <LayoutLoading show />}</div>}
        >
          <InvestTable
            filters={filters}
            invests={invests}
            refetch={(filters: Record<string, any>) => refetch(filters, 0)}
          />
        </InfiniteScroll>
      </div>
    </div>
  )
}
