import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'flowbite-react'
import { Button } from 'stories/components'

const options: Record<string, string> = {
  '0': 'Up to $500K',
  '500000': '$500K - $1M',
  '1000000': '$1M - $5M',
  '5000000': 'More than $5M',
}

export const InvestableAssetsSection = ({ step, onSubmit }: { step: JSX.Element; onSubmit: Function }) => {
  return (
    <div className="max-w-lg px-8 md:px-0 md:mx-auto w-full md:w-120">
      <div className="mb-4">{step}</div>
      <div className="mb-4">
        <p className="text-xl font-medium">What is the total value of your investable assets?</p>
      </div>
      <div className="p-8 bg-black/5 flex flex-col gap-4 text-gray-700 rounded-xl mb-4 text-sm">
        <p>This includes cash, stocks, mutual funds, and any other investments, excluding your primary residence.</p>
      </div>
      <p className="text-sm text-gray-700 mb-4">Select one of the following</p>
      {Object.keys(options).map((v) => (
        <Button full color="white" key={v} onClick={() => onSubmit(v)} className="py-4 !border-gray-300 font-medium">
          {options[v]}
        </Button>
      ))}
      <p className="text-sm text-gray-700 mt-4 flex justify-center gap-x-2 items-center">
        Why do we ask this?
        <Tooltip content="We gather user provided data so our investor relations team can better help you with your investment goals.">
          <span className="cursor-pointer text-gray-500">
            <InformationCircleIcon className="w-4 h-4 text-indigo-500" />
          </span>
        </Tooltip>
      </p>
    </div>
  )
}
