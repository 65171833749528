import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useTitle(title: string, hasCompanyName = true) {
  if (!hasCompanyName) document.title = title
  else document.title = `${title} - ${process.env.REACT_APP_COMPANY}`
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
