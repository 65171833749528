import { useEffect, useState } from 'react'

export const CustomXsCarousel = (props: {
  activeIndex: number
  children: JSX.Element[]
  className: string
  onSlideChange: Function
  invisibleOriginal?: boolean
}) => {
  const [offset, setOffset] = useState(props.activeIndex || 0)

  useEffect(() => {
    if (offset == props.activeIndex) return
    props.onSlideChange(offset)
  }, [offset])

  useEffect(() => {
    if (offset == props.activeIndex) return
    setOffset(props.activeIndex)
  }, [props.activeIndex])

  const length = props.children.length
  const leftControl = (
    <span
      className="absolute left-0 top-[45%] inline-flex h-8 w-8 items-center justify-center rounded-full bg-black/30 group-hover:bg-black/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-black/10 dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10"
      onClick={() => setOffset((offset - 1 + length) % length)}
    >
      <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"></path>
      </svg>
    </span>
  )
  const rightControl = (
    <span
      className="absolute right-0 top-[45%] inline-flex h-8 w-8 items-center justify-center rounded-full bg-black/30 group-hover:bg-black/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-black/10 dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10"
      onClick={() => setOffset((offset + 1 + length) % length)}
    >
      <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        aria-hidden="true"
        className="h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"></path>
      </svg>
    </span>
  )

  return (
    <>
      <div className="block xs:hidden overflow-hidden relative">
        <div
          className="flex items-center"
          style={{ width: `${length}00%`, marginLeft: `${-100 * offset}%`, transition: 'margin-left ease 0.5s' }}
        >
          {props.children.map((item, index) => (
            <div style={{ width: `${100 / length}%` }} key={index}>
              {item}
            </div>
          ))}
        </div>

        <div className="absolute bottom-4 left-0 right-0">
          <div className="w-full flex justify-center py-2">
            {props.children.map((_, index) => (
              <div
                className={`w-4 h-4 p-1 cursor-pointer`}
                key={index}
                onClick={() => offset != index && setOffset(index)}
              >
                <div className={`w-2 h-2 ${index == offset ? 'bg-gray-500' : 'bg-gray-200'} rounded-full`} />
              </div>
            ))}
          </div>
        </div>

        {leftControl}
        {rightControl}
      </div>
      {!props.invisibleOriginal && <div className={props.className}>{props.children}</div>}
    </>
  )
}
