import { ArrowDownTrayIcon, ArrowUturnLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { AccountType } from 'config'
import { cloneDeep } from 'lodash'
import type { IdentityVerificationGetResponse } from 'plaid'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from 'reducers'
import { openS3Document } from 'services/apis'
import { Button, Input, InputFile, Select } from 'stories/components'
import { getOnlyNumber, InputValidate, removeComma, ssnConvertor } from 'utils'

import {
  IR_FormContainerElement,
  IR_FormElement,
  IR_FormElementConditional,
  IR_FormPropertyElement,
  IR_FormResponse,
  IR_FormSection,
  IR_FormTableElement,
  IR_FormTemplate,
  IR_FormTemplateElement,
  IR_QuestionSetType,
  IR_Response,
} from './type'

interface IR_Value {
  value: any
  error: string
  required: boolean
  inputType: string
  type: string
}

const gridCls = 'grid grid-cols-1 md:grid-cols-2 gap-x-4'

export const InvestReadyForm = ({
  type,
  formData: _formData,
  defaultValues = {},
  editable = true,
  visibleDescription = true,
  kycDetails = null,
  onSubmit: _onSubmit = () => {},
  onBack = null,
}: {
  type: IR_QuestionSetType
  editable?: boolean
  formData: IR_Response<IR_FormResponse>
  defaultValues: Record<string, any>
  visibleDescription?: boolean
  kycDetails?: IdentityVerificationGetResponse | null
  onSubmit?: Function
  onBack?: Function | null
}) => {
  const auth = useSelector((state: RootState) => state.auth)

  const [formData, setFormData] = useState<IR_Response<IR_FormResponse> | null>(null)
  const [values, setValues] = useState<Record<string, IR_Value | any>>({})

  useEffect(() => {
    if (!_formData) return

    if (![AccountType.Trust, AccountType.Company].includes(auth.profile.accountType))
      _formData.data.section = _formData.data.section.filter(
        (item) => !item.label.toLowerCase().includes('trust') && !item.label.toLowerCase().includes('entity'),
      )

    const values: Record<string, IR_Value> = {}
    getInitialValues(_formData.data.section, defaultValues, values)
    setValues(values)
    setFormData(_formData)
  }, [_formData, defaultValues])

  const isNew = useMemo(() => {
    return Object.keys(defaultValues).length == 0 && editable
  }, [defaultValues, editable])

  const kycDetailedInitialValues = useMemo((): Record<string, string | undefined | null> => {
    if (!isNew || !kycDetails) return {}

    const address = kycDetails.user.address
    return {
      'accredify[person][first_name]': kycDetails.user.name?.given_name,
      'accredify[person][last_name]': kycDetails.user.name?.family_name,
      'accredify[person][dob]': kycDetails.user.date_of_birth,
      'accredify[person][phone]': kycDetails.user.phone_number,
      'accredify[person][social]': ssnConvertor(kycDetails.user.id_number?.value),
      'accredify[properties][primary][street_address]': address?.street,
      'accredify[properties][primary][appt_suite]': address?.street2,
      'accredify[properties][primary][city]': address?.city,
      'accredify[properties][primary][state]': address?.region,
      'accredify[properties][primary][zip]': address?.postal_code,
    }
  }, [isNew, kycDetails])

  const getInitialValues = (
    data: IR_FormSection[],
    defaultValues: Record<string, any>,
    values: Record<string, IR_Value>,
  ) => {
    data.forEach((section) => {
      section.elements.forEach((element) => getElementInitialValue(element, defaultValues, values))
    })
  }

  const getInputTypes = (element: IR_FormElement) => {
    switch (element.input) {
      case 'text':
      case 'password':
      case 'email': {
        let type: string = element.input
        if (
          element.placeholder &&
          element.placeholder.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19\d{2}|20\d{2})$/)
        )
          type = 'date'
        else if (element.placeholder == 'Phone Number') type = 'phone'
        else if (element.placeholder == 'xx-xxx-xxxx') type = 'ssn'
        else if (element.placeholder && ['Asset Value', 'Liability Value'].includes(element.placeholder))
          type = 'thousandSep'
        else if (element.label && element.label.includes('($ USD)')) type = 'thousandSep'

        return {
          inputType: 'text',
          type,
        }
      }
      case 'file': {
        return {
          inputType: 'file',
          type: '',
        }
      }
    }
    return {
      inputType: '',
      type: '',
    }
  }

  const getElementInitialValue = (
    element: IR_FormElement,
    defaultValues: Record<string, any>,
    values: Record<string, IR_Value | any>,
  ) => {
    let elementName = ''
    if ((element as any).name)
      elementName = !(element as any).name.includes('xxx') ? (element as any).name : (element as any).template_ident

    if (elementName && defaultValues[elementName] !== undefined && (element as any).conditional) {
      const value = defaultValues[elementName]
      const conditional: IR_FormElementConditional = (element as any).conditional
      const currentDom = conditional.test.find((test) => test.value == value)
      if (currentDom && currentDom.dom)
        currentDom.dom.forEach((v) => getElementInitialValue(v as any, defaultValues, values))
    }
    const types = getInputTypes(element as any)

    switch (element.input) {
      case 'text':
      case 'select':
      case 'password':
      case 'email':
        {
          values[elementName] = {
            value: defaultValues[elementName] !== undefined ? defaultValues[elementName] : '', // (element as any).value || '',
            required: (element as any).required,
            error: '',
            ...(types || {}),
          }
          if (isNew && !!kycDetailedInitialValues[elementName])
            values[elementName].value = kycDetailedInitialValues[elementName]
        }
        return
      case 'hidden':
        {
          values[elementName] = {
            value: elementName,
            required: false, // (element as any).required,
            error: '',
          }
        }
        return
      case 'file': {
        values[elementName] = {
          value: defaultValues[elementName] || '', // (element as any).value || null,
          required: (element as any).required,
          error: '',
          ...(types || {}),
        }
        return
      }
      case 'property':
        const elements = (element as any).template.value as IR_FormTemplateElement[]
        elements.forEach((v) => getElementInitialValue(v as any, defaultValues, values))
        return
      case 'canvas':
        return
      case 'tabel':
      case 'container': {
        values[element.template.key] = (defaultValues[element.template.key] || []).map(
          (defaultValue: Record<string, any>) => {
            const elements = element.template.value
            const values = {}
            elements.forEach((v) => getElementInitialValue(v as any, defaultValue, values))
            return values
          },
        )
        return
      }
    }
  }

  const onChange = (key: string, value: any, element: IR_FormElement) => {
    if (!editable) return

    const newValues = cloneDeep(values)
    newValues[key] = {
      ...(newValues[key] || {}),
      value,
      error: '',
    }

    if (element && (element as any).conditional) {
      const conditional = (element as any).conditional as IR_FormElementConditional
      const doms = conditional.test.find((test) => test.value == value)
      if (doms && doms.dom) {
        doms.dom.forEach(
          (dom) =>
            (newValues[(dom as any).name] = {
              value: (dom as any).value || '',
              error: '',
              required: (dom as any).required,
              ...(getInputTypes(dom) || {}),
            }),
        )
      } else {
        const doms: IR_FormElement[] = []
        conditional.test.forEach((test) => {
          if (test.dom) doms.push(...test.dom)
        })
        doms.forEach((dom) => delete newValues[(dom as any).name])
      }
    }
    setValues(newValues)
  }

  const onAddTemplate = (template: IR_FormTemplate) => {
    const { key, value } = template

    const newValues = cloneDeep(values)
    const rowValues: Record<string, IR_Value> = {}

    value.forEach((element: IR_FormTemplateElement) => {
      const types = getInputTypes(element as any)
      rowValues[element.template_ident] = {
        value: (element as any).value || null,
        error: '',
        required: element.required,
        ...(types || {}),
      } as any
    })
    newValues[key].push(rowValues)
    setValues(newValues)
  }

  const onRemoveTemplate = (template: IR_FormTemplate, index: number) => {
    const { key } = template

    const newValues = cloneDeep(values)
    newValues[key].splice(index, 1)
    setValues(newValues)
  }

  const validateForm = (
    data: Record<string, any> | Record<string, any>[],
    newValues: Record<string, any>,
    values: any,
  ): boolean => {
    let hasError = false
    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        const innerData = {}
        values.push(innerData)
        hasError = validateForm(value, newValues[index], innerData) || hasError
      })
    } else {
      for (let key in data) {
        if (data[key].required === undefined) {
          if (!values[key]) {
            if (Array.isArray(data[key])) values[key] = []
            else values[key] = {}
          }

          hasError = validateForm(data[key], newValues[key], values[key]) || hasError
        } else {
          if (data[key].type == 'ssn') {
            data[key].value = getOnlyNumber(ssnConvertor(data[key].value || ''))
            newValues[key].value = data[key].value
          }

          values[key] = data[key].value
          const error = InputValidate(data[key])
          if (error) {
            console.log(error, data[key].value)
          }
          newValues[key].error = error
          hasError = hasError || !!error
          if (!error && data[key].type == 'thousandSep') values[key] = String(removeComma(values[key]))
        }
      }
    }
    return hasError
  }

  const onSubmit = async () => {
    const data: Record<string, any> = {}
    const newValues = cloneDeep(values)
    const hasError = validateForm(values, newValues, data)

    if (hasError) {
      setValues(newValues)
      return
    }
    _onSubmit(data)
  }

  const renderProperty = (element: IR_FormPropertyElement) => {
    return (
      <div className="col-span-full mb-4 pb-4 border-b-2">
        <p className="text-base mb-2 inline-block underline rounded-md">{element.label}</p>
        <div className={gridCls}>
          {element.template.value.map((template) =>
            renderElement(template as any, values[(template as any).name], onChange),
          )}
        </div>
      </div>
    )
  }

  const renderTableContainer = (element: IR_FormTableElement | IR_FormContainerElement) => {
    const { key, value } = element.template
    const length = value.length

    const onChange = (index: number, template_ident: string, value: any) => {
      if (!editable) return

      const newValues = cloneDeep(values)
      newValues[key][index][template_ident] = {
        ...(newValues[key][index][template_ident] || {}),
        value,
        error: '',
      }
      setValues(newValues)
    }

    return (
      <div className="col-span-full mb-4 pb-4 border-b-2">
        <p className="text-base mb-2 inline-block underline rounded-md">{element.label}</p>
        {!!element.notes && <div className="text-sm mb-2 pl-2" dangerouslySetInnerHTML={{ __html: element.notes }} />}

        {editable && (
          <Button outline onClick={() => onAddTemplate(element.template)} className="py-2">
            Add
          </Button>
        )}

        {element.input == 'tabel' ? (
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {element.thead.map((v, index) =>
                  index >= length ? null : (
                    <td className="p-2" dangerouslySetInnerHTML={{ __html: v }} key={index}></td>
                  ),
                )}
                {editable && <td className="p-2" />}
              </tr>
            </thead>
            <tbody className="text-sm">
              {(values[key] || []).map((rowValues: Record<string, IR_Value>, index: number) => (
                <tr key={index} className={`border-b ${index % 2 == 0 && 'bg-slate-50'} text-gray-900`}>
                  {value.map((ele, _index) => (
                    <td className="p-2" key={_index}>
                      <div className="-mb-4">
                        {renderElement(ele as any, rowValues[ele.template_ident], (_: string, value: any) =>
                          onChange(index, ele.template_ident, value),
                        )}
                      </div>
                    </td>
                  ))}
                  {editable && (
                    <td className="p-2">
                      <div className="flex">
                        <span
                          className="p-1 cursor-pointer hover-shadow1 rounded bg-red-500 text-white"
                          onClick={() => onRemoveTemplate(element.template, index)}
                        >
                          <XMarkIcon className="w-4 h-4" />
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="divide-y">
            {(values[key] || []).map((rowValues: Record<string, IR_Value>, index: number) => (
              <div key={index} className={`mb-2 p-2 ${gridCls} relative`}>
                <div className="flex col-span-full items-center gap-4 mb-2">
                  <p className="text-base underline">Property {index + 1}</p>

                  {editable && (
                    <div className="flex">
                      <span
                        className="p-1 cursor-pointer hover-shadow1 rounded bg-red-500 text-white"
                        onClick={() => onRemoveTemplate(element.template, index)}
                      >
                        <XMarkIcon className="w-4 h-4" />
                      </span>
                    </div>
                  )}
                </div>
                {value.map((ele) =>
                  renderElement(ele as any, rowValues[ele.template_ident], (_: string, value: any) =>
                    onChange(index, ele.template_ident, value),
                  ),
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  const renderElement = (element: IR_FormElement, value: IR_Value, onChange: Function) => {
    if (element.input == 'password') (element as any).input = 'text'

    switch (element.input) {
      case 'text':
      case 'password':
      case 'email': {
        return (
          <Input
            key={element.name}
            className={element.class}
            type={value.type}
            title={element.label}
            name={element.name}
            placeholder={element.placeholder}
            required={element.required}
            value={value.value}
            error={value.error}
            disabled={!editable}
            onChange={(v) => onChange(element.name, v, element)}
          />
        )
      }
      case 'file': {
        const downloadBtn =
          value.value && typeof value.value == 'string' ? (
            <span
              className="flex items-center gap-2 hover:underline cursor-pointer text-indigo-600 mb-4"
              onClick={() => openS3Document(value.value)}
            >
              <ArrowDownTrayIcon className="w-4 h-4" />
              <p className="text-sm text-indigo-600">{element.label || 'Download'}</p>
            </span>
          ) : null
        if (!editable) return downloadBtn

        return (
          <InputFile
            key={element.name}
            className={element.class}
            title={element.label}
            name={element.name}
            required={element.required}
            value={value.value}
            error={value.error}
            multiple={false}
            downloadable
            onChange={(v) => onChange(element.name, v, element)}
          />
        )
      }
      case 'select':
        return (
          <Select
            id={element.name}
            key={element.name}
            className={element.class}
            title={element.label}
            name={element.name}
            options={element.options}
            required={element.required}
            hasDefaultOption
            value={Array.isArray(element.options) ? element.options[value.value] : value.value}
            error={value.error}
            disabled={!editable}
            onChange={(v) => {
              v = Array.isArray(element.options) ? element.options.indexOf(v) : v
              if (v === -1) v = ''
              onChange(element.name, v, element)
            }}
          />
        )
      case 'property':
        return renderProperty(element)
      case 'canvas':
        return null
      // return <div className="mb-4" dangerouslySetInnerHTML={{ __html: element.DOM }} />
      case 'tabel':
      case 'container':
        return renderTableContainer(element)
    }
  }

  const renderThirdPartyAdditionalTexts = () => {
    return (
      <div className="text-sm mb-4">
        <p className="text-gray-600 font-medium mb-1">
          Request a valid third party to verify your status electronically. The following third parties are eligible to
          verify your accredited status:
        </p>
        <ul className="list-decimal pl-6">
          {[
            'Licensed CPA (A tax preparer is not sufficient.)',
            'Registered Investment advisor with an active Series 65 license that is in good standing.',
            'Licensed Attorney.',
          ].map((v, index) => (
            <li className="text-gray-500" key={index}>
              {v}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const renderVerifierEmailTexts = () => {
    return (
      <div className="inline-block text-sm mb-4 p-2 rounded-md border border-gray-400 bg-gray-50 col-span-full">
        <p className="text-gray-500">
          To make the verification process faster, kindly inform the verifier to expect an email from{' '}
          <a className="text-link" href="https://investready.com" target="_blank">
            investready.com
          </a>{' '}
          with instructions. The verifier will then have 48 hours to complete the verification on your behalf.
        </p>
      </div>
    )
  }

  const renderSection = (section: IR_FormSection) => {
    const { label, notes, elements } = section
    const hasVerifierEmail = !!elements.find((element) => (element as any).label == `Verifier's Email`)

    return (
      <div>
        <p className="text-lg mb-2">{label}</p>

        {editable && type == IR_QuestionSetType.ThirdPartyRequest && label == 'Third Party Documents'
          ? renderThirdPartyAdditionalTexts()
          : editable &&
            notes && (
              <p className="text-sm text-gray-500 mb-2 a-underline" dangerouslySetInnerHTML={{ __html: notes }}></p>
            )}
        <div className={gridCls}>
          {elements.map((v: any, index) => {
            if (v.conditional) {
              const conditional = v.conditional as IR_FormElementConditional
              const doms = conditional.test.find((test) => test.value == values[(v as any).name].value)

              return (
                <div className={`col-span-full ${gridCls}`} key={index}>
                  {renderElement(v, values[(v as any).name], onChange)}

                  <div className={`${gridCls} col-span-full`}>
                    {doms && doms.dom && doms.dom.map((dom) => renderElement(dom, values[(dom as any).name], onChange))}
                  </div>
                </div>
              )
            }

            return renderElement(v, values[(v as any).name], onChange)
          })}
          {editable && hasVerifierEmail && renderVerifierEmailTexts()}
        </div>
      </div>
    )
  }

  const renderFormData = () => {
    if (!formData) return null

    const {
      message,
      data: { section, terms },
    } = formData

    return (
      <div>
        {visibleDescription && (
          <>
            <p className="text-2xl mb-2 flex items-center">
              {!!onBack && (
                <>
                  <span className="p-2 cursor-pointer" onClick={() => onBack()}>
                    <ArrowUturnLeftIcon className="w-6 h-6" />
                  </span>{' '}
                </>
              )}
              {message}
            </p>
            {isNew && <p className="text-base mb-4">Confirm that below details are correct</p>}
          </>
        )}
        <div className="mb-4 text-sm">{section.map((v) => renderSection(v))}</div>
        {visibleDescription && <p className="text-xs text-gray-500 mb-4">{terms}</p>}

        {editable && (
          <div className="flex flex-col items-center">
            <div className="max-w-[16rem] mx-auto">
              <Button full className="font-medium py-3.5 px-6" onClick={onSubmit}>
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return renderFormData()
}
