import { ClockIcon, EyeIcon } from '@heroicons/react/24/outline'
import { AsYouType, type CountryCode, getCountryCallingCode } from 'libphonenumber-js'
import { useEffect, useRef, useState } from 'react'
import type { Color } from 'stories/types'
import { InputConvert } from 'utils'

import { countryCodeByCallingCode, PhoneSelector } from './PhoneSelector'

interface InputProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is autofocus
   */
  autoFocus?: boolean
  /**
   * Is autofocus
   */
  autoComplete?: string
  /**
   * Type of Input
   */
  type?: string | undefined
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: string | number
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string

  containerClassName?: string

  inputClassName?: string

  border?: boolean
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Icon component
   */
  icon?: string | JSX.Element | null

  /**
   * onChange
   */
  history?: boolean

  isLegacy?: boolean
  /**
   * Icon component
   */
  onChange: (e: any) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: Function
  showHistory?: () => void
  onKeyPress?: (e: any) => void
  onKeyDown?: (e: any) => void
}

/**
 * Primary UI component for user interaction
 */
export const Input = ({
  color = 'sky',
  disabled = false,
  autoFocus = false,
  autoComplete = '',
  type = 'text',
  title = '',
  placeholder = ' ',
  name = '',
  value = '',
  error = '',
  className = '',
  containerClassName = 'mb-4',
  inputClassName = '',
  border = true,
  hasIcon = false,
  icon = null,
  history = false,
  required = false,
  isLegacy = true,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
  ...props
}: InputProps) => {
  const [isShowContent, setShowContent] = useState(false)
  const isPassword = type == 'password'
  const eyeIcon = <EyeIcon className="w-5 h-5 cursor-pointer hover:text-blue-500" />
  const isPhone = type == 'phone'
  const [countryCode, setCountryCode] = useState<CountryCode>('US')
  const ref = (props as any).ref ? (props as any).ref : useRef<any>()

  const classNames = [
    'block',
    inputClassName.includes('rounded') ? '' : 'rounded',
    inputClassName.includes('py-') ? '' : 'py-1.5',
    inputClassName.includes('px-') ? '' : 'px-2',
    !isPhone ? 'w-full' : 'flex-1',
    'text-sm',
    'text-gray-900',
    'bg-transparent',
    'appearance-none',
    'dark:text-white',
    'focus:outline-none',
    'focus:ring-0',
    border
      ? `border border-gray-300 dark:border-gray-600 dark:focus:border-${color}-500 focus:border-${color}-600`
      : 'border-0',
    'peer',
    hasIcon && icon ? 'pl-10' : '',
    hasIcon && isPassword ? 'pr-10' : '',
    inputClassName,
  ]

  if (value === null) value = ''
  if (isPhone && !value) {
    const callingCode = getCountryCallingCode(countryCode)
    value = `+${callingCode} `
  }
  value = InputConvert({ type }, value)

  useEffect(() => {
    isPhone && updateCodeByValue(`${value}`)
  }, [])

  useEffect(() => {
    if (isPhone && value && typeof value == 'string') updateCodeByValue(value)
  }, [value])

  const changeInput = (e: any) => {
    let _value = e.target.value
    if (type.toLowerCase() === 'email') _value = _value.trim().toLowerCase()

    const { maxLength } = props as any
    if (maxLength && _value.length > maxLength) _value = _value.substring(0, maxLength)
    onChange(_value)

    isPhone && updateCodeByValue(_value)
  }

  const updateCodeByValue = (value: string) => {
    if (!isPhone) return
    value = InputConvert({ type }, value)
    try {
      const asType = new AsYouType()
      asType.input(value)
      const country = asType.getCountry()
      const callingCode = asType.getCallingCode()
      if (country) {
        countryCode != country && setCountryCode(country)
      } else if (callingCode && countryCodeByCallingCode[callingCode]) {
        setCountryCode(countryCodeByCallingCode[callingCode])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onChangeCountryCode = (code: CountryCode) => {
    setCountryCode(code)
    const callingCode = getCountryCallingCode(code)
    onChange(`+${callingCode} `)
    ref && ref?.current?.focus()
  }

  return (
    <div className={`input-container ${containerClassName} `}>
      {isLegacy && title && (
        <p className="font-medium mb-2 text-gray-600 text-sm">
          {title} {required && '*'}
        </p>
      )}
      <div
        className={`group relative w-full ${className} ${disabled ? 'bg-gray-100' : ''} ${
          isPhone ? 'flex items-center gap-2' : ''
        }`}
      >
        {hasIcon && icon && typeof icon === 'string' && (
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <img src={icon} className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
        )}
        {hasIcon && icon && typeof icon !== 'string' && (
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">{icon}</div>
        )}
        {isPhone && (
          <div className="relative phone-selector">
            <PhoneSelector countryCode={countryCode} onChange={onChangeCountryCode} disabled={disabled} />
          </div>
        )}
        <input
          type={isShowContent ? 'text' : type}
          name={name}
          className={`${classNames.join(' ')} ${isPhone ? '!w-[inherit]' : ''}`}
          placeholder={placeholder && `${placeholder} ${required ? '*' : ''}`}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          required={required}
          autoComplete={autoComplete}
          onChange={changeInput}
          onBlur={() => onBlur && onBlur()}
          ref={ref}
          {...props}
        />
        {hasIcon && isPassword && (
          <div
            className={`flex absolute inset-y-0 right-0 items-center pr-3 ${isShowContent ? 'text-blue-500' : ''}`}
            onPointerDown={() => setShowContent(true)}
            onMouseLeave={() => setShowContent(false)}
          >
            {eyeIcon}
          </div>
        )}
        {history && (
          <span className="ml-1 hidden group-hover:inline absolute right-2 -top-4" onClick={() => showHistory()}>
            <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
          </span>
        )}
        {!isLegacy && title && (
          <label
            htmlFor={name}
            className={`absolute top-[10.25px] text-xs text-gray-700 dark:text-gray-400 duration-300 transform -translate-y-6 origin-[0] peer-focus:left-0 peer-focus:text-${color}-700 peer-focus:dark:text-${color}-700 peer-placeholder-shown:text-sm peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:text-xs peer-focus:-translate-y-6 peer-placeholder-shown:leading-3 ml-2 px-1 rounded ${
              disabled && value.toString().length === 0 ? '' : 'bg-white'
            } peer-placeholder-shown:-z-10 peer-focus:z-10 ${hasIcon && icon ? 'pl-8' : ''} ${
              hasIcon && isPassword ? 'pr-8' : ''
            } peer-focus:pl-1 left-0`}
          >
            {title}
            {required && '*'}
          </label>
        )}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1">{error}</p>}
    </div>
  )
}
