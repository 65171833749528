import { ClockIcon, MagnifyingGlassIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import { cloneDeep } from 'lodash'
import { Loan, LoanStatus, LoanStatusText } from 'pages/Marketplace'
import { InvestStatus } from 'pages/Payment'
import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Api from 'services/api'
import { deleteLoan, findLoans, getInvestments, updateLoanData } from 'services/apis'
import {
  Button,
  Input2,
  MultiSelect,
  Pagination,
  PlainTable,
  ProgressBar,
  Select,
  Select2,
  Toggle,
} from 'stories/components'
import {
  confirm,
  filterObject,
  formatDate,
  loanIdentifier,
  normalizeNumber,
  openAuditLog,
  renderHeader,
  thousandSeperator,
  useTitle,
} from 'utils'

import { InvestorsDetail } from './InvestorsDetail'

export const PipelinePage = () => {
  useTitle('Pipeline')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const defaultQuery = query.replace(/_/g, '#')

  const [filters, setFilters] = useState<Record<string, any>>({
    query: defaultQuery,
    isFeatured: '',
    orderBy: 'updatedAt',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [loans, setLoans] = useState<Loan[]>([])
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [selectedLoan, setSelectedLoan] = useState<number | undefined>(undefined)

  useEffect(() => {
    setLoading(false)
    filterLoanData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterLoanData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    setLoading(true)
    const filterStatus = filters.status || {}

    if (!['true', 'false'].includes(filters.isFeatured)) delete filters.isFeatured

    return findLoans(
      {
        ...filters,
        status: filterObject(filterStatus),
        skip: _pageNum * itemCountPerPage,
        count: itemCountPerPage,
      },
      true,
    )
      .then(({ data, total }) => {
        setTotal(total)
        setLoans(data)
      })
      .finally(() => setLoading(false))
  }

  const onChangeFilter = (
    key: 'query' | 'status' | 'isFeatured' | 'orderBy' | 'orderDir' | 'pageNum',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterLoanData(newFilters)
  }

  const onBlurLoanStatus = () => {
    filterLoanData(filters, 0)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeLoan = async (id: number, index: number, key: string, value: any) => {
    if (key == 'status' && loans[index].status == LoanStatus.ACTIVE && value != LoanStatus.ACTIVE) {
      const { data: invests, total: count } = await getInvestments({
        query: `##${id}`,
        status: [InvestStatus.Settled],
        isAll: true,
        count: itemCountPerPage,
      })
      const result = await confirm(
        <div className="mb-4">
          <p className="mb-2">Are you sure to change status?</p>
          <p className="flex items-center justify-center gap-2">
            <b>{LoanStatusText[loans[index].status]}</b> to <b>{LoanStatusText[value]}</b>
          </p>
          {!!count && (
            <>
              <p className="mb-2">{count} Investments are active now and they will be returned.</p>
              <PlainTable
                header={['ID', 'User', 'Amount']}
                data={invests.map((invest: any) => [
                  `#${invest.id}`,
                  invest.user.name,
                  `$${normalizeNumber(invest.amount)}`,
                ])}
              />
            </>
          )}
        </div>,
      )
      if (!result) return
    }
    const newLoans = cloneDeep(loans)
    ;(newLoans[index] as any)[key] = value
    setLoans(newLoans)

    setLoading(true)
    await updateLoanData(id, { [key]: value })
    setLoading(false)
  }

  const onDelete = async (index: number) => {
    const loan = loans[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this loan?
        <br />
        <span className="text-gray-600">Loan ID: #{loan.id}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setLoading(true)
    deleteLoan(loan.id)
      .then(() => {
        toast(`Loan #${loan.id} has been removed.`, { type: 'success' })
        filterLoanData(filters)
      })
      .finally(() => setLoading(false))
  }

  const onOpenLog = (id: number, key: string) => {
    Api.setLoanNumber(`${id}`)
    const options = {
      table: 'Loan',
      field: key,
      keys: {
        field: key,
        loanNumber: id,
      },
    }
    openAuditLog(options)
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterLoanData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
      className: 'whitespace-nowrap',
    })
  }

  return (
    <div className="fullContent pt-8 min-h-[100vh]">
      <p className="text-3xl font-semibold mb-6 flex items-center gap-4">
        Pipeline ({total}) {isLoading && <Loading />}
      </p>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <MultiSelect
          id="loan-status"
          title="Loan Status"
          options={LoanStatusText}
          value={filters.status}
          hasDefaultOption
          defaultOptionText="All"
          defaultSelected={false}
          onChange={(value) => onChangeFilter('status', value, false)}
          onBlur={onBlurLoanStatus}
        />
        <Select2
          id="loan-isFeatured"
          title="Is Featured"
          options={{ false: 'Unfeatured', true: 'Featured' }}
          value={filters.isFeatured}
          hasDefaultOption
          defaultOptionText="All"
          onChange={(value) => onChangeFilter('isFeatured', value)}
        />
        <div className="text-right">
          <Button onClick={() => navigate('/pipeline/new')}>+ Add</Button>
        </div>
      </div>
      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {_renderHeader('No', false, 'no')}
              {_renderHeader('Identifier', true, 'loanNumber')}
              {_renderHeader('Borrower', true, 'borrower')}
              {_renderHeader('Address', true, 'propertyAddress')}
              {_renderHeader('Loan Amount', true, 'loanAmount')}
              {_renderHeader('Status', true, 'status')}
              {_renderHeader('Investable', true, 'canInvest')}
              {_renderHeader('Investors', true, 'investors')}
              {_renderHeader('Progress', true, 'progress')}
              {_renderHeader('Updated At', true, 'updatedAt')}
              {/* {_renderHeader('Featured', true, 'isFeatured')} */}
              {_renderHeader('Distributions', false, 'distributions')}
              {_renderHeader('', false, 'action')}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {loans.map((item, index: number) => {
              const isExpand = selectedLoan === item.id

              return (
                <Fragment key={index}>
                  <tr className={`border-b border-t ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                    <td className="pl-3 py-3">{index + 1 + filters.pageNum * itemCountPerPage}</td>
                    <td className="px-2 py-2">
                      <div className="flex items-center gap-2">
                        <p>{loanIdentifier(item)}</p>
                        <p className={`font-bold text-indigo-500 hover:underline cursor-pointer`}>
                          <Link to={`/pipeline/${item.id}`} className="block">
                            <span>#{item.id}</span>
                          </Link>
                        </p>
                      </div>
                    </td>
                    <td className="px-2 py-2">{item.borrower}</td>
                    <td className="px-2 py-2 min-w-[170px]">{item.propertyAddress}</td>
                    <td className="px-2 py-2 whitespace-nowrap">$ {thousandSeperator(item.loanAmount)}</td>
                    <td className="px-2 py-2 min-w-[130px]">
                      <div className="flex items-center gap-1">
                        <Select
                          id="loan-status-${index}"
                          className="-mb-4"
                          options={LoanStatusText}
                          value={item.status}
                          disabled={item.status == LoanStatus.PENDING}
                          onChange={(value) => onChangeLoan(item.id, index, 'status', value)}
                        />
                        <span className="" onClick={() => onOpenLog(item.id, 'status')}>
                          <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
                        </span>
                      </div>
                    </td>
                    <td className="px-2">
                      {item.status == LoanStatus.ACTIVE && (
                        <div className="flex items-center gap-1">
                          <Toggle
                            id={`canInvest-${index}`}
                            value={item.canInvest}
                            className="scale-75"
                            onChange={(value) => onChangeLoan(item.id, index, 'canInvest', value)}
                            history={false}
                            showHistory={() => onOpenLog(item.id, 'canInvest')}
                          />
                        </div>
                      )}
                    </td>
                    <td className="px-3 py-2">
                      <div className="flex items-center gap-2">
                        {item.activeInvestor} / {item.totalInvestor}
                        <Tooltip content={`View Details`}>
                          <UsersIcon
                            className="w-6 h-6 cursor-pointer text-indigo-600 hover-shadow1 p-1"
                            onClick={() => {
                              if (selectedLoan === undefined || selectedLoan !== item.id) setSelectedLoan(item.id)
                              else setSelectedLoan(undefined)
                            }}
                          />
                        </Tooltip>
                      </div>
                    </td>
                    <td className="px-2 py-2">
                      <ProgressBar progress={item.progress} />
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap">{formatDate(item.updatedAt)}</td>
                    {/* <td className="px-2">
                      <Toggle
                        id={`isFeatured-${index}`}
                        value={item.isFeatured}
                        className="scale-75"
                        disabled={item.status == LoanStatus.PENDING}
                        onChange={(value) => onChangeLoan(item.id, index, 'isFeatured', value)}
                      />
                    </td> */}
                    <td className="px-2 py-2">
                      <div className="flex flex-col gap-1">
                        <p className="text-green-500 font-bold w-fit border-b">
                          ${normalizeNumber(item.approvedProfit!, 2)}
                        </p>
                        <p className="text-gray-500">${normalizeNumber(item.pendingProfit!, 2)}</p>
                      </div>
                    </td>
                    <td className="p-2">
                      <Tooltip content={`Remove`}>
                        <TrashIcon
                          className="w-6 h-6 cursor-pointer transition-all duration-200 text-red-600 hover-shadow1 p-1"
                          onClick={() => onDelete(index)}
                        />
                      </Tooltip>
                    </td>
                  </tr>

                  {isExpand && (
                    <tr>
                      <td />

                      <td colSpan={11} className="pb-6 pt-2">
                        <InvestorsDetail loanId={selectedLoan} onClose={() => setSelectedLoan(undefined)} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end items-center mt-3 mb-3">
        <Pagination
          totalCount={total}
          itemCountPerPage={itemCountPerPage}
          onNavigate={onPageNavigate}
          pageNum={filters.pageNum}
        />
      </div>
    </div>
  )
}
