import { AccountStatus } from 'config'
import type { IdentityVerificationGetResponse, MatchSummaryCode } from 'plaid'
import { useEffect, useState } from 'react'
import { getPlaidIdentityVerificationData } from 'services/apis'
import { Modal } from 'stories/components'
import { formatDate, formatTime, phoneConvertor, plaidIdentityLink, ssnConvertor } from 'utils'

import type { User } from './type'

const renderStatus = (status: MatchSummaryCode | undefined) => {
  if (!status) return null

  return (
    <span
      className={`border rounded-lg px-1 py-0.5 ml-4 text-sm ${
        status == 'match' ? 'border-green-400 text-green-400' : 'border-yellow-400 text-yellow-400'
      }`}
    >
      {status}
    </span>
  )
}

export const renderKycData = (kycData: IdentityVerificationGetResponse, showSSN = false) => {
  if (!kycData) return null

  const { id, user, status, kyc_check, created_at, completed_at } = kycData
  const plaidLink = plaidIdentityLink(id)
  const data: Record<string, any> = {
    'Email:': user.email_address,
    'Name:': (
      <p>
        {user.name?.given_name} {user.name?.family_name} {renderStatus(kyc_check?.name.summary)}
      </p>
    ),
    'Address:': (
      <p>
        {user.address?.street} {user.address?.street2} {user.address?.city}, {user.address?.region},
        {user.address?.postal_code} {user.address?.country} {renderStatus(kyc_check?.address.summary)}
      </p>
    ),
    'Phone Number:': (
      <p>
        {phoneConvertor(user.phone_number)} {renderStatus(kyc_check?.phone_number.summary)}
      </p>
    ),
    'Date of Birth:': (
      <p>
        {user.date_of_birth && formatDate(user.date_of_birth)} {renderStatus(kyc_check?.date_of_birth.summary)}
      </p>
    ),
    'SSN:': (
      <p>
        {ssnConvertor(user.id_number?.value)} {renderStatus(kyc_check?.id_number.summary)}
      </p>
    ),
    'IP Address:': user.ip_address,
    'Created At:': formatTime(created_at),
    'Completed At:': completed_at && formatTime(completed_at),
    'Status:': status,
    Link: (
      <a href={plaidLink} target="_blank" className="text-link">
        Open Plaid
      </a>
    ),
  }
  if (!showSSN) delete data.SSN

  return (
    <>
      {Object.keys(data).map((key) => (
        <div className="grid grid-cols-3 gap-2 mb-2 items-center" key={key}>
          <p className="text-sm">{key}</p>
          <div className={`${key != 'Email:' && 'capitalize'} break-words col-span-2`}>{data[key]}</div>
        </div>
      ))}
    </>
  )
}

export const UserKycDetails = ({ data, onClose }: { data: User; onClose: Function }) => {
  const [isLoading, setLoading] = useState(true)
  const [kycData, setKycData] = useState<IdentityVerificationGetResponse | null>(null)

  useEffect(() => {
    if (data.status < AccountStatus.KycPassed) {
      onClose()
      return
    }
    getPlaidIdentityVerificationData(data.id)
      .then((data) => setKycData(data))
      .finally(() => setLoading(false))
  }, [data])

  return (
    <Modal isOpen loading={isLoading} title={`User KYC Details - ${data.id}`} titleOkay="" onClose={() => onClose()}>
      <div className="text-gray-600 text-md">{kycData && renderKycData(kycData, true)}</div>
    </Modal>
  )
}
